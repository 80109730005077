import React from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"
import { Input as InputAntd } from "antd"
import MaskInput from "../mask-input"

const Input = ({
	type,
	placeholder,
	value,
	maxLength,
	minLength,
	onChange,
	onInput,
	onBlur,
	isBlockPaste,
	isReadOnly,
	...props
}) => (
	<input
		{...props}
		// 
		className="simple-input"
		//
		type={type}
		value={value}
		placeholder={placeholder}
		//
		onBlur={onBlur}
		onInput={onInput}
		onChange={onChange}
		onPaste={(e) => {
			if (isBlockPaste)
				e.preventDefault()
			return false
		}}
		onCopy={(e) => {
			if (isBlockPaste)
				e.preventDefault()
			return false
		}}
		// 
		autoComplete={isBlockPaste ? "new-email" : null}
		autofill={isBlockPaste ? "off" : null}
		// 
		maxLength={maxLength}
		minLength={minLength}
		readOnly={isReadOnly}
	/>
)

const InputPassword = ({ ...props }) => {
	return <InputAntd.Password className="simple-input" {...props} />
}


const InputPhone = ({ ...props }) => {
	// const prefixSelector = (
	// 	<Form.Item name="phone_prefix" initialValue={"55"} noStyle>
	// 		<AntSelect style={{ width: 100 }}>
	// 			<AntSelect.Option value="55">+55</AntSelect.Option>
	// 			<AntSelect.Option value="87">+87</AntSelect.Option>
	// 		</AntSelect>
	// 	</Form.Item>
	// );


	return <MaskInput mask={
		'(00) 00000-0000'
	} {...props}/>
}


Input.defaultProps = {
	placeholder: null,
	//
	maxLength: null,
	minLength: null,
	//
	onBlur: null,
	onInput: null,
	onChange: null,
	// 
	isReadOnly: false,
	isBlockPaste: false,
}

Input.propTypes = {
	type: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	//
	maxLength: PropTypes.number,
	minLength: PropTypes.number,
	//
	onBlur: PropTypes.func,
	onInput: PropTypes.func,
	onChange: PropTypes.func,
	// 
	isReadOnly: PropTypes.bool,
	isBlockPaste: PropTypes.bool,
}

export { InputPassword, InputPhone }
export default Input
