import api from "../../../../service/api";
import routes from "../../../../service/apiRoutes";

export const createReport = (data) => api.post(routes.createReport, data)
export const calcReportPrice = (data) => api.post(routes.calcReportPrice, data)
export const editReport = (id, data) => api.put(`${routes.reportRestrict}?report_id=${id}`, data)
export const listReports = (data) => api.get(`${routes.listReports}`, {params: data})
export const getReport = (reportId) => api.get(routes.report, { params: { report_id: reportId }, noauth: true })
export const getOrder = (orderCode) => api.get(routes.purchase, { params: { order_code: orderCode }})
export const createOrder = (data) => api.post(routes.purchase, data)
export const editOrder = (id, data) => api.put(routes.purchase, data, { params: { id }})
