import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import StateStatus from "../../utils/stateStatus";
import { createReportUnauth } from "./service";


export const createReportUnauthThunk = createAsyncThunk('createReportUnauth/create', async (data) => {
    return createReportUnauth(data)
})

const initialState = {
    status: {
        createReportUnauth: StateStatus.idle,
    },
    data: {
        redirect_url: null,
    },
    errMessage: {
        createReportUnauth: null,
    }
}

export const createReportUnauthSlice = createSlice({
    name: 'createReportUnauthSlice',
    initialState,
    reducers: {
        resetCreateReport: (state, action) => {
            state.status.createReportUnauth = StateStatus.idle;
            state.data.redirect_url = null
        },
    },
    extraReducers: {
        // createReportUnauthThunk
        [createReportUnauthThunk.pending]: (state) => {
            state.status.createReportUnauth = StateStatus.loading
        },
        [createReportUnauthThunk.fulfilled]: (state, action) => {
            state.status.createReportUnauth = StateStatus.succeeded
            state.data.redirect_url = action.payload.data.redirect_url
        },
        [createReportUnauthThunk.rejected]: (state, action) => {
            state.status.createReportUnauth = StateStatus.failed
            state.errMessage = action.error.message
        },

    }
})

export const { resetCreateReport } = createReportUnauthSlice.actions;

export const selectCreateReportUnauthState = (state) => state.createReportUnauth