import React from "react";

//
import "./styles.scss";
import { Row, Upload } from "antd";
import Icons from "../Icons";
import PropTypes from "prop-types"

const { Dragger } = Upload;

const FileUploadBox = ({ accept, label, maxCount, ...props }) => {
  return (
    <Dragger
      accept={accept}
      className="file-upload-box"
      maxCount={maxCount}
      showUploadList={{
        showRemoveIcon: true,
        removeIcon: <Icons icon="delete" />,
      }}
      {...props}
    >
      <Row justify="center" className="icon">
        <Icons icon="download" />
      </Row>
      <Row justify="center" className="label">
        {label || "Solte seu arquivo aqui..."}
      </Row>
    </Dragger>
  );
};

FileUploadBox.defaultProps = {
  accept: null,
  label: false,
  maxCount: 1,
}

FileUploadBox.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.string,
  maxCount: PropTypes.number,
}


export default FileUploadBox;
