/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import {
  selectReportState,
  listReportFilesThunk,
  getReportThunk,
  buildFileReportThunk,
} from "./slices";
import StateStatus from "../../utils/stateStatus";
import {
  Tooltip,
  Button,
  Select,
  Row,
  Col,
  Skeleton,
  message,
  Popover,
  Divider,
  Card,
} from "antd";
import logo from "../../common/images/shortinquestazul.png";
import { urlToNewTabLink } from "../../utils/files";
import { beautifyCpfCnpj } from "../../utils/formatters";
import Icons from "../../common/components/Icons";
import TargetPage from "../target_page";
import { RoudedButton } from "../../common/components/button";
import { getProfileThunk } from "../login/slice";
// import { selectTargetState } from "../target_page/slices";

import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { HeaderDash } from "../../common/components/header";

const buildIconStatus = (status) => {
  switch (status) {
    case "completed":
      return (
        <Tooltip title="Pronto">
          <CheckCircleOutlined className="status-completed" />
        </Tooltip>
      );
    case "error":
      return (
        <Tooltip title="Erro ao criar arquivo">
          <WarningOutlined className="status-error" />
        </Tooltip>
      );
    case "loading":
      return (
        <Tooltip title="Criando arquivo...">
          <LoadingOutlined spin className="status-loading" />
        </Tooltip>
      );
    case "idle":
    default:
      return (
        <Tooltip title="Na fila de criação...">
          <MinusCircleOutlined className="status-idle" />
        </Tooltip>
      );
  }
};

const ReportFilesPopover = ({ report, files }) => {
  return (
    <div>
      <div>
        <Row className="msg-file-report">
          {!files.has_target_completed ? (
            <div style={{ color: "#d97e00" }}>
              Aguarde algum pesquisado deste caso ter sua busca completa para
              solicitar os arquivos...
            </div>
          ) : !files?.has_file_completed ? (
            <div>
              Aguarde a criação dos arquivos abaixo estarem prontos para
              baixá-los...
            </div>
          ) : (
            <div>
              Enquanto alguns arquivos abaixo estão sendo criados, você pode
              baixar os arquivos que já estão prontos...
            </div>
          )}
        </Row>
      </div>
      <div className="targets-file-info">
        <Row style={{ fontWeight: "bold" }} className="title-section">
          <Col span={16}>PESQUISADO</Col>
          <Col span={4} className="file-status-title">
          {files?.target_files?.filter(x => x.pdf?.status === "completed")?.length > 0 ? (
            "PDF"
          ) : null}
          </Col>
          <Col span={4} className="file-status-title">
            EXCEL
          </Col>
        </Row>
        <div className="targets-file-content">
          {files?.target_files?.map((item, idx) => (
            <Row key={idx} className="row-section">
              <Col span={16} className="file-target-name">
                <div>{item.name ? item.name : " - "}</div>
                <div>
                  <Tooltip
                    title={
                      item.status !== "completed"
                        ? "Busca em andamento..."
                        : "Busca completa"
                    }
                  >
                    <span style={{ marginRight: "10px" }}>
                      {item.status !== "completed" ? (
                        <Icons icon="loading" spin />
                      ) : (
                        <Icons icon="checked-circle" />
                      )}
                    </span>
                  </Tooltip>

                  {beautifyCpfCnpj(item.cpf_cnpj)}
                </div>
              </Col>
              {item.pdf?.status === "completed" ? (
                <Col span={4} className="file-btn">
                  <Tooltip title="Baixar PDF">
                    <Button
                      type="primary"
                      icon={<Icons icon="download" />}
                      shape="circle"
                      size={15}
                      onClick={() => {
                        const link = urlToNewTabLink(
                          item.pdf.link,
                          item.filename
                        );
                        link.click();
                      }}
                    />
                  </Tooltip>
                </Col>
              ) : <Col span={4} className="file-btn"></Col>}
              {item.xlsx.status !== "completed" ? (
                <Col span={4} className="file-status-icon">
                  {buildIconStatus(item.xlsx.status)}
                </Col>
              ) : (
                <Col span={4} className="file-btn">
                  <Tooltip title="Baixar EXCEL">
                    <Button
                      type="primary"
                      icon={<Icons icon="download" />}
                      shape="circle"
                      size={15}
                      onClick={() => {
                        const link = urlToNewTabLink(
                          item.xlsx.link,
                          item.filename
                        );
                        link.click();
                      }}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          ))}
        </div>
      </div>
      <Divider />
      <div className="report-file-info">
        {files?.report_files?.filter(x => !x.type?.includes("part")).map((item, idx) => (
          <Row
            style={{ fontWeight: "bold" }}
            key={idx}
            className="title-section"
          >
            <Col span={20}><span className="not-overflow">{item.label ?? ""}</span></Col>
            {item.status !== "completed" ? (
              <Col span={4} className="file-status-icon">
                {buildIconStatus(item.status)}
              </Col>
            ) : (
              <Col span={4} className="file-btn">
                <Tooltip title="Baixar EXCEL com todos os pesquisados">
                  <Button
                    type="primary"
                    icon={<Icons icon="download" />}
                    shape="circle"
                    size={15}
                    onClick={() => {
                      const link = urlToNewTabLink(item.link, item.filename);
                      link.click();
                    }}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        ))}
      </div>
      <Divider />
      <div>
        <Row justify={"center"}>
          <Button
            type="primary"
            disabled={files?.zip_all?.status !== "completed"}
            onClick={() => {
              const item = files?.zip_all;
              const link = urlToNewTabLink(item.link, item.filename);
              link.click();
            }}
          >
            {files?.zip_all?.label?.toUpperCase() ??
              "BAIXAR TODOS ARQUIVOS PRONTOS"}
          </Button>
        </Row>
      </div>
    </div>
  );
};

const ReportPage = () => {
  const params = useParams();
  const reportState = useSelector(selectReportState);
  const [showContext, setShowContext] = useState(false);
  const [heightDivOrientations, setHeightDivOrientations] = useState(null);
  // const [maxHeightDivOrientations, setMaxHeightDivOrientations] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const caseType = reportState.data.report?.case_type?.toUpperCase();

  const content = useMemo(
    () => (
      <ReportFilesPopover
        report={reportState.data.report}
        files={reportState.data.files}
      />
    ),
    [reportState.status.listReportFiles]
  );

  const copyPasswordVideo = () => {
    navigator.clipboard.writeText(reportState.data.report?.video_description_password);
    message.success("Senha copiada para área de trasnferência");
  };

  useEffect(() => {
    dispatch(getProfileThunk());
    dispatch(getReportThunk(params.reportId));
  }, [params.reportId]);

  useEffect(() => {
    if (reportState.status.getReport === StateStatus.succeeded) {
      const caseName = reportState.data.report?.case_name?.toUpperCase();
      document.title = `${caseName}`;
      dispatch(listReportFilesThunk(params.reportId));
    }
  }, [reportState.status.getReport]);

  const getMaxHeihtOrientationsSection = () => {
    const elem  = document.querySelector(".orientations-section")
    if(!elem) return

    let maxH = parseInt(localStorage.getItem("max-h"))

    if(!maxH || elem.offsetHeight > maxH) {
      maxH = elem.offsetHeight
      localStorage.setItem("max-h", parseInt(elem.offsetHeight).toString())
    }

    return maxH
  }

  useEffect(() => {
    localStorage.setItem("max-h", "0")
    const handleScroll = (doc) => {
      let scrollPosition = doc.scrollTop;
      let steps = 1 // Ajuste o divisor para mudar a velocidade da redução
      let initScrollOnPixel = 230

      const maxH = getMaxHeihtOrientationsSection()

      if(!maxH) return

      if(window.innerWidth < 600) { // Ajuste para mobile
        initScrollOnPixel = 580
        steps = 2
      } 

      scrollPosition = scrollPosition - initScrollOnPixel
      
      if(scrollPosition < 1){ 
        setHeightDivOrientations(maxH);
        return
      }

      const newHeight = maxH - (scrollPosition / steps); 

      if (newHeight < 30) { // Altura mínima da div
        setHeightDivOrientations(30);
      } else {
        setHeightDivOrientations(newHeight);
      }
    };

    const doc = document.querySelector(".report-content")
    const listener = () => handleScroll(doc)

    doc.addEventListener('scroll', listener)
    return () => doc.removeEventListener('scroll', listener)
  }, []);

  return (
    <>
      <HeaderDash redirectWithNoAuth={false} />
      <div className="report-page" data-case-type={caseType}>
        <div className="report-content">
          <img id="logo" alt="" className="logo" src={logo} />
          {reportState.status.getReport === StateStatus.succeeded ? (
            <>
              <Row
                gutter={[16, 24]}
                className="report-header"
                justify="space-between"
                align="top"
              >
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="report-title">
                    <label>Caso</label>
                    {reportState.data.report?.case_name?.toUpperCase()}
                  </div>
                  <div className="report-info">
                    <div>
                      <label>Cliente</label>
                      <span>{reportState.data.report?.client?.name}</span>
                    </div>
                    {/* <div>
                    <label>E-mail</label>
                    <span>{reportState.data.report?.client?.email}</span>
                  </div> */}
                    {/* <div>
                                <label>CPF/CNPJ</label>
                                <span>{beautifyCpfCnpj(reportState.data.report?.client?.cpf_cnpj)}</span>
                            </div> */}
                    <div>
                      <label>Tipo</label>
                      <span className="case-type-value">
                        {reportState.data.report?.case_type_display}
                      </span>
                    </div>
                    <div>
                      <label>Criado em</label>
                      <span>{reportState.data.report?.created_at}</span>
                    </div>
                    <div
                      onClick={() => setShowContext(!showContext)}
                      className="label-show-context"
                      // data-has-context={reportState.data.report?.context_values?.length > 0}
                      data-has-context={true}
                    >
                      <label>Contexto</label>
                      <span>
                        {reportState.data.report?.context_display}
                        <Icons
                          onClick={() => setShowContext(!showContext)}
                          icon={
                            showContext
                              ? "up-arrow-circle"
                              : "down-arrow-circle"
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div
                    className={`context-info ${!showContext ? "closed" : ""}`}
                  >
                    <div>
                      <label>Pesquisados</label>
                      <ul>
                        {reportState.data.report?.targets?.map((item, idx) => (
                          <li key={idx}>
                            {beautifyCpfCnpj(item.cpf_cnpj)}
                            {item.name ? `, ${item.name}` : ""}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {reportState.data.report?.context_values?.map(
                      (item, idx) => (
                        <div key={idx}>
                          <label>{item.label}</label>
                          {item.values ? (
                            <ul>
                              {item.values.map((value, i) => (
                                <li key={i}>{value}</li>
                              ))}
                            </ul>
                          ) : (
                            <span>{item.value}</span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Row
                    justify="center"
                    className="report-status-info"
                    align="middle"
                  >
                    {reportState.data.report.status === "completed" ? (
                      <>
                        <Col span={4}>
                          <Icons
                            icon="checked-circle"
                            title={reportState.data.report.status_display}
                          />
                        </Col>
                        <Col span={20}>
                          <p>
                            A buscas estão executadas, você pode analisar abaixo
                            os dados coletados dos pesquisados ou através dos
                            arquivos anexados ao caso.
                          </p>
                        </Col>
                      </>
                    ) : reportState.data.report.status === "released" ? (
                      <>
                        <Col span={4}>
                          <Icons
                            icon="circle-alert"
                            title={reportState.data.report.status_display}
                          />
                        </Col>
                        <Col span={20}>
                          <p>
                            A pré-visualização das buscas foi liberada, mas
                            ainda há pendências a serem entregues. Aguarde o
                            caso estar totalmente completo para concluir sua
                            pesquisa.
                          </p>
                        </Col>
                      </>
                    ) : reportState.data.report.billing_mode !== "mensal" &&
                      (!reportState.data.report.order ||
                        ["idle", "waiting", "error"].includes(
                          reportState.data.report.order.status
                        )) ? (
                      <>
                        <Col span={4}>
                          <Icons
                            icon="circle-alert"
                            title={reportState.data.report.status_display}
                          />
                        </Col>
                        <Col span={20}>
                          <p>
                            <Link to="/dashboard">Clique aqui </Link> para
                            acessar a plataforma e realizar o pagamento desta
                            pesquisa.
                          </p>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={4}>
                          <Icons
                            icon="loading"
                            spin
                            title={reportState.data.report.status_display}
                          />
                        </Col>
                        <Col span={20}>
                          <p>
                            Aguarde as buscas terminarem para analisar o caso
                            completo. Você receberá um e-mail em breve, quando
                            as buscas estiverem executadas.
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} className="report-actions">
                  <Row gutter={[16, 16]} align="top">
                    {!["docs", "mapa_calor"].includes(
                      reportState.data.report?.case_type
                    ) ? (
                      <>
                        <Col span={24}>
                          <Popover
                            placement="bottomLeft"
                            content={content}
                            title="Exportar arquivos"
                            trigger="click"
                            style={{ width: "400px" }}
                          >
                            <RoudedButton
                              className={
                                !reportState.data.files?.has_file_requested
                                  ? "request-file-btn"
                                  : ""
                              }
                              icon={<Icons icon="zip-file" />}
                              onClick={() => {
                                if (
                                  !reportState.data.files?.has_file_requested &&
                                  reportState.data.files?.has_target_completed
                                ) {
                                  dispatch(
                                    buildFileReportThunk({
                                      reportId: params.reportId,
                                    })
                                  );

                                  message.loading(
                                    "Aguarde a criação dos arquivos, em breve estarão disponíveis para baixá-los.",
                                    5
                                  );

                                  setTimeout(() => {
                                    dispatch(
                                      listReportFilesThunk(params.reportId)
                                    );
                                  }, 5000);
                                }

                                dispatch(listReportFilesThunk(params.reportId));
                              }}
                            >
                              {reportState.data.files?.has_file_requested
                                ? "Exportar arquivos"
                                : "Solicitar arquivos"}
                            </RoudedButton>
                          </Popover>
                        </Col>

                        <Col span={24}>
                          <Tooltip title="Visualizar grafo com todos os pesquisados">
                            <Button
                              className="rounded-button"
                              onClick={() => {
                                // dispatch(resetGraphState())
                                navigate(`/report/${params.reportId}/graph`);
                              }}
                              icon={<Icons icon="graph" />}
                            >
                              Acessar Grafos
                            </Button>
                          </Tooltip>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </Col>
              </Row>
              {!["docs", "mapa_calor"].includes(
                reportState.data.report?.case_type
              ) ? (
                <Row
                  justify="space-between"
                  gutter={[24, 24]}
                  className="orientations-section"
                  style={{
                    height:
                      heightDivOrientations !== null
                        ? `${heightDivOrientations}px`
                        : "auto",
                  }}
                >
                  <Col xs={24} sm={24} md={12}>
                    <div className="section-title">
                      <h4>
                        Orientações{" "}
                        {reportState.data.report?.video_description_link ||
                        reportState.data.report?.video_description_embed
                          ? "e Parecer Técnico"
                          : ""}
                      </h4>
                    </div>
                    <ol>
                      {reportState.data.report?.video_description_embed ? (
                        !reportState.data.report?.video_description_password ? (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos assistir ao vídeo do parecer
                            técnico anexado em seguida.
                          </li>
                        ) : (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos assistir ao vídeo do parecer
                            técnico anexado em seguida. Insira a senha{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={copyPasswordVideo}
                            >
                              {
                                reportState.data.report
                                  ?.video_description_password
                              }
                            </span>
                            <span className="copy-icon">
                              <Icons icon="copy" onClick={copyPasswordVideo} />
                            </span>{" "}
                            para iniciar o vídeo;
                          </li>
                        )
                      ) : reportState.data.report?.video_description_link ? (
                        reportState.data.report?.video_description_password ? (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos acessar o{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={() => {
                                window.open(
                                  reportState.data.report
                                    ?.video_description_link,
                                  "__blank"
                                );
                              }}
                            >
                              {" "}
                              link do parecer técnico{" "}
                            </span>{" "}
                            para assistir ao vídeo de apresentação. Insira a
                            senha{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={copyPasswordVideo}
                            >
                              {
                                reportState.data.report
                                  ?.video_description_password
                              }
                            </span>
                            <span className="copy-icon">
                              <Icons icon="copy" onClick={copyPasswordVideo} />
                            </span>{" "}
                            para inciar o vídeo;
                          </li>
                        ) : (
                          <li>
                            Primeiramente, para entender todos os dados
                            apresentados, sugerimos acessar o{" "}
                            <span
                              className="text-highlight clicable"
                              onClick={() => {
                                window.open(
                                  reportState.data.report
                                    ?.video_description_link,
                                  "__blank"
                                );
                              }}
                            >
                              link do parecer técnico
                            </span>{" "}
                            para assistir ao vídeo de apresentação;
                          </li>
                        )
                      ) : null}
                      <li>
                        Navegue em todos os pesquisados disponíveis neste REPORT
                        clicando sobre{" "}
                        <span className="text-highlight">
                          "Selecione o pesquisado..."
                        </span>{" "}
                        logo abaixo;
                      </li>
                      <li>
                        Obtenha todas estas pesquisas prontas em formato EXCEL
                        clicando em{" "}
                        <span className="text-highlight">
                          {reportState.data.files?.has_file_requested
                            ? '"EXPORTAR ARQUIVOS"'
                            : '"SOLICITAR ARQUIVOS"'}
                        </span>
                        , disponível no canto superior direito.
                      </li>
                    </ol>
                  </Col>
                  {reportState.data.report?.video_description_embed ? (
                    <Col xs={24} sm={24} md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            reportState.data.report?.video_description_embed,
                        }}
                      ></div>
                    </Col>
                  ) : null}
                </Row>
              ) : null}

              {!["docs", "mapa_calor"].includes(
                reportState.data.report?.case_type
              ) ? (
                <div className="report-targets">
                  {reportState.data.report?.targets && (
                    <div
                      className="font-normal-14-600"
                      style={{ color: "#6F6F6F", marginBottom: "10px" }}
                    >
                      {`${reportState.data.report?.targets?.length} pesquisado(s)`}
                    </div>
                  )}
                  <Select
                    showSearch
                    placeholder="Selecione o pesquisado..."
                    optionFilterProp="children"
                    // defaultValue={params.targetId} // Sempre exibir placeholder
                    onChange={(id) => {
                      if (id !== params.targetId) {
                        navigate(`/report/${params.reportId}/target/${id}`);
                        window.location.reload();
                      }
                    }}
                    // onSearch={() => {}}
                    filterOption={(input, option) => {
                      const children = option?.label.props.children;
                      const label = `${children[2]} ${children[3]}`.replace(
                        /[./-]/g,
                        ""
                      );

                      return label.toLowerCase().includes(input.toLowerCase());
                    }}
                    options={reportState.data.report?.targets?.map(
                      (item, idx) => ({
                        value: item.id,
                        label: (
                          <span className="select-target-item">
                            <span className="counter">{idx + 1}</span>
                            {item.status !== "completed" ? (
                              <Icons icon="loading" spin />
                            ) : (
                              <Icons icon="checked-circle" />
                            )}
                            {beautifyCpfCnpj(item.cpf_cnpj)}
                            {item.name ? " - " + item.name : ""}
                          </span>
                        ),
                      })
                    )}
                  />
                </div>
              ) : null}

              {reportState.data?.files?.report_files?.filter(
                (x) =>
                  x.status === "completed" &&
                  !['xlsx', 'zip_xlsx', 'xlsx_part', 'zip_pdf'].includes(x.type)
              )?.length > 0 ? (
                <Row justify={"center"} className="files-attachment">
                  <Col xs={24} sm={24} md={8}>
                    <Card>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <h4 style={{ textTransform: "uppercase" }}>
                            Arquivo(s) anexo(s) ao{" "}
                            {reportState.data?.report?.case_type_display}
                          </h4>
                        </Col>
                        {reportState.data?.files?.report_files
                          ?.filter(
                            (x) =>
                              x.status === "completed" &&
                              ![
                                'xlsx',
                                'zip_xlsx',
                                'xlsx_part',
                                'zip_pdf',
                              ].includes(x.type)
                          )
                          .map((item, idx) => (
                            <Col key={idx} span={24}>
                              <Row
                                style={{ fontWeight: "bold" }}
                                className="title-section"
                                justify={"space-between"}
                              >
                                <Col span={24} className="file-infos">
                                  <div className="file-type">
                                    {idx + 1} - {item.type_display}
                                  </div>
                                  <div className="file-created-at">
                                    Atualizado em{" "}
                                    {item.updated_at ?? item.created_at}
                                  </div>
                                </Col>
                                <Col span={18}>
                                  <span className="filename not-overflow">
                                    {item.label ?? ""}
                                  </span>
                                </Col>
                                {item.status === "completed" ? (
                                  <Col span={4} className="file-btn">
                                    <Tooltip title="Baixar arquivo">
                                      <Button
                                        type="primary"
                                        icon={<Icons icon="download" />}
                                        shape="circle"
                                        size={15}
                                        onClick={() => {
                                          const link = urlToNewTabLink(
                                            item.link,
                                            item.filename
                                          );
                                          link.click();
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          ))}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ) : ["docs", "mapa_calor"].includes(
                  reportState.data.report?.case_type
                ) ? (
                <Row justify={"center"}>
                  <Col xs={24} sm={24} md={8}>
                    <Card>
                      Nenhum arquivo foi anexado ao{" "}
                      {reportState.data?.report?.case_type_display}
                    </Card>
                  </Col>
                </Row>
              ) : null}

              {params.targetId &&
              !["docs", "mapa_calor"].includes(
                reportState.data.report?.case_type
              ) ? (
                <div className="view-target">
                  <TargetPage />
                </div>
              ) : null}
            </>
          ) : (
            <div className="loading-text">
              <Skeleton active />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportPage;
