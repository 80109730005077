import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { buildFileReport, getReport, getReportFiles } from "./service"


export const getReportThunk = createAsyncThunk("reportPage/get", async (targetId) => {
    const response = await getReport(targetId)
    return response.data
})

export const listReportFilesThunk = createAsyncThunk("reportPage/list-files", async (targetId) => {
    const response = await getReportFiles(targetId)
    return response.data
})

export const buildFileReportThunk = createAsyncThunk("reportPage/build-file-target", async ({reportId}) => {
    const response = await buildFileReport(reportId)
    return response.data
})



const initialState = {
    status: {
        getReport: StateStatus.idle,
        listReportFiles: StateStatus.idle,
        buildFileReport: StateStatus.idle,
    },
    data: {
        report: {
            id: null,
            status: null,
            services: [],
            cpf_cnpj: null,
            requester_email: null
        },
        files: {},
    },
    errMessage: {
        getReport: null,
        listReportFiles: null,
        buildFileReport: null,
    }
}

export const reportPageSlice = createSlice({
    name: "reportPage",
    initialState,
    extraReducers: {

        // report thunk
        [getReportThunk.pending]: (state) => {
            state.status.getReport = StateStatus.loading
        },
        [getReportThunk.fulfilled]: (state, action) => {
            state.data.report = action.payload
            state.status.getReport = StateStatus.succeeded
        },
        [getReportThunk.failed]: (state, action) => {
            state.status.getReport = StateStatus.failed
            state.errMessage.getReport = action.error.message
        },

        // build xlsx thunk
        [buildFileReportThunk.pending]: (state, action) => {
            state.status.buildFileReport = StateStatus.loading
        },
        [buildFileReportThunk.fulfilled]: (state, action) => {
            state.status.buildFileReport = StateStatus.succeeded
        },
        [buildFileReportThunk.failed]: (state, action) => {
            state.status.buildFileReport = StateStatus.failed
            state.errMessage.buildFileReport = action.error.message
        },

        // report thunk
        [listReportFilesThunk.pending]: (state) => {
            state.status.listReportFiles = StateStatus.loading
        },
        [listReportFilesThunk.fulfilled]: (state, action) => {
            state.data.files = action.payload
            state.status.listReportFiles = StateStatus.succeeded
        },
        [listReportFilesThunk.failed]: (state, action) => {
            state.status.listReportFiles = StateStatus.failed
            state.errMessage.listReportFiles = action.error.message
        },
    }
})

export const selectReportState = (state) => state.reportPage