/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Row, Form, Modal, message } from "antd";
import "./styles.scss";
import { BlueSquareButton } from "../../../../common/components/button";
import Input from "../../../../common/components/input";
import SelectSearchType, { searchOptions } from "../../../../common/components/select_search_type";
import InputCpfCnpjList from "./components/input-cpfcnpj-list";
import InputDocsList from "./components/input-docs-list";
import ContextOptions from "./components/context-options";
import CustomServicesOptions from "./components/custom-services-options";
import {
  calcReportPriceThunk,
  selectSearchListState,
  resetCalcReportPrice,
  resetCreateReport,
} from "../../pages/list_reports/slice";
import StateStatus from "../../../../utils/stateStatus";
import { useDispatch, useSelector } from "react-redux";
import { getProfileThunk, loginSelector } from "../../../login/slice";

const CreateReportModal = ({ editReport, onSuccess, onCancel }) => {
  const [reportInfo, setReportInfo] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loginState = useSelector(loginSelector);
  const state = useSelector(selectSearchListState);

  useEffect(() => {
    setFieldsToEdit(editReport);

    if(!loginState.data.client?.id) {
      dispatch(getProfileThunk());
    }
  }, [editReport]);


  useEffect(() => {
    if (state.status.calcReportPrice === StateStatus.succeeded) {
      dispatch(resetCalcReportPrice());
      onSuccess();
    }

    if (state.status.calcReportPrice === StateStatus.failed) {
      message.error("Falha ao calcular valor do caso.");
      dispatch(resetCalcReportPrice());
    }
  }, [state.status.calcReportPrice]);

  useEffect(() => {
    if (state.status.createReport === StateStatus.succeeded) {
      dispatch(resetCreateReport());
      onCancel();
    }

    if (state.status.createReport === StateStatus.failed) {
      message.error("Falha ao criar o caso.");
      dispatch(resetCreateReport());
      
    }
  }, [state.status.createReport]);


  const onFinish = (values) => {
    values.client_id = loginState.data.client?.id;
    dispatch(calcReportPriceThunk(values));
  };

  const disclaimer = (disclaimerTitle, disclaimerText) => {
    return (
      <div className="label">
        <div className="disclaimer-title">{disclaimerTitle}</div>
        <div className="disclaimer">
          <div className="disclaimer-bar"></div>
          <div className="disclaimer-text">{disclaimerText}</div>
        </div>
        {/* {labelText} */}
      </div>
    );
  };

  const disclaimerRender = (caseType) => {
    switch (caseType) {
      case "docs":
        return disclaimer(
          <>
            Você selecionou o <strong>"DOCS"</strong>
          </>,
          "Este serviço é ideal caso você precise buscar ou adquirir documentos como matrículas, escrituras e procurações."
        );
      case "preventivo":
        return disclaimer(
          <>
            Você selecionou o <strong>"REPORT PREVENTIVO"</strong> que tem um
            preço de <strong>R$ 35,00</strong> por CPF/CNPJ
          </>,
          "O diferencial do preventivo é a criação de um parecer final via IA e algoritmos cognitivos, nele resumimos tudo que é importante para a sua tomada de decisão e preveni-lo de possíveis fraudes antes de iniciar uma nova relação com a pessoa ou empresa pesquisada. O preventivo não realiza busca de bens materiais do pesquisado, como veículos e imóveis."
        );

      case "custom":
        return disclaimer(
          <>
            Você selecionou o <strong>"REPORT PERSONALIZADO"</strong> que tem um
            preço de <strong>R$ 10,90</strong> por CPF/CNPJ
          </>,
          "Selecione as buscas mais importantes para investigar uma pessoa, com este REPORT PERSONALIZADO você consegue ter um custo reduzido selecionando somente as informações necessárias."
        );

      case "simples":
        return disclaimer(
          <>
            Você selecionou o <strong>"REPORT SIMPLES"</strong> que tem um preço
            de <strong>R$ 68,00</strong> por CPF/CNPJ
          </>,
          "O Report Simples te entrega a busca mais completa, com ele você tem acesso a todas as informações disponíveis sobre a pessoa ou empresa pesquisada. A pesquisa imobiliária estadual é apenas em São Paulo."
        );

      case "smart":
        return disclaimer(
          <>
            Você selecionou o <strong>"REPORT SMART"</strong> que tem um preço
            de <strong>R$ 578,00</strong> por CPF/CNPJ
          </>,
          "Uma solução integrada para análise de ativos e perfis jurídicos, com análise completa de processos judicias, busca de ativos imobiliários por região do pesquisado e relacionamentos juntamente com um parecer técnico."
        );

      case "smart_pro":
        return disclaimer(
          <>
            Você selecionou o <strong>"REPORT SMART PRO"</strong> que tem um
            preço de <strong>R$ 997,00</strong> por CPF/CNPJ{" "}
          </>,
          "A mais completa solução integrada para análise de ativos e perfis jurídicos, com análise completa de processos judicias, busca de ativos imobiliários em todo o Brasil com mapa de calor e relacionamentos juntamente com um parecer técnico."
        );

      case "mapa_calor":
        return disclaimer(
          <>
            Você selecionou o <strong>"MAPA DE CALOR"</strong> que tem um preço
            de <strong>R$ 415,00</strong> por CPF/CNPJ{" "}
          </>,
          "A solução para encontrar escrituras e procurações do investigado em todo o terrintório nacional."
        );

      default:
        return null;
    }
  };

  const setFieldsToEdit = (data) => {
    if (data) {

      const targets = data?.targets?.map((item) => ({
        cpf_cnpj: item.cpf_cnpj,
      }));

      const doc_id = data?.docs_products?.length > 0
            ? data?.docs_products[0].product
            : null

      let doc
      if(doc_id) {
        const docsOptions = searchOptions.filter(x => x.label === "Busca de documentos")[0]
        doc = docsOptions.options.filter(y => y.data.doc === doc_id)[0]
      }

      setReportInfo({
        case_name: data?.case_name,
        case_type: data?.case_type,
        case_type_display: data?.case_type_display,
        case_option: data?.case_option,
        doc: doc_id,
        context: data.context,
        context_data: (data?.context) ? data[data?.context] : null,
        states: doc_id ? doc.data.states : [],
        excludeStates: doc_id ? doc.data.exclude_states : [],
      });

      form.setFieldsValue({
        targets: targets,
        docs_products: data?.docs_products,
        case_option: data?.case_option,
        context: data?.context,
        case_name: data?.case_name,
        services: data?.services,
      });

      if (data?.context && data[data?.context]) {
        form.setFieldValue([data.context], data[data.context]);
      }
    }
  };
  const resetSelectionsForm = () => {
    form.setFieldValue(["context"], null);
    form.setFieldValue(["docs_products"], [{ qtd: null, product: null, city: null, uf: null, livro: null, num_matricula: null }]);
    form.setFieldValue(["general", "focus_city"], []);
    form.setFieldValue(["judicial_recovery", "focus_city"], []);
    form.setFieldValue(
      ["due_dilligence_merge_and_acquisition", "focus_city"],
      []
    );
    form.setFieldValue(["due_dilligence_general", "focus_city"], []);
    form.setFieldValue(["compliance", "focus_city"], []);
    form.setFieldValue(["divorce", "focus_city"], []);
    form.setFieldValue(["credit_analysis", "focus_city"], []);
    form.setFieldValue(["docs", "focus_city"], []);
  };

  return (
    <Modal
      className="create-report"
      title={"Solicitar nova pesquisa"}
      open={true}
      onCancel={onCancel}
      footer={[
        <Row justify="end" style={{ marginTop: "30px" }}>
          {/* <BlueSquareOulinedButton
            onClick={onCancel}>
            Cancelar
          </BlueSquareOulinedButton> */}

          <BlueSquareButton
            onClick={() => form.submit()}
            isLoading={
              state.status.calcReportPrice === StateStatus.loading ||
              state.status.createReport === StateStatus.loading
            }
            disabled={
              state.status.calcReportPrice === StateStatus.loading ||
              state.status.createReport === StateStatus.loading
            }
          >
            Revisar caso
          </BlueSquareButton>
        </Row>,
      ]}
      width={"900px"}
    >
      <div
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "16px",
          marginBottom: "30px",
          marginTop: "15px",
        }}
      >
        {loginState.data.client?.billing_mode !== "mensal" ? (
          "ETAPA 1 de 3: Escolher pesquisa"
        ) : (
          "ETAPA 1 de 2: Escolher pesquisa"
        )}
      </div>
      <Form
        form={form}
        layout={"vertical"}
        // initialValues={{
        //   // targets: reportInfo?.targets,
        //   targets: [{cpf_cnpj: "113.671.836-26"}, {cpf_cnpj: "113.671.836-26"}],
        // }}
        scrollToFirstError={true}
        onFinish={(values) => {
          let docs = [];
          if (reportInfo.case_type === "docs") {
            docs = values.docs_products?.map((item) => {
              return {
                ...item,
                product: reportInfo.doc,
              };
            });
          }

          const data = {
            case_type: reportInfo.case_type,
            case_type_display: reportInfo.case_type_display,
            context: reportInfo.context,
            case_option: reportInfo.case_option,
            case_name: values.case_name ?? "",
            targets: values.targets,
            docs_products: docs,
            services: values.services,
          };

          if (values.context) {
            data.context = values.context;
            data[values.context] = values[values.context];
          }

          if (editReport) {
            data.id = editReport.id;
          }

          onFinish(data);
        }}
      >
        <Form.Item
          name={"case_option"}
          // label="1. Vamos começar: Qual é o seu objetivo?"
          rules={[
            {
              required: true,
              message: (
                <div className="normal-12">Informe uma opção válida!</div>
              ),
            },
          ]}
        >
          <div className="modal-step-name">
            <strong>1.</strong> Vamos Começar: Qual o seu objetivo?
          </div>
          <SelectSearchType
            defaultValue={reportInfo?.case_option ?? undefined}
            listHeight={400}
            onChange={(option) => {
              resetSelectionsForm();

              setFieldsToEdit({
                case_type: option.data.case_type.value,
                case_type_display: option.data.case_type.label,
                case_name: editReport?.case_name,
                context: option.data.context,
                case_option: option.value,
                states: option.data.states,
                excludeStates: option.data.exclude_states,
                targets: option.data.case_type.value !== "docs" ? editReport?.targets : [],
                docs_products: option.data.case_type.value === "docs" ? [ { product: option.data.doc }] : [],
              });
            }}
          />
        </Form.Item>
        <br />

        {reportInfo?.case_type ? disclaimerRender(reportInfo.case_type) : null}
        <br />

        {reportInfo?.case_type ? (
          <>
            <div className="modal-step-name">
              <strong>2.</strong> Dê um nome para esta solicitação: <br/>
              <p style={{fontSize: "11px", lineHeight: "14px"}}>
              Exemplos: <br/>Caso Família Silva; Caso João Gabriel Silva; Caso Nome Empresa LTDA;<br/>
              </p>
            </div>
            <Form.Item
              name={"case_name"}
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Campo obrigatório</div>,
                },
                {
                  min: 15,
                  message: (
                    <div className="normal-12">Mínimo de 15 caracteres</div>
                  ),
                },
                {
                  max: 150,
                  message: (
                    <div className="normal-12">
                      A identificação não pode ultrapassar 150 caracteres.
                    </div>
                  ),
                },
              ]}
            >
              <Input placeholder="Ex.: Caso Família Silva; Caso João Gabriel Silva; Caso Nome Empresa LTDA;" />
            </Form.Item>
            <br />

            {reportInfo?.case_type === "docs" ? (
              <InputDocsList
                form={form}
                states={reportInfo.states}
                excludeStates={reportInfo.excludeStates}
                name={"docs_products"}
                service={reportInfo.doc}
                label={
                  <div className="modal-step-name">
                    <strong>3.</strong> Adicione pessoas físicas e jurídicas a serem pesquisadas:
                  </div>
                }
                // initialValue={reportInfo.docs_products}
              />
            ) : (
              <InputCpfCnpjList
                form={form}
                name={"targets"}
                label={
                  <div className="modal-step-name">
                    <strong>3.</strong> Adicione pessoas físicas e jurídicas a serem pesquisadas:
                  </div>
                }
                // initialValue={reportInfo.targets}
              />
            )}

            {reportInfo?.case_type === "custom" ? (
              <>
                <div className="modal-step-name">
                <strong>4.</strong> Selecione ao menos uma informação extra a ser buscada:
                </div>
                <span style={{ fontSize: "12px" }}>
                  * Dados de cadastro (nome completo, endereços, contatos,
                  Receita Federal) e o ecossistema do pesquisado{" "}
                  <strong>já estão inclusos.</strong>
                </span>
                <br />
                <br />
                <CustomServicesOptions name={"services"} label="" />
              </>
            ) : null}

            {["smart", "smart_pro"].includes(reportInfo?.case_type) ? (
              <>
                <br />
                <div className="modal-step-name">
                  <strong>4.</strong> Selecione qual o contexto da pesquisa
                </div>
                <ContextOptions
                  form={form}
                  initialValue={reportInfo?.context}
                  initialData={reportInfo?.context_data}
                  name={"context"}
                  // label="4. Selecione qual o contexto da pesquisa"
                  caseType={reportInfo?.case_type}
                />
              </>
            ) : null}
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

CreateReportModal.propTypes = {
  editReport: propTypes.object,
  onSuccess: propTypes.func,
  onCancel: propTypes.func,
  isLoading: propTypes.bool,
};

export default CreateReportModal;
