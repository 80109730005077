import StateStatus from "../../utils/stateStatus"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getGraph } from "./service"


export const getGraphThunk = createAsyncThunk("graph/get", async (targetId) => {
    const response = await getGraph(targetId)
    return response.data
})


const initialState = {
    status: {
        getGraph: {},
        getAllGraph: StateStatus.idle,
    },
    errMessage: {
        getGraph: null
    },
    graph: {
        nodes: [],
        edges: [],
    },
    cache: {
        nodes: [],
        edges: []
    }
}


export const graphSlice = createSlice({
    name: "Graph",
    initialState,
    reducers: {
        resetGraphState: (state) => {
            state.status.getGraph = {}
            state.status.getAllGraph = StateStatus.idle
            state.graph.nodes = []
            state.graph.edges = []
        },
    },
    extraReducers: {
        [getGraphThunk.pending]: (state, action) => {
            state.status.getAllGraph = StateStatus.loading
            state.status.getGraph[action.meta.arg] = StateStatus.loading
        },
        [getGraphThunk.fulfilled]: (state, action) => {
            state.status.getGraph[action.meta.arg] = StateStatus.succeeded
            state.graph.nodes.push(...action.payload.graph.nodes.filter(node => !state.cache.nodes.includes(node.id)))
            state.graph.edges.push(...action.payload.graph.edges.filter(edge => !state.cache.edges.includes(edge.id)))
            
            state.cache.nodes.push(...action.payload.graph.nodes.map(node => node.id))
            state.cache.edges.push(...action.payload.graph.edges.map(edge => edge.id))

            const completed = Object.values(state.status.getGraph).filter(
                (x) => x === StateStatus.succeeded
            ).length 

            if(completed === Object.values(state.status.getGraph).length) {
                state.status.getAllGraph = StateStatus.succeeded  
                
                const nodeLibrary = {}
                state.graph.nodes.forEach((item) => nodeLibrary[item.id] = item)
                state.graph.nodeLibrary = nodeLibrary
            }
        },
        [getGraphThunk.failed]: (state, action) => {
            state.status.getGraph[action.meta.arg] = StateStatus.failed
            state.status.getAllGraph = StateStatus.failed

            state.errMessage.getGraph = action.error.message
        }
    }
})

export const {
    resetGraphState
} = graphSlice.actions
export const selectGraphState = (state) => state.graph



























