/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Col, Form, Modal, Result, Row } from "antd";
import Input from "../../common/components/input";

import "./style.scss";
import logo from "../../common/images/logo_azul.png";
import { BlueSquareButton, RoudedButton } from "../../common/components/button";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordSelector,
  changePasswordThunk,
  resetChangePasswordState,
} from "./slice";
import StateStatus from "../../utils/stateStatus";
import { useNavigate, useParams } from "react-router-dom";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [showModal, updateShowModal] = useState();
  // const [passValidation, updatePassValidation] = useState({});

  const params = useParams();

  const passwordSelector = useSelector(changePasswordSelector);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    dispatch(
      changePasswordThunk({
        token: params.token,
        new_password: values.password,
      })
    );
  };

  // const passwordValidation = (value) => {
  //   const result = {
  //     hasEigthLength: value.length >= 8,
  //     hasOneNumber: value.search(/\d/g) !== -1,
  //     hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
  //     hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
  //   };

  //   updatePassValidation(result);
  //   return result;
  // };

  useEffect(() => {
    switch (passwordSelector.status.changePassword) {
      case StateStatus.failed:
        updateShowModal("error-change");
        dispatch(resetChangePasswordState());
        break;

      case StateStatus.succeeded:
        // navigate('/home')
        navigate("/dashboard");
        break;

      default:
        break;
    }
  }, [passwordSelector.status.changePassword]);

  return (
    <>
      <div className="login-container">
        <div className="login-logo">
        </div>
        <div className="login-form">
          <div className="login-form-logo">
            <img src={logo} alt="Inquest" />
          </div>

          <div className="auth-page-name">
            ALTERAR SENHA
          </div>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Row>
              <Col span={16} offset={4}>
                <Form.Item
                  label="Senha"
                  name="password"
                  rules={[
                    { required: true, message: "Favor, digite a senha!" },
                  ]}
                  hasFeedback
                >
                  <Input
                    type="password"
                    // onChange={(event) => {
                    //   passwordValidation(event.target.value);
                    // }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={16} offset={4}>
                <Form.Item
                  label="Confirmar senha"
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Favor, confirme a senha!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "A nova senha que você digitou não corresponde!"
                          )
                        );
                      },
                    })
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Row justify="center" className="btn-salvar">
              <BlueSquareButton
                isLoading={
                  passwordSelector.status.changePassword ===
                  StateStatus.loading
                }
                disabled={
                  passwordSelector.status.changePassword ===
                  StateStatus.loading
                }
                className={"btn-blue"}
                htmlType="submit"
              >
                Alterar
              </BlueSquareButton>
            </Row>
          </Form>
          <br />
          <br />
          <br />
          <br />
          <div style={{width: '100%', textAlign: 'center'}}><a href="#/">Fazer login</a></div>
        </div>
      </div>
      <Modal
        open={showModal === "error-change"}
        footer={null}
        onCancel={() => updateShowModal(null)}
      >
        <Result
          status="error"
          title="Falha ao alterar a senha"
          subTitle={<>Não foi possível alterar a sua senha.</>}
          extra={[
            <RoudedButton
              className="btn-blue"
              onClick={() => {
                updateShowModal(null);
              }}
            >
              Fechar
            </RoudedButton>,
          ]}
        />
      </Modal>
    </>
  );
};

export default ChangePassword;
