import { Steps } from "antd";
import "./styles.scss";

const Stepper = ({current, items}) => {
  return (
    <Steps
      className="stepper"
      current={current}
      items={items?.map((x) => (
        {
            title: x.title,
            description: x.description,
            // subTitle: x.subTitle,
        }
      ))}
    />
  );
};

export default Stepper
