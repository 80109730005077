/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Form, Input, message } from "antd"
import Button from "../../common/components/button"
import { selectAddTargetsState, addTargetsThunk, getReportThunk, clearReport } from "./slice"
import StateStatus from "../../utils/stateStatus"
import Header from "../../common/components/header"
import InputCpfCnpjList from "../dashborad/components/create_report_modal/components/input-cpfcnpj-list"
import "./styles.scss"
import { beautifyCpfCnpj } from "../../utils/formatters"
import { useParams } from "react-router-dom"


const AddTargetsPage = () => {
    const [form] = Form.useForm()
    const params = useParams()
    const state = useSelector(selectAddTargetsState)
    const dispatch = useDispatch()
    const [cpfCnpjs, updateCpfCnpjs] = useState([])

    const onFinish = (values) => {
        const valids = values.targets.map((target, index) => {
            const value = target.cpf_cnpj.replace(/\D/g, "")
            if(cpfCnpjs.includes(value)) {
                form.setFields([{
                    name: ["targets", index, "cpf_cnpj"], 
                    errors: [`${value.length > 11 ? "CNPJ" : "CPF"} já adicionado ao caso`],
                }])
                return false
            }
            return true
        })
        if(!valids.includes(false)) {
            dispatch(addTargetsThunk(values))
        }
    }

    useEffect(() => {
        if(params.reportId) { 
            dispatch(getReportThunk(params.reportId))
        }
    }, [params.reportId])

    useEffect(() => {
        if (state.status.addTargets === StateStatus.succeeded) {
            message.success("Caso atualizado com sucesso!")
            form.resetFields(["targets"])
        }

        if (state.status.addTargets === StateStatus.failed) {
            message.error("Erro ao atualizar novo caso.")
        }

    }, [state.status.addTargets])

    useEffect(() => {
        if (state.status.getReport === StateStatus.succeeded) {
            updateCpfCnpjs(state.data.report.targets.map((x) => x.cpf_cnpj))
        }
        
        if (state.status.getReport === StateStatus.failed) {
            form.setFields([{
                name: "report_id", 
                errors: ["Caso não encontrado"],
            }])
        }

    }, [state.status.getReport])


    return (
        <>
            <Header />
            <div className="home-page">
                <div className="form-side">
                    <div className="step-about">
                        <h1 className="form-title">Adicionar pesquisados</h1>
                        <h2 className="form-subtitle">
                            Indique abaixo o ID do caso e adicione novas pessoas físicas ou jurídicas a serem pesquisadas.
                        </h2>
                    </div>
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                        <Form.Item
                            initialValue={params.reportId}
                            label="ID do caso"
                            name="report_id"
                            rules={
                                [
                                    { required: true, message: "Campo obrigatório" }
                                ]
                            }
                        >
                            <Input.Search 
                                style={{maxWidth: "600px"}}
                                disabled={!!params.reportId}
                                allowClear 
                                placeholder="Id do caso" 
                                loading={state.status.getReport === StateStatus.loading}
                                onBlur={(e) => {
                                    if(state.data.report?.id !== e.target.value) {
                                        dispatch(getReportThunk(e.target.value))
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        dispatch(clearReport())
                                    }

                                    if(e.target.value.length === 36) {
                                        dispatch(getReportThunk(e.target.value))
                                    }
                                }} 
                            />
                        </Form.Item>
                        {state.data.report && (
                            <>
                                <div className="case-info">
                                    <h3>{state.data.report?.case_name?.toUpperCase() }</h3>   
                                    <div>{state.data.report?.client?.name} - {beautifyCpfCnpj(state.data.report?.client?.cpf_cnpj)}</div>
                                    <div>{`${state.data.report?.targets?.length} pesquisado(s)`.toUpperCase()}</div>
                                </div>
                                <InputCpfCnpjList 
                                form={form}
                                name={"targets"}
                                label={
                                  <div style={{margin: "10px 0"}}>
                                    Adicione pessoas físicas e jurídicas a serem pesquisadas:
                                  </div>
                                }
                                // initialValue={reportInfo.targets}
                                />
                            </>
                        )}      
                        {state.data.report?.id && (
                            <Button 
                                loading={state.status.addTargets === StateStatus.loading} 
                                type="submit"
                            > 
                                Enviar
                            </Button>
                        )}         
                    </Form>
                </div>
            </div>
		</>
    )
}
export default AddTargetsPage;