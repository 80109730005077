import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clients, topReports} from "./service";
import StateStatus from "../../../../utils/stateStatus";

export const getTopReportsThunk = createAsyncThunk('dash/top-reports', async (values) => {
    return await topReports(values)
})

export const getClientsThunk = createAsyncThunk('dash/clients', async (values) => {
    return await clients(values)
})

const initialState = {
    status: {
        topReports: StateStatus.idle,
        clients: StateStatus.idle,
    },
    data: {
        reports: null,
        clients: null,
    },
    errMessage: {
        reports: null,
        clients: null,
    },
}

export const homeDashSlice = createSlice({
    name: 'homeDash',
    initialState,
    reducers: {
        resetTopReports: (state, action) => {
            state.status.topReports = StateStatus.idle
            state.data.reports = null
        },
        resetClients: (state, action) => {
            state.status.clients = StateStatus.idle
            state.data.clients = null
        },
    },
    extraReducers: {
        //topReports
        [getTopReportsThunk.pending]: (state) => {
            state.status.topReports = StateStatus.loading
        },
        [getTopReportsThunk.fulfilled]: (state, action) => {
            state.status.topReports = StateStatus.succeeded
            state.data.reports = action.payload.data.items
        },
        [getTopReportsThunk.rejected]: (state, action) => {
            state.status.topReports = StateStatus.failed
            state.errMessage.reports = action.error.message
        },

        //clients
        [getClientsThunk.pending]: (state) => {
            state.status.clients = StateStatus.loading
        },
        [getClientsThunk.fulfilled]: (state, action) => {
            state.status.clients = StateStatus.succeeded
            state.data.clients = action.payload.data
        },
        [getClientsThunk.rejected]: (state, action) => {
            state.status.clients = StateStatus.failed
            state.errMessage.clients = action.error.message
        },
    }
})

export const {resetTopReports, resetClients} = homeDashSlice.actions
export const selectDashState = (state) => state.homeDash