import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { passwordForgot } from "./service"


export const forgotPassword = createAsyncThunk(
    "auth/forgot-password",
    async (data) => {
        const response = await passwordForgot(data)
        return response.data
    }
)

const initialState = {
    status: {
        forgotPassword: StateStatus.idle,
    },
    token: null,
    errMessage: null,
}

export const forgotPassSlice = createSlice({
    name: "forgotPass",
    initialState,
    reducers: {
        resetPasswState: (state, action) => {
            state.errMessage = null
            state.status.forgotPassword = StateStatus.idle
            state.token = null
        },
    },
    extraReducers: {
        [forgotPassword.pending]: (state, action) => {
            state.status.forgotPassword = StateStatus.loading
        },
        [forgotPassword.fulfilled]: (state, action) => {
            state.status.forgotPassword = StateStatus.succeeded
        },
        [forgotPassword.rejected]: (state, action) => {
            state.status.forgotPassword = StateStatus.failed
            state.errMessage = action.error.message
        },
    },
})

export const { resetPasswState } = forgotPassSlice.actions
export const selectPasswordState = (state) => state.forgotPass