import { configureStore } from "@reduxjs/toolkit"
import { addTargetsSlice } from "../features/addTargets/slice"
import { graphSlice } from "../features/graph/slices"
import { targetPageSlice } from "../features/target_page/slices"
import { surveyPageSlice } from "../features/survey_page/slices"
import { targetStatusPageSlice } from "../features/target_status_page/slices"
import { reportPageSlice } from "../features/report_page/slices"
import { loginSlice } from "../features/login/slice"
import { forgotPassSlice } from "../features/forgot_password/slice"
import { changePasswordSlice } from "../features/change_password/slice"
import { createAccountSlice } from "../features/new_account/slice"
import { homeDashSlice } from "../features/dashborad/pages/home/slice";
import { listReportsSlice } from "../features/dashborad/pages/list_reports/slice";
import { monitoringSlice } from "../features/dashborad/pages/monitoring_page/slice";
import { newClientSlice } from "../features/dashborad/components/common/new-client/slice";
import { listUsersSlice } from "../features/dashborad/pages/users/slice";
import { listClientsSlice } from "../features/dashborad/pages/clients-adm/slice"
import { purchasedProductsSlice } from "../features/dashborad/pages/purchased_products/slice"
import { createReportUnauthSlice } from "../features/create_report_unauth_page/slice"

export const store = configureStore({
  reducer: {
    addTargets: addTargetsSlice.reducer,
    reportPage: reportPageSlice.reducer,
    targetPage: targetPageSlice.reducer,
    surveyPage: surveyPageSlice.reducer,
    targetStatusPage: targetStatusPageSlice.reducer,
    graph: graphSlice.reducer,
    login: loginSlice.reducer,
    forgotPass: forgotPassSlice.reducer,
    changePassword: changePasswordSlice.reducer,
    createAccount: createAccountSlice.reducer,
    createReportUnauth: createReportUnauthSlice.reducer,

    //dashboard
    homeDash: homeDashSlice.reducer,
    listReports: listReportsSlice.reducer,
    monitoring: monitoringSlice.reducer,
    newClient: newClientSlice.reducer,
    listUsers: listUsersSlice.reducer,
    listClients: listClientsSlice.reducer,
    purchasedProducts: purchasedProductsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
