/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
// import PropTypes from "prop-types"
import MaskedInput from "antd-mask-input";
// 
import "../input/index.scss"

const MaskInput = ({...props}) => {

	const change = (event) => {
		const onlyNumbers = event.target.value.replace(/\D/g, "");
		if (props.onChange) {
			props.onChange(onlyNumbers)
		}
	}
	
	return (
		<MaskedInput {...props} className="simple-input simple-input-mask" onChange={change}/>
	)
}

MaskInput.defaultProps = {}
MaskInput.propTypes = {}

export default MaskInput
