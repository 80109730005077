/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Row, Col, Form, Modal, Checkbox } from "antd";
// import "./styles.scss"
import Input from "../../../../common/components/input";
import { RoudedButton } from "../../../../common/components/button";
import { loginSelector } from "../../../login/slice";
import { useSelector } from "react-redux";

const EditUserModal = ({
  isAdmUser,
  isClientAdm,
  clientName,
  user,
  isVisible,
  isLoading,
  onSave = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();
  const loginState = useSelector(loginSelector);
  const profile = loginState.data.profile;
  const isAdmUser_ = profile?.is_staff || profile?.is_superuser

  useEffect(() => {
    form.resetFields();

    if (user) {
      form.setFieldsValue({
        email: user.email,
        name: user.name,
        is_active: user.is_active ?? true,
        is_staff: user.is_staff ?? false,
        is_superuser: user.is_superuser ?? false,
        is_client_adm: user.is_client_adm ?? false,
      });
    }
  }, [isVisible]);

  // const searchAddressByCep = (value) => {
  //   if (value?.length !== 9) return;
  //   setLoadingCep(true);

  //   getAddressByCep(value)
  //     .then((response) => {
  //       console.log(response);
  //       form.setFields([
  //         { name: ["address", "cep"], value: response.cep },
  //         { name: ["address", "city"], value: response.localidade },
  //         { name: ["address", "street"], value: response.logradouro },
  //         { name: ["address", "state"], value: response.uf },
  //         { name: ["address", "neighborhood"], value: response.bairro },
  //       ]);
  //       setLoadingCep(false);
  //       form.validateFields();
  //     })
  //     .catch((err) => {
  //       setLoadingCep(false);
  //     });
  // };

  return (
    <Modal
      className="edit-client-modal"
      title={user ? "Editar usuário" : "Cadatrar usuário"}
      open={isVisible}
      onCancel={onCancel}
      footer={[
        <Row justify={"space-between"} style={{ marginTop: "30px" }}>
          <RoudedButton key="back" onClick={onCancel}>
            Cancelar
          </RoudedButton>
          <RoudedButton
            className={"btn-blue"}
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Salvar
          </RoudedButton>
        </Row>,
      ]}
      width={"900px"}
    >
      <div className="normal-14">
        Preencha com os dados do usuário.
      </div>
      {/* <hr
        style={{
          width: "100%",
          height: "1px",
          border: "none",
          backgroundColor: "#C2C2C2",
        }}
      /> */}
      <br />
      <Form
        form={form}
        onFinish={(values) => {
          onSave(values);
        }}
        layout="vertical"
        size="large"
        scrollToFirstError="true"
        initialValues={{
          is_client_adm: false,
          is_staff: false,
          is_superuser: false,
          is_active: true,
        }}
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"name"}
              label="Nome"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Informe um nome válido!</div>
                  ),
                },
              ]}
            >
              <Input placeholder="Digite o nome do usuário" />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"email"}
              label="E-mail"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Informe um e-mail válido!</div>
                  ),
                },
                {
                  type: "email",
                  message: (
                    <div className="normal-12">Informe um e-mail válido!</div>
                  ),
                },
              ]}
            >
              <Input placeholder="Digite o e-mail" disabled={!!user?.email} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={24} xs={24} md={24}>
            <Form.Item name={"is_active"} valuePropName="checked">
              <Checkbox>Usuário ativo?</Checkbox>
            </Form.Item>
            {isAdmUser_ ? (<>
              <Form.Item name={"is_staff"} valuePropName="checked">
                <Checkbox>É funcionário da Inquest?</Checkbox>
              </Form.Item>
              {profile?.is_superuser ? (
                <Form.Item name={"is_superuser"} valuePropName="checked">
                  <Checkbox>É super usuário da Inquest?</Checkbox>
                </Form.Item>
              ) : null}
            </>) : null}
            {isAdmUser_ || isClientAdm ? (
            <Form.Item name={"is_client_adm"} valuePropName="checked">
              <Checkbox>Este usuário pode administrar outros usuários e/ou cadastro deste cliente <strong>{clientName}</strong>?</Checkbox>
            </Form.Item> 
            ): null}          
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

EditUserModal.propTypes = {
  isVisible: propTypes.bool,
  isAdmUser: propTypes.bool,
  isClientAdm: propTypes.bool,
  isLoading: propTypes.bool,
  clientName: propTypes.string,
  onSave: propTypes.func,
  onCancel: propTypes.func,
  user: propTypes.object,
};

export default EditUserModal;
