import React from "react";
import PropTypes from "prop-types";
//
import { Form, Row, Col } from "antd";
// import Input from "../../../../../../common/components/input"
// import MaskInput from "../../../../../../common/components/mask-input";
//
import { cnpj, cpf } from "cpf-cnpj-validator";
import "./index.scss";
import Icons from "../../../../../../common/components/Icons";
import { BlueSquareOulinedSmallButton } from "../../../../../../common/components/button";
import MaskedInput from "../../../../../../common/components/masked-input";

const InputCpfCnpjList = ({ service, initialValue, form, name, label }) => {
  const prices = {
    1: 1518,
    2: 1278,
    3: 1014,
    4: 985,
  };

  return (
    <div className="input-cpf-cnpj-list">
      {/* <p className="form-list-title"> */}
        {label}
      {/* </p> */}
      <Form.List
        name={name}
        // label={label}
        initialValue={initialValue?.length > 0 ? initialValue : [{ cpf_cnpj: null, reason: null }]}
        rules={[
          () => ({
            validator(_, values) {
              if (
                values === null ||
                values === undefined ||
                values === "undefined" ||
                values.length < 1
              ) {
                return Promise.reject(
                  new Error("Adicione pessoas a sua pesquisa")
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <Row
                    className={`fields-edit ${index > 0 ? "border" : ""}`}
                    justify="space-between"
                    align={"top"}
                    gutter={[16, 16]}
                    key={field}
                  >
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="CPF ou CNPJ"
                        key="cpf_cnpj"
                        name={[index, "cpf_cnpj"]}
                        initialValue={""}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value)
                                return Promise.reject(
                                  new Error("Informe um CPF ou CNPJ")
                                );

                              const val = value.replace(/[^0-9]+/g, "");

                              if (val.length > 11) {
                                if (cnpj.isValid(val)) return Promise.resolve();

                                return Promise.reject(
                                  new Error("Informe um  CNPJ inválido")
                                );
                              }

                              if (!cpf.isValid(val))
                                return Promise.reject(
                                  new Error("Informe um CPF inválido")
                                );

                              const values =
                                form?.getFieldValue(name) || [];
                              const cpfCnpjs = Array.from(
                                values
                                  .filter((x) => !!x)
                                  .map((x) =>
                                    x.cpf_cnpj.replace(/[^0-9]+/g, "")
                                  )
                              );
                              if (
                                cpfCnpjs.filter((cpfCnpj) => cpfCnpj === val)
                                  .length > 1
                              ) {
                                return Promise.reject(
                                  new Error(
                                    `${val.length > 11 ? "CNPJ" : "CPF"
                                    } duplicado no formulário`
                                  )
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <MaskedInput
                          initialValue={null}
                          formats={[
                            {
                              condition: (value) => value.length <= 11,
                              format: "###.###.###-##",
                            },
                            {
                              condition: (value) => value.length > 11,
                              format: "##.###.###/####-##",
                            },
                          ]}
                          placeholder="Insira o CPF ou CNPJ a ser pesquisado"
                          mask="_"
                        />
                      </Form.Item>
                    </Col>


                    <Col xs={24} md={12} lg={12}>
                      {fields.length > 1 && (
                        <Icons
                          icon="delete"
                          onClick={() => {
                            // form && form.validateFields();
                            remove(index);
                          }}
                          className="button-remove"
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}

              <Form.Item>
                <BlueSquareOulinedSmallButton
                  onClick={() => add()}
                >
                  Incluir outra pessoa
                  </BlueSquareOulinedSmallButton>
              </Form.Item>

              <Form.ErrorList className="list-error" errors={errors} />

              <br />

              {fields.length > 0 && service && service === "3" && (
                <div className="price">
                  Para <b>{fields.length}</b> pessoa(s), você paga{" "}
                  <b>
                    {prices[
                      fields.length >= 4 ? 4 : fields.length
                    ].toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>{" "}
                  por pessoa. Totalizando em{" "}
                  <b>
                    {(
                      fields.length *
                      prices[fields.length >= 4 ? 4 : fields.length]
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>
                  .
                </div>
              )}
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

InputCpfCnpjList.defaultProps = {
  initialValue: [],
  service: null,
  form: null,
};

InputCpfCnpjList.propTypes = {
  service: PropTypes.string,
  initialValue: PropTypes.any,
  form: PropTypes.object,
};

export default InputCpfCnpjList;
