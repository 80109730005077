import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAccount } from "./service";
import StateStatus from "../../utils/stateStatus";

export const createAccountThunk = createAsyncThunk("createAccount/create", async (data) => {
    try {
        const response = await createAccount(data)

        localStorage.setItem("access_token", response.data.access_token)
        localStorage.setItem("refresh_token", response.data.refresh_token)
        
        return response.data.data
    } catch(err) {
        const message = err.response.data.msg ?? "Não foi possível criar usuário."

        const customError = {
            message,
            name: "Api Error",
            data: err.data,
        }
        throw customError
    }
})


const initialState = {
    status: {
        create: StateStatus.idle
    },
    errMessage: null,
}

export const createAccountSlice = createSlice({
    name: "createAccount",
    initialState,
    reducers: {
        resetCreateAccount: (state, action) => {
            state.status.create = StateStatus.idle
        }
    },
    extraReducers: {
        [createAccountThunk.pending]: (state) => {
            state.status.create = StateStatus.loading
        },
        [createAccountThunk.rejected]: (state, action) => {
            state.status.create = StateStatus.failed
            state.errMessage = action.error.message
        },
        [createAccountThunk.fulfilled]: (state) => {
            state.status.create = StateStatus.succeeded
        },
    }
})

export const { resetCreateAccount } = createAccountSlice.actions
export const createAccountSelector = (state) => state.createAccount