import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listUsers, addUserToClient} from "./service";
import StateStatus from "../../../../utils/stateStatus";

export const getListUsersThunk = createAsyncThunk('client/list-users', 
async (clientId) => {
    return listUsers(clientId)
})

export const addUserToClientThunk = createAsyncThunk('client/add-user', 
async ({clientId, data}) => {
    data.client_id = clientId
    return addUserToClient(data)
})




const initialState = {
    status: {
        listUsers: StateStatus.idle,
        addUserToClient: StateStatus.idle,
    },
    data: {
        client: null,
        users: null
    },
    errMessage: null
}

export const listUsersSlice = createSlice({
    name: 'listUsers',
    initialState,
    reducers: {
        resetListUsersCase: (state, action) => {
            state.status.listUsers = StateStatus.idle
        }
    },
    extraReducers: {

        [getListUsersThunk.pending]: (state) => {
            state.status.listUsers = StateStatus.loading
        },
        [getListUsersThunk.fulfilled]: (state, action) => {
            state.status.listUsers = StateStatus.succeeded
            state.data.client = action.payload.data.client
            state.data.users = action.payload.data.users
        },
        [getListUsersThunk.rejected]: (state, action) => {
            state.status.listUsers = StateStatus.failed
            state.errMessage = action.error.message
        },

        [addUserToClientThunk.pending]: (state) => {
            state.status.addUserToClient = StateStatus.loading
        },
        [addUserToClientThunk.fulfilled]: (state, action) => {
            state.status.addUserToClient = StateStatus.succeeded
        },
        [addUserToClientThunk.rejected]: (state, action) => {
            state.status.addUserToClient = StateStatus.failed
        },
    }
})

export const {resetListUsersCase} = listUsersSlice.actions
export const selectListUsersState = (state) => state.listUsers