/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import "./styles.print.scss";
import { selectTargetState, getTargetThunk, getServiceThunk } from "./slices";
import StateStatus from "../../utils/stateStatus";
import { Row, Skeleton, Tooltip } from 'antd';
import logo from "../../common/images/shortinquestazul.png";
import { FloatButton, Spin } from 'antd';
import { beautifyCpfCnpj } from "../../utils/formatters";
import { CaretUpOutlined } from "@ant-design/icons"
import Icons from "../../common/components/Icons";
import { isMobileDevice } from "../../utils/mobile";

const MAX_ROWS_TO_MINIMIZE_TABLE = 3

const stackToLoad = (stack) => {
    const allLoading = Object.entries(stack).filter(service =>  service[1].status === StateStatus.loading)

    return Object.entries(stack).filter(service => {
        if (service[1].status === StateStatus.idle) {
            return true
        } else {
            return false
        }
    }).map(service => service[1]).slice(0, 2 - allLoading.length)
}

// this part of code is used to filld a standard setence when the MS returns nothing.
const FillBlankTables = () => {
    const td = document.createElement('td')
    td.innerHTML = 'Nenhum resultado encontrado.'
    td.className = 'cell-without-result'
    td.colSpan = "150" // expand cell to unknown maximum


    const tr = document.createElement('tr')
    tr.appendChild(td)

    const tables = Array.from(document.getElementsByTagName('tbody')).filter((item) => item.childElementCount <= 1)

    tables.forEach((item) => {
        item.appendChild(tr)
    })
}

const configureProgressBars = () => {
    let elems = Array.from(document.getElementsByClassName("progress-bar-point"))
    elems.forEach(elem => {
        let pointValue = elem.attributes["data-point"].value
        let pointMax = elem.attributes["data-max"].value
        let percent = (pointValue / pointMax) * 100
        elem.style.marginLeft = `calc(${percent}% - 30px)`
    })
}

const configureMinimizeTables = () => {
    const tables = Array.from(document.querySelectorAll(".service-table-box table"))
    
    tables.forEach((table, index) => {
        const tableBox = table.parentElement
        const hasMinimizeTable = table.firstElementChild.childElementCount > MAX_ROWS_TO_MINIMIZE_TABLE

        if(!tableBox.className.includes("minimize-table") && hasMinimizeTable) {
            tableBox.className += " minimize-table closed"
            
            const expandirContent = document.createElement("div")
            expandirContent.className = "maximize-btn-content"
            
            const expandirBtn = document.createElement("span")
            expandirBtn.textContent = "Expandir"
            expandirBtn.addEventListener("click", (e) => handleMaximizeTable(tableBox, e, true), false)
            expandirContent.appendChild(expandirBtn)
            
            tableBox.after(expandirContent)
            tableBox.addEventListener("click", (e) => handleMaximizeTable(tableBox, e, false), false)
        }
    }) 
}

const handleMaximizeTable = (boxTable, event, isFromMaximizeBtn) => {
    const isClosed = !boxTable.className.includes("opened")

    const maximizeBtn = boxTable.nextElementSibling.firstChild
    
    if(isClosed) {
        boxTable.className = boxTable.className.replace(" closed", "")
        boxTable.className += " opened"
        maximizeBtn.textContent = "Minimizar"
    } else if(isFromMaximizeBtn || event.target.localName === "th") { // minimize table only if clicked on header
        boxTable.className = boxTable.className.replace(" opened", "")
        boxTable.className += " closed"
        maximizeBtn.textContent = "Expandir"
    }
}

const removeMinimizeTables = () => {
    const tables = Array.from(document.querySelectorAll(".service-table-box table"))

    tables.forEach((table, index) => {
        const tableBox = table.parentElement
        tableBox.className = tableBox.className.replace("minimize-table", "")
    }) 
}

const handleParecer = () => {

    const parecer = document.getElementById("parecer")
    const parecerContent = document.getElementById("parecer-content")
    if(parecerContent && parecer) {
        parecerContent.innerHTML = parecer.innerHTML
        parecer.style.display = "none"
    }

    
    const elems = Array.from(document.querySelectorAll(
        ".parecer_red, .parecer_green, .parecer_yellow, .parecer_orange, .parecer_blue"
    ))

    elems.forEach((elem, idx) => {
        if(elem.childElementCount > 0) return

        let pointValue = 0
        const pointMax = 100

        if(elem.className.includes("parecer_red")) {
            pointValue = 3
        } else if(elem.className.includes("parecer_orange")) {
            pointValue = 25
        } else if(elem.className.includes("parecer_yellow")) {
            pointValue = 50
        } else if(elem.className.includes("parecer_blue")) {
            pointValue = 75
        } else if(elem.className.includes("parecer_green")) {
            pointValue = 97
        } 

        const progressBar = document.createElement("div")
        progressBar.className = "progress-bar"

        const parecerBarPoint = document.createElement("div")
        parecerBarPoint.className = "parecer-bar-point"
        let percent = (pointValue / pointMax) * 100
        parecerBarPoint.style.marginLeft = `calc(${percent}% - 30px)`

        const parecerValue = document.createElement("div")
        parecerValue.className = "parecer-value"

        const parecerPoint = document.createElement("div")
        parecerPoint.className = "parecer-point"

        parecerBarPoint.appendChild(parecerValue)
        parecerBarPoint.appendChild(parecerPoint)
        progressBar.appendChild(parecerBarPoint)
        elem.appendChild(progressBar)
    })
}

export const printTargetHandler = () => {
    /** Altera HTML para alihar ao formato PDF */

    let viewport
    let defaultViewport

    if(isMobileDevice()) {
        viewport = document.querySelector("meta[name=viewport]");
        defaultViewport = viewport.attributes.content.value
        viewport.setAttribute('content', 'width=900, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    }
    
    removeMinimizeTables()            

    const pageHeight = 736 // Mudar no 'styles.print.scss' também
    

    const scores = Array.from(document.getElementsByClassName("score-card"))
    // const sections = Array.from(receitaFederal.getElementsByClassName("simple-section"))
    const elems = Array.from(document.getElementsByClassName("service-box"))

    const calcHeigthSubElements = (elems, spaceOnFirst) => {
        elems.forEach((elem, idx) => {
            if(!elem.className.includes("resized")) {
                elem.className = `${elem.className} resized`
                
                let batch = Math.ceil(elem.offsetHeight / pageHeight)
                let height = batch * pageHeight 
                height += (batch > 2? (batch - 1) * (48 - batch * 1.4) : 0) // corrige margem de erro

                if(idx === 0) { 
                    height -= spaceOnFirst // espaço vertical ocupado pelo título do serviço
                }
                
                // elem.style.height = `${height}px`
                elem.style.height = "auto"
                elem.style.minHeight = `${height}px`
            }
        })
    }

    calcHeigthSubElements(scores, 150)
    // calcHeigthSubElements(sections, 150)
    calcHeigthSubElements(elems, 0)

    
    if(isMobileDevice()) {
        setTimeout(() => {
            viewport.setAttribute("content", defaultViewport)
            window.location.reload()
        }, 3000)
    }
}

const buildSummary = () => {
     const elements = Array.from(document.getElementsByClassName("totalizer"))
     const totalizers = elements.map(item => item.attributes.data.nodeValue.split("|"))
     if (elements.length === 0) return {}
     
     return {
        totalizers: totalizers,
        tableHtml: (
            <table> 
                <tr>
                    <th className="td-type">Tipo</th>
                    <th className="td-total">Quantidade</th>
                    <th className="td-nominal">Valor nominal</th>
                    <th className="td-description">Descrição</th>
                </tr>
                {
                    totalizers.sort((item1, item2) => parseInt(item2[1]) - parseInt(item1[1])).map(item => {
                        // const values = item[1] ? item[1].split("|") : []
                        // const noResults = values[0].trim() === "0" || !values[0] || values[2].includes(" 0,00")
                        const class_ = parseInt(item[1]) > 0 ? "" : "no-data"
                        return (
                            <tr key={item[0]}>
                                <td className={`td-type ${class_}`}><span style={{ cursor: "pointer" }} onClick={() => document.getElementById(item[4]).scrollIntoView()}>{item[0]}</span></td>
                                <td className={`td-total ${class_}`}>{item[1]}</td>
                                <td className={`td-nominal ${class_}`}>{parseInt(item[1]) > 0 ? item[3] : "-"}</td>
                                <td className={`td-description ${class_}`}>{parseInt(item[1]) > 0 ? item[2] : "-"}</td>
                            </tr>
                        )
                    })
                }
            </table>
        )
    }
}

const totalizersCountCNPJ = {
    "smart_pro": 27,
    "simples": 27,
    "smart": 27,
    "preventivo": 21,
}

const totalizersCountCPF = {
    "smart_pro": 27,
    "simples": 27,
    "smart": 27,
    "preventivo": 21,
}

const TargetPage = () => {
    const params = useParams()
    const targetState = useSelector(selectTargetState)
    const dispatch = useDispatch()
    const [summary, setSummary] = useState({})
    const [pageIsLoaded, setPageIsLoaded] = useState(false)
    
    const target = targetState.data?.target
    
    const caseType = target?.report?.case_type?.toUpperCase()?.replace("_", " ")

    useEffect(() => {
        const summary = buildSummary()
        setSummary(summary)
        configurePage(summary)
    }, [targetState.stackService])

    useEffect(() => {
        if (targetState.status.getAllServices === StateStatus.succeeded) {
            configurePage(summary)
            
            if(!params.reportId) { // verifica se é a pagina de target (Pág do PDF)
                setTimeout(() => printTargetHandler(), 1000 * 2) // 2s
            }
        }
    }, [targetState.status.getAllServices])

    useEffect(() => {
        setPageIsLoaded(false)
        dispatch(getTargetThunk(params.targetId))
        // dispatch(listReportFilesThunk(params.targetId))
    }, [])

    useEffect(() => {
        if (targetState.status.getTarget === StateStatus.succeeded) {
            const caseName = target.report.case_name?.toUpperCase()
            const cpfCnpj = beautifyCpfCnpj(target.cpf_cnpj)
            const type = target.cpf_cnpj?.length === 11 ? "CPF" : "CNPJ"
            document.title = `${caseName} - ${type} ${cpfCnpj}`
            stackToLoad(targetState.stackService).forEach(
                (item) => {
                    dispatch(getServiceThunk({ 
                        targetId: targetState.data.target.id, 
                        serviceId: item.id, 
                        next: item.next 
                    }))
                }
            )
        }
    }, [targetState.status.getTarget, dispatch, targetState.data.target.id, targetState.stackService, targetState.data.target.status])
    
    const configurePage = (summary) => {
        FillBlankTables()
        configureProgressBars()
        configureMinimizeTables()
        handleParecer()

        const type = target?.report?.case_type
        const minTotalizers = (target?.cpf_cnpj?.length === 11) ? totalizersCountCPF[type] : totalizersCountCNPJ[type]
        if(
            summary?.totalizers?.length >= (minTotalizers - 1) // ignora tst
            && targetState.status.getAllServices === StateStatus.succeeded
        ) {
            setPageIsLoaded(true)            
        }
    }


    return targetState.status.getTarget === StateStatus.loading ? (
            <Row align="center"  style={{height: "150px"}}>
                <Spin size="large" /> 
            </Row>
        ) : (
        <div className={params.reportId ? `target-page` : `target-page-print`} data-case-type={caseType} >
            <div className="target-content">
                <img id="logo" alt="" className="logo" src={logo} />
                <div className="target-header">
                    <div className="target-title">REPORT {caseType}{caseType?.includes("SMART") ? "– PESQUISAS PATRIMONIAIS PRELIMINARES ETAPA (1/3)" : ""}</div>
                    <div className="target-subtitle">
                        {target?.status !== "completed" 
                            ? <Icons icon="loading" spin /> 
                            : <Icons icon="checked-circle" />}
                        {target?.name 
                            ? `${beautifyCpfCnpj(target?.cpf_cnpj)} - ${target?.name}` 
                            : beautifyCpfCnpj(target?.cpf_cnpj)}
                    </div>
                </div>
                {pageIsLoaded ? ( // <span> usado pelo BOT gerador de PDF
                    <span className="page-loaded"></span>
                ) : null}
                <div id="parecer-content"></div>
                <div className="summary">
                {summary?.tableHtml && (
                    <>
                        <div className="summary-title">
                            TABELA RESUMO DA PESSOA PESQUISADA
                        </div>
                        <div className="summary-table">
                            {summary.tableHtml}
                        </div>
                        <span style={{
                            fontSize: "25px", 
                            color: "red",
                            display: "none",
                        }} >{summary.totalizers.length}</span>
                    </>
                )}
                </div>
                <div className="target-actions">
                    {/* <Button onClick={() => window.print()}>
                            Exportar PDF
                    </Button> */}
                </div>
                
                {Object.keys(targetState.stackService).sort(
                    (a, b) => new Date(targetState.stackService[a].order) - new Date(targetState.stackService[b].order)
                ).map((key) => {
                    if (targetState.stackService[key]['status'] === StateStatus.succeeded) {
                        return (
                            <>
                            <div
                                id={targetState.stackService[key].service}
                                className="service-section"
                                style={{
                                    width: '100%',
                                }}
                                key={targetState.stackService[key].id}
                                dangerouslySetInnerHTML={{ __html: targetState.stackService[key]['block_data']['block'] }}>
                            </div>
                            </>

                        )
                    } else {
                        return <div key={targetState.stackService[key].id} className="loading-text"><Skeleton active /></div>
                    }
                })}
            </div>
            <FloatButton.Group shape="circle" style={{ right: 94 }}>
                <Tooltip title="Voltar ao topo">
                    <FloatButton.BackTop
                        type="primary"
                        style={{ height: 50, width: 50 }}
                        visibilityHeight={0}
                        alt="Voltar ao topo"
                        icon={<CaretUpOutlined />}
                        onClick={() => document.getElementById("logo").scrollIntoView()}
                    />
                </Tooltip>
            </FloatButton.Group>
        </div >
        )
}

export default TargetPage