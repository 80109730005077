export const cities = [{
        "name": "Afonso Cláudio",
        "state": "ES"
    },
    {
        "name": "Água Doce do Norte",
        "state": "ES"
    },
    {
        "name": "Águia Branca",
        "state": "ES"
    },
    {
        "name": "Alegre",
        "state": "ES"
    },
    {
        "name": "Alfredo Chaves",
        "state": "ES"
    },
    {
        "name": "Alto Rio Novo",
        "state": "ES"
    },
    {
        "name": "Anchieta",
        "state": "ES"
    },
    {
        "name": "Apiacá",
        "state": "ES"
    },
    {
        "name": "Aracruz",
        "state": "ES"
    },
    {
        "name": "Atilio Vivacqua",
        "state": "ES"
    },
    {
        "name": "Baixo Guandu",
        "state": "ES"
    },
    {
        "name": "Barra de São Francisco",
        "state": "ES"
    },
    {
        "name": "Boa Esperança",
        "state": "ES"
    },
    {
        "name": "Bom Jesus do Norte",
        "state": "ES"
    },
    {
        "name": "Brejetuba",
        "state": "ES"
    },
    {
        "name": "Cachoeiro de Itapemirim",
        "state": "ES"
    },
    {
        "name": "Cariacica",
        "state": "ES"
    },
    {
        "name": "Castelo",
        "state": "ES"
    },
    {
        "name": "Colatina",
        "state": "ES"
    },
    {
        "name": "Conceição da Barra",
        "state": "ES"
    },
    {
        "name": "Conceição do Castelo",
        "state": "ES"
    },
    {
        "name": "Divino de São Lourenço",
        "state": "ES"
    },
    {
        "name": "Domingos Martins",
        "state": "ES"
    },
    {
        "name": "Dores do Rio Preto",
        "state": "ES"
    },
    {
        "name": "Ecoporanga",
        "state": "ES"
    },
    {
        "name": "Fundão",
        "state": "ES"
    },
    {
        "name": "Governador Lindenberg",
        "state": "ES"
    },
    {
        "name": "Guaçuí",
        "state": "ES"
    },
    {
        "name": "Guarapari",
        "state": "ES"
    },
    {
        "name": "Ibatiba",
        "state": "ES"
    },
    {
        "name": "Ibiraçu",
        "state": "ES"
    },
    {
        "name": "Ibitirama",
        "state": "ES"
    },
    {
        "name": "Iconha",
        "state": "ES"
    },
    {
        "name": "Irupi",
        "state": "ES"
    },
    {
        "name": "Itaguaçu",
        "state": "ES"
    },
    {
        "name": "Itapemirim",
        "state": "ES"
    },
    {
        "name": "Itarana",
        "state": "ES"
    },
    {
        "name": "Iúna",
        "state": "ES"
    },
    {
        "name": "Jaguaré",
        "state": "ES"
    },
    {
        "name": "Jerônimo Monteiro",
        "state": "ES"
    },
    {
        "name": "João Neiva",
        "state": "ES"
    },
    {
        "name": "Laranja da Terra",
        "state": "ES"
    },
    {
        "name": "Linhares",
        "state": "ES"
    },
    {
        "name": "Mantenópolis",
        "state": "ES"
    },
    {
        "name": "Marataízes",
        "state": "ES"
    },
    {
        "name": "Marechal Floriano",
        "state": "ES"
    },
    {
        "name": "Marilândia",
        "state": "ES"
    },
    {
        "name": "Mimoso do Sul",
        "state": "ES"
    },
    {
        "name": "Montanha",
        "state": "ES"
    },
    {
        "name": "Mucurici",
        "state": "ES"
    },
    {
        "name": "Muniz Freire",
        "state": "ES"
    },
    {
        "name": "Muqui",
        "state": "ES"
    },
    {
        "name": "Nova Venécia",
        "state": "ES"
    },
    {
        "name": "Pancas",
        "state": "ES"
    },
    {
        "name": "Pedro Canário",
        "state": "ES"
    },
    {
        "name": "Pinheiros",
        "state": "ES"
    },
    {
        "name": "Piúma",
        "state": "ES"
    },
    {
        "name": "Ponto Belo",
        "state": "ES"
    },
    {
        "name": "Presidente Kennedy",
        "state": "ES"
    },
    {
        "name": "Rio Bananal",
        "state": "ES"
    },
    {
        "name": "Rio Novo do Sul",
        "state": "ES"
    },
    {
        "name": "Santa Leopoldina",
        "state": "ES"
    },
    {
        "name": "Santa Maria de Jetibá",
        "state": "ES"
    },
    {
        "name": "Santa Teresa",
        "state": "ES"
    },
    {
        "name": "São Domingos do Norte",
        "state": "ES"
    },
    {
        "name": "São Gabriel da Palha",
        "state": "ES"
    },
    {
        "name": "São José do Calçado",
        "state": "ES"
    },
    {
        "name": "São Mateus",
        "state": "ES"
    },
    {
        "name": "São Roque do Canaã",
        "state": "ES"
    },
    {
        "name": "Serra",
        "state": "ES"
    },
    {
        "name": "Sooretama",
        "state": "ES"
    },
    {
        "name": "Vargem Alta",
        "state": "ES"
    },
    {
        "name": "Venda Nova do Imigrante",
        "state": "ES"
    },
    {
        "name": "Viana",
        "state": "ES"
    },
    {
        "name": "Vila Pavão",
        "state": "ES"
    },
    {
        "name": "Vila Valério",
        "state": "ES"
    },
    {
        "name": "Vila Velha",
        "state": "ES"
    },
    {
        "name": "Vitória",
        "state": "ES"
    },
    {
        "name": "Acrelândia",
        "state": "AC"
    },
    {
        "name": "Assis Brasil",
        "state": "AC"
    },
    {
        "name": "Brasiléia",
        "state": "AC"
    },
    {
        "name": "Bujari",
        "state": "AC"
    },
    {
        "name": "Capixaba",
        "state": "AC"
    },
    {
        "name": "Cruzeiro do Sul",
        "state": "AC"
    },
    {
        "name": "Epitaciolândia",
        "state": "AC"
    },
    {
        "name": "Feijó",
        "state": "AC"
    },
    {
        "name": "Jordão",
        "state": "AC"
    },
    {
        "name": "Mâncio Lima",
        "state": "AC"
    },
    {
        "name": "Manoel Urbano",
        "state": "AC"
    },
    {
        "name": "Marechal Thaumaturgo",
        "state": "AC"
    },
    {
        "name": "Plácido de Castro",
        "state": "AC"
    },
    {
        "name": "Porto Acre",
        "state": "AC"
    },
    {
        "name": "Porto Walter",
        "state": "AC"
    },
    {
        "name": "Rio Branco",
        "state": "AC"
    },
    {
        "name": "Rodrigues Alves",
        "state": "AC"
    },
    {
        "name": "Santa Rosa do Purus",
        "state": "AC"
    },
    {
        "name": "Sena Madureira",
        "state": "AC"
    },
    {
        "name": "Senador Guiomard",
        "state": "AC"
    },
    {
        "name": "Tarauacá",
        "state": "AC"
    },
    {
        "name": "Xapuri",
        "state": "AC"
    },
    {
        "name": "Água Branca",
        "state": "AL"
    },
    {
        "name": "Anadia",
        "state": "AL"
    },
    {
        "name": "Arapiraca",
        "state": "AL"
    },
    {
        "name": "Atalaia",
        "state": "AL"
    },
    {
        "name": "Barra de Santo Antônio",
        "state": "AL"
    },
    {
        "name": "Barra de São Miguel",
        "state": "AL"
    },
    {
        "name": "Batalha",
        "state": "AL"
    },
    {
        "name": "Belém",
        "state": "AL"
    },
    {
        "name": "Belo Monte",
        "state": "AL"
    },
    {
        "name": "Boca da Mata",
        "state": "AL"
    },
    {
        "name": "Branquinha",
        "state": "AL"
    },
    {
        "name": "Cacimbinhas",
        "state": "AL"
    },
    {
        "name": "Cajueiro",
        "state": "AL"
    },
    {
        "name": "Campestre",
        "state": "AL"
    },
    {
        "name": "Campo Alegre",
        "state": "AL"
    },
    {
        "name": "Campo Grande",
        "state": "AL"
    },
    {
        "name": "Canapi",
        "state": "AL"
    },
    {
        "name": "Capela",
        "state": "AL"
    },
    {
        "name": "Carneiros",
        "state": "AL"
    },
    {
        "name": "Chã Preta",
        "state": "AL"
    },
    {
        "name": "Coité do Nóia",
        "state": "AL"
    },
    {
        "name": "Colônia Leopoldina",
        "state": "AL"
    },
    {
        "name": "Coqueiro Seco",
        "state": "AL"
    },
    {
        "name": "Coruripe",
        "state": "AL"
    },
    {
        "name": "Craíbas",
        "state": "AL"
    },
    {
        "name": "Delmiro Gouveia",
        "state": "AL"
    },
    {
        "name": "Dois Riachos",
        "state": "AL"
    },
    {
        "name": "Estrela de Alagoas",
        "state": "AL"
    },
    {
        "name": "Feira Grande",
        "state": "AL"
    },
    {
        "name": "Feliz Deserto",
        "state": "AL"
    },
    {
        "name": "Flexeiras",
        "state": "AL"
    },
    {
        "name": "Girau do Ponciano",
        "state": "AL"
    },
    {
        "name": "Ibateguara",
        "state": "AL"
    },
    {
        "name": "Igaci",
        "state": "AL"
    },
    {
        "name": "Igreja Nova",
        "state": "AL"
    },
    {
        "name": "Inhapi",
        "state": "AL"
    },
    {
        "name": "Jacaré dos Homens",
        "state": "AL"
    },
    {
        "name": "Jacuípe",
        "state": "AL"
    },
    {
        "name": "Japaratinga",
        "state": "AL"
    },
    {
        "name": "Jaramataia",
        "state": "AL"
    },
    {
        "name": "Jequiá da Praia",
        "state": "AL"
    },
    {
        "name": "Joaquim Gomes",
        "state": "AL"
    },
    {
        "name": "Jundiá",
        "state": "AL"
    },
    {
        "name": "Junqueiro",
        "state": "AL"
    },
    {
        "name": "Lagoa da Canoa",
        "state": "AL"
    },
    {
        "name": "Limoeiro de Anadia",
        "state": "AL"
    },
    {
        "name": "Maceió",
        "state": "AL"
    },
    {
        "name": "Major Isidoro",
        "state": "AL"
    },
    {
        "name": "Mar Vermelho",
        "state": "AL"
    },
    {
        "name": "Maragogi",
        "state": "AL"
    },
    {
        "name": "Maravilha",
        "state": "AL"
    },
    {
        "name": "Marechal Deodoro",
        "state": "AL"
    },
    {
        "name": "Maribondo",
        "state": "AL"
    },
    {
        "name": "Mata Grande",
        "state": "AL"
    },
    {
        "name": "Matriz de Camaragibe",
        "state": "AL"
    },
    {
        "name": "Messias",
        "state": "AL"
    },
    {
        "name": "Minador do Negrão",
        "state": "AL"
    },
    {
        "name": "Monteirópolis",
        "state": "AL"
    },
    {
        "name": "Murici",
        "state": "AL"
    },
    {
        "name": "Novo Lino",
        "state": "AL"
    },
    {
        "name": "Olho d`Água das Flores",
        "state": "AL"
    },
    {
        "name": "Olho d`Água do Casado",
        "state": "AL"
    },
    {
        "name": "Olho d`Água Grande",
        "state": "AL"
    },
    {
        "name": "Olivença",
        "state": "AL"
    },
    {
        "name": "Ouro Branco",
        "state": "AL"
    },
    {
        "name": "Palestina",
        "state": "AL"
    },
    {
        "name": "Palmeira dos Índios",
        "state": "AL"
    },
    {
        "name": "Pão de Açúcar",
        "state": "AL"
    },
    {
        "name": "Pariconha",
        "state": "AL"
    },
    {
        "name": "Paripueira",
        "state": "AL"
    },
    {
        "name": "Passo de Camaragibe",
        "state": "AL"
    },
    {
        "name": "Paulo Jacinto",
        "state": "AL"
    },
    {
        "name": "Penedo",
        "state": "AL"
    },
    {
        "name": "Piaçabuçu",
        "state": "AL"
    },
    {
        "name": "Pilar",
        "state": "AL"
    },
    {
        "name": "Pindoba",
        "state": "AL"
    },
    {
        "name": "Piranhas",
        "state": "AL"
    },
    {
        "name": "Poço das Trincheiras",
        "state": "AL"
    },
    {
        "name": "Porto Calvo",
        "state": "AL"
    },
    {
        "name": "Porto de Pedras",
        "state": "AL"
    },
    {
        "name": "Porto Real do Colégio",
        "state": "AL"
    },
    {
        "name": "Quebrangulo",
        "state": "AL"
    },
    {
        "name": "Rio Largo",
        "state": "AL"
    },
    {
        "name": "Roteiro",
        "state": "AL"
    },
    {
        "name": "Santa Luzia do Norte",
        "state": "AL"
    },
    {
        "name": "Santana do Ipanema",
        "state": "AL"
    },
    {
        "name": "Santana do Mundaú",
        "state": "AL"
    },
    {
        "name": "São Brás",
        "state": "AL"
    },
    {
        "name": "São José da Laje",
        "state": "AL"
    },
    {
        "name": "São José da Tapera",
        "state": "AL"
    },
    {
        "name": "São Luís do Quitunde",
        "state": "AL"
    },
    {
        "name": "São Miguel dos Campos",
        "state": "AL"
    },
    {
        "name": "São Miguel dos Milagres",
        "state": "AL"
    },
    {
        "name": "São Sebastião",
        "state": "AL"
    },
    {
        "name": "Satuba",
        "state": "AL"
    },
    {
        "name": "Senador Rui Palmeira",
        "state": "AL"
    },
    {
        "name": "Tanque d`Arca",
        "state": "AL"
    },
    {
        "name": "Taquarana",
        "state": "AL"
    },
    {
        "name": "Teotônio Vilela",
        "state": "AL"
    },
    {
        "name": "Traipu",
        "state": "AL"
    },
    {
        "name": "União dos Palmares",
        "state": "AL"
    },
    {
        "name": "Viçosa",
        "state": "AL"
    },
    {
        "name": "Amapá",
        "state": "AP"
    },
    {
        "name": "Calçoene",
        "state": "AP"
    },
    {
        "name": "Cutias",
        "state": "AP"
    },
    {
        "name": "Ferreira Gomes",
        "state": "AP"
    },
    {
        "name": "Itaubal",
        "state": "AP"
    },
    {
        "name": "Laranjal do Jari",
        "state": "AP"
    },
    {
        "name": "Macapá",
        "state": "AP"
    },
    {
        "name": "Mazagão",
        "state": "AP"
    },
    {
        "name": "Oiapoque",
        "state": "AP"
    },
    {
        "name": "Pedra Branca do Amaparí",
        "state": "AP"
    },
    {
        "name": "Porto Grande",
        "state": "AP"
    },
    {
        "name": "Pracuúba",
        "state": "AP"
    },
    {
        "name": "Santana",
        "state": "AP"
    },
    {
        "name": "Serra do Navio",
        "state": "AP"
    },
    {
        "name": "Tartarugalzinho",
        "state": "AP"
    },
    {
        "name": "Vitória do Jari",
        "state": "AP"
    },
    {
        "name": "Alvarães",
        "state": "AM"
    },
    {
        "name": "Amaturá",
        "state": "AM"
    },
    {
        "name": "Anamã",
        "state": "AM"
    },
    {
        "name": "Anori",
        "state": "AM"
    },
    {
        "name": "Apuí",
        "state": "AM"
    },
    {
        "name": "Atalaia do Norte",
        "state": "AM"
    },
    {
        "name": "Autazes",
        "state": "AM"
    },
    {
        "name": "Barcelos",
        "state": "AM"
    },
    {
        "name": "Barreirinha",
        "state": "AM"
    },
    {
        "name": "Benjamin Constant",
        "state": "AM"
    },
    {
        "name": "Beruri",
        "state": "AM"
    },
    {
        "name": "Boa Vista do Ramos",
        "state": "AM"
    },
    {
        "name": "Boca do Acre",
        "state": "AM"
    },
    {
        "name": "Borba",
        "state": "AM"
    },
    {
        "name": "Caapiranga",
        "state": "AM"
    },
    {
        "name": "Canutama",
        "state": "AM"
    },
    {
        "name": "Carauari",
        "state": "AM"
    },
    {
        "name": "Careiro",
        "state": "AM"
    },
    {
        "name": "Careiro da Várzea",
        "state": "AM"
    },
    {
        "name": "Coari",
        "state": "AM"
    },
    {
        "name": "Codajás",
        "state": "AM"
    },
    {
        "name": "Eirunepé",
        "state": "AM"
    },
    {
        "name": "Envira",
        "state": "AM"
    },
    {
        "name": "Fonte Boa",
        "state": "AM"
    },
    {
        "name": "Guajará",
        "state": "AM"
    },
    {
        "name": "Humaitá",
        "state": "AM"
    },
    {
        "name": "Ipixuna",
        "state": "AM"
    },
    {
        "name": "Iranduba",
        "state": "AM"
    },
    {
        "name": "Itacoatiara",
        "state": "AM"
    },
    {
        "name": "Itamarati",
        "state": "AM"
    },
    {
        "name": "Itapiranga",
        "state": "AM"
    },
    {
        "name": "Japurá",
        "state": "AM"
    },
    {
        "name": "Juruá",
        "state": "AM"
    },
    {
        "name": "Jutaí",
        "state": "AM"
    },
    {
        "name": "Lábrea",
        "state": "AM"
    },
    {
        "name": "Manacapuru",
        "state": "AM"
    },
    {
        "name": "Manaquiri",
        "state": "AM"
    },
    {
        "name": "Manaus",
        "state": "AM"
    },
    {
        "name": "Manicoré",
        "state": "AM"
    },
    {
        "name": "Maraã",
        "state": "AM"
    },
    {
        "name": "Maués",
        "state": "AM"
    },
    {
        "name": "Nhamundá",
        "state": "AM"
    },
    {
        "name": "Nova Olinda do Norte",
        "state": "AM"
    },
    {
        "name": "Novo Airão",
        "state": "AM"
    },
    {
        "name": "Novo Aripuanã",
        "state": "AM"
    },
    {
        "name": "Parintins",
        "state": "AM"
    },
    {
        "name": "Pauini",
        "state": "AM"
    },
    {
        "name": "Presidente Figueiredo",
        "state": "AM"
    },
    {
        "name": "Rio Preto da Eva",
        "state": "AM"
    },
    {
        "name": "Santa Isabel do Rio Negro",
        "state": "AM"
    },
    {
        "name": "Santo Antônio do Içá",
        "state": "AM"
    },
    {
        "name": "São Gabriel da Cachoeira",
        "state": "AM"
    },
    {
        "name": "São Paulo de Olivença",
        "state": "AM"
    },
    {
        "name": "São Sebastião do Uatumã",
        "state": "AM"
    },
    {
        "name": "Silves",
        "state": "AM"
    },
    {
        "name": "Tabatinga",
        "state": "AM"
    },
    {
        "name": "Tapauá",
        "state": "AM"
    },
    {
        "name": "Tefé",
        "state": "AM"
    },
    {
        "name": "Tonantins",
        "state": "AM"
    },
    {
        "name": "Uarini",
        "state": "AM"
    },
    {
        "name": "Urucará",
        "state": "AM"
    },
    {
        "name": "Urucurituba",
        "state": "AM"
    },
    {
        "name": "Abaíra",
        "state": "BA"
    },
    {
        "name": "Abaré",
        "state": "BA"
    },
    {
        "name": "Acajutiba",
        "state": "BA"
    },
    {
        "name": "Adustina",
        "state": "BA"
    },
    {
        "name": "Água Fria",
        "state": "BA"
    },
    {
        "name": "Aiquara",
        "state": "BA"
    },
    {
        "name": "Alagoinhas",
        "state": "BA"
    },
    {
        "name": "Alcobaça",
        "state": "BA"
    },
    {
        "name": "Almadina",
        "state": "BA"
    },
    {
        "name": "Amargosa",
        "state": "BA"
    },
    {
        "name": "Amélia Rodrigues",
        "state": "BA"
    },
    {
        "name": "América Dourada",
        "state": "BA"
    },
    {
        "name": "Anagé",
        "state": "BA"
    },
    {
        "name": "Andaraí",
        "state": "BA"
    },
    {
        "name": "Andorinha",
        "state": "BA"
    },
    {
        "name": "Angical",
        "state": "BA"
    },
    {
        "name": "Anguera",
        "state": "BA"
    },
    {
        "name": "Antas",
        "state": "BA"
    },
    {
        "name": "Antônio Cardoso",
        "state": "BA"
    },
    {
        "name": "Antônio Gonçalves",
        "state": "BA"
    },
    {
        "name": "Aporá",
        "state": "BA"
    },
    {
        "name": "Apuarema",
        "state": "BA"
    },
    {
        "name": "Araças",
        "state": "BA"
    },
    {
        "name": "Aracatu",
        "state": "BA"
    },
    {
        "name": "Araci",
        "state": "BA"
    },
    {
        "name": "Aramari",
        "state": "BA"
    },
    {
        "name": "Arataca",
        "state": "BA"
    },
    {
        "name": "Aratuípe",
        "state": "BA"
    },
    {
        "name": "Aurelino Leal",
        "state": "BA"
    },
    {
        "name": "Baianópolis",
        "state": "BA"
    },
    {
        "name": "Baixa Grande",
        "state": "BA"
    },
    {
        "name": "Banzaê",
        "state": "BA"
    },
    {
        "name": "Barra",
        "state": "BA"
    },
    {
        "name": "Barra da Estiva",
        "state": "BA"
    },
    {
        "name": "Barra do Choça",
        "state": "BA"
    },
    {
        "name": "Barra do Mendes",
        "state": "BA"
    },
    {
        "name": "Barra do Rocha",
        "state": "BA"
    },
    {
        "name": "Barreiras",
        "state": "BA"
    },
    {
        "name": "Barro Alto",
        "state": "BA"
    },
    {
        "name": "Barro Preto (antigo Gov. Lomanto Jr.)",
        "state": "BA"
    },
    {
        "name": "Barrocas",
        "state": "BA"
    },
    {
        "name": "Belmonte",
        "state": "BA"
    },
    {
        "name": "Belo Campo",
        "state": "BA"
    },
    {
        "name": "Biritinga",
        "state": "BA"
    },
    {
        "name": "Boa Nova",
        "state": "BA"
    },
    {
        "name": "Boa Vista do Tupim",
        "state": "BA"
    },
    {
        "name": "Bom Jesus da Lapa",
        "state": "BA"
    },
    {
        "name": "Bom Jesus da Serra",
        "state": "BA"
    },
    {
        "name": "Boninal",
        "state": "BA"
    },
    {
        "name": "Bonito",
        "state": "BA"
    },
    {
        "name": "Boquira",
        "state": "BA"
    },
    {
        "name": "Botuporã",
        "state": "BA"
    },
    {
        "name": "Brejões",
        "state": "BA"
    },
    {
        "name": "Brejolândia",
        "state": "BA"
    },
    {
        "name": "Brotas de Macaúbas",
        "state": "BA"
    },
    {
        "name": "Brumado",
        "state": "BA"
    },
    {
        "name": "Buerarema",
        "state": "BA"
    },
    {
        "name": "Buritirama",
        "state": "BA"
    },
    {
        "name": "Caatiba",
        "state": "BA"
    },
    {
        "name": "Cabaceiras do Paraguaçu",
        "state": "BA"
    },
    {
        "name": "Cachoeira",
        "state": "BA"
    },
    {
        "name": "Caculé",
        "state": "BA"
    },
    {
        "name": "Caém",
        "state": "BA"
    },
    {
        "name": "Caetanos",
        "state": "BA"
    },
    {
        "name": "Caetité",
        "state": "BA"
    },
    {
        "name": "Cafarnaum",
        "state": "BA"
    },
    {
        "name": "Cairu",
        "state": "BA"
    },
    {
        "name": "Caldeirão Grande",
        "state": "BA"
    },
    {
        "name": "Camacan",
        "state": "BA"
    },
    {
        "name": "Camaçari",
        "state": "BA"
    },
    {
        "name": "Camamu",
        "state": "BA"
    },
    {
        "name": "Campo Alegre de Lourdes",
        "state": "BA"
    },
    {
        "name": "Campo Formoso",
        "state": "BA"
    },
    {
        "name": "Canápolis",
        "state": "BA"
    },
    {
        "name": "Canarana",
        "state": "BA"
    },
    {
        "name": "Canavieiras",
        "state": "BA"
    },
    {
        "name": "Candeal",
        "state": "BA"
    },
    {
        "name": "Candeias",
        "state": "BA"
    },
    {
        "name": "Candiba",
        "state": "BA"
    },
    {
        "name": "Cândido Sales",
        "state": "BA"
    },
    {
        "name": "Cansanção",
        "state": "BA"
    },
    {
        "name": "Canudos",
        "state": "BA"
    },
    {
        "name": "Capela do Alto Alegre",
        "state": "BA"
    },
    {
        "name": "Capim Grosso",
        "state": "BA"
    },
    {
        "name": "Caraíbas",
        "state": "BA"
    },
    {
        "name": "Caravelas",
        "state": "BA"
    },
    {
        "name": "Cardeal da Silva",
        "state": "BA"
    },
    {
        "name": "Carinhanha",
        "state": "BA"
    },
    {
        "name": "Casa Nova",
        "state": "BA"
    },
    {
        "name": "Castro Alves",
        "state": "BA"
    },
    {
        "name": "Catolândia",
        "state": "BA"
    },
    {
        "name": "Catu",
        "state": "BA"
    },
    {
        "name": "Caturama",
        "state": "BA"
    },
    {
        "name": "Central",
        "state": "BA"
    },
    {
        "name": "Chorrochó",
        "state": "BA"
    },
    {
        "name": "Cícero Dantas",
        "state": "BA"
    },
    {
        "name": "Cipó",
        "state": "BA"
    },
    {
        "name": "Coaraci",
        "state": "BA"
    },
    {
        "name": "Cocos",
        "state": "BA"
    },
    {
        "name": "Conceição da Feira",
        "state": "BA"
    },
    {
        "name": "Conceição do Almeida",
        "state": "BA"
    },
    {
        "name": "Conceição do Coité",
        "state": "BA"
    },
    {
        "name": "Conceição do Jacuípe",
        "state": "BA"
    },
    {
        "name": "Conde",
        "state": "BA"
    },
    {
        "name": "Condeúba",
        "state": "BA"
    },
    {
        "name": "Contendas do Sincorá",
        "state": "BA"
    },
    {
        "name": "Coração de Maria",
        "state": "BA"
    },
    {
        "name": "Cordeiros",
        "state": "BA"
    },
    {
        "name": "Coribe",
        "state": "BA"
    },
    {
        "name": "Coronel João Sá",
        "state": "BA"
    },
    {
        "name": "Correntina",
        "state": "BA"
    },
    {
        "name": "Cotegipe",
        "state": "BA"
    },
    {
        "name": "Cravolândia",
        "state": "BA"
    },
    {
        "name": "Crisópolis",
        "state": "BA"
    },
    {
        "name": "Cristópolis",
        "state": "BA"
    },
    {
        "name": "Cruz das Almas",
        "state": "BA"
    },
    {
        "name": "Curaçá",
        "state": "BA"
    },
    {
        "name": "Dário Meira",
        "state": "BA"
    },
    {
        "name": "Dias d`Ávila",
        "state": "BA"
    },
    {
        "name": "Dom Basílio",
        "state": "BA"
    },
    {
        "name": "Dom Macedo Costa",
        "state": "BA"
    },
    {
        "name": "Elísio Medrado",
        "state": "BA"
    },
    {
        "name": "Encruzilhada",
        "state": "BA"
    },
    {
        "name": "Entre Rios",
        "state": "BA"
    },
    {
        "name": "Érico Cardoso",
        "state": "BA"
    },
    {
        "name": "Esplanada",
        "state": "BA"
    },
    {
        "name": "Euclides da Cunha",
        "state": "BA"
    },
    {
        "name": "Eunápolis",
        "state": "BA"
    },
    {
        "name": "Fátima",
        "state": "BA"
    },
    {
        "name": "Feira da Mata",
        "state": "BA"
    },
    {
        "name": "Feira de Santana",
        "state": "BA"
    },
    {
        "name": "Filadélfia",
        "state": "BA"
    },
    {
        "name": "Firmino Alves",
        "state": "BA"
    },
    {
        "name": "Floresta Azul",
        "state": "BA"
    },
    {
        "name": "Formosa do Rio Preto",
        "state": "BA"
    },
    {
        "name": "Gandu",
        "state": "BA"
    },
    {
        "name": "Gavião",
        "state": "BA"
    },
    {
        "name": "Gentio do Ouro",
        "state": "BA"
    },
    {
        "name": "Glória",
        "state": "BA"
    },
    {
        "name": "Gongogi",
        "state": "BA"
    },
    {
        "name": "Governador Mangabeira",
        "state": "BA"
    },
    {
        "name": "Guajeru",
        "state": "BA"
    },
    {
        "name": "Guanambi",
        "state": "BA"
    },
    {
        "name": "Guaratinga",
        "state": "BA"
    },
    {
        "name": "Heliópolis",
        "state": "BA"
    },
    {
        "name": "Iaçu",
        "state": "BA"
    },
    {
        "name": "Ibiassucê",
        "state": "BA"
    },
    {
        "name": "Ibicaraí",
        "state": "BA"
    },
    {
        "name": "Ibicoara",
        "state": "BA"
    },
    {
        "name": "Ibicuí",
        "state": "BA"
    },
    {
        "name": "Ibipeba",
        "state": "BA"
    },
    {
        "name": "Ibipitanga",
        "state": "BA"
    },
    {
        "name": "Ibiquera",
        "state": "BA"
    },
    {
        "name": "Ibirapitanga",
        "state": "BA"
    },
    {
        "name": "Ibirapuã",
        "state": "BA"
    },
    {
        "name": "Ibirataia",
        "state": "BA"
    },
    {
        "name": "Ibitiara",
        "state": "BA"
    },
    {
        "name": "Ibititá",
        "state": "BA"
    },
    {
        "name": "Ibotirama",
        "state": "BA"
    },
    {
        "name": "Ichu",
        "state": "BA"
    },
    {
        "name": "Igaporã",
        "state": "BA"
    },
    {
        "name": "Igrapiúna",
        "state": "BA"
    },
    {
        "name": "Iguaí",
        "state": "BA"
    },
    {
        "name": "Ilhéus",
        "state": "BA"
    },
    {
        "name": "Inhambupe",
        "state": "BA"
    },
    {
        "name": "Ipecaetá",
        "state": "BA"
    },
    {
        "name": "Ipiaú",
        "state": "BA"
    },
    {
        "name": "Ipirá",
        "state": "BA"
    },
    {
        "name": "Ipupiara",
        "state": "BA"
    },
    {
        "name": "Irajuba",
        "state": "BA"
    },
    {
        "name": "Iramaia",
        "state": "BA"
    },
    {
        "name": "Iraquara",
        "state": "BA"
    },
    {
        "name": "Irará",
        "state": "BA"
    },
    {
        "name": "Irecê",
        "state": "BA"
    },
    {
        "name": "Itabela",
        "state": "BA"
    },
    {
        "name": "Itaberaba",
        "state": "BA"
    },
    {
        "name": "Itabuna",
        "state": "BA"
    },
    {
        "name": "Itacaré",
        "state": "BA"
    },
    {
        "name": "Itaeté",
        "state": "BA"
    },
    {
        "name": "Itagi",
        "state": "BA"
    },
    {
        "name": "Itagibá",
        "state": "BA"
    },
    {
        "name": "Itagimirim",
        "state": "BA"
    },
    {
        "name": "Itaguaçu da Bahia",
        "state": "BA"
    },
    {
        "name": "Itaju do Colônia",
        "state": "BA"
    },
    {
        "name": "Itajuípe",
        "state": "BA"
    },
    {
        "name": "Itamaraju",
        "state": "BA"
    },
    {
        "name": "Itamari",
        "state": "BA"
    },
    {
        "name": "Itambé",
        "state": "BA"
    },
    {
        "name": "Itanagra",
        "state": "BA"
    },
    {
        "name": "Itanhém",
        "state": "BA"
    },
    {
        "name": "Itaparica",
        "state": "BA"
    },
    {
        "name": "Itapé",
        "state": "BA"
    },
    {
        "name": "Itapebi",
        "state": "BA"
    },
    {
        "name": "Itapetinga",
        "state": "BA"
    },
    {
        "name": "Itapicuru",
        "state": "BA"
    },
    {
        "name": "Itapitanga",
        "state": "BA"
    },
    {
        "name": "Itaquara",
        "state": "BA"
    },
    {
        "name": "Itarantim",
        "state": "BA"
    },
    {
        "name": "Itatim",
        "state": "BA"
    },
    {
        "name": "Itiruçu",
        "state": "BA"
    },
    {
        "name": "Itiúba",
        "state": "BA"
    },
    {
        "name": "Itororó",
        "state": "BA"
    },
    {
        "name": "Ituaçu",
        "state": "BA"
    },
    {
        "name": "Ituberá",
        "state": "BA"
    },
    {
        "name": "Iuiú",
        "state": "BA"
    },
    {
        "name": "Jaborandi",
        "state": "BA"
    },
    {
        "name": "Jacaraci",
        "state": "BA"
    },
    {
        "name": "Jacobina",
        "state": "BA"
    },
    {
        "name": "Jaguaquara",
        "state": "BA"
    },
    {
        "name": "Jaguarari",
        "state": "BA"
    },
    {
        "name": "Jaguaripe",
        "state": "BA"
    },
    {
        "name": "Jandaíra",
        "state": "BA"
    },
    {
        "name": "Jequié",
        "state": "BA"
    },
    {
        "name": "Jeremoabo",
        "state": "BA"
    },
    {
        "name": "Jiquiriçá",
        "state": "BA"
    },
    {
        "name": "Jitaúna",
        "state": "BA"
    },
    {
        "name": "João Dourado",
        "state": "BA"
    },
    {
        "name": "Juazeiro",
        "state": "BA"
    },
    {
        "name": "Jucuruçu",
        "state": "BA"
    },
    {
        "name": "Jussara",
        "state": "BA"
    },
    {
        "name": "Jussari",
        "state": "BA"
    },
    {
        "name": "Jussiape",
        "state": "BA"
    },
    {
        "name": "Lafaiete Coutinho",
        "state": "BA"
    },
    {
        "name": "Lagoa Real",
        "state": "BA"
    },
    {
        "name": "Laje",
        "state": "BA"
    },
    {
        "name": "Lajedão",
        "state": "BA"
    },
    {
        "name": "Lajedinho",
        "state": "BA"
    },
    {
        "name": "Lajedo do Tabocal",
        "state": "BA"
    },
    {
        "name": "Lamarão",
        "state": "BA"
    },
    {
        "name": "Lapão",
        "state": "BA"
    },
    {
        "name": "Lauro de Freitas",
        "state": "BA"
    },
    {
        "name": "Lençóis",
        "state": "BA"
    },
    {
        "name": "Licínio de Almeida",
        "state": "BA"
    },
    {
        "name": "Livramento de Nossa Senhora",
        "state": "BA"
    },
    {
        "name": "Luís Eduardo Magalhães",
        "state": "BA"
    },
    {
        "name": "Macajuba",
        "state": "BA"
    },
    {
        "name": "Macarani",
        "state": "BA"
    },
    {
        "name": "Macaúbas",
        "state": "BA"
    },
    {
        "name": "Macururé",
        "state": "BA"
    },
    {
        "name": "Madre de Deus",
        "state": "BA"
    },
    {
        "name": "Maetinga",
        "state": "BA"
    },
    {
        "name": "Maiquinique",
        "state": "BA"
    },
    {
        "name": "Mairi",
        "state": "BA"
    },
    {
        "name": "Malhada",
        "state": "BA"
    },
    {
        "name": "Malhada de Pedras",
        "state": "BA"
    },
    {
        "name": "Manoel Vitorino",
        "state": "BA"
    },
    {
        "name": "Mansidão",
        "state": "BA"
    },
    {
        "name": "Maracás",
        "state": "BA"
    },
    {
        "name": "Maragogipe",
        "state": "BA"
    },
    {
        "name": "Maraú",
        "state": "BA"
    },
    {
        "name": "Marcionílio Souza",
        "state": "BA"
    },
    {
        "name": "Mascote",
        "state": "BA"
    },
    {
        "name": "Mata de São João",
        "state": "BA"
    },
    {
        "name": "Matina",
        "state": "BA"
    },
    {
        "name": "Medeiros Neto",
        "state": "BA"
    },
    {
        "name": "Miguel Calmon",
        "state": "BA"
    },
    {
        "name": "Milagres",
        "state": "BA"
    },
    {
        "name": "Mirangaba",
        "state": "BA"
    },
    {
        "name": "Mirante",
        "state": "BA"
    },
    {
        "name": "Monte Santo",
        "state": "BA"
    },
    {
        "name": "Morpará",
        "state": "BA"
    },
    {
        "name": "Morro do Chapéu",
        "state": "BA"
    },
    {
        "name": "Mortugaba",
        "state": "BA"
    },
    {
        "name": "Mucugê",
        "state": "BA"
    },
    {
        "name": "Mucuri",
        "state": "BA"
    },
    {
        "name": "Mulungu do Morro",
        "state": "BA"
    },
    {
        "name": "Mundo Novo",
        "state": "BA"
    },
    {
        "name": "Muniz Ferreira",
        "state": "BA"
    },
    {
        "name": "Muquém de São Francisco",
        "state": "BA"
    },
    {
        "name": "Muritiba",
        "state": "BA"
    },
    {
        "name": "Mutuípe",
        "state": "BA"
    },
    {
        "name": "Nazaré",
        "state": "BA"
    },
    {
        "name": "Nilo Peçanha",
        "state": "BA"
    },
    {
        "name": "Nordestina",
        "state": "BA"
    },
    {
        "name": "Nova Canaã",
        "state": "BA"
    },
    {
        "name": "Nova Fátima",
        "state": "BA"
    },
    {
        "name": "Nova Ibiá",
        "state": "BA"
    },
    {
        "name": "Nova Itarana",
        "state": "BA"
    },
    {
        "name": "Nova Redenção",
        "state": "BA"
    },
    {
        "name": "Nova Soure",
        "state": "BA"
    },
    {
        "name": "Nova Viçosa",
        "state": "BA"
    },
    {
        "name": "Novo Horizonte",
        "state": "BA"
    },
    {
        "name": "Novo Triunfo",
        "state": "BA"
    },
    {
        "name": "Olindina",
        "state": "BA"
    },
    {
        "name": "Oliveira dos Brejinhos",
        "state": "BA"
    },
    {
        "name": "Ouriçangas",
        "state": "BA"
    },
    {
        "name": "Ourolândia",
        "state": "BA"
    },
    {
        "name": "Palmas de Monte Alto",
        "state": "BA"
    },
    {
        "name": "Palmeiras",
        "state": "BA"
    },
    {
        "name": "Paramirim",
        "state": "BA"
    },
    {
        "name": "Paratinga",
        "state": "BA"
    },
    {
        "name": "Paripiranga",
        "state": "BA"
    },
    {
        "name": "Pau Brasil",
        "state": "BA"
    },
    {
        "name": "Paulo Afonso",
        "state": "BA"
    },
    {
        "name": "Pé de Serra",
        "state": "BA"
    },
    {
        "name": "Pedrão",
        "state": "BA"
    },
    {
        "name": "Pedro Alexandre",
        "state": "BA"
    },
    {
        "name": "Piatã",
        "state": "BA"
    },
    {
        "name": "Pilão Arcado",
        "state": "BA"
    },
    {
        "name": "Pindaí",
        "state": "BA"
    },
    {
        "name": "Pindobaçu",
        "state": "BA"
    },
    {
        "name": "Pintadas",
        "state": "BA"
    },
    {
        "name": "Piraí do Norte",
        "state": "BA"
    },
    {
        "name": "Piripá",
        "state": "BA"
    },
    {
        "name": "Piritiba",
        "state": "BA"
    },
    {
        "name": "Planaltino",
        "state": "BA"
    },
    {
        "name": "Planalto",
        "state": "BA"
    },
    {
        "name": "Poções",
        "state": "BA"
    },
    {
        "name": "Pojuca",
        "state": "BA"
    },
    {
        "name": "Ponto Novo",
        "state": "BA"
    },
    {
        "name": "Porto Seguro",
        "state": "BA"
    },
    {
        "name": "Potiraguá",
        "state": "BA"
    },
    {
        "name": "Prado",
        "state": "BA"
    },
    {
        "name": "Presidente Dutra",
        "state": "BA"
    },
    {
        "name": "Presidente Jânio Quadros",
        "state": "BA"
    },
    {
        "name": "Presidente Tancredo Neves",
        "state": "BA"
    },
    {
        "name": "Queimadas",
        "state": "BA"
    },
    {
        "name": "Quijingue",
        "state": "BA"
    },
    {
        "name": "Quixabeira",
        "state": "BA"
    },
    {
        "name": "Rafael Jambeiro",
        "state": "BA"
    },
    {
        "name": "Remanso",
        "state": "BA"
    },
    {
        "name": "Retirolândia",
        "state": "BA"
    },
    {
        "name": "Riachão das Neves",
        "state": "BA"
    },
    {
        "name": "Riachão do Jacuípe",
        "state": "BA"
    },
    {
        "name": "Riacho de Santana",
        "state": "BA"
    },
    {
        "name": "Ribeira do Amparo",
        "state": "BA"
    },
    {
        "name": "Ribeira do Pombal",
        "state": "BA"
    },
    {
        "name": "Ribeirão do Largo",
        "state": "BA"
    },
    {
        "name": "Rio de Contas",
        "state": "BA"
    },
    {
        "name": "Rio do Antônio",
        "state": "BA"
    },
    {
        "name": "Rio do Pires",
        "state": "BA"
    },
    {
        "name": "Rio Real",
        "state": "BA"
    },
    {
        "name": "Rodelas",
        "state": "BA"
    },
    {
        "name": "Ruy Barbosa",
        "state": "BA"
    },
    {
        "name": "Salinas da Margarida",
        "state": "BA"
    },
    {
        "name": "Salvador",
        "state": "BA"
    },
    {
        "name": "Santa Bárbara",
        "state": "BA"
    },
    {
        "name": "Santa Brígida",
        "state": "BA"
    },
    {
        "name": "Santa Cruz Cabrália",
        "state": "BA"
    },
    {
        "name": "Santa Cruz da Vitória",
        "state": "BA"
    },
    {
        "name": "Santa Inês",
        "state": "BA"
    },
    {
        "name": "Santa Luzia",
        "state": "BA"
    },
    {
        "name": "Santa Maria da Vitória",
        "state": "BA"
    },
    {
        "name": "Santa Rita de Cássia",
        "state": "BA"
    },
    {
        "name": "Santa Teresinha",
        "state": "BA"
    },
    {
        "name": "Santaluz",
        "state": "BA"
    },
    {
        "name": "Santana",
        "state": "BA"
    },
    {
        "name": "Santanópolis",
        "state": "BA"
    },
    {
        "name": "Santo Amaro",
        "state": "BA"
    },
    {
        "name": "Santo Antônio de Jesus",
        "state": "BA"
    },
    {
        "name": "Santo Estêvão",
        "state": "BA"
    },
    {
        "name": "São Desidério",
        "state": "BA"
    },
    {
        "name": "São Domingos",
        "state": "BA"
    },
    {
        "name": "São Felipe",
        "state": "BA"
    },
    {
        "name": "São Félix",
        "state": "BA"
    },
    {
        "name": "São Félix do Coribe",
        "state": "BA"
    },
    {
        "name": "São Francisco do Conde",
        "state": "BA"
    },
    {
        "name": "São Gabriel",
        "state": "BA"
    },
    {
        "name": "São Gonçalo dos Campos",
        "state": "BA"
    },
    {
        "name": "São José da Vitória",
        "state": "BA"
    },
    {
        "name": "São José do Jacuípe",
        "state": "BA"
    },
    {
        "name": "São Miguel das Matas",
        "state": "BA"
    },
    {
        "name": "São Sebastião do Passé",
        "state": "BA"
    },
    {
        "name": "Sapeaçu",
        "state": "BA"
    },
    {
        "name": "Sátiro Dias",
        "state": "BA"
    },
    {
        "name": "Saubara",
        "state": "BA"
    },
    {
        "name": "Saúde",
        "state": "BA"
    },
    {
        "name": "Seabra",
        "state": "BA"
    },
    {
        "name": "Sebastião Laranjeiras",
        "state": "BA"
    },
    {
        "name": "Senhor do Bonfim",
        "state": "BA"
    },
    {
        "name": "Sento Sé",
        "state": "BA"
    },
    {
        "name": "Serra do Ramalho",
        "state": "BA"
    },
    {
        "name": "Serra Dourada",
        "state": "BA"
    },
    {
        "name": "Serra Preta",
        "state": "BA"
    },
    {
        "name": "Serrinha",
        "state": "BA"
    },
    {
        "name": "Serrolândia",
        "state": "BA"
    },
    {
        "name": "Simões Filho",
        "state": "BA"
    },
    {
        "name": "Sítio do Mato",
        "state": "BA"
    },
    {
        "name": "Sítio do Quinto",
        "state": "BA"
    },
    {
        "name": "Sobradinho",
        "state": "BA"
    },
    {
        "name": "Souto Soares",
        "state": "BA"
    },
    {
        "name": "Tabocas do Brejo Velho",
        "state": "BA"
    },
    {
        "name": "Tanhaçu",
        "state": "BA"
    },
    {
        "name": "Tanque Novo",
        "state": "BA"
    },
    {
        "name": "Tanquinho",
        "state": "BA"
    },
    {
        "name": "Taperoá",
        "state": "BA"
    },
    {
        "name": "Tapiramutá",
        "state": "BA"
    },
    {
        "name": "Teixeira de Freitas",
        "state": "BA"
    },
    {
        "name": "Teodoro Sampaio",
        "state": "BA"
    },
    {
        "name": "Teofilândia",
        "state": "BA"
    },
    {
        "name": "Teolândia",
        "state": "BA"
    },
    {
        "name": "Terra Nova",
        "state": "BA"
    },
    {
        "name": "Tremedal",
        "state": "BA"
    },
    {
        "name": "Tucano",
        "state": "BA"
    },
    {
        "name": "Uauá",
        "state": "BA"
    },
    {
        "name": "Ubaíra",
        "state": "BA"
    },
    {
        "name": "Ubaitaba",
        "state": "BA"
    },
    {
        "name": "Ubatã",
        "state": "BA"
    },
    {
        "name": "Uibaí",
        "state": "BA"
    },
    {
        "name": "Umburanas",
        "state": "BA"
    },
    {
        "name": "Una",
        "state": "BA"
    },
    {
        "name": "Urandi",
        "state": "BA"
    },
    {
        "name": "Uruçuca",
        "state": "BA"
    },
    {
        "name": "Utinga",
        "state": "BA"
    },
    {
        "name": "Valença",
        "state": "BA"
    },
    {
        "name": "Valente",
        "state": "BA"
    },
    {
        "name": "Várzea da Roça",
        "state": "BA"
    },
    {
        "name": "Várzea do Poço",
        "state": "BA"
    },
    {
        "name": "Várzea Nova",
        "state": "BA"
    },
    {
        "name": "Varzedo",
        "state": "BA"
    },
    {
        "name": "Vera Cruz",
        "state": "BA"
    },
    {
        "name": "Vereda",
        "state": "BA"
    },
    {
        "name": "Vitória da Conquista",
        "state": "BA"
    },
    {
        "name": "Wagner",
        "state": "BA"
    },
    {
        "name": "Wanderley",
        "state": "BA"
    },
    {
        "name": "Wenceslau Guimarães",
        "state": "BA"
    },
    {
        "name": "Xique-Xique",
        "state": "BA"
    },
    {
        "name": "Abaiara",
        "state": "CE"
    },
    {
        "name": "Acarape",
        "state": "CE"
    },
    {
        "name": "Acaraú",
        "state": "CE"
    },
    {
        "name": "Acopiara",
        "state": "CE"
    },
    {
        "name": "Aiuaba",
        "state": "CE"
    },
    {
        "name": "Alcântaras",
        "state": "CE"
    },
    {
        "name": "Altaneira",
        "state": "CE"
    },
    {
        "name": "Alto Santo",
        "state": "CE"
    },
    {
        "name": "Amontada",
        "state": "CE"
    },
    {
        "name": "Antonina do Norte",
        "state": "CE"
    },
    {
        "name": "Apuiarés",
        "state": "CE"
    },
    {
        "name": "Aquiraz",
        "state": "CE"
    },
    {
        "name": "Aracati",
        "state": "CE"
    },
    {
        "name": "Aracoiaba",
        "state": "CE"
    },
    {
        "name": "Ararendá",
        "state": "CE"
    },
    {
        "name": "Araripe",
        "state": "CE"
    },
    {
        "name": "Aratuba",
        "state": "CE"
    },
    {
        "name": "Arneiroz",
        "state": "CE"
    },
    {
        "name": "Assaré",
        "state": "CE"
    },
    {
        "name": "Aurora",
        "state": "CE"
    },
    {
        "name": "Baixio",
        "state": "CE"
    },
    {
        "name": "Banabuiú",
        "state": "CE"
    },
    {
        "name": "Barbalha",
        "state": "CE"
    },
    {
        "name": "Barreira",
        "state": "CE"
    },
    {
        "name": "Barro",
        "state": "CE"
    },
    {
        "name": "Barroquinha",
        "state": "CE"
    },
    {
        "name": "Baturité",
        "state": "CE"
    },
    {
        "name": "Beberibe",
        "state": "CE"
    },
    {
        "name": "Bela Cruz",
        "state": "CE"
    },
    {
        "name": "Boa Viagem",
        "state": "CE"
    },
    {
        "name": "Brejo Santo",
        "state": "CE"
    },
    {
        "name": "Camocim",
        "state": "CE"
    },
    {
        "name": "Campos Sales",
        "state": "CE"
    },
    {
        "name": "Canindé",
        "state": "CE"
    },
    {
        "name": "Capistrano",
        "state": "CE"
    },
    {
        "name": "Caridade",
        "state": "CE"
    },
    {
        "name": "Cariré",
        "state": "CE"
    },
    {
        "name": "Caririaçu",
        "state": "CE"
    },
    {
        "name": "Cariús",
        "state": "CE"
    },
    {
        "name": "Carnaubal",
        "state": "CE"
    },
    {
        "name": "Cascavel",
        "state": "CE"
    },
    {
        "name": "Catarina",
        "state": "CE"
    },
    {
        "name": "Catunda",
        "state": "CE"
    },
    {
        "name": "Caucaia",
        "state": "CE"
    },
    {
        "name": "Cedro",
        "state": "CE"
    },
    {
        "name": "Chaval",
        "state": "CE"
    },
    {
        "name": "Choró",
        "state": "CE"
    },
    {
        "name": "Chorozinho",
        "state": "CE"
    },
    {
        "name": "Coreaú",
        "state": "CE"
    },
    {
        "name": "Crateús",
        "state": "CE"
    },
    {
        "name": "Crato",
        "state": "CE"
    },
    {
        "name": "Croatá",
        "state": "CE"
    },
    {
        "name": "Cruz",
        "state": "CE"
    },
    {
        "name": "Deputado Irapuan Pinheiro",
        "state": "CE"
    },
    {
        "name": "Ererê",
        "state": "CE"
    },
    {
        "name": "Eusébio",
        "state": "CE"
    },
    {
        "name": "Farias Brito",
        "state": "CE"
    },
    {
        "name": "Forquilha",
        "state": "CE"
    },
    {
        "name": "Fortaleza",
        "state": "CE"
    },
    {
        "name": "Fortim",
        "state": "CE"
    },
    {
        "name": "Frecheirinha",
        "state": "CE"
    },
    {
        "name": "General Sampaio",
        "state": "CE"
    },
    {
        "name": "Graça",
        "state": "CE"
    },
    {
        "name": "Granja",
        "state": "CE"
    },
    {
        "name": "Granjeiro",
        "state": "CE"
    },
    {
        "name": "Groaíras",
        "state": "CE"
    },
    {
        "name": "Guaiúba",
        "state": "CE"
    },
    {
        "name": "Guaraciaba do Norte",
        "state": "CE"
    },
    {
        "name": "Guaramiranga",
        "state": "CE"
    },
    {
        "name": "Hidrolândia",
        "state": "CE"
    },
    {
        "name": "Horizonte",
        "state": "CE"
    },
    {
        "name": "Ibaretama",
        "state": "CE"
    },
    {
        "name": "Ibiapina",
        "state": "CE"
    },
    {
        "name": "Ibicuitinga",
        "state": "CE"
    },
    {
        "name": "Icapuí",
        "state": "CE"
    },
    {
        "name": "Icó",
        "state": "CE"
    },
    {
        "name": "Iguatu",
        "state": "CE"
    },
    {
        "name": "Independência",
        "state": "CE"
    },
    {
        "name": "Ipaporanga",
        "state": "CE"
    },
    {
        "name": "Ipaumirim",
        "state": "CE"
    },
    {
        "name": "Ipu",
        "state": "CE"
    },
    {
        "name": "Ipueiras",
        "state": "CE"
    },
    {
        "name": "Iracema",
        "state": "CE"
    },
    {
        "name": "Irauçuba",
        "state": "CE"
    },
    {
        "name": "Itaiçaba",
        "state": "CE"
    },
    {
        "name": "Itaitinga",
        "state": "CE"
    },
    {
        "name": "Itapagé",
        "state": "CE"
    },
    {
        "name": "Itapipoca",
        "state": "CE"
    },
    {
        "name": "Itapiúna",
        "state": "CE"
    },
    {
        "name": "Itarema",
        "state": "CE"
    },
    {
        "name": "Itatira",
        "state": "CE"
    },
    {
        "name": "Jaguaretama",
        "state": "CE"
    },
    {
        "name": "Jaguaribara",
        "state": "CE"
    },
    {
        "name": "Jaguaribe",
        "state": "CE"
    },
    {
        "name": "Jaguaruana",
        "state": "CE"
    },
    {
        "name": "Jardim",
        "state": "CE"
    },
    {
        "name": "Jati",
        "state": "CE"
    },
    {
        "name": "Jijoca de Jericoacoara",
        "state": "CE"
    },
    {
        "name": "Juazeiro do Norte",
        "state": "CE"
    },
    {
        "name": "Jucás",
        "state": "CE"
    },
    {
        "name": "Lavras da Mangabeira",
        "state": "CE"
    },
    {
        "name": "Limoeiro do Norte",
        "state": "CE"
    },
    {
        "name": "Madalena",
        "state": "CE"
    },
    {
        "name": "Maracanaú",
        "state": "CE"
    },
    {
        "name": "Maranguape",
        "state": "CE"
    },
    {
        "name": "Marco",
        "state": "CE"
    },
    {
        "name": "Martinópole",
        "state": "CE"
    },
    {
        "name": "Massapê",
        "state": "CE"
    },
    {
        "name": "Mauriti",
        "state": "CE"
    },
    {
        "name": "Meruoca",
        "state": "CE"
    },
    {
        "name": "Milagres",
        "state": "CE"
    },
    {
        "name": "Milhã",
        "state": "CE"
    },
    {
        "name": "Miraíma",
        "state": "CE"
    },
    {
        "name": "Missão Velha",
        "state": "CE"
    },
    {
        "name": "Mombaça",
        "state": "CE"
    },
    {
        "name": "Monsenhor Tabosa",
        "state": "CE"
    },
    {
        "name": "Morada Nova",
        "state": "CE"
    },
    {
        "name": "Moraújo",
        "state": "CE"
    },
    {
        "name": "Morrinhos",
        "state": "CE"
    },
    {
        "name": "Mucambo",
        "state": "CE"
    },
    {
        "name": "Mulungu",
        "state": "CE"
    },
    {
        "name": "Nova Olinda",
        "state": "CE"
    },
    {
        "name": "Nova Russas",
        "state": "CE"
    },
    {
        "name": "Novo Oriente",
        "state": "CE"
    },
    {
        "name": "Ocara",
        "state": "CE"
    },
    {
        "name": "Orós",
        "state": "CE"
    },
    {
        "name": "Pacajus",
        "state": "CE"
    },
    {
        "name": "Pacatuba",
        "state": "CE"
    },
    {
        "name": "Pacoti",
        "state": "CE"
    },
    {
        "name": "Pacujá",
        "state": "CE"
    },
    {
        "name": "Palhano",
        "state": "CE"
    },
    {
        "name": "Palmácia",
        "state": "CE"
    },
    {
        "name": "Paracuru",
        "state": "CE"
    },
    {
        "name": "Paraipaba",
        "state": "CE"
    },
    {
        "name": "Parambu",
        "state": "CE"
    },
    {
        "name": "Paramoti",
        "state": "CE"
    },
    {
        "name": "Pedra Branca",
        "state": "CE"
    },
    {
        "name": "Penaforte",
        "state": "CE"
    },
    {
        "name": "Pentecoste",
        "state": "CE"
    },
    {
        "name": "Pereiro",
        "state": "CE"
    },
    {
        "name": "Pindoretama",
        "state": "CE"
    },
    {
        "name": "Piquet Carneiro",
        "state": "CE"
    },
    {
        "name": "Pires Ferreira",
        "state": "CE"
    },
    {
        "name": "Poranga",
        "state": "CE"
    },
    {
        "name": "Porteiras",
        "state": "CE"
    },
    {
        "name": "Potengi",
        "state": "CE"
    },
    {
        "name": "Potiretama",
        "state": "CE"
    },
    {
        "name": "Quiterianópolis",
        "state": "CE"
    },
    {
        "name": "Quixadá",
        "state": "CE"
    },
    {
        "name": "Quixelô",
        "state": "CE"
    },
    {
        "name": "Quixeramobim",
        "state": "CE"
    },
    {
        "name": "Quixeré",
        "state": "CE"
    },
    {
        "name": "Redenção",
        "state": "CE"
    },
    {
        "name": "Reriutaba",
        "state": "CE"
    },
    {
        "name": "Russas",
        "state": "CE"
    },
    {
        "name": "Saboeiro",
        "state": "CE"
    },
    {
        "name": "Salitre",
        "state": "CE"
    },
    {
        "name": "Santa Quitéria",
        "state": "CE"
    },
    {
        "name": "Santana do Acaraú",
        "state": "CE"
    },
    {
        "name": "Santana do Cariri",
        "state": "CE"
    },
    {
        "name": "São Benedito",
        "state": "CE"
    },
    {
        "name": "São Gonçalo do Amarante",
        "state": "CE"
    },
    {
        "name": "São João do Jaguaribe",
        "state": "CE"
    },
    {
        "name": "São Luís do Curu",
        "state": "CE"
    },
    {
        "name": "Senador Pompeu",
        "state": "CE"
    },
    {
        "name": "Senador Sá",
        "state": "CE"
    },
    {
        "name": "Sobral",
        "state": "CE"
    },
    {
        "name": "Solonópole",
        "state": "CE"
    },
    {
        "name": "Tabuleiro do Norte",
        "state": "CE"
    },
    {
        "name": "Tamboril",
        "state": "CE"
    },
    {
        "name": "Tarrafas",
        "state": "CE"
    },
    {
        "name": "Tauá",
        "state": "CE"
    },
    {
        "name": "Tejuçuoca",
        "state": "CE"
    },
    {
        "name": "Tianguá",
        "state": "CE"
    },
    {
        "name": "Trairi",
        "state": "CE"
    },
    {
        "name": "Tururu",
        "state": "CE"
    },
    {
        "name": "Ubajara",
        "state": "CE"
    },
    {
        "name": "Umari",
        "state": "CE"
    },
    {
        "name": "Umirim",
        "state": "CE"
    },
    {
        "name": "Uruburetama",
        "state": "CE"
    },
    {
        "name": "Uruoca",
        "state": "CE"
    },
    {
        "name": "Varjota",
        "state": "CE"
    },
    {
        "name": "Várzea Alegre",
        "state": "CE"
    },
    {
        "name": "Viçosa do Ceará",
        "state": "CE"
    },
    {
        "name": "Brasília",
        "state": "DF"
    },
    {
        "name": "Abadia de Goiás",
        "state": "GO"
    },
    {
        "name": "Abadiânia",
        "state": "GO"
    },
    {
        "name": "Acreúna",
        "state": "GO"
    },
    {
        "name": "Adelândia",
        "state": "GO"
    },
    {
        "name": "Água Fria de Goiás",
        "state": "GO"
    },
    {
        "name": "Água Limpa",
        "state": "GO"
    },
    {
        "name": "Águas Lindas de Goiás",
        "state": "GO"
    },
    {
        "name": "Alexânia",
        "state": "GO"
    },
    {
        "name": "Aloândia",
        "state": "GO"
    },
    {
        "name": "Alto Horizonte",
        "state": "GO"
    },
    {
        "name": "Alto Paraíso de Goiás",
        "state": "GO"
    },
    {
        "name": "Alvorada do Norte",
        "state": "GO"
    },
    {
        "name": "Amaralina",
        "state": "GO"
    },
    {
        "name": "Americano do Brasil",
        "state": "GO"
    },
    {
        "name": "Amorinópolis",
        "state": "GO"
    },
    {
        "name": "Anápolis",
        "state": "GO"
    },
    {
        "name": "Anhanguera",
        "state": "GO"
    },
    {
        "name": "Anicuns",
        "state": "GO"
    },
    {
        "name": "Aparecida de Goiânia",
        "state": "GO"
    },
    {
        "name": "Aparecida do Rio Doce",
        "state": "GO"
    },
    {
        "name": "Aporé",
        "state": "GO"
    },
    {
        "name": "Araçu",
        "state": "GO"
    },
    {
        "name": "Aragarças",
        "state": "GO"
    },
    {
        "name": "Aragoiânia",
        "state": "GO"
    },
    {
        "name": "Araguapaz",
        "state": "GO"
    },
    {
        "name": "Arenópolis",
        "state": "GO"
    },
    {
        "name": "Aruanã",
        "state": "GO"
    },
    {
        "name": "Aurilândia",
        "state": "GO"
    },
    {
        "name": "Avelinópolis",
        "state": "GO"
    },
    {
        "name": "Baliza",
        "state": "GO"
    },
    {
        "name": "Barro Alto",
        "state": "GO"
    },
    {
        "name": "Bela Vista de Goiás",
        "state": "GO"
    },
    {
        "name": "Bom Jardim de Goiás",
        "state": "GO"
    },
    {
        "name": "Bom Jesus de Goiás",
        "state": "GO"
    },
    {
        "name": "Bonfinópolis",
        "state": "GO"
    },
    {
        "name": "Bonópolis",
        "state": "GO"
    },
    {
        "name": "Brazabrantes",
        "state": "GO"
    },
    {
        "name": "Britânia",
        "state": "GO"
    },
    {
        "name": "Buriti Alegre",
        "state": "GO"
    },
    {
        "name": "Buriti de Goiás",
        "state": "GO"
    },
    {
        "name": "Buritinópolis",
        "state": "GO"
    },
    {
        "name": "Cabeceiras",
        "state": "GO"
    },
    {
        "name": "Cachoeira Alta",
        "state": "GO"
    },
    {
        "name": "Cachoeira de Goiás",
        "state": "GO"
    },
    {
        "name": "Cachoeira Dourada",
        "state": "GO"
    },
    {
        "name": "Caçu",
        "state": "GO"
    },
    {
        "name": "Caiapônia",
        "state": "GO"
    },
    {
        "name": "Caldas Novas",
        "state": "GO"
    },
    {
        "name": "Caldazinha",
        "state": "GO"
    },
    {
        "name": "Campestre de Goiás",
        "state": "GO"
    },
    {
        "name": "Campinaçu",
        "state": "GO"
    },
    {
        "name": "Campinorte",
        "state": "GO"
    },
    {
        "name": "Campo Alegre de Goiás",
        "state": "GO"
    },
    {
        "name": "Campo Limpo de Goiás",
        "state": "GO"
    },
    {
        "name": "Campos Belos",
        "state": "GO"
    },
    {
        "name": "Campos Verdes",
        "state": "GO"
    },
    {
        "name": "Carmo do Rio Verde",
        "state": "GO"
    },
    {
        "name": "Castelândia",
        "state": "GO"
    },
    {
        "name": "Catalão",
        "state": "GO"
    },
    {
        "name": "Caturaí",
        "state": "GO"
    },
    {
        "name": "Cavalcante",
        "state": "GO"
    },
    {
        "name": "Ceres",
        "state": "GO"
    },
    {
        "name": "Cezarina",
        "state": "GO"
    },
    {
        "name": "Chapadão do Céu",
        "state": "GO"
    },
    {
        "name": "Cidade Ocidental",
        "state": "GO"
    },
    {
        "name": "Cocalzinho de Goiás",
        "state": "GO"
    },
    {
        "name": "Colinas do Sul",
        "state": "GO"
    },
    {
        "name": "Córrego do Ouro",
        "state": "GO"
    },
    {
        "name": "Corumbá de Goiás",
        "state": "GO"
    },
    {
        "name": "Corumbaíba",
        "state": "GO"
    },
    {
        "name": "Cristalina",
        "state": "GO"
    },
    {
        "name": "Cristianópolis",
        "state": "GO"
    },
    {
        "name": "Crixás",
        "state": "GO"
    },
    {
        "name": "Cromínia",
        "state": "GO"
    },
    {
        "name": "Cumari",
        "state": "GO"
    },
    {
        "name": "Damianópolis",
        "state": "GO"
    },
    {
        "name": "Damolândia",
        "state": "GO"
    },
    {
        "name": "Davinópolis",
        "state": "GO"
    },
    {
        "name": "Diorama",
        "state": "GO"
    },
    {
        "name": "Divinópolis de Goiás",
        "state": "GO"
    },
    {
        "name": "Doverlândia",
        "state": "GO"
    },
    {
        "name": "Edealina",
        "state": "GO"
    },
    {
        "name": "Edéia",
        "state": "GO"
    },
    {
        "name": "Estrela do Norte",
        "state": "GO"
    },
    {
        "name": "Faina",
        "state": "GO"
    },
    {
        "name": "Fazenda Nova",
        "state": "GO"
    },
    {
        "name": "Firminópolis",
        "state": "GO"
    },
    {
        "name": "Flores de Goiás",
        "state": "GO"
    },
    {
        "name": "Formosa",
        "state": "GO"
    },
    {
        "name": "Formoso",
        "state": "GO"
    },
    {
        "name": "Gameleira de Goiás",
        "state": "GO"
    },
    {
        "name": "Goianápolis",
        "state": "GO"
    },
    {
        "name": "Goiandira",
        "state": "GO"
    },
    {
        "name": "Goianésia",
        "state": "GO"
    },
    {
        "name": "Goiânia",
        "state": "GO"
    },
    {
        "name": "Goianira",
        "state": "GO"
    },
    {
        "name": "Goiás",
        "state": "GO"
    },
    {
        "name": "Goiatuba",
        "state": "GO"
    },
    {
        "name": "Gouvelândia",
        "state": "GO"
    },
    {
        "name": "Guapó",
        "state": "GO"
    },
    {
        "name": "Guaraíta",
        "state": "GO"
    },
    {
        "name": "Guarani de Goiás",
        "state": "GO"
    },
    {
        "name": "Guarinos",
        "state": "GO"
    },
    {
        "name": "Heitoraí",
        "state": "GO"
    },
    {
        "name": "Hidrolândia",
        "state": "GO"
    },
    {
        "name": "Hidrolina",
        "state": "GO"
    },
    {
        "name": "Iaciara",
        "state": "GO"
    },
    {
        "name": "Inaciolândia",
        "state": "GO"
    },
    {
        "name": "Indiara",
        "state": "GO"
    },
    {
        "name": "Inhumas",
        "state": "GO"
    },
    {
        "name": "Ipameri",
        "state": "GO"
    },
    {
        "name": "Ipiranga de Goiás",
        "state": "GO"
    },
    {
        "name": "Iporá",
        "state": "GO"
    },
    {
        "name": "Israelândia",
        "state": "GO"
    },
    {
        "name": "Itaberaí",
        "state": "GO"
    },
    {
        "name": "Itaguari",
        "state": "GO"
    },
    {
        "name": "Itaguaru",
        "state": "GO"
    },
    {
        "name": "Itajá",
        "state": "GO"
    },
    {
        "name": "Itapaci",
        "state": "GO"
    },
    {
        "name": "Itapirapuã",
        "state": "GO"
    },
    {
        "name": "Itapuranga",
        "state": "GO"
    },
    {
        "name": "Itarumã",
        "state": "GO"
    },
    {
        "name": "Itauçu",
        "state": "GO"
    },
    {
        "name": "Itumbiara",
        "state": "GO"
    },
    {
        "name": "Ivolândia",
        "state": "GO"
    },
    {
        "name": "Jandaia",
        "state": "GO"
    },
    {
        "name": "Jaraguá",
        "state": "GO"
    },
    {
        "name": "Jataí",
        "state": "GO"
    },
    {
        "name": "Jaupaci",
        "state": "GO"
    },
    {
        "name": "Jesúpolis",
        "state": "GO"
    },
    {
        "name": "Joviânia",
        "state": "GO"
    },
    {
        "name": "Jussara",
        "state": "GO"
    },
    {
        "name": "Lagoa Santa",
        "state": "GO"
    },
    {
        "name": "Leopoldo de Bulhões",
        "state": "GO"
    },
    {
        "name": "Luziânia",
        "state": "GO"
    },
    {
        "name": "Mairipotaba",
        "state": "GO"
    },
    {
        "name": "Mambaí",
        "state": "GO"
    },
    {
        "name": "Mara Rosa",
        "state": "GO"
    },
    {
        "name": "Marzagão",
        "state": "GO"
    },
    {
        "name": "Matrinchã",
        "state": "GO"
    },
    {
        "name": "Maurilândia",
        "state": "GO"
    },
    {
        "name": "Mimoso de Goiás",
        "state": "GO"
    },
    {
        "name": "Minaçu",
        "state": "GO"
    },
    {
        "name": "Mineiros",
        "state": "GO"
    },
    {
        "name": "Moiporá",
        "state": "GO"
    },
    {
        "name": "Monte Alegre de Goiás",
        "state": "GO"
    },
    {
        "name": "Montes Claros de Goiás",
        "state": "GO"
    },
    {
        "name": "Montividiu",
        "state": "GO"
    },
    {
        "name": "Montividiu do Norte",
        "state": "GO"
    },
    {
        "name": "Morrinhos",
        "state": "GO"
    },
    {
        "name": "Morro Agudo de Goiás",
        "state": "GO"
    },
    {
        "name": "Mossâmedes",
        "state": "GO"
    },
    {
        "name": "Mozarlândia",
        "state": "GO"
    },
    {
        "name": "Mundo Novo",
        "state": "GO"
    },
    {
        "name": "Mutunópolis",
        "state": "GO"
    },
    {
        "name": "Nazário",
        "state": "GO"
    },
    {
        "name": "Nerópolis",
        "state": "GO"
    },
    {
        "name": "Niquelândia",
        "state": "GO"
    },
    {
        "name": "Nova América",
        "state": "GO"
    },
    {
        "name": "Nova Aurora",
        "state": "GO"
    },
    {
        "name": "Nova Crixás",
        "state": "GO"
    },
    {
        "name": "Nova Glória",
        "state": "GO"
    },
    {
        "name": "Nova Iguaçu de Goiás",
        "state": "GO"
    },
    {
        "name": "Nova Roma",
        "state": "GO"
    },
    {
        "name": "Nova Veneza",
        "state": "GO"
    },
    {
        "name": "Novo Brasil",
        "state": "GO"
    },
    {
        "name": "Novo Gama",
        "state": "GO"
    },
    {
        "name": "Novo Planalto",
        "state": "GO"
    },
    {
        "name": "Orizona",
        "state": "GO"
    },
    {
        "name": "Ouro Verde de Goiás",
        "state": "GO"
    },
    {
        "name": "Ouvidor",
        "state": "GO"
    },
    {
        "name": "Padre Bernardo",
        "state": "GO"
    },
    {
        "name": "Palestina de Goiás",
        "state": "GO"
    },
    {
        "name": "Palmeiras de Goiás",
        "state": "GO"
    },
    {
        "name": "Palmelo",
        "state": "GO"
    },
    {
        "name": "Palminópolis",
        "state": "GO"
    },
    {
        "name": "Panamá",
        "state": "GO"
    },
    {
        "name": "Paranaiguara",
        "state": "GO"
    },
    {
        "name": "Paraúna",
        "state": "GO"
    },
    {
        "name": "Perolândia",
        "state": "GO"
    },
    {
        "name": "Petrolina de Goiás",
        "state": "GO"
    },
    {
        "name": "Pilar de Goiás",
        "state": "GO"
    },
    {
        "name": "Piracanjuba",
        "state": "GO"
    },
    {
        "name": "Piranhas",
        "state": "GO"
    },
    {
        "name": "Pirenópolis",
        "state": "GO"
    },
    {
        "name": "Pires do Rio",
        "state": "GO"
    },
    {
        "name": "Planaltina",
        "state": "GO"
    },
    {
        "name": "Pontalina",
        "state": "GO"
    },
    {
        "name": "Porangatu",
        "state": "GO"
    },
    {
        "name": "Porteirão",
        "state": "GO"
    },
    {
        "name": "Portelândia",
        "state": "GO"
    },
    {
        "name": "Posse",
        "state": "GO"
    },
    {
        "name": "Professor Jamil",
        "state": "GO"
    },
    {
        "name": "Quirinópolis",
        "state": "GO"
    },
    {
        "name": "Rialma",
        "state": "GO"
    },
    {
        "name": "Rianápolis",
        "state": "GO"
    },
    {
        "name": "Rio Quente",
        "state": "GO"
    },
    {
        "name": "Rio Verde",
        "state": "GO"
    },
    {
        "name": "Rubiataba",
        "state": "GO"
    },
    {
        "name": "Sanclerlândia",
        "state": "GO"
    },
    {
        "name": "Santa Bárbara de Goiás",
        "state": "GO"
    },
    {
        "name": "Santa Cruz de Goiás",
        "state": "GO"
    },
    {
        "name": "Santa Fé de Goiás",
        "state": "GO"
    },
    {
        "name": "Santa Helena de Goiás",
        "state": "GO"
    },
    {
        "name": "Santa Isabel",
        "state": "GO"
    },
    {
        "name": "Santa Rita do Araguaia",
        "state": "GO"
    },
    {
        "name": "Santa Rita do Novo Destino",
        "state": "GO"
    },
    {
        "name": "Santa Rosa de Goiás",
        "state": "GO"
    },
    {
        "name": "Santa Tereza de Goiás",
        "state": "GO"
    },
    {
        "name": "Santa Terezinha de Goiás",
        "state": "GO"
    },
    {
        "name": "Santo Antônio da Barra",
        "state": "GO"
    },
    {
        "name": "Santo Antônio de Goiás",
        "state": "GO"
    },
    {
        "name": "Santo Antônio do Descoberto",
        "state": "GO"
    },
    {
        "name": "São Domingos",
        "state": "GO"
    },
    {
        "name": "São Francisco de Goiás",
        "state": "GO"
    },
    {
        "name": "São João d`Aliança",
        "state": "GO"
    },
    {
        "name": "São João da Paraúna",
        "state": "GO"
    },
    {
        "name": "São Luís de Montes Belos",
        "state": "GO"
    },
    {
        "name": "São Luíz do Norte",
        "state": "GO"
    },
    {
        "name": "São Miguel do Araguaia",
        "state": "GO"
    },
    {
        "name": "São Miguel do Passa Quatro",
        "state": "GO"
    },
    {
        "name": "São Patrício",
        "state": "GO"
    },
    {
        "name": "São Simão",
        "state": "GO"
    },
    {
        "name": "Senador Canedo",
        "state": "GO"
    },
    {
        "name": "Serranópolis",
        "state": "GO"
    },
    {
        "name": "Silvânia",
        "state": "GO"
    },
    {
        "name": "Simolândia",
        "state": "GO"
    },
    {
        "name": "Sítio d`Abadia",
        "state": "GO"
    },
    {
        "name": "Taquaral de Goiás",
        "state": "GO"
    },
    {
        "name": "Teresina de Goiás",
        "state": "GO"
    },
    {
        "name": "Terezópolis de Goiás",
        "state": "GO"
    },
    {
        "name": "Três Ranchos",
        "state": "GO"
    },
    {
        "name": "Trindade",
        "state": "GO"
    },
    {
        "name": "Trombas",
        "state": "GO"
    },
    {
        "name": "Turvânia",
        "state": "GO"
    },
    {
        "name": "Turvelândia",
        "state": "GO"
    },
    {
        "name": "Uirapuru",
        "state": "GO"
    },
    {
        "name": "Uruaçu",
        "state": "GO"
    },
    {
        "name": "Uruana",
        "state": "GO"
    },
    {
        "name": "Urutaí",
        "state": "GO"
    },
    {
        "name": "Valparaíso de Goiás",
        "state": "GO"
    },
    {
        "name": "Varjão",
        "state": "GO"
    },
    {
        "name": "Vianópolis",
        "state": "GO"
    },
    {
        "name": "Vicentinópolis",
        "state": "GO"
    },
    {
        "name": "Vila Boa",
        "state": "GO"
    },
    {
        "name": "Vila Propício",
        "state": "GO"
    },
    {
        "name": "Açailândia",
        "state": "MA"
    },
    {
        "name": "Afonso Cunha",
        "state": "MA"
    },
    {
        "name": "Água Doce do Maranhão",
        "state": "MA"
    },
    {
        "name": "Alcântara",
        "state": "MA"
    },
    {
        "name": "Aldeias Altas",
        "state": "MA"
    },
    {
        "name": "Altamira do Maranhão",
        "state": "MA"
    },
    {
        "name": "Alto Alegre do Maranhão",
        "state": "MA"
    },
    {
        "name": "Alto Alegre do Pindaré",
        "state": "MA"
    },
    {
        "name": "Alto Parnaíba",
        "state": "MA"
    },
    {
        "name": "Amapá do Maranhão",
        "state": "MA"
    },
    {
        "name": "Amarante do Maranhão",
        "state": "MA"
    },
    {
        "name": "Anajatuba",
        "state": "MA"
    },
    {
        "name": "Anapurus",
        "state": "MA"
    },
    {
        "name": "Apicum-Açu",
        "state": "MA"
    },
    {
        "name": "Araguanã",
        "state": "MA"
    },
    {
        "name": "Araioses",
        "state": "MA"
    },
    {
        "name": "Arame",
        "state": "MA"
    },
    {
        "name": "Arari",
        "state": "MA"
    },
    {
        "name": "Axixá",
        "state": "MA"
    },
    {
        "name": "Bacabal",
        "state": "MA"
    },
    {
        "name": "Bacabeira",
        "state": "MA"
    },
    {
        "name": "Bacuri",
        "state": "MA"
    },
    {
        "name": "Bacurituba",
        "state": "MA"
    },
    {
        "name": "Balsas",
        "state": "MA"
    },
    {
        "name": "Barão de Grajaú",
        "state": "MA"
    },
    {
        "name": "Barra do Corda",
        "state": "MA"
    },
    {
        "name": "Barreirinhas",
        "state": "MA"
    },
    {
        "name": "Bela Vista do Maranhão",
        "state": "MA"
    },
    {
        "name": "Belágua",
        "state": "MA"
    },
    {
        "name": "Benedito Leite",
        "state": "MA"
    },
    {
        "name": "Bequimão",
        "state": "MA"
    },
    {
        "name": "Bernardo do Mearim",
        "state": "MA"
    },
    {
        "name": "Boa Vista do Gurupi",
        "state": "MA"
    },
    {
        "name": "Bom Jardim",
        "state": "MA"
    },
    {
        "name": "Bom Jesus das Selvas",
        "state": "MA"
    },
    {
        "name": "Bom Lugar",
        "state": "MA"
    },
    {
        "name": "Brejo",
        "state": "MA"
    },
    {
        "name": "Brejo de Areia",
        "state": "MA"
    },
    {
        "name": "Buriti",
        "state": "MA"
    },
    {
        "name": "Buriti Bravo",
        "state": "MA"
    },
    {
        "name": "Buriticupu",
        "state": "MA"
    },
    {
        "name": "Buritirana",
        "state": "MA"
    },
    {
        "name": "Cachoeira Grande",
        "state": "MA"
    },
    {
        "name": "Cajapió",
        "state": "MA"
    },
    {
        "name": "Cajari",
        "state": "MA"
    },
    {
        "name": "Campestre do Maranhão",
        "state": "MA"
    },
    {
        "name": "Cândido Mendes",
        "state": "MA"
    },
    {
        "name": "Cantanhede",
        "state": "MA"
    },
    {
        "name": "Capinzal do Norte",
        "state": "MA"
    },
    {
        "name": "Carolina",
        "state": "MA"
    },
    {
        "name": "Carutapera",
        "state": "MA"
    },
    {
        "name": "Caxias",
        "state": "MA"
    },
    {
        "name": "Cedral",
        "state": "MA"
    },
    {
        "name": "Central do Maranhão",
        "state": "MA"
    },
    {
        "name": "Centro do Guilherme",
        "state": "MA"
    },
    {
        "name": "Centro Novo do Maranhão",
        "state": "MA"
    },
    {
        "name": "Chapadinha",
        "state": "MA"
    },
    {
        "name": "Cidelândia",
        "state": "MA"
    },
    {
        "name": "Codó",
        "state": "MA"
    },
    {
        "name": "Coelho Neto",
        "state": "MA"
    },
    {
        "name": "Colinas",
        "state": "MA"
    },
    {
        "name": "Conceição do Lago-Açu",
        "state": "MA"
    },
    {
        "name": "Coroatá",
        "state": "MA"
    },
    {
        "name": "Cururupu",
        "state": "MA"
    },
    {
        "name": "Davinópolis",
        "state": "MA"
    },
    {
        "name": "Dom Pedro",
        "state": "MA"
    },
    {
        "name": "Duque Bacelar",
        "state": "MA"
    },
    {
        "name": "Esperantinópolis",
        "state": "MA"
    },
    {
        "name": "Estreito",
        "state": "MA"
    },
    {
        "name": "Feira Nova do Maranhão",
        "state": "MA"
    },
    {
        "name": "Fernando Falcão",
        "state": "MA"
    },
    {
        "name": "Formosa da Serra Negra",
        "state": "MA"
    },
    {
        "name": "Fortaleza dos Nogueiras",
        "state": "MA"
    },
    {
        "name": "Fortuna",
        "state": "MA"
    },
    {
        "name": "Godofredo Viana",
        "state": "MA"
    },
    {
        "name": "Gonçalves Dias",
        "state": "MA"
    },
    {
        "name": "Governador Archer",
        "state": "MA"
    },
    {
        "name": "Governador Edison Lobão",
        "state": "MA"
    },
    {
        "name": "Governador Eugênio Barros",
        "state": "MA"
    },
    {
        "name": "Governador Luiz Rocha",
        "state": "MA"
    },
    {
        "name": "Governador Newton Bello",
        "state": "MA"
    },
    {
        "name": "Governador Nunes Freire",
        "state": "MA"
    },
    {
        "name": "Graça Aranha",
        "state": "MA"
    },
    {
        "name": "Grajaú",
        "state": "MA"
    },
    {
        "name": "Guimarães",
        "state": "MA"
    },
    {
        "name": "Humberto de Campos",
        "state": "MA"
    },
    {
        "name": "Icatu",
        "state": "MA"
    },
    {
        "name": "Igarapé do Meio",
        "state": "MA"
    },
    {
        "name": "Igarapé Grande",
        "state": "MA"
    },
    {
        "name": "Imperatriz",
        "state": "MA"
    },
    {
        "name": "Itaipava do Grajaú",
        "state": "MA"
    },
    {
        "name": "Itapecuru Mirim",
        "state": "MA"
    },
    {
        "name": "Itinga do Maranhão",
        "state": "MA"
    },
    {
        "name": "Jatobá",
        "state": "MA"
    },
    {
        "name": "Jenipapo dos Vieiras",
        "state": "MA"
    },
    {
        "name": "João Lisboa",
        "state": "MA"
    },
    {
        "name": "Joselândia",
        "state": "MA"
    },
    {
        "name": "Junco do Maranhão",
        "state": "MA"
    },
    {
        "name": "Lago da Pedra",
        "state": "MA"
    },
    {
        "name": "Lago do Junco",
        "state": "MA"
    },
    {
        "name": "Lago dos Rodrigues",
        "state": "MA"
    },
    {
        "name": "Lago Verde",
        "state": "MA"
    },
    {
        "name": "Lagoa do Mato",
        "state": "MA"
    },
    {
        "name": "Lagoa Grande do Maranhão",
        "state": "MA"
    },
    {
        "name": "Lajeado Novo",
        "state": "MA"
    },
    {
        "name": "Lima Campos",
        "state": "MA"
    },
    {
        "name": "Loreto",
        "state": "MA"
    },
    {
        "name": "Luís Domingues",
        "state": "MA"
    },
    {
        "name": "Magalhães de Almeida",
        "state": "MA"
    },
    {
        "name": "Maracaçumé",
        "state": "MA"
    },
    {
        "name": "Marajá do Sena",
        "state": "MA"
    },
    {
        "name": "Maranhãozinho",
        "state": "MA"
    },
    {
        "name": "Mata Roma",
        "state": "MA"
    },
    {
        "name": "Matinha",
        "state": "MA"
    },
    {
        "name": "Matões",
        "state": "MA"
    },
    {
        "name": "Matões do Norte",
        "state": "MA"
    },
    {
        "name": "Milagres do Maranhão",
        "state": "MA"
    },
    {
        "name": "Mirador",
        "state": "MA"
    },
    {
        "name": "Miranda do Norte",
        "state": "MA"
    },
    {
        "name": "Mirinzal",
        "state": "MA"
    },
    {
        "name": "Monção",
        "state": "MA"
    },
    {
        "name": "Montes Altos",
        "state": "MA"
    },
    {
        "name": "Morros",
        "state": "MA"
    },
    {
        "name": "Nina Rodrigues",
        "state": "MA"
    },
    {
        "name": "Nova Colinas",
        "state": "MA"
    },
    {
        "name": "Nova Iorque",
        "state": "MA"
    },
    {
        "name": "Nova Olinda do Maranhão",
        "state": "MA"
    },
    {
        "name": "Olho d`Água das Cunhãs",
        "state": "MA"
    },
    {
        "name": "Olinda Nova do Maranhão",
        "state": "MA"
    },
    {
        "name": "Paço do Lumiar",
        "state": "MA"
    },
    {
        "name": "Palmeirândia",
        "state": "MA"
    },
    {
        "name": "Paraibano",
        "state": "MA"
    },
    {
        "name": "Parnarama",
        "state": "MA"
    },
    {
        "name": "Passagem Franca",
        "state": "MA"
    },
    {
        "name": "Pastos Bons",
        "state": "MA"
    },
    {
        "name": "Paulino Neves",
        "state": "MA"
    },
    {
        "name": "Paulo Ramos",
        "state": "MA"
    },
    {
        "name": "Pedreiras",
        "state": "MA"
    },
    {
        "name": "Pedro do Rosário",
        "state": "MA"
    },
    {
        "name": "Penalva",
        "state": "MA"
    },
    {
        "name": "Peri Mirim",
        "state": "MA"
    },
    {
        "name": "Peritoró",
        "state": "MA"
    },
    {
        "name": "Pindaré-Mirim",
        "state": "MA"
    },
    {
        "name": "Pinheiro",
        "state": "MA"
    },
    {
        "name": "Pio XII",
        "state": "MA"
    },
    {
        "name": "Pirapemas",
        "state": "MA"
    },
    {
        "name": "Poção de Pedras",
        "state": "MA"
    },
    {
        "name": "Porto Franco",
        "state": "MA"
    },
    {
        "name": "Porto Rico do Maranhão",
        "state": "MA"
    },
    {
        "name": "Presidente Dutra",
        "state": "MA"
    },
    {
        "name": "Presidente Juscelino",
        "state": "MA"
    },
    {
        "name": "Presidente Médici",
        "state": "MA"
    },
    {
        "name": "Presidente Sarney",
        "state": "MA"
    },
    {
        "name": "Presidente Vargas",
        "state": "MA"
    },
    {
        "name": "Primeira Cruz",
        "state": "MA"
    },
    {
        "name": "Raposa",
        "state": "MA"
    },
    {
        "name": "Riachão",
        "state": "MA"
    },
    {
        "name": "Ribamar Fiquene",
        "state": "MA"
    },
    {
        "name": "Rosário",
        "state": "MA"
    },
    {
        "name": "Sambaíba",
        "state": "MA"
    },
    {
        "name": "Santa Filomena do Maranhão",
        "state": "MA"
    },
    {
        "name": "Santa Helena",
        "state": "MA"
    },
    {
        "name": "Santa Inês",
        "state": "MA"
    },
    {
        "name": "Santa Luzia",
        "state": "MA"
    },
    {
        "name": "Santa Luzia do Paruá",
        "state": "MA"
    },
    {
        "name": "Santa Quitéria do Maranhão",
        "state": "MA"
    },
    {
        "name": "Santa Rita",
        "state": "MA"
    },
    {
        "name": "Santana do Maranhão",
        "state": "MA"
    },
    {
        "name": "Santo Amaro do Maranhão",
        "state": "MA"
    },
    {
        "name": "Santo Antônio dos Lopes",
        "state": "MA"
    },
    {
        "name": "São Benedito do Rio Preto",
        "state": "MA"
    },
    {
        "name": "São Bento",
        "state": "MA"
    },
    {
        "name": "São Bernardo",
        "state": "MA"
    },
    {
        "name": "São Domingos do Azeitão",
        "state": "MA"
    },
    {
        "name": "São Domingos do Maranhão",
        "state": "MA"
    },
    {
        "name": "São Félix de Balsas",
        "state": "MA"
    },
    {
        "name": "São Francisco do Brejão",
        "state": "MA"
    },
    {
        "name": "São Francisco do Maranhão",
        "state": "MA"
    },
    {
        "name": "São João Batista",
        "state": "MA"
    },
    {
        "name": "São João do Carú",
        "state": "MA"
    },
    {
        "name": "São João do Paraíso",
        "state": "MA"
    },
    {
        "name": "São João do Soter",
        "state": "MA"
    },
    {
        "name": "São João dos Patos",
        "state": "MA"
    },
    {
        "name": "São José de Ribamar",
        "state": "MA"
    },
    {
        "name": "São José dos Basílios",
        "state": "MA"
    },
    {
        "name": "São Luís",
        "state": "MA"
    },
    {
        "name": "São Luís Gonzaga do Maranhão",
        "state": "MA"
    },
    {
        "name": "São Mateus do Maranhão",
        "state": "MA"
    },
    {
        "name": "São Pedro da Água Branca",
        "state": "MA"
    },
    {
        "name": "São Pedro dos Crentes",
        "state": "MA"
    },
    {
        "name": "São Raimundo das Mangabeiras",
        "state": "MA"
    },
    {
        "name": "São Raimundo do Doca Bezerra",
        "state": "MA"
    },
    {
        "name": "São Roberto",
        "state": "MA"
    },
    {
        "name": "São Vicente Ferrer",
        "state": "MA"
    },
    {
        "name": "Satubinha",
        "state": "MA"
    },
    {
        "name": "Senador Alexandre Costa",
        "state": "MA"
    },
    {
        "name": "Senador La Rocque",
        "state": "MA"
    },
    {
        "name": "Serrano do Maranhão",
        "state": "MA"
    },
    {
        "name": "Sítio Novo",
        "state": "MA"
    },
    {
        "name": "Sucupira do Norte",
        "state": "MA"
    },
    {
        "name": "Sucupira do Riachão",
        "state": "MA"
    },
    {
        "name": "Tasso Fragoso",
        "state": "MA"
    },
    {
        "name": "Timbiras",
        "state": "MA"
    },
    {
        "name": "Timon",
        "state": "MA"
    },
    {
        "name": "Trizidela do Vale",
        "state": "MA"
    },
    {
        "name": "Tufilândia",
        "state": "MA"
    },
    {
        "name": "Tuntum",
        "state": "MA"
    },
    {
        "name": "Turiaçu",
        "state": "MA"
    },
    {
        "name": "Turilândia",
        "state": "MA"
    },
    {
        "name": "Tutóia",
        "state": "MA"
    },
    {
        "name": "Urbano Santos",
        "state": "MA"
    },
    {
        "name": "Vargem Grande",
        "state": "MA"
    },
    {
        "name": "Viana",
        "state": "MA"
    },
    {
        "name": "Vila Nova dos Martírios",
        "state": "MA"
    },
    {
        "name": "Vitória do Mearim",
        "state": "MA"
    },
    {
        "name": "Vitorino Freire",
        "state": "MA"
    },
    {
        "name": "Zé Doca",
        "state": "MA"
    },
    {
        "name": "Acorizal",
        "state": "MT"
    },
    {
        "name": "Água Boa",
        "state": "MT"
    },
    {
        "name": "Alta Floresta",
        "state": "MT"
    },
    {
        "name": "Alto Araguaia",
        "state": "MT"
    },
    {
        "name": "Alto Boa Vista",
        "state": "MT"
    },
    {
        "name": "Alto Garças",
        "state": "MT"
    },
    {
        "name": "Alto Paraguai",
        "state": "MT"
    },
    {
        "name": "Alto Taquari",
        "state": "MT"
    },
    {
        "name": "Apiacás",
        "state": "MT"
    },
    {
        "name": "Araguaiana",
        "state": "MT"
    },
    {
        "name": "Araguainha",
        "state": "MT"
    },
    {
        "name": "Araputanga",
        "state": "MT"
    },
    {
        "name": "Arenápolis",
        "state": "MT"
    },
    {
        "name": "Aripuanã",
        "state": "MT"
    },
    {
        "name": "Barão de Melgaço",
        "state": "MT"
    },
    {
        "name": "Barra do Bugres",
        "state": "MT"
    },
    {
        "name": "Barra do Garças",
        "state": "MT"
    },
    {
        "name": "Bom Jesus do Araguaia",
        "state": "MT"
    },
    {
        "name": "Brasnorte",
        "state": "MT"
    },
    {
        "name": "Cáceres",
        "state": "MT"
    },
    {
        "name": "Campinápolis",
        "state": "MT"
    },
    {
        "name": "Campo Novo do Parecis",
        "state": "MT"
    },
    {
        "name": "Campo Verde",
        "state": "MT"
    },
    {
        "name": "Campos de Júlio",
        "state": "MT"
    },
    {
        "name": "Canabrava do Norte",
        "state": "MT"
    },
    {
        "name": "Canarana",
        "state": "MT"
    },
    {
        "name": "Carlinda",
        "state": "MT"
    },
    {
        "name": "Castanheira",
        "state": "MT"
    },
    {
        "name": "Chapada dos Guimarães",
        "state": "MT"
    },
    {
        "name": "Cláudia",
        "state": "MT"
    },
    {
        "name": "Cocalinho",
        "state": "MT"
    },
    {
        "name": "Colíder",
        "state": "MT"
    },
    {
        "name": "Colniza",
        "state": "MT"
    },
    {
        "name": "Comodoro",
        "state": "MT"
    },
    {
        "name": "Confresa",
        "state": "MT"
    },
    {
        "name": "Conquista d`Oeste",
        "state": "MT"
    },
    {
        "name": "Cotriguaçu",
        "state": "MT"
    },
    {
        "name": "Cuiabá",
        "state": "MT"
    },
    {
        "name": "Curvelândia",
        "state": "MT"
    },
    {
        "name": "Denise",
        "state": "MT"
    },
    {
        "name": "Diamantino",
        "state": "MT"
    },
    {
        "name": "Dom Aquino",
        "state": "MT"
    },
    {
        "name": "Feliz Natal",
        "state": "MT"
    },
    {
        "name": "Figueirópolis d`Oeste",
        "state": "MT"
    },
    {
        "name": "Gaúcha do Norte",
        "state": "MT"
    },
    {
        "name": "General Carneiro",
        "state": "MT"
    },
    {
        "name": "Glória d`Oeste",
        "state": "MT"
    },
    {
        "name": "Guarantã do Norte",
        "state": "MT"
    },
    {
        "name": "Guiratinga",
        "state": "MT"
    },
    {
        "name": "Indiavaí",
        "state": "MT"
    },
    {
        "name": "Ipiranga do Norte",
        "state": "MT"
    },
    {
        "name": "Itanhangá",
        "state": "MT"
    },
    {
        "name": "Itaúba",
        "state": "MT"
    },
    {
        "name": "Itiquira",
        "state": "MT"
    },
    {
        "name": "Jaciara",
        "state": "MT"
    },
    {
        "name": "Jangada",
        "state": "MT"
    },
    {
        "name": "Jauru",
        "state": "MT"
    },
    {
        "name": "Juara",
        "state": "MT"
    },
    {
        "name": "Juína",
        "state": "MT"
    },
    {
        "name": "Juruena",
        "state": "MT"
    },
    {
        "name": "Juscimeira",
        "state": "MT"
    },
    {
        "name": "Lambari d`Oeste",
        "state": "MT"
    },
    {
        "name": "Lucas do Rio Verde",
        "state": "MT"
    },
    {
        "name": "Luciára",
        "state": "MT"
    },
    {
        "name": "Marcelândia",
        "state": "MT"
    },
    {
        "name": "Matupá",
        "state": "MT"
    },
    {
        "name": "Mirassol d`Oeste",
        "state": "MT"
    },
    {
        "name": "Nobres",
        "state": "MT"
    },
    {
        "name": "Nortelândia",
        "state": "MT"
    },
    {
        "name": "Nossa Senhora do Livramento",
        "state": "MT"
    },
    {
        "name": "Nova Bandeirantes",
        "state": "MT"
    },
    {
        "name": "Nova Brasilândia",
        "state": "MT"
    },
    {
        "name": "Nova Canaã do Norte",
        "state": "MT"
    },
    {
        "name": "Nova Guarita",
        "state": "MT"
    },
    {
        "name": "Nova Lacerda",
        "state": "MT"
    },
    {
        "name": "Nova Marilândia",
        "state": "MT"
    },
    {
        "name": "Nova Maringá",
        "state": "MT"
    },
    {
        "name": "Nova Monte verde",
        "state": "MT"
    },
    {
        "name": "Nova Mutum",
        "state": "MT"
    },
    {
        "name": "Nova Olímpia",
        "state": "MT"
    },
    {
        "name": "Nova Santa Helena",
        "state": "MT"
    },
    {
        "name": "Nova Ubiratã",
        "state": "MT"
    },
    {
        "name": "Nova Xavantina",
        "state": "MT"
    },
    {
        "name": "Novo Horizonte do Norte",
        "state": "MT"
    },
    {
        "name": "Novo Mundo",
        "state": "MT"
    },
    {
        "name": "Novo Santo Antônio",
        "state": "MT"
    },
    {
        "name": "Novo São Joaquim",
        "state": "MT"
    },
    {
        "name": "Paranaíta",
        "state": "MT"
    },
    {
        "name": "Paranatinga",
        "state": "MT"
    },
    {
        "name": "Pedra Preta",
        "state": "MT"
    },
    {
        "name": "Peixoto de Azevedo",
        "state": "MT"
    },
    {
        "name": "Planalto da Serra",
        "state": "MT"
    },
    {
        "name": "Poconé",
        "state": "MT"
    },
    {
        "name": "Pontal do Araguaia",
        "state": "MT"
    },
    {
        "name": "Ponte Branca",
        "state": "MT"
    },
    {
        "name": "Pontes e Lacerda",
        "state": "MT"
    },
    {
        "name": "Porto Alegre do Norte",
        "state": "MT"
    },
    {
        "name": "Porto dos Gaúchos",
        "state": "MT"
    },
    {
        "name": "Porto Esperidião",
        "state": "MT"
    },
    {
        "name": "Porto Estrela",
        "state": "MT"
    },
    {
        "name": "Poxoréo",
        "state": "MT"
    },
    {
        "name": "Primavera do Leste",
        "state": "MT"
    },
    {
        "name": "Querência",
        "state": "MT"
    },
    {
        "name": "Reserva do Cabaçal",
        "state": "MT"
    },
    {
        "name": "Ribeirão Cascalheira",
        "state": "MT"
    },
    {
        "name": "Ribeirãozinho",
        "state": "MT"
    },
    {
        "name": "Rio Branco",
        "state": "MT"
    },
    {
        "name": "Rondolândia",
        "state": "MT"
    },
    {
        "name": "Rondonópolis",
        "state": "MT"
    },
    {
        "name": "Rosário Oeste",
        "state": "MT"
    },
    {
        "name": "Salto do Céu",
        "state": "MT"
    },
    {
        "name": "Santa Carmem",
        "state": "MT"
    },
    {
        "name": "Santa Cruz do Xingu",
        "state": "MT"
    },
    {
        "name": "Santa Rita do Trivelato",
        "state": "MT"
    },
    {
        "name": "Santa Terezinha",
        "state": "MT"
    },
    {
        "name": "Santo Afonso",
        "state": "MT"
    },
    {
        "name": "Santo Antônio do Leste",
        "state": "MT"
    },
    {
        "name": "Santo Antônio do Leverger",
        "state": "MT"
    },
    {
        "name": "São Félix do Araguaia",
        "state": "MT"
    },
    {
        "name": "São José do Povo",
        "state": "MT"
    },
    {
        "name": "São José do Rio Claro",
        "state": "MT"
    },
    {
        "name": "São José do Xingu",
        "state": "MT"
    },
    {
        "name": "São José dos Quatro Marcos",
        "state": "MT"
    },
    {
        "name": "São Pedro da Cipa",
        "state": "MT"
    },
    {
        "name": "Sapezal",
        "state": "MT"
    },
    {
        "name": "Serra Nova Dourada",
        "state": "MT"
    },
    {
        "name": "Sinop",
        "state": "MT"
    },
    {
        "name": "Sorriso",
        "state": "MT"
    },
    {
        "name": "Tabaporã",
        "state": "MT"
    },
    {
        "name": "Tangará da Serra",
        "state": "MT"
    },
    {
        "name": "Tapurah",
        "state": "MT"
    },
    {
        "name": "Terra Nova do Norte",
        "state": "MT"
    },
    {
        "name": "Tesouro",
        "state": "MT"
    },
    {
        "name": "Torixoréu",
        "state": "MT"
    },
    {
        "name": "União do Sul",
        "state": "MT"
    },
    {
        "name": "Vale de São Domingos",
        "state": "MT"
    },
    {
        "name": "Várzea Grande",
        "state": "MT"
    },
    {
        "name": "Vera",
        "state": "MT"
    },
    {
        "name": "Vila Bela da Santíssima Trindade",
        "state": "MT"
    },
    {
        "name": "Vila Rica",
        "state": "MT"
    },
    {
        "name": "Água Clara",
        "state": "MS"
    },
    {
        "name": "Alcinópolis",
        "state": "MS"
    },
    {
        "name": "Amambaí",
        "state": "MS"
    },
    {
        "name": "Anastácio",
        "state": "MS"
    },
    {
        "name": "Anaurilândia",
        "state": "MS"
    },
    {
        "name": "Angélica",
        "state": "MS"
    },
    {
        "name": "Antônio João",
        "state": "MS"
    },
    {
        "name": "Aparecida do Taboado",
        "state": "MS"
    },
    {
        "name": "Aquidauana",
        "state": "MS"
    },
    {
        "name": "Aral Moreira",
        "state": "MS"
    },
    {
        "name": "Bandeirantes",
        "state": "MS"
    },
    {
        "name": "Bataguassu",
        "state": "MS"
    },
    {
        "name": "Bataiporã",
        "state": "MS"
    },
    {
        "name": "Bela Vista",
        "state": "MS"
    },
    {
        "name": "Bodoquena",
        "state": "MS"
    },
    {
        "name": "Bonito",
        "state": "MS"
    },
    {
        "name": "Brasilândia",
        "state": "MS"
    },
    {
        "name": "Caarapó",
        "state": "MS"
    },
    {
        "name": "Camapuã",
        "state": "MS"
    },
    {
        "name": "Campo Grande",
        "state": "MS"
    },
    {
        "name": "Caracol",
        "state": "MS"
    },
    {
        "name": "Cassilândia",
        "state": "MS"
    },
    {
        "name": "Chapadão do Sul",
        "state": "MS"
    },
    {
        "name": "Corguinho",
        "state": "MS"
    },
    {
        "name": "Coronel Sapucaia",
        "state": "MS"
    },
    {
        "name": "Corumbá",
        "state": "MS"
    },
    {
        "name": "Costa Rica",
        "state": "MS"
    },
    {
        "name": "Coxim",
        "state": "MS"
    },
    {
        "name": "Deodápolis",
        "state": "MS"
    },
    {
        "name": "Dois Irmãos do Buriti",
        "state": "MS"
    },
    {
        "name": "Douradina",
        "state": "MS"
    },
    {
        "name": "Dourados",
        "state": "MS"
    },
    {
        "name": "Eldorado",
        "state": "MS"
    },
    {
        "name": "Fátima do Sul",
        "state": "MS"
    },
    {
        "name": "Figueirão",
        "state": "MS"
    },
    {
        "name": "Glória de Dourados",
        "state": "MS"
    },
    {
        "name": "Guia Lopes da Laguna",
        "state": "MS"
    },
    {
        "name": "Iguatemi",
        "state": "MS"
    },
    {
        "name": "Inocência",
        "state": "MS"
    },
    {
        "name": "Itaporã",
        "state": "MS"
    },
    {
        "name": "Itaquiraí",
        "state": "MS"
    },
    {
        "name": "Ivinhema",
        "state": "MS"
    },
    {
        "name": "Japorã",
        "state": "MS"
    },
    {
        "name": "Jaraguari",
        "state": "MS"
    },
    {
        "name": "Jardim",
        "state": "MS"
    },
    {
        "name": "Jateí",
        "state": "MS"
    },
    {
        "name": "Juti",
        "state": "MS"
    },
    {
        "name": "Ladário",
        "state": "MS"
    },
    {
        "name": "Laguna Carapã",
        "state": "MS"
    },
    {
        "name": "Maracaju",
        "state": "MS"
    },
    {
        "name": "Miranda",
        "state": "MS"
    },
    {
        "name": "Mundo Novo",
        "state": "MS"
    },
    {
        "name": "Naviraí",
        "state": "MS"
    },
    {
        "name": "Nioaque",
        "state": "MS"
    },
    {
        "name": "Nova Alvorada do Sul",
        "state": "MS"
    },
    {
        "name": "Nova Andradina",
        "state": "MS"
    },
    {
        "name": "Novo Horizonte do Sul",
        "state": "MS"
    },
    {
        "name": "Paranaíba",
        "state": "MS"
    },
    {
        "name": "Paranhos",
        "state": "MS"
    },
    {
        "name": "Pedro Gomes",
        "state": "MS"
    },
    {
        "name": "Ponta Porã",
        "state": "MS"
    },
    {
        "name": "Porto Murtinho",
        "state": "MS"
    },
    {
        "name": "Ribas do Rio Pardo",
        "state": "MS"
    },
    {
        "name": "Rio Brilhante",
        "state": "MS"
    },
    {
        "name": "Rio Negro",
        "state": "MS"
    },
    {
        "name": "Rio Verde de Mato Grosso",
        "state": "MS"
    },
    {
        "name": "Rochedo",
        "state": "MS"
    },
    {
        "name": "Santa Rita do Pardo",
        "state": "MS"
    },
    {
        "name": "São Gabriel do Oeste",
        "state": "MS"
    },
    {
        "name": "Selvíria",
        "state": "MS"
    },
    {
        "name": "Sete Quedas",
        "state": "MS"
    },
    {
        "name": "Sidrolândia",
        "state": "MS"
    },
    {
        "name": "Sonora",
        "state": "MS"
    },
    {
        "name": "Tacuru",
        "state": "MS"
    },
    {
        "name": "Taquarussu",
        "state": "MS"
    },
    {
        "name": "Terenos",
        "state": "MS"
    },
    {
        "name": "Três Lagoas",
        "state": "MS"
    },
    {
        "name": "Vicentina",
        "state": "MS"
    },
    {
        "name": "Abadia dos Dourados",
        "state": "MG"
    },
    {
        "name": "Abaeté",
        "state": "MG"
    },
    {
        "name": "Abre Campo",
        "state": "MG"
    },
    {
        "name": "Acaiaca",
        "state": "MG"
    },
    {
        "name": "Açucena",
        "state": "MG"
    },
    {
        "name": "Água Boa",
        "state": "MG"
    },
    {
        "name": "Água Comprida",
        "state": "MG"
    },
    {
        "name": "Aguanil",
        "state": "MG"
    },
    {
        "name": "Águas Formosas",
        "state": "MG"
    },
    {
        "name": "Águas Vermelhas",
        "state": "MG"
    },
    {
        "name": "Aimorés",
        "state": "MG"
    },
    {
        "name": "Aiuruoca",
        "state": "MG"
    },
    {
        "name": "Alagoa",
        "state": "MG"
    },
    {
        "name": "Albertina",
        "state": "MG"
    },
    {
        "name": "Além Paraíba",
        "state": "MG"
    },
    {
        "name": "Alfenas",
        "state": "MG"
    },
    {
        "name": "Alfredo Vasconcelos",
        "state": "MG"
    },
    {
        "name": "Almenara",
        "state": "MG"
    },
    {
        "name": "Alpercata",
        "state": "MG"
    },
    {
        "name": "Alpinópolis",
        "state": "MG"
    },
    {
        "name": "Alterosa",
        "state": "MG"
    },
    {
        "name": "Alto Caparaó",
        "state": "MG"
    },
    {
        "name": "Alto Jequitibá",
        "state": "MG"
    },
    {
        "name": "Alto Rio Doce",
        "state": "MG"
    },
    {
        "name": "Alvarenga",
        "state": "MG"
    },
    {
        "name": "Alvinópolis",
        "state": "MG"
    },
    {
        "name": "Alvorada de Minas",
        "state": "MG"
    },
    {
        "name": "Amparo do Serra",
        "state": "MG"
    },
    {
        "name": "Andradas",
        "state": "MG"
    },
    {
        "name": "Andrelândia",
        "state": "MG"
    },
    {
        "name": "Angelândia",
        "state": "MG"
    },
    {
        "name": "Antônio Carlos",
        "state": "MG"
    },
    {
        "name": "Antônio Dias",
        "state": "MG"
    },
    {
        "name": "Antônio Prado de Minas",
        "state": "MG"
    },
    {
        "name": "Araçaí",
        "state": "MG"
    },
    {
        "name": "Aracitaba",
        "state": "MG"
    },
    {
        "name": "Araçuaí",
        "state": "MG"
    },
    {
        "name": "Araguari",
        "state": "MG"
    },
    {
        "name": "Arantina",
        "state": "MG"
    },
    {
        "name": "Araponga",
        "state": "MG"
    },
    {
        "name": "Araporã",
        "state": "MG"
    },
    {
        "name": "Arapuá",
        "state": "MG"
    },
    {
        "name": "Araújos",
        "state": "MG"
    },
    {
        "name": "Araxá",
        "state": "MG"
    },
    {
        "name": "Arceburgo",
        "state": "MG"
    },
    {
        "name": "Arcos",
        "state": "MG"
    },
    {
        "name": "Areado",
        "state": "MG"
    },
    {
        "name": "Argirita",
        "state": "MG"
    },
    {
        "name": "Aricanduva",
        "state": "MG"
    },
    {
        "name": "Arinos",
        "state": "MG"
    },
    {
        "name": "Astolfo Dutra",
        "state": "MG"
    },
    {
        "name": "Ataléia",
        "state": "MG"
    },
    {
        "name": "Augusto de Lima",
        "state": "MG"
    },
    {
        "name": "Baependi",
        "state": "MG"
    },
    {
        "name": "Baldim",
        "state": "MG"
    },
    {
        "name": "Bambuí",
        "state": "MG"
    },
    {
        "name": "Bandeira",
        "state": "MG"
    },
    {
        "name": "Bandeira do Sul",
        "state": "MG"
    },
    {
        "name": "Barão de Cocais",
        "state": "MG"
    },
    {
        "name": "Barão de Monte Alto",
        "state": "MG"
    },
    {
        "name": "Barbacena",
        "state": "MG"
    },
    {
        "name": "Barra Longa",
        "state": "MG"
    },
    {
        "name": "Barroso",
        "state": "MG"
    },
    {
        "name": "Bela Vista de Minas",
        "state": "MG"
    },
    {
        "name": "Belmiro Braga",
        "state": "MG"
    },
    {
        "name": "Belo Horizonte",
        "state": "MG"
    },
    {
        "name": "Belo Oriente",
        "state": "MG"
    },
    {
        "name": "Belo Vale",
        "state": "MG"
    },
    {
        "name": "Berilo",
        "state": "MG"
    },
    {
        "name": "Berizal",
        "state": "MG"
    },
    {
        "name": "Bertópolis",
        "state": "MG"
    },
    {
        "name": "Betim",
        "state": "MG"
    },
    {
        "name": "Bias Fortes",
        "state": "MG"
    },
    {
        "name": "Bicas",
        "state": "MG"
    },
    {
        "name": "Biquinhas",
        "state": "MG"
    },
    {
        "name": "Boa Esperança",
        "state": "MG"
    },
    {
        "name": "Bocaina de Minas",
        "state": "MG"
    },
    {
        "name": "Bocaiúva",
        "state": "MG"
    },
    {
        "name": "Bom Despacho",
        "state": "MG"
    },
    {
        "name": "Bom Jardim de Minas",
        "state": "MG"
    },
    {
        "name": "Bom Jesus da Penha",
        "state": "MG"
    },
    {
        "name": "Bom Jesus do Amparo",
        "state": "MG"
    },
    {
        "name": "Bom Jesus do Galho",
        "state": "MG"
    },
    {
        "name": "Bom Repouso",
        "state": "MG"
    },
    {
        "name": "Bom Sucesso",
        "state": "MG"
    },
    {
        "name": "Bonfim",
        "state": "MG"
    },
    {
        "name": "Bonfinópolis de Minas",
        "state": "MG"
    },
    {
        "name": "Bonito de Minas",
        "state": "MG"
    },
    {
        "name": "Borda da Mata",
        "state": "MG"
    },
    {
        "name": "Botelhos",
        "state": "MG"
    },
    {
        "name": "Botumirim",
        "state": "MG"
    },
    {
        "name": "Brás Pires",
        "state": "MG"
    },
    {
        "name": "Brasilândia de Minas",
        "state": "MG"
    },
    {
        "name": "Brasília de Minas",
        "state": "MG"
    },
    {
        "name": "Brasópolis",
        "state": "MG"
    },
    {
        "name": "Braúnas",
        "state": "MG"
    },
    {
        "name": "Brumadinho",
        "state": "MG"
    },
    {
        "name": "Bueno Brandão",
        "state": "MG"
    },
    {
        "name": "Buenópolis",
        "state": "MG"
    },
    {
        "name": "Bugre",
        "state": "MG"
    },
    {
        "name": "Buritis",
        "state": "MG"
    },
    {
        "name": "Buritizeiro",
        "state": "MG"
    },
    {
        "name": "Cabeceira Grande",
        "state": "MG"
    },
    {
        "name": "Cabo Verde",
        "state": "MG"
    },
    {
        "name": "Cachoeira da Prata",
        "state": "MG"
    },
    {
        "name": "Cachoeira de Minas",
        "state": "MG"
    },
    {
        "name": "Cachoeira de Pajeú",
        "state": "MG"
    },
    {
        "name": "Cachoeira Dourada",
        "state": "MG"
    },
    {
        "name": "Caetanópolis",
        "state": "MG"
    },
    {
        "name": "Caeté",
        "state": "MG"
    },
    {
        "name": "Caiana",
        "state": "MG"
    },
    {
        "name": "Cajuri",
        "state": "MG"
    },
    {
        "name": "Caldas",
        "state": "MG"
    },
    {
        "name": "Camacho",
        "state": "MG"
    },
    {
        "name": "Camanducaia",
        "state": "MG"
    },
    {
        "name": "Cambuí",
        "state": "MG"
    },
    {
        "name": "Cambuquira",
        "state": "MG"
    },
    {
        "name": "Campanário",
        "state": "MG"
    },
    {
        "name": "Campanha",
        "state": "MG"
    },
    {
        "name": "Campestre",
        "state": "MG"
    },
    {
        "name": "Campina Verde",
        "state": "MG"
    },
    {
        "name": "Campo Azul",
        "state": "MG"
    },
    {
        "name": "Campo Belo",
        "state": "MG"
    },
    {
        "name": "Campo do Meio",
        "state": "MG"
    },
    {
        "name": "Campo Florido",
        "state": "MG"
    },
    {
        "name": "Campos Altos",
        "state": "MG"
    },
    {
        "name": "Campos Gerais",
        "state": "MG"
    },
    {
        "name": "Cana Verde",
        "state": "MG"
    },
    {
        "name": "Canaã",
        "state": "MG"
    },
    {
        "name": "Canápolis",
        "state": "MG"
    },
    {
        "name": "Candeias",
        "state": "MG"
    },
    {
        "name": "Cantagalo",
        "state": "MG"
    },
    {
        "name": "Caparaó",
        "state": "MG"
    },
    {
        "name": "Capela Nova",
        "state": "MG"
    },
    {
        "name": "Capelinha",
        "state": "MG"
    },
    {
        "name": "Capetinga",
        "state": "MG"
    },
    {
        "name": "Capim Branco",
        "state": "MG"
    },
    {
        "name": "Capinópolis",
        "state": "MG"
    },
    {
        "name": "Capitão Andrade",
        "state": "MG"
    },
    {
        "name": "Capitão Enéas",
        "state": "MG"
    },
    {
        "name": "Capitólio",
        "state": "MG"
    },
    {
        "name": "Caputira",
        "state": "MG"
    },
    {
        "name": "Caraí",
        "state": "MG"
    },
    {
        "name": "Caranaíba",
        "state": "MG"
    },
    {
        "name": "Carandaí",
        "state": "MG"
    },
    {
        "name": "Carangola",
        "state": "MG"
    },
    {
        "name": "Caratinga",
        "state": "MG"
    },
    {
        "name": "Carbonita",
        "state": "MG"
    },
    {
        "name": "Careaçu",
        "state": "MG"
    },
    {
        "name": "Carlos Chagas",
        "state": "MG"
    },
    {
        "name": "Carmésia",
        "state": "MG"
    },
    {
        "name": "Carmo da Cachoeira",
        "state": "MG"
    },
    {
        "name": "Carmo da Mata",
        "state": "MG"
    },
    {
        "name": "Carmo de Minas",
        "state": "MG"
    },
    {
        "name": "Carmo do Cajuru",
        "state": "MG"
    },
    {
        "name": "Carmo do Paranaíba",
        "state": "MG"
    },
    {
        "name": "Carmo do Rio Claro",
        "state": "MG"
    },
    {
        "name": "Carmópolis de Minas",
        "state": "MG"
    },
    {
        "name": "Carneirinho",
        "state": "MG"
    },
    {
        "name": "Carrancas",
        "state": "MG"
    },
    {
        "name": "Carvalhópolis",
        "state": "MG"
    },
    {
        "name": "Carvalhos",
        "state": "MG"
    },
    {
        "name": "Casa Grande",
        "state": "MG"
    },
    {
        "name": "Cascalho Rico",
        "state": "MG"
    },
    {
        "name": "Cássia",
        "state": "MG"
    },
    {
        "name": "Cataguases",
        "state": "MG"
    },
    {
        "name": "Catas Altas",
        "state": "MG"
    },
    {
        "name": "Catas Altas da Noruega",
        "state": "MG"
    },
    {
        "name": "Catuji",
        "state": "MG"
    },
    {
        "name": "Catuti",
        "state": "MG"
    },
    {
        "name": "Caxambu",
        "state": "MG"
    },
    {
        "name": "Cedro do Abaeté",
        "state": "MG"
    },
    {
        "name": "Central de Minas",
        "state": "MG"
    },
    {
        "name": "Centralina",
        "state": "MG"
    },
    {
        "name": "Chácara",
        "state": "MG"
    },
    {
        "name": "Chalé",
        "state": "MG"
    },
    {
        "name": "Chapada do Norte",
        "state": "MG"
    },
    {
        "name": "Chapada Gaúcha",
        "state": "MG"
    },
    {
        "name": "Chiador",
        "state": "MG"
    },
    {
        "name": "Cipotânea",
        "state": "MG"
    },
    {
        "name": "Claraval",
        "state": "MG"
    },
    {
        "name": "Claro dos Poções",
        "state": "MG"
    },
    {
        "name": "Cláudio",
        "state": "MG"
    },
    {
        "name": "Coimbra",
        "state": "MG"
    },
    {
        "name": "Coluna",
        "state": "MG"
    },
    {
        "name": "Comendador Gomes",
        "state": "MG"
    },
    {
        "name": "Comercinho",
        "state": "MG"
    },
    {
        "name": "Conceição da Aparecida",
        "state": "MG"
    },
    {
        "name": "Conceição da Barra de Minas",
        "state": "MG"
    },
    {
        "name": "Conceição das Alagoas",
        "state": "MG"
    },
    {
        "name": "Conceição das Pedras",
        "state": "MG"
    },
    {
        "name": "Conceição de Ipanema",
        "state": "MG"
    },
    {
        "name": "Conceição do Mato Dentro",
        "state": "MG"
    },
    {
        "name": "Conceição do Pará",
        "state": "MG"
    },
    {
        "name": "Conceição do Rio Verde",
        "state": "MG"
    },
    {
        "name": "Conceição dos Ouros",
        "state": "MG"
    },
    {
        "name": "Cônego Marinho",
        "state": "MG"
    },
    {
        "name": "Confins",
        "state": "MG"
    },
    {
        "name": "Congonhal",
        "state": "MG"
    },
    {
        "name": "Congonhas",
        "state": "MG"
    },
    {
        "name": "Congonhas do Norte",
        "state": "MG"
    },
    {
        "name": "Conquista",
        "state": "MG"
    },
    {
        "name": "Conselheiro Lafaiete",
        "state": "MG"
    },
    {
        "name": "Conselheiro Pena",
        "state": "MG"
    },
    {
        "name": "Consolação",
        "state": "MG"
    },
    {
        "name": "Contagem",
        "state": "MG"
    },
    {
        "name": "Coqueiral",
        "state": "MG"
    },
    {
        "name": "Coração de Jesus",
        "state": "MG"
    },
    {
        "name": "Cordisburgo",
        "state": "MG"
    },
    {
        "name": "Cordislândia",
        "state": "MG"
    },
    {
        "name": "Corinto",
        "state": "MG"
    },
    {
        "name": "Coroaci",
        "state": "MG"
    },
    {
        "name": "Coromandel",
        "state": "MG"
    },
    {
        "name": "Coronel Fabriciano",
        "state": "MG"
    },
    {
        "name": "Coronel Murta",
        "state": "MG"
    },
    {
        "name": "Coronel Pacheco",
        "state": "MG"
    },
    {
        "name": "Coronel Xavier Chaves",
        "state": "MG"
    },
    {
        "name": "Córrego Danta",
        "state": "MG"
    },
    {
        "name": "Córrego do Bom Jesus",
        "state": "MG"
    },
    {
        "name": "Córrego Fundo",
        "state": "MG"
    },
    {
        "name": "Córrego Novo",
        "state": "MG"
    },
    {
        "name": "Couto de Magalhães de Minas",
        "state": "MG"
    },
    {
        "name": "Crisólita",
        "state": "MG"
    },
    {
        "name": "Cristais",
        "state": "MG"
    },
    {
        "name": "Cristália",
        "state": "MG"
    },
    {
        "name": "Cristiano Otoni",
        "state": "MG"
    },
    {
        "name": "Cristina",
        "state": "MG"
    },
    {
        "name": "Crucilândia",
        "state": "MG"
    },
    {
        "name": "Cruzeiro da Fortaleza",
        "state": "MG"
    },
    {
        "name": "Cruzília",
        "state": "MG"
    },
    {
        "name": "Cuparaque",
        "state": "MG"
    },
    {
        "name": "Curral de Dentro",
        "state": "MG"
    },
    {
        "name": "Curvelo",
        "state": "MG"
    },
    {
        "name": "Datas",
        "state": "MG"
    },
    {
        "name": "Delfim Moreira",
        "state": "MG"
    },
    {
        "name": "Delfinópolis",
        "state": "MG"
    },
    {
        "name": "Delta",
        "state": "MG"
    },
    {
        "name": "Descoberto",
        "state": "MG"
    },
    {
        "name": "Desterro de Entre Rios",
        "state": "MG"
    },
    {
        "name": "Desterro do Melo",
        "state": "MG"
    },
    {
        "name": "Diamantina",
        "state": "MG"
    },
    {
        "name": "Diogo de Vasconcelos",
        "state": "MG"
    },
    {
        "name": "Dionísio",
        "state": "MG"
    },
    {
        "name": "Divinésia",
        "state": "MG"
    },
    {
        "name": "Divino",
        "state": "MG"
    },
    {
        "name": "Divino das Laranjeiras",
        "state": "MG"
    },
    {
        "name": "Divinolândia de Minas",
        "state": "MG"
    },
    {
        "name": "Divinópolis",
        "state": "MG"
    },
    {
        "name": "Divisa Alegre",
        "state": "MG"
    },
    {
        "name": "Divisa Nova",
        "state": "MG"
    },
    {
        "name": "Divisópolis",
        "state": "MG"
    },
    {
        "name": "Dom Bosco",
        "state": "MG"
    },
    {
        "name": "Dom Cavati",
        "state": "MG"
    },
    {
        "name": "Dom Joaquim",
        "state": "MG"
    },
    {
        "name": "Dom Silvério",
        "state": "MG"
    },
    {
        "name": "Dom Viçoso",
        "state": "MG"
    },
    {
        "name": "Dona Eusébia",
        "state": "MG"
    },
    {
        "name": "Dores de Campos",
        "state": "MG"
    },
    {
        "name": "Dores de Guanhães",
        "state": "MG"
    },
    {
        "name": "Dores do Indaiá",
        "state": "MG"
    },
    {
        "name": "Dores do Turvo",
        "state": "MG"
    },
    {
        "name": "Doresópolis",
        "state": "MG"
    },
    {
        "name": "Douradoquara",
        "state": "MG"
    },
    {
        "name": "Durandé",
        "state": "MG"
    },
    {
        "name": "Elói Mendes",
        "state": "MG"
    },
    {
        "name": "Engenheiro Caldas",
        "state": "MG"
    },
    {
        "name": "Engenheiro Navarro",
        "state": "MG"
    },
    {
        "name": "Entre Folhas",
        "state": "MG"
    },
    {
        "name": "Entre Rios de Minas",
        "state": "MG"
    },
    {
        "name": "Ervália",
        "state": "MG"
    },
    {
        "name": "Esmeraldas",
        "state": "MG"
    },
    {
        "name": "Espera Feliz",
        "state": "MG"
    },
    {
        "name": "Espinosa",
        "state": "MG"
    },
    {
        "name": "Espírito Santo do Dourado",
        "state": "MG"
    },
    {
        "name": "Estiva",
        "state": "MG"
    },
    {
        "name": "Estrela Dalva",
        "state": "MG"
    },
    {
        "name": "Estrela do Indaiá",
        "state": "MG"
    },
    {
        "name": "Estrela do Sul",
        "state": "MG"
    },
    {
        "name": "Eugenópolis",
        "state": "MG"
    },
    {
        "name": "Ewbank da Câmara",
        "state": "MG"
    },
    {
        "name": "Extrema",
        "state": "MG"
    },
    {
        "name": "Fama",
        "state": "MG"
    },
    {
        "name": "Faria Lemos",
        "state": "MG"
    },
    {
        "name": "Felício dos Santos",
        "state": "MG"
    },
    {
        "name": "Felisburgo",
        "state": "MG"
    },
    {
        "name": "Felixlândia",
        "state": "MG"
    },
    {
        "name": "Fernandes Tourinho",
        "state": "MG"
    },
    {
        "name": "Ferros",
        "state": "MG"
    },
    {
        "name": "Fervedouro",
        "state": "MG"
    },
    {
        "name": "Florestal",
        "state": "MG"
    },
    {
        "name": "Formiga",
        "state": "MG"
    },
    {
        "name": "Formoso",
        "state": "MG"
    },
    {
        "name": "Fortaleza de Minas",
        "state": "MG"
    },
    {
        "name": "Fortuna de Minas",
        "state": "MG"
    },
    {
        "name": "Francisco Badaró",
        "state": "MG"
    },
    {
        "name": "Francisco Dumont",
        "state": "MG"
    },
    {
        "name": "Francisco Sá",
        "state": "MG"
    },
    {
        "name": "Franciscópolis",
        "state": "MG"
    },
    {
        "name": "Frei Gaspar",
        "state": "MG"
    },
    {
        "name": "Frei Inocêncio",
        "state": "MG"
    },
    {
        "name": "Frei Lagonegro",
        "state": "MG"
    },
    {
        "name": "Fronteira",
        "state": "MG"
    },
    {
        "name": "Fronteira dos Vales",
        "state": "MG"
    },
    {
        "name": "Fruta de Leite",
        "state": "MG"
    },
    {
        "name": "Frutal",
        "state": "MG"
    },
    {
        "name": "Funilândia",
        "state": "MG"
    },
    {
        "name": "Galiléia",
        "state": "MG"
    },
    {
        "name": "Gameleiras",
        "state": "MG"
    },
    {
        "name": "Glaucilândia",
        "state": "MG"
    },
    {
        "name": "Goiabeira",
        "state": "MG"
    },
    {
        "name": "Goianá",
        "state": "MG"
    },
    {
        "name": "Gonçalves",
        "state": "MG"
    },
    {
        "name": "Gonzaga",
        "state": "MG"
    },
    {
        "name": "Gouveia",
        "state": "MG"
    },
    {
        "name": "Governador Valadares",
        "state": "MG"
    },
    {
        "name": "Grão Mogol",
        "state": "MG"
    },
    {
        "name": "Grupiara",
        "state": "MG"
    },
    {
        "name": "Guanhães",
        "state": "MG"
    },
    {
        "name": "Guapé",
        "state": "MG"
    },
    {
        "name": "Guaraciaba",
        "state": "MG"
    },
    {
        "name": "Guaraciama",
        "state": "MG"
    },
    {
        "name": "Guaranésia",
        "state": "MG"
    },
    {
        "name": "Guarani",
        "state": "MG"
    },
    {
        "name": "Guarará",
        "state": "MG"
    },
    {
        "name": "Guarda-Mor",
        "state": "MG"
    },
    {
        "name": "Guaxupé",
        "state": "MG"
    },
    {
        "name": "Guidoval",
        "state": "MG"
    },
    {
        "name": "Guimarânia",
        "state": "MG"
    },
    {
        "name": "Guiricema",
        "state": "MG"
    },
    {
        "name": "Gurinhatã",
        "state": "MG"
    },
    {
        "name": "Heliodora",
        "state": "MG"
    },
    {
        "name": "Iapu",
        "state": "MG"
    },
    {
        "name": "Ibertioga",
        "state": "MG"
    },
    {
        "name": "Ibiá",
        "state": "MG"
    },
    {
        "name": "Ibiaí",
        "state": "MG"
    },
    {
        "name": "Ibiracatu",
        "state": "MG"
    },
    {
        "name": "Ibiraci",
        "state": "MG"
    },
    {
        "name": "Ibirité",
        "state": "MG"
    },
    {
        "name": "Ibitiúra de Minas",
        "state": "MG"
    },
    {
        "name": "Ibituruna",
        "state": "MG"
    },
    {
        "name": "Icaraí de Minas",
        "state": "MG"
    },
    {
        "name": "Igarapé",
        "state": "MG"
    },
    {
        "name": "Igaratinga",
        "state": "MG"
    },
    {
        "name": "Iguatama",
        "state": "MG"
    },
    {
        "name": "Ijaci",
        "state": "MG"
    },
    {
        "name": "Ilicínea",
        "state": "MG"
    },
    {
        "name": "Imbé de Minas",
        "state": "MG"
    },
    {
        "name": "Inconfidentes",
        "state": "MG"
    },
    {
        "name": "Indaiabira",
        "state": "MG"
    },
    {
        "name": "Indianópolis",
        "state": "MG"
    },
    {
        "name": "Ingaí",
        "state": "MG"
    },
    {
        "name": "Inhapim",
        "state": "MG"
    },
    {
        "name": "Inhaúma",
        "state": "MG"
    },
    {
        "name": "Inimutaba",
        "state": "MG"
    },
    {
        "name": "Ipaba",
        "state": "MG"
    },
    {
        "name": "Ipanema",
        "state": "MG"
    },
    {
        "name": "Ipatinga",
        "state": "MG"
    },
    {
        "name": "Ipiaçu",
        "state": "MG"
    },
    {
        "name": "Ipuiúna",
        "state": "MG"
    },
    {
        "name": "Iraí de Minas",
        "state": "MG"
    },
    {
        "name": "Itabira",
        "state": "MG"
    },
    {
        "name": "Itabirinha de Mantena",
        "state": "MG"
    },
    {
        "name": "Itabirito",
        "state": "MG"
    },
    {
        "name": "Itacambira",
        "state": "MG"
    },
    {
        "name": "Itacarambi",
        "state": "MG"
    },
    {
        "name": "Itaguara",
        "state": "MG"
    },
    {
        "name": "Itaipé",
        "state": "MG"
    },
    {
        "name": "Itajubá",
        "state": "MG"
    },
    {
        "name": "Itamarandiba",
        "state": "MG"
    },
    {
        "name": "Itamarati de Minas",
        "state": "MG"
    },
    {
        "name": "Itambacuri",
        "state": "MG"
    },
    {
        "name": "Itambé do Mato Dentro",
        "state": "MG"
    },
    {
        "name": "Itamogi",
        "state": "MG"
    },
    {
        "name": "Itamonte",
        "state": "MG"
    },
    {
        "name": "Itanhandu",
        "state": "MG"
    },
    {
        "name": "Itanhomi",
        "state": "MG"
    },
    {
        "name": "Itaobim",
        "state": "MG"
    },
    {
        "name": "Itapagipe",
        "state": "MG"
    },
    {
        "name": "Itapecerica",
        "state": "MG"
    },
    {
        "name": "Itapeva",
        "state": "MG"
    },
    {
        "name": "Itatiaiuçu",
        "state": "MG"
    },
    {
        "name": "Itaú de Minas",
        "state": "MG"
    },
    {
        "name": "Itaúna",
        "state": "MG"
    },
    {
        "name": "Itaverava",
        "state": "MG"
    },
    {
        "name": "Itinga",
        "state": "MG"
    },
    {
        "name": "Itueta",
        "state": "MG"
    },
    {
        "name": "Ituiutaba",
        "state": "MG"
    },
    {
        "name": "Itumirim",
        "state": "MG"
    },
    {
        "name": "Iturama",
        "state": "MG"
    },
    {
        "name": "Itutinga",
        "state": "MG"
    },
    {
        "name": "Jaboticatubas",
        "state": "MG"
    },
    {
        "name": "Jacinto",
        "state": "MG"
    },
    {
        "name": "Jacuí",
        "state": "MG"
    },
    {
        "name": "Jacutinga",
        "state": "MG"
    },
    {
        "name": "Jaguaraçu",
        "state": "MG"
    },
    {
        "name": "Jaíba",
        "state": "MG"
    },
    {
        "name": "Jampruca",
        "state": "MG"
    },
    {
        "name": "Janaúba",
        "state": "MG"
    },
    {
        "name": "Januária",
        "state": "MG"
    },
    {
        "name": "Japaraíba",
        "state": "MG"
    },
    {
        "name": "Japonvar",
        "state": "MG"
    },
    {
        "name": "Jeceaba",
        "state": "MG"
    },
    {
        "name": "Jenipapo de Minas",
        "state": "MG"
    },
    {
        "name": "Jequeri",
        "state": "MG"
    },
    {
        "name": "Jequitaí",
        "state": "MG"
    },
    {
        "name": "Jequitibá",
        "state": "MG"
    },
    {
        "name": "Jequitinhonha",
        "state": "MG"
    },
    {
        "name": "Jesuânia",
        "state": "MG"
    },
    {
        "name": "Joaíma",
        "state": "MG"
    },
    {
        "name": "Joanésia",
        "state": "MG"
    },
    {
        "name": "João Monlevade",
        "state": "MG"
    },
    {
        "name": "João Pinheiro",
        "state": "MG"
    },
    {
        "name": "Joaquim Felício",
        "state": "MG"
    },
    {
        "name": "Jordânia",
        "state": "MG"
    },
    {
        "name": "José Gonçalves de Minas",
        "state": "MG"
    },
    {
        "name": "José Raydan",
        "state": "MG"
    },
    {
        "name": "Josenópolis",
        "state": "MG"
    },
    {
        "name": "Juatuba",
        "state": "MG"
    },
    {
        "name": "Juiz de Fora",
        "state": "MG"
    },
    {
        "name": "Juramento",
        "state": "MG"
    },
    {
        "name": "Juruaia",
        "state": "MG"
    },
    {
        "name": "Juvenília",
        "state": "MG"
    },
    {
        "name": "Ladainha",
        "state": "MG"
    },
    {
        "name": "Lagamar",
        "state": "MG"
    },
    {
        "name": "Lagoa da Prata",
        "state": "MG"
    },
    {
        "name": "Lagoa dos Patos",
        "state": "MG"
    },
    {
        "name": "Lagoa Dourada",
        "state": "MG"
    },
    {
        "name": "Lagoa Formosa",
        "state": "MG"
    },
    {
        "name": "Lagoa Grande",
        "state": "MG"
    },
    {
        "name": "Lagoa Santa",
        "state": "MG"
    },
    {
        "name": "Lajinha",
        "state": "MG"
    },
    {
        "name": "Lambari",
        "state": "MG"
    },
    {
        "name": "Lamim",
        "state": "MG"
    },
    {
        "name": "Laranjal",
        "state": "MG"
    },
    {
        "name": "Lassance",
        "state": "MG"
    },
    {
        "name": "Lavras",
        "state": "MG"
    },
    {
        "name": "Leandro Ferreira",
        "state": "MG"
    },
    {
        "name": "Leme do Prado",
        "state": "MG"
    },
    {
        "name": "Leopoldina",
        "state": "MG"
    },
    {
        "name": "Liberdade",
        "state": "MG"
    },
    {
        "name": "Lima Duarte",
        "state": "MG"
    },
    {
        "name": "Limeira do Oeste",
        "state": "MG"
    },
    {
        "name": "Lontra",
        "state": "MG"
    },
    {
        "name": "Luisburgo",
        "state": "MG"
    },
    {
        "name": "Luislândia",
        "state": "MG"
    },
    {
        "name": "Luminárias",
        "state": "MG"
    },
    {
        "name": "Luz",
        "state": "MG"
    },
    {
        "name": "Machacalis",
        "state": "MG"
    },
    {
        "name": "Machado",
        "state": "MG"
    },
    {
        "name": "Madre de Deus de Minas",
        "state": "MG"
    },
    {
        "name": "Malacacheta",
        "state": "MG"
    },
    {
        "name": "Mamonas",
        "state": "MG"
    },
    {
        "name": "Manga",
        "state": "MG"
    },
    {
        "name": "Manhuaçu",
        "state": "MG"
    },
    {
        "name": "Manhumirim",
        "state": "MG"
    },
    {
        "name": "Mantena",
        "state": "MG"
    },
    {
        "name": "Mar de Espanha",
        "state": "MG"
    },
    {
        "name": "Maravilhas",
        "state": "MG"
    },
    {
        "name": "Maria da Fé",
        "state": "MG"
    },
    {
        "name": "Mariana",
        "state": "MG"
    },
    {
        "name": "Marilac",
        "state": "MG"
    },
    {
        "name": "Mário Campos",
        "state": "MG"
    },
    {
        "name": "Maripá de Minas",
        "state": "MG"
    },
    {
        "name": "Marliéria",
        "state": "MG"
    },
    {
        "name": "Marmelópolis",
        "state": "MG"
    },
    {
        "name": "Martinho Campos",
        "state": "MG"
    },
    {
        "name": "Martins Soares",
        "state": "MG"
    },
    {
        "name": "Mata Verde",
        "state": "MG"
    },
    {
        "name": "Materlândia",
        "state": "MG"
    },
    {
        "name": "Mateus Leme",
        "state": "MG"
    },
    {
        "name": "Mathias Lobato",
        "state": "MG"
    },
    {
        "name": "Matias Barbosa",
        "state": "MG"
    },
    {
        "name": "Matias Cardoso",
        "state": "MG"
    },
    {
        "name": "Matipó",
        "state": "MG"
    },
    {
        "name": "Mato Verde",
        "state": "MG"
    },
    {
        "name": "Matozinhos",
        "state": "MG"
    },
    {
        "name": "Matutina",
        "state": "MG"
    },
    {
        "name": "Medeiros",
        "state": "MG"
    },
    {
        "name": "Medina",
        "state": "MG"
    },
    {
        "name": "Mendes Pimentel",
        "state": "MG"
    },
    {
        "name": "Mercês",
        "state": "MG"
    },
    {
        "name": "Mesquita",
        "state": "MG"
    },
    {
        "name": "Minas Novas",
        "state": "MG"
    },
    {
        "name": "Minduri",
        "state": "MG"
    },
    {
        "name": "Mirabela",
        "state": "MG"
    },
    {
        "name": "Miradouro",
        "state": "MG"
    },
    {
        "name": "Miraí",
        "state": "MG"
    },
    {
        "name": "Miravânia",
        "state": "MG"
    },
    {
        "name": "Moeda",
        "state": "MG"
    },
    {
        "name": "Moema",
        "state": "MG"
    },
    {
        "name": "Monjolos",
        "state": "MG"
    },
    {
        "name": "Monsenhor Paulo",
        "state": "MG"
    },
    {
        "name": "Montalvânia",
        "state": "MG"
    },
    {
        "name": "Monte Alegre de Minas",
        "state": "MG"
    },
    {
        "name": "Monte Azul",
        "state": "MG"
    },
    {
        "name": "Monte Belo",
        "state": "MG"
    },
    {
        "name": "Monte Carmelo",
        "state": "MG"
    },
    {
        "name": "Monte Formoso",
        "state": "MG"
    },
    {
        "name": "Monte Santo de Minas",
        "state": "MG"
    },
    {
        "name": "Monte Sião",
        "state": "MG"
    },
    {
        "name": "Montes Claros",
        "state": "MG"
    },
    {
        "name": "Montezuma",
        "state": "MG"
    },
    {
        "name": "Morada Nova de Minas",
        "state": "MG"
    },
    {
        "name": "Morro da Garça",
        "state": "MG"
    },
    {
        "name": "Morro do Pilar",
        "state": "MG"
    },
    {
        "name": "Munhoz",
        "state": "MG"
    },
    {
        "name": "Muriaé",
        "state": "MG"
    },
    {
        "name": "Mutum",
        "state": "MG"
    },
    {
        "name": "Muzambinho",
        "state": "MG"
    },
    {
        "name": "Nacip Raydan",
        "state": "MG"
    },
    {
        "name": "Nanuque",
        "state": "MG"
    },
    {
        "name": "Naque",
        "state": "MG"
    },
    {
        "name": "Natalândia",
        "state": "MG"
    },
    {
        "name": "Natércia",
        "state": "MG"
    },
    {
        "name": "Nazareno",
        "state": "MG"
    },
    {
        "name": "Nepomuceno",
        "state": "MG"
    },
    {
        "name": "Ninheira",
        "state": "MG"
    },
    {
        "name": "Nova Belém",
        "state": "MG"
    },
    {
        "name": "Nova Era",
        "state": "MG"
    },
    {
        "name": "Nova Lima",
        "state": "MG"
    },
    {
        "name": "Nova Módica",
        "state": "MG"
    },
    {
        "name": "Nova Ponte",
        "state": "MG"
    },
    {
        "name": "Nova Porteirinha",
        "state": "MG"
    },
    {
        "name": "Nova Resende",
        "state": "MG"
    },
    {
        "name": "Nova Serrana",
        "state": "MG"
    },
    {
        "name": "Nova União",
        "state": "MG"
    },
    {
        "name": "Novo Cruzeiro",
        "state": "MG"
    },
    {
        "name": "Novo Oriente de Minas",
        "state": "MG"
    },
    {
        "name": "Novorizonte",
        "state": "MG"
    },
    {
        "name": "Olaria",
        "state": "MG"
    },
    {
        "name": "Olhos-d`Água",
        "state": "MG"
    },
    {
        "name": "Olímpio Noronha",
        "state": "MG"
    },
    {
        "name": "Oliveira",
        "state": "MG"
    },
    {
        "name": "Oliveira Fortes",
        "state": "MG"
    },
    {
        "name": "Onça de Pitangui",
        "state": "MG"
    },
    {
        "name": "Oratórios",
        "state": "MG"
    },
    {
        "name": "Orizânia",
        "state": "MG"
    },
    {
        "name": "Ouro Branco",
        "state": "MG"
    },
    {
        "name": "Ouro Fino",
        "state": "MG"
    },
    {
        "name": "Ouro Preto",
        "state": "MG"
    },
    {
        "name": "Ouro Verde de Minas",
        "state": "MG"
    },
    {
        "name": "Padre Carvalho",
        "state": "MG"
    },
    {
        "name": "Padre Paraíso",
        "state": "MG"
    },
    {
        "name": "Pai Pedro",
        "state": "MG"
    },
    {
        "name": "Paineiras",
        "state": "MG"
    },
    {
        "name": "Pains",
        "state": "MG"
    },
    {
        "name": "Paiva",
        "state": "MG"
    },
    {
        "name": "Palma",
        "state": "MG"
    },
    {
        "name": "Palmópolis",
        "state": "MG"
    },
    {
        "name": "Papagaios",
        "state": "MG"
    },
    {
        "name": "Pará de Minas",
        "state": "MG"
    },
    {
        "name": "Paracatu",
        "state": "MG"
    },
    {
        "name": "Paraguaçu",
        "state": "MG"
    },
    {
        "name": "Paraisópolis",
        "state": "MG"
    },
    {
        "name": "Paraopeba",
        "state": "MG"
    },
    {
        "name": "Passa Quatro",
        "state": "MG"
    },
    {
        "name": "Passa Tempo",
        "state": "MG"
    },
    {
        "name": "Passabém",
        "state": "MG"
    },
    {
        "name": "Passa-Vinte",
        "state": "MG"
    },
    {
        "name": "Passos",
        "state": "MG"
    },
    {
        "name": "Patis",
        "state": "MG"
    },
    {
        "name": "Patos de Minas",
        "state": "MG"
    },
    {
        "name": "Patrocínio",
        "state": "MG"
    },
    {
        "name": "Patrocínio do Muriaé",
        "state": "MG"
    },
    {
        "name": "Paula Cândido",
        "state": "MG"
    },
    {
        "name": "Paulistas",
        "state": "MG"
    },
    {
        "name": "Pavão",
        "state": "MG"
    },
    {
        "name": "Peçanha",
        "state": "MG"
    },
    {
        "name": "Pedra Azul",
        "state": "MG"
    },
    {
        "name": "Pedra Bonita",
        "state": "MG"
    },
    {
        "name": "Pedra do Anta",
        "state": "MG"
    },
    {
        "name": "Pedra do Indaiá",
        "state": "MG"
    },
    {
        "name": "Pedra Dourada",
        "state": "MG"
    },
    {
        "name": "Pedralva",
        "state": "MG"
    },
    {
        "name": "Pedras de Maria da Cruz",
        "state": "MG"
    },
    {
        "name": "Pedrinópolis",
        "state": "MG"
    },
    {
        "name": "Pedro Leopoldo",
        "state": "MG"
    },
    {
        "name": "Pedro Teixeira",
        "state": "MG"
    },
    {
        "name": "Pequeri",
        "state": "MG"
    },
    {
        "name": "Pequi",
        "state": "MG"
    },
    {
        "name": "Perdigão",
        "state": "MG"
    },
    {
        "name": "Perdizes",
        "state": "MG"
    },
    {
        "name": "Perdões",
        "state": "MG"
    },
    {
        "name": "Periquito",
        "state": "MG"
    },
    {
        "name": "Pescador",
        "state": "MG"
    },
    {
        "name": "Piau",
        "state": "MG"
    },
    {
        "name": "Piedade de Caratinga",
        "state": "MG"
    },
    {
        "name": "Piedade de Ponte Nova",
        "state": "MG"
    },
    {
        "name": "Piedade do Rio Grande",
        "state": "MG"
    },
    {
        "name": "Piedade dos Gerais",
        "state": "MG"
    },
    {
        "name": "Pimenta",
        "state": "MG"
    },
    {
        "name": "Pingo-d`Água",
        "state": "MG"
    },
    {
        "name": "Pintópolis",
        "state": "MG"
    },
    {
        "name": "Piracema",
        "state": "MG"
    },
    {
        "name": "Pirajuba",
        "state": "MG"
    },
    {
        "name": "Piranga",
        "state": "MG"
    },
    {
        "name": "Piranguçu",
        "state": "MG"
    },
    {
        "name": "Piranguinho",
        "state": "MG"
    },
    {
        "name": "Pirapetinga",
        "state": "MG"
    },
    {
        "name": "Pirapora",
        "state": "MG"
    },
    {
        "name": "Piraúba",
        "state": "MG"
    },
    {
        "name": "Pitangui",
        "state": "MG"
    },
    {
        "name": "Piumhi",
        "state": "MG"
    },
    {
        "name": "Planura",
        "state": "MG"
    },
    {
        "name": "Poço Fundo",
        "state": "MG"
    },
    {
        "name": "Poços de Caldas",
        "state": "MG"
    },
    {
        "name": "Pocrane",
        "state": "MG"
    },
    {
        "name": "Pompéu",
        "state": "MG"
    },
    {
        "name": "Ponte Nova",
        "state": "MG"
    },
    {
        "name": "Ponto Chique",
        "state": "MG"
    },
    {
        "name": "Ponto dos Volantes",
        "state": "MG"
    },
    {
        "name": "Porteirinha",
        "state": "MG"
    },
    {
        "name": "Porto Firme",
        "state": "MG"
    },
    {
        "name": "Poté",
        "state": "MG"
    },
    {
        "name": "Pouso Alegre",
        "state": "MG"
    },
    {
        "name": "Pouso Alto",
        "state": "MG"
    },
    {
        "name": "Prados",
        "state": "MG"
    },
    {
        "name": "Prata",
        "state": "MG"
    },
    {
        "name": "Pratápolis",
        "state": "MG"
    },
    {
        "name": "Pratinha",
        "state": "MG"
    },
    {
        "name": "Presidente Bernardes",
        "state": "MG"
    },
    {
        "name": "Presidente Juscelino",
        "state": "MG"
    },
    {
        "name": "Presidente Kubitschek",
        "state": "MG"
    },
    {
        "name": "Presidente Olegário",
        "state": "MG"
    },
    {
        "name": "Prudente de Morais",
        "state": "MG"
    },
    {
        "name": "Quartel Geral",
        "state": "MG"
    },
    {
        "name": "Queluzito",
        "state": "MG"
    },
    {
        "name": "Raposos",
        "state": "MG"
    },
    {
        "name": "Raul Soares",
        "state": "MG"
    },
    {
        "name": "Recreio",
        "state": "MG"
    },
    {
        "name": "Reduto",
        "state": "MG"
    },
    {
        "name": "Resende Costa",
        "state": "MG"
    },
    {
        "name": "Resplendor",
        "state": "MG"
    },
    {
        "name": "Ressaquinha",
        "state": "MG"
    },
    {
        "name": "Riachinho",
        "state": "MG"
    },
    {
        "name": "Riacho dos Machados",
        "state": "MG"
    },
    {
        "name": "Ribeirão das Neves",
        "state": "MG"
    },
    {
        "name": "Ribeirão Vermelho",
        "state": "MG"
    },
    {
        "name": "Rio Acima",
        "state": "MG"
    },
    {
        "name": "Rio Casca",
        "state": "MG"
    },
    {
        "name": "Rio do Prado",
        "state": "MG"
    },
    {
        "name": "Rio Doce",
        "state": "MG"
    },
    {
        "name": "Rio Espera",
        "state": "MG"
    },
    {
        "name": "Rio Manso",
        "state": "MG"
    },
    {
        "name": "Rio Novo",
        "state": "MG"
    },
    {
        "name": "Rio Paranaíba",
        "state": "MG"
    },
    {
        "name": "Rio Pardo de Minas",
        "state": "MG"
    },
    {
        "name": "Rio Piracicaba",
        "state": "MG"
    },
    {
        "name": "Rio Pomba",
        "state": "MG"
    },
    {
        "name": "Rio Preto",
        "state": "MG"
    },
    {
        "name": "Rio Vermelho",
        "state": "MG"
    },
    {
        "name": "Ritápolis",
        "state": "MG"
    },
    {
        "name": "Rochedo de Minas",
        "state": "MG"
    },
    {
        "name": "Rodeiro",
        "state": "MG"
    },
    {
        "name": "Romaria",
        "state": "MG"
    },
    {
        "name": "Rosário da Limeira",
        "state": "MG"
    },
    {
        "name": "Rubelita",
        "state": "MG"
    },
    {
        "name": "Rubim",
        "state": "MG"
    },
    {
        "name": "Sabará",
        "state": "MG"
    },
    {
        "name": "Sabinópolis",
        "state": "MG"
    },
    {
        "name": "Sacramento",
        "state": "MG"
    },
    {
        "name": "Salinas",
        "state": "MG"
    },
    {
        "name": "Salto da Divisa",
        "state": "MG"
    },
    {
        "name": "Santa Bárbara",
        "state": "MG"
    },
    {
        "name": "Santa Bárbara do Leste",
        "state": "MG"
    },
    {
        "name": "Santa Bárbara do Monte Verde",
        "state": "MG"
    },
    {
        "name": "Santa Bárbara do Tugúrio",
        "state": "MG"
    },
    {
        "name": "Santa Cruz de Minas",
        "state": "MG"
    },
    {
        "name": "Santa Cruz de Salinas",
        "state": "MG"
    },
    {
        "name": "Santa Cruz do Escalvado",
        "state": "MG"
    },
    {
        "name": "Santa Efigênia de Minas",
        "state": "MG"
    },
    {
        "name": "Santa Fé de Minas",
        "state": "MG"
    },
    {
        "name": "Santa Helena de Minas",
        "state": "MG"
    },
    {
        "name": "Santa Juliana",
        "state": "MG"
    },
    {
        "name": "Santa Luzia",
        "state": "MG"
    },
    {
        "name": "Santa Margarida",
        "state": "MG"
    },
    {
        "name": "Santa Maria de Itabira",
        "state": "MG"
    },
    {
        "name": "Santa Maria do Salto",
        "state": "MG"
    },
    {
        "name": "Santa Maria do Suaçuí",
        "state": "MG"
    },
    {
        "name": "Santa Rita de Caldas",
        "state": "MG"
    },
    {
        "name": "Santa Rita de Ibitipoca",
        "state": "MG"
    },
    {
        "name": "Santa Rita de Jacutinga",
        "state": "MG"
    },
    {
        "name": "Santa Rita de Minas",
        "state": "MG"
    },
    {
        "name": "Santa Rita do Itueto",
        "state": "MG"
    },
    {
        "name": "Santa Rita do Sapucaí",
        "state": "MG"
    },
    {
        "name": "Santa Rosa da Serra",
        "state": "MG"
    },
    {
        "name": "Santa Vitória",
        "state": "MG"
    },
    {
        "name": "Santana da Vargem",
        "state": "MG"
    },
    {
        "name": "Santana de Cataguases",
        "state": "MG"
    },
    {
        "name": "Santana de Pirapama",
        "state": "MG"
    },
    {
        "name": "Santana do Deserto",
        "state": "MG"
    },
    {
        "name": "Santana do Garambéu",
        "state": "MG"
    },
    {
        "name": "Santana do Jacaré",
        "state": "MG"
    },
    {
        "name": "Santana do Manhuaçu",
        "state": "MG"
    },
    {
        "name": "Santana do Paraíso",
        "state": "MG"
    },
    {
        "name": "Santana do Riacho",
        "state": "MG"
    },
    {
        "name": "Santana dos Montes",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Amparo",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Aventureiro",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Grama",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Itambé",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Jacinto",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Monte",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Retiro",
        "state": "MG"
    },
    {
        "name": "Santo Antônio do Rio Abaixo",
        "state": "MG"
    },
    {
        "name": "Santo Hipólito",
        "state": "MG"
    },
    {
        "name": "Santos Dumont",
        "state": "MG"
    },
    {
        "name": "São Bento Abade",
        "state": "MG"
    },
    {
        "name": "São Brás do Suaçuí",
        "state": "MG"
    },
    {
        "name": "São Domingos das Dores",
        "state": "MG"
    },
    {
        "name": "São Domingos do Prata",
        "state": "MG"
    },
    {
        "name": "São Félix de Minas",
        "state": "MG"
    },
    {
        "name": "São Francisco",
        "state": "MG"
    },
    {
        "name": "São Francisco de Paula",
        "state": "MG"
    },
    {
        "name": "São Francisco de Sales",
        "state": "MG"
    },
    {
        "name": "São Francisco do Glória",
        "state": "MG"
    },
    {
        "name": "São Geraldo",
        "state": "MG"
    },
    {
        "name": "São Geraldo da Piedade",
        "state": "MG"
    },
    {
        "name": "São Geraldo do Baixio",
        "state": "MG"
    },
    {
        "name": "São Gonçalo do Abaeté",
        "state": "MG"
    },
    {
        "name": "São Gonçalo do Pará",
        "state": "MG"
    },
    {
        "name": "São Gonçalo do Rio Abaixo",
        "state": "MG"
    },
    {
        "name": "São Gonçalo do Rio Preto",
        "state": "MG"
    },
    {
        "name": "São Gonçalo do Sapucaí",
        "state": "MG"
    },
    {
        "name": "São Gotardo",
        "state": "MG"
    },
    {
        "name": "São João Batista do Glória",
        "state": "MG"
    },
    {
        "name": "São João da Lagoa",
        "state": "MG"
    },
    {
        "name": "São João da Mata",
        "state": "MG"
    },
    {
        "name": "São João da Ponte",
        "state": "MG"
    },
    {
        "name": "São João das Missões",
        "state": "MG"
    },
    {
        "name": "São João del Rei",
        "state": "MG"
    },
    {
        "name": "São João do Manhuaçu",
        "state": "MG"
    },
    {
        "name": "São João do Manteninha",
        "state": "MG"
    },
    {
        "name": "São João do Oriente",
        "state": "MG"
    },
    {
        "name": "São João do Pacuí",
        "state": "MG"
    },
    {
        "name": "São João do Paraíso",
        "state": "MG"
    },
    {
        "name": "São João Evangelista",
        "state": "MG"
    },
    {
        "name": "São João Nepomuceno",
        "state": "MG"
    },
    {
        "name": "São Joaquim de Bicas",
        "state": "MG"
    },
    {
        "name": "São José da Barra",
        "state": "MG"
    },
    {
        "name": "São José da Lapa",
        "state": "MG"
    },
    {
        "name": "São José da Safira",
        "state": "MG"
    },
    {
        "name": "São José da Varginha",
        "state": "MG"
    },
    {
        "name": "São José do Alegre",
        "state": "MG"
    },
    {
        "name": "São José do Divino",
        "state": "MG"
    },
    {
        "name": "São José do Goiabal",
        "state": "MG"
    },
    {
        "name": "São José do Jacuri",
        "state": "MG"
    },
    {
        "name": "São José do Mantimento",
        "state": "MG"
    },
    {
        "name": "São Lourenço",
        "state": "MG"
    },
    {
        "name": "São Miguel do Anta",
        "state": "MG"
    },
    {
        "name": "São Pedro da União",
        "state": "MG"
    },
    {
        "name": "São Pedro do Suaçuí",
        "state": "MG"
    },
    {
        "name": "São Pedro dos Ferros",
        "state": "MG"
    },
    {
        "name": "São Romão",
        "state": "MG"
    },
    {
        "name": "São Roque de Minas",
        "state": "MG"
    },
    {
        "name": "São Sebastião da Bela Vista",
        "state": "MG"
    },
    {
        "name": "São Sebastião da Vargem Alegre",
        "state": "MG"
    },
    {
        "name": "São Sebastião do Anta",
        "state": "MG"
    },
    {
        "name": "São Sebastião do Maranhão",
        "state": "MG"
    },
    {
        "name": "São Sebastião do Oeste",
        "state": "MG"
    },
    {
        "name": "São Sebastião do Paraíso",
        "state": "MG"
    },
    {
        "name": "São Sebastião do Rio Preto",
        "state": "MG"
    },
    {
        "name": "São Sebastião do Rio Verde",
        "state": "MG"
    },
    {
        "name": "São Thomé das Letras",
        "state": "MG"
    },
    {
        "name": "São Tiago",
        "state": "MG"
    },
    {
        "name": "São Tomás de Aquino",
        "state": "MG"
    },
    {
        "name": "São Vicente de Minas",
        "state": "MG"
    },
    {
        "name": "Sapucaí-Mirim",
        "state": "MG"
    },
    {
        "name": "Sardoá",
        "state": "MG"
    },
    {
        "name": "Sarzedo",
        "state": "MG"
    },
    {
        "name": "Sem-Peixe",
        "state": "MG"
    },
    {
        "name": "Senador Amaral",
        "state": "MG"
    },
    {
        "name": "Senador Cortes",
        "state": "MG"
    },
    {
        "name": "Senador Firmino",
        "state": "MG"
    },
    {
        "name": "Senador José Bento",
        "state": "MG"
    },
    {
        "name": "Senador Modestino Gonçalves",
        "state": "MG"
    },
    {
        "name": "Senhora de Oliveira",
        "state": "MG"
    },
    {
        "name": "Senhora do Porto",
        "state": "MG"
    },
    {
        "name": "Senhora dos Remédios",
        "state": "MG"
    },
    {
        "name": "Sericita",
        "state": "MG"
    },
    {
        "name": "Seritinga",
        "state": "MG"
    },
    {
        "name": "Serra Azul de Minas",
        "state": "MG"
    },
    {
        "name": "Serra da Saudade",
        "state": "MG"
    },
    {
        "name": "Serra do Salitre",
        "state": "MG"
    },
    {
        "name": "Serra dos Aimorés",
        "state": "MG"
    },
    {
        "name": "Serrania",
        "state": "MG"
    },
    {
        "name": "Serranópolis de Minas",
        "state": "MG"
    },
    {
        "name": "Serranos",
        "state": "MG"
    },
    {
        "name": "Serro",
        "state": "MG"
    },
    {
        "name": "Sete Lagoas",
        "state": "MG"
    },
    {
        "name": "Setubinha",
        "state": "MG"
    },
    {
        "name": "Silveirânia",
        "state": "MG"
    },
    {
        "name": "Silvianópolis",
        "state": "MG"
    },
    {
        "name": "Simão Pereira",
        "state": "MG"
    },
    {
        "name": "Simonésia",
        "state": "MG"
    },
    {
        "name": "Sobrália",
        "state": "MG"
    },
    {
        "name": "Soledade de Minas",
        "state": "MG"
    },
    {
        "name": "Tabuleiro",
        "state": "MG"
    },
    {
        "name": "Taiobeiras",
        "state": "MG"
    },
    {
        "name": "Taparuba",
        "state": "MG"
    },
    {
        "name": "Tapira",
        "state": "MG"
    },
    {
        "name": "Tapiraí",
        "state": "MG"
    },
    {
        "name": "Taquaraçu de Minas",
        "state": "MG"
    },
    {
        "name": "Tarumirim",
        "state": "MG"
    },
    {
        "name": "Teixeiras",
        "state": "MG"
    },
    {
        "name": "Teófilo Otoni",
        "state": "MG"
    },
    {
        "name": "Timóteo",
        "state": "MG"
    },
    {
        "name": "Tiradentes",
        "state": "MG"
    },
    {
        "name": "Tiros",
        "state": "MG"
    },
    {
        "name": "Tocantins",
        "state": "MG"
    },
    {
        "name": "Tocos do Moji",
        "state": "MG"
    },
    {
        "name": "Toledo",
        "state": "MG"
    },
    {
        "name": "Tombos",
        "state": "MG"
    },
    {
        "name": "Três Corações",
        "state": "MG"
    },
    {
        "name": "Três Marias",
        "state": "MG"
    },
    {
        "name": "Três Pontas",
        "state": "MG"
    },
    {
        "name": "Tumiritinga",
        "state": "MG"
    },
    {
        "name": "Tupaciguara",
        "state": "MG"
    },
    {
        "name": "Turmalina",
        "state": "MG"
    },
    {
        "name": "Turvolândia",
        "state": "MG"
    },
    {
        "name": "Ubá",
        "state": "MG"
    },
    {
        "name": "Ubaí",
        "state": "MG"
    },
    {
        "name": "Ubaporanga",
        "state": "MG"
    },
    {
        "name": "Uberaba",
        "state": "MG"
    },
    {
        "name": "Uberlândia",
        "state": "MG"
    },
    {
        "name": "Umburatiba",
        "state": "MG"
    },
    {
        "name": "Unaí",
        "state": "MG"
    },
    {
        "name": "União de Minas",
        "state": "MG"
    },
    {
        "name": "Uruana de Minas",
        "state": "MG"
    },
    {
        "name": "Urucânia",
        "state": "MG"
    },
    {
        "name": "Urucuia",
        "state": "MG"
    },
    {
        "name": "Vargem Alegre",
        "state": "MG"
    },
    {
        "name": "Vargem Bonita",
        "state": "MG"
    },
    {
        "name": "Vargem Grande do Rio Pardo",
        "state": "MG"
    },
    {
        "name": "Varginha",
        "state": "MG"
    },
    {
        "name": "Varjão de Minas",
        "state": "MG"
    },
    {
        "name": "Várzea da Palma",
        "state": "MG"
    },
    {
        "name": "Varzelândia",
        "state": "MG"
    },
    {
        "name": "Vazante",
        "state": "MG"
    },
    {
        "name": "Verdelândia",
        "state": "MG"
    },
    {
        "name": "Veredinha",
        "state": "MG"
    },
    {
        "name": "Veríssimo",
        "state": "MG"
    },
    {
        "name": "Vermelho Novo",
        "state": "MG"
    },
    {
        "name": "Vespasiano",
        "state": "MG"
    },
    {
        "name": "Viçosa",
        "state": "MG"
    },
    {
        "name": "Vieiras",
        "state": "MG"
    },
    {
        "name": "Virgem da Lapa",
        "state": "MG"
    },
    {
        "name": "Virgínia",
        "state": "MG"
    },
    {
        "name": "Virginópolis",
        "state": "MG"
    },
    {
        "name": "Virgolândia",
        "state": "MG"
    },
    {
        "name": "Visconde do Rio Branco",
        "state": "MG"
    },
    {
        "name": "Volta Grande",
        "state": "MG"
    },
    {
        "name": "Wenceslau Braz",
        "state": "MG"
    },
    {
        "name": "Abaetetuba",
        "state": "PA"
    },
    {
        "name": "Abel Figueiredo",
        "state": "PA"
    },
    {
        "name": "Acará",
        "state": "PA"
    },
    {
        "name": "Afuá",
        "state": "PA"
    },
    {
        "name": "Água Azul do Norte",
        "state": "PA"
    },
    {
        "name": "Alenquer",
        "state": "PA"
    },
    {
        "name": "Almeirim",
        "state": "PA"
    },
    {
        "name": "Altamira",
        "state": "PA"
    },
    {
        "name": "Anajás",
        "state": "PA"
    },
    {
        "name": "Ananindeua",
        "state": "PA"
    },
    {
        "name": "Anapu",
        "state": "PA"
    },
    {
        "name": "Augusto Corrêa",
        "state": "PA"
    },
    {
        "name": "Aurora do Pará",
        "state": "PA"
    },
    {
        "name": "Aveiro",
        "state": "PA"
    },
    {
        "name": "Bagre",
        "state": "PA"
    },
    {
        "name": "Baião",
        "state": "PA"
    },
    {
        "name": "Bannach",
        "state": "PA"
    },
    {
        "name": "Barcarena",
        "state": "PA"
    },
    {
        "name": "Belém",
        "state": "PA"
    },
    {
        "name": "Belterra",
        "state": "PA"
    },
    {
        "name": "Benevides",
        "state": "PA"
    },
    {
        "name": "Bom Jesus do Tocantins",
        "state": "PA"
    },
    {
        "name": "Bonito",
        "state": "PA"
    },
    {
        "name": "Bragança",
        "state": "PA"
    },
    {
        "name": "Brasil Novo",
        "state": "PA"
    },
    {
        "name": "Brejo Grande do Araguaia",
        "state": "PA"
    },
    {
        "name": "Breu Branco",
        "state": "PA"
    },
    {
        "name": "Breves",
        "state": "PA"
    },
    {
        "name": "Bujaru",
        "state": "PA"
    },
    {
        "name": "Cachoeira do Arari",
        "state": "PA"
    },
    {
        "name": "Cachoeira do Piriá",
        "state": "PA"
    },
    {
        "name": "Cametá",
        "state": "PA"
    },
    {
        "name": "Canaã dos Carajás",
        "state": "PA"
    },
    {
        "name": "Capanema",
        "state": "PA"
    },
    {
        "name": "Capitão Poço",
        "state": "PA"
    },
    {
        "name": "Castanhal",
        "state": "PA"
    },
    {
        "name": "Chaves",
        "state": "PA"
    },
    {
        "name": "Colares",
        "state": "PA"
    },
    {
        "name": "Conceição do Araguaia",
        "state": "PA"
    },
    {
        "name": "Concórdia do Pará",
        "state": "PA"
    },
    {
        "name": "Cumaru do Norte",
        "state": "PA"
    },
    {
        "name": "Curionópolis",
        "state": "PA"
    },
    {
        "name": "Curralinho",
        "state": "PA"
    },
    {
        "name": "Curuá",
        "state": "PA"
    },
    {
        "name": "Curuçá",
        "state": "PA"
    },
    {
        "name": "Dom Eliseu",
        "state": "PA"
    },
    {
        "name": "Eldorado dos Carajás",
        "state": "PA"
    },
    {
        "name": "Faro",
        "state": "PA"
    },
    {
        "name": "Floresta do Araguaia",
        "state": "PA"
    },
    {
        "name": "Garrafão do Norte",
        "state": "PA"
    },
    {
        "name": "Goianésia do Pará",
        "state": "PA"
    },
    {
        "name": "Gurupá",
        "state": "PA"
    },
    {
        "name": "Igarapé-Açu",
        "state": "PA"
    },
    {
        "name": "Igarapé-Miri",
        "state": "PA"
    },
    {
        "name": "Inhangapi",
        "state": "PA"
    },
    {
        "name": "Ipixuna do Pará",
        "state": "PA"
    },
    {
        "name": "Irituia",
        "state": "PA"
    },
    {
        "name": "Itaituba",
        "state": "PA"
    },
    {
        "name": "Itupiranga",
        "state": "PA"
    },
    {
        "name": "Jacareacanga",
        "state": "PA"
    },
    {
        "name": "Jacundá",
        "state": "PA"
    },
    {
        "name": "Juruti",
        "state": "PA"
    },
    {
        "name": "Limoeiro do Ajuru",
        "state": "PA"
    },
    {
        "name": "Mãe do Rio",
        "state": "PA"
    },
    {
        "name": "Magalhães Barata",
        "state": "PA"
    },
    {
        "name": "Marabá",
        "state": "PA"
    },
    {
        "name": "Maracanã",
        "state": "PA"
    },
    {
        "name": "Marapanim",
        "state": "PA"
    },
    {
        "name": "Marituba",
        "state": "PA"
    },
    {
        "name": "Medicilândia",
        "state": "PA"
    },
    {
        "name": "Melgaço",
        "state": "PA"
    },
    {
        "name": "Mocajuba",
        "state": "PA"
    },
    {
        "name": "Moju",
        "state": "PA"
    },
    {
        "name": "Monte Alegre",
        "state": "PA"
    },
    {
        "name": "Muaná",
        "state": "PA"
    },
    {
        "name": "Nova Esperança do Piriá",
        "state": "PA"
    },
    {
        "name": "Nova Ipixuna",
        "state": "PA"
    },
    {
        "name": "Nova Timboteua",
        "state": "PA"
    },
    {
        "name": "Novo Progresso",
        "state": "PA"
    },
    {
        "name": "Novo Repartimento",
        "state": "PA"
    },
    {
        "name": "Óbidos",
        "state": "PA"
    },
    {
        "name": "Oeiras do Pará",
        "state": "PA"
    },
    {
        "name": "Oriximiná",
        "state": "PA"
    },
    {
        "name": "Ourém",
        "state": "PA"
    },
    {
        "name": "Ourilândia do Norte",
        "state": "PA"
    },
    {
        "name": "Pacajá",
        "state": "PA"
    },
    {
        "name": "Palestina do Pará",
        "state": "PA"
    },
    {
        "name": "Paragominas",
        "state": "PA"
    },
    {
        "name": "Parauapebas",
        "state": "PA"
    },
    {
        "name": "Pau d`Arco",
        "state": "PA"
    },
    {
        "name": "Peixe-Boi",
        "state": "PA"
    },
    {
        "name": "Piçarra",
        "state": "PA"
    },
    {
        "name": "Placas",
        "state": "PA"
    },
    {
        "name": "Ponta de Pedras",
        "state": "PA"
    },
    {
        "name": "Portel",
        "state": "PA"
    },
    {
        "name": "Porto de Moz",
        "state": "PA"
    },
    {
        "name": "Prainha",
        "state": "PA"
    },
    {
        "name": "Primavera",
        "state": "PA"
    },
    {
        "name": "Quatipuru",
        "state": "PA"
    },
    {
        "name": "Redenção",
        "state": "PA"
    },
    {
        "name": "Rio Maria",
        "state": "PA"
    },
    {
        "name": "Rondon do Pará",
        "state": "PA"
    },
    {
        "name": "Rurópolis",
        "state": "PA"
    },
    {
        "name": "Salinópolis",
        "state": "PA"
    },
    {
        "name": "Salvaterra",
        "state": "PA"
    },
    {
        "name": "Santa Bárbara do Pará",
        "state": "PA"
    },
    {
        "name": "Santa Cruz do Arari",
        "state": "PA"
    },
    {
        "name": "Santa Isabel do Pará",
        "state": "PA"
    },
    {
        "name": "Santa Luzia do Pará",
        "state": "PA"
    },
    {
        "name": "Santa Maria das Barreiras",
        "state": "PA"
    },
    {
        "name": "Santa Maria do Pará",
        "state": "PA"
    },
    {
        "name": "Santana do Araguaia",
        "state": "PA"
    },
    {
        "name": "Santarém",
        "state": "PA"
    },
    {
        "name": "Santarém Novo",
        "state": "PA"
    },
    {
        "name": "Santo Antônio do Tauá",
        "state": "PA"
    },
    {
        "name": "São Caetano de Odivelas",
        "state": "PA"
    },
    {
        "name": "São Domingos do Araguaia",
        "state": "PA"
    },
    {
        "name": "São Domingos do Capim",
        "state": "PA"
    },
    {
        "name": "São Félix do Xingu",
        "state": "PA"
    },
    {
        "name": "São Francisco do Pará",
        "state": "PA"
    },
    {
        "name": "São Geraldo do Araguaia",
        "state": "PA"
    },
    {
        "name": "São João da Ponta",
        "state": "PA"
    },
    {
        "name": "São João de Pirabas",
        "state": "PA"
    },
    {
        "name": "São João do Araguaia",
        "state": "PA"
    },
    {
        "name": "São Miguel do Guamá",
        "state": "PA"
    },
    {
        "name": "São Sebastião da Boa Vista",
        "state": "PA"
    },
    {
        "name": "Sapucaia",
        "state": "PA"
    },
    {
        "name": "Senador José Porfírio",
        "state": "PA"
    },
    {
        "name": "Soure",
        "state": "PA"
    },
    {
        "name": "Tailândia",
        "state": "PA"
    },
    {
        "name": "Terra Alta",
        "state": "PA"
    },
    {
        "name": "Terra Santa",
        "state": "PA"
    },
    {
        "name": "Tomé-Açu",
        "state": "PA"
    },
    {
        "name": "Tracuateua",
        "state": "PA"
    },
    {
        "name": "Trairão",
        "state": "PA"
    },
    {
        "name": "Tucumã",
        "state": "PA"
    },
    {
        "name": "Tucuruí",
        "state": "PA"
    },
    {
        "name": "Ulianópolis",
        "state": "PA"
    },
    {
        "name": "Uruará",
        "state": "PA"
    },
    {
        "name": "Vigia",
        "state": "PA"
    },
    {
        "name": "Viseu",
        "state": "PA"
    },
    {
        "name": "Vitória do Xingu",
        "state": "PA"
    },
    {
        "name": "Xinguara",
        "state": "PA"
    },
    {
        "name": "Água Branca",
        "state": "PB"
    },
    {
        "name": "Aguiar",
        "state": "PB"
    },
    {
        "name": "Alagoa Grande",
        "state": "PB"
    },
    {
        "name": "Alagoa Nova",
        "state": "PB"
    },
    {
        "name": "Alagoinha",
        "state": "PB"
    },
    {
        "name": "Alcantil",
        "state": "PB"
    },
    {
        "name": "Algodão de Jandaíra",
        "state": "PB"
    },
    {
        "name": "Alhandra",
        "state": "PB"
    },
    {
        "name": "Amparo",
        "state": "PB"
    },
    {
        "name": "Aparecida",
        "state": "PB"
    },
    {
        "name": "Araçagi",
        "state": "PB"
    },
    {
        "name": "Arara",
        "state": "PB"
    },
    {
        "name": "Araruna",
        "state": "PB"
    },
    {
        "name": "Areia",
        "state": "PB"
    },
    {
        "name": "Areia de Baraúnas",
        "state": "PB"
    },
    {
        "name": "Areial",
        "state": "PB"
    },
    {
        "name": "Aroeiras",
        "state": "PB"
    },
    {
        "name": "Assunção",
        "state": "PB"
    },
    {
        "name": "Baía da Traição",
        "state": "PB"
    },
    {
        "name": "Bananeiras",
        "state": "PB"
    },
    {
        "name": "Baraúna",
        "state": "PB"
    },
    {
        "name": "Barra de Santa Rosa",
        "state": "PB"
    },
    {
        "name": "Barra de Santana",
        "state": "PB"
    },
    {
        "name": "Barra de São Miguel",
        "state": "PB"
    },
    {
        "name": "Bayeux",
        "state": "PB"
    },
    {
        "name": "Belém",
        "state": "PB"
    },
    {
        "name": "Belém do Brejo do Cruz",
        "state": "PB"
    },
    {
        "name": "Bernardino Batista",
        "state": "PB"
    },
    {
        "name": "Boa Ventura",
        "state": "PB"
    },
    {
        "name": "Boa Vista",
        "state": "PB"
    },
    {
        "name": "Bom Jesus",
        "state": "PB"
    },
    {
        "name": "Bom Sucesso",
        "state": "PB"
    },
    {
        "name": "Bonito de Santa Fé",
        "state": "PB"
    },
    {
        "name": "Boqueirão",
        "state": "PB"
    },
    {
        "name": "Borborema",
        "state": "PB"
    },
    {
        "name": "Brejo do Cruz",
        "state": "PB"
    },
    {
        "name": "Brejo dos Santos",
        "state": "PB"
    },
    {
        "name": "Caaporã",
        "state": "PB"
    },
    {
        "name": "Cabaceiras",
        "state": "PB"
    },
    {
        "name": "Cabedelo",
        "state": "PB"
    },
    {
        "name": "Cachoeira dos Índios",
        "state": "PB"
    },
    {
        "name": "Cacimba de Areia",
        "state": "PB"
    },
    {
        "name": "Cacimba de Dentro",
        "state": "PB"
    },
    {
        "name": "Cacimbas",
        "state": "PB"
    },
    {
        "name": "Caiçara",
        "state": "PB"
    },
    {
        "name": "Cajazeiras",
        "state": "PB"
    },
    {
        "name": "Cajazeirinhas",
        "state": "PB"
    },
    {
        "name": "Caldas Brandão",
        "state": "PB"
    },
    {
        "name": "Camalaú",
        "state": "PB"
    },
    {
        "name": "Campina Grande",
        "state": "PB"
    },
    {
        "name": "Campo de Santana",
        "state": "PB"
    },
    {
        "name": "Capim",
        "state": "PB"
    },
    {
        "name": "Caraúbas",
        "state": "PB"
    },
    {
        "name": "Carrapateira",
        "state": "PB"
    },
    {
        "name": "Casserengue",
        "state": "PB"
    },
    {
        "name": "Catingueira",
        "state": "PB"
    },
    {
        "name": "Catolé do Rocha",
        "state": "PB"
    },
    {
        "name": "Caturité",
        "state": "PB"
    },
    {
        "name": "Conceição",
        "state": "PB"
    },
    {
        "name": "Condado",
        "state": "PB"
    },
    {
        "name": "Conde",
        "state": "PB"
    },
    {
        "name": "Congo",
        "state": "PB"
    },
    {
        "name": "Coremas",
        "state": "PB"
    },
    {
        "name": "Coxixola",
        "state": "PB"
    },
    {
        "name": "Cruz do Espírito Santo",
        "state": "PB"
    },
    {
        "name": "Cubati",
        "state": "PB"
    },
    {
        "name": "Cuité",
        "state": "PB"
    },
    {
        "name": "Cuité de Mamanguape",
        "state": "PB"
    },
    {
        "name": "Cuitegi",
        "state": "PB"
    },
    {
        "name": "Curral de Cima",
        "state": "PB"
    },
    {
        "name": "Curral Velho",
        "state": "PB"
    },
    {
        "name": "Damião",
        "state": "PB"
    },
    {
        "name": "Desterro",
        "state": "PB"
    },
    {
        "name": "Diamante",
        "state": "PB"
    },
    {
        "name": "Dona Inês",
        "state": "PB"
    },
    {
        "name": "Duas Estradas",
        "state": "PB"
    },
    {
        "name": "Emas",
        "state": "PB"
    },
    {
        "name": "Esperança",
        "state": "PB"
    },
    {
        "name": "Fagundes",
        "state": "PB"
    },
    {
        "name": "Frei Martinho",
        "state": "PB"
    },
    {
        "name": "Gado Bravo",
        "state": "PB"
    },
    {
        "name": "Guarabira",
        "state": "PB"
    },
    {
        "name": "Gurinhém",
        "state": "PB"
    },
    {
        "name": "Gurjão",
        "state": "PB"
    },
    {
        "name": "Ibiara",
        "state": "PB"
    },
    {
        "name": "Igaracy",
        "state": "PB"
    },
    {
        "name": "Imaculada",
        "state": "PB"
    },
    {
        "name": "Ingá",
        "state": "PB"
    },
    {
        "name": "Itabaiana",
        "state": "PB"
    },
    {
        "name": "Itaporanga",
        "state": "PB"
    },
    {
        "name": "Itapororoca",
        "state": "PB"
    },
    {
        "name": "Itatuba",
        "state": "PB"
    },
    {
        "name": "Jacaraú",
        "state": "PB"
    },
    {
        "name": "Jericó",
        "state": "PB"
    },
    {
        "name": "João Pessoa",
        "state": "PB"
    },
    {
        "name": "Juarez Távora",
        "state": "PB"
    },
    {
        "name": "Juazeirinho",
        "state": "PB"
    },
    {
        "name": "Junco do Seridó",
        "state": "PB"
    },
    {
        "name": "Juripiranga",
        "state": "PB"
    },
    {
        "name": "Juru",
        "state": "PB"
    },
    {
        "name": "Lagoa",
        "state": "PB"
    },
    {
        "name": "Lagoa de Dentro",
        "state": "PB"
    },
    {
        "name": "Lagoa Seca",
        "state": "PB"
    },
    {
        "name": "Lastro",
        "state": "PB"
    },
    {
        "name": "Livramento",
        "state": "PB"
    },
    {
        "name": "Logradouro",
        "state": "PB"
    },
    {
        "name": "Lucena",
        "state": "PB"
    },
    {
        "name": "Mãe d`Água",
        "state": "PB"
    },
    {
        "name": "Malta",
        "state": "PB"
    },
    {
        "name": "Mamanguape",
        "state": "PB"
    },
    {
        "name": "Manaíra",
        "state": "PB"
    },
    {
        "name": "Marcação",
        "state": "PB"
    },
    {
        "name": "Mari",
        "state": "PB"
    },
    {
        "name": "Marizópolis",
        "state": "PB"
    },
    {
        "name": "Massaranduba",
        "state": "PB"
    },
    {
        "name": "Mataraca",
        "state": "PB"
    },
    {
        "name": "Matinhas",
        "state": "PB"
    },
    {
        "name": "Mato Grosso",
        "state": "PB"
    },
    {
        "name": "Maturéia",
        "state": "PB"
    },
    {
        "name": "Mogeiro",
        "state": "PB"
    },
    {
        "name": "Montadas",
        "state": "PB"
    },
    {
        "name": "Monte Horebe",
        "state": "PB"
    },
    {
        "name": "Monteiro",
        "state": "PB"
    },
    {
        "name": "Mulungu",
        "state": "PB"
    },
    {
        "name": "Natuba",
        "state": "PB"
    },
    {
        "name": "Nazarezinho",
        "state": "PB"
    },
    {
        "name": "Nova Floresta",
        "state": "PB"
    },
    {
        "name": "Nova Olinda",
        "state": "PB"
    },
    {
        "name": "Nova Palmeira",
        "state": "PB"
    },
    {
        "name": "Olho d`Água",
        "state": "PB"
    },
    {
        "name": "Olivedos",
        "state": "PB"
    },
    {
        "name": "Ouro Velho",
        "state": "PB"
    },
    {
        "name": "Parari",
        "state": "PB"
    },
    {
        "name": "Passagem",
        "state": "PB"
    },
    {
        "name": "Patos",
        "state": "PB"
    },
    {
        "name": "Paulista",
        "state": "PB"
    },
    {
        "name": "Pedra Branca",
        "state": "PB"
    },
    {
        "name": "Pedra Lavrada",
        "state": "PB"
    },
    {
        "name": "Pedras de Fogo",
        "state": "PB"
    },
    {
        "name": "Pedro Régis",
        "state": "PB"
    },
    {
        "name": "Piancó",
        "state": "PB"
    },
    {
        "name": "Picuí",
        "state": "PB"
    },
    {
        "name": "Pilar",
        "state": "PB"
    },
    {
        "name": "Pilões",
        "state": "PB"
    },
    {
        "name": "Pilõezinhos",
        "state": "PB"
    },
    {
        "name": "Pirpirituba",
        "state": "PB"
    },
    {
        "name": "Pitimbu",
        "state": "PB"
    },
    {
        "name": "Pocinhos",
        "state": "PB"
    },
    {
        "name": "Poço Dantas",
        "state": "PB"
    },
    {
        "name": "Poço de José de Moura",
        "state": "PB"
    },
    {
        "name": "Pombal",
        "state": "PB"
    },
    {
        "name": "Prata",
        "state": "PB"
    },
    {
        "name": "Princesa Isabel",
        "state": "PB"
    },
    {
        "name": "Puxinanã",
        "state": "PB"
    },
    {
        "name": "Queimadas",
        "state": "PB"
    },
    {
        "name": "Quixabá",
        "state": "PB"
    },
    {
        "name": "Remígio",
        "state": "PB"
    },
    {
        "name": "Riachão",
        "state": "PB"
    },
    {
        "name": "Riachão do Bacamarte",
        "state": "PB"
    },
    {
        "name": "Riachão do Poço",
        "state": "PB"
    },
    {
        "name": "Riacho de Santo Antônio",
        "state": "PB"
    },
    {
        "name": "Riacho dos Cavalos",
        "state": "PB"
    },
    {
        "name": "Rio Tinto",
        "state": "PB"
    },
    {
        "name": "Salgadinho",
        "state": "PB"
    },
    {
        "name": "Salgado de São Félix",
        "state": "PB"
    },
    {
        "name": "Santa Cecília",
        "state": "PB"
    },
    {
        "name": "Santa Cruz",
        "state": "PB"
    },
    {
        "name": "Santa Helena",
        "state": "PB"
    },
    {
        "name": "Santa Inês",
        "state": "PB"
    },
    {
        "name": "Santa Luzia",
        "state": "PB"
    },
    {
        "name": "Santa Rita",
        "state": "PB"
    },
    {
        "name": "Santa Teresinha",
        "state": "PB"
    },
    {
        "name": "Santana de Mangueira",
        "state": "PB"
    },
    {
        "name": "Santana dos Garrotes",
        "state": "PB"
    },
    {
        "name": "Santarém",
        "state": "PB"
    },
    {
        "name": "Santo André",
        "state": "PB"
    },
    {
        "name": "São Bentinho",
        "state": "PB"
    },
    {
        "name": "São Bento",
        "state": "PB"
    },
    {
        "name": "São Domingos de Pombal",
        "state": "PB"
    },
    {
        "name": "São Domingos do Cariri",
        "state": "PB"
    },
    {
        "name": "São Francisco",
        "state": "PB"
    },
    {
        "name": "São João do Cariri",
        "state": "PB"
    },
    {
        "name": "São João do Rio do Peixe",
        "state": "PB"
    },
    {
        "name": "São João do Tigre",
        "state": "PB"
    },
    {
        "name": "São José da Lagoa Tapada",
        "state": "PB"
    },
    {
        "name": "São José de Caiana",
        "state": "PB"
    },
    {
        "name": "São José de Espinharas",
        "state": "PB"
    },
    {
        "name": "São José de Piranhas",
        "state": "PB"
    },
    {
        "name": "São José de Princesa",
        "state": "PB"
    },
    {
        "name": "São José do Bonfim",
        "state": "PB"
    },
    {
        "name": "São José do Brejo do Cruz",
        "state": "PB"
    },
    {
        "name": "São José do Sabugi",
        "state": "PB"
    },
    {
        "name": "São José dos Cordeiros",
        "state": "PB"
    },
    {
        "name": "São José dos Ramos",
        "state": "PB"
    },
    {
        "name": "São Mamede",
        "state": "PB"
    },
    {
        "name": "São Miguel de Taipu",
        "state": "PB"
    },
    {
        "name": "São Sebastião de Lagoa de Roça",
        "state": "PB"
    },
    {
        "name": "São Sebastião do Umbuzeiro",
        "state": "PB"
    },
    {
        "name": "Sapé",
        "state": "PB"
    },
    {
        "name": "Seridó",
        "state": "PB"
    },
    {
        "name": "Serra Branca",
        "state": "PB"
    },
    {
        "name": "Serra da Raiz",
        "state": "PB"
    },
    {
        "name": "Serra Grande",
        "state": "PB"
    },
    {
        "name": "Serra Redonda",
        "state": "PB"
    },
    {
        "name": "Serraria",
        "state": "PB"
    },
    {
        "name": "Sertãozinho",
        "state": "PB"
    },
    {
        "name": "Sobrado",
        "state": "PB"
    },
    {
        "name": "Solânea",
        "state": "PB"
    },
    {
        "name": "Soledade",
        "state": "PB"
    },
    {
        "name": "Sossêgo",
        "state": "PB"
    },
    {
        "name": "Sousa",
        "state": "PB"
    },
    {
        "name": "Sumé",
        "state": "PB"
    },
    {
        "name": "Taperoá",
        "state": "PB"
    },
    {
        "name": "Tavares",
        "state": "PB"
    },
    {
        "name": "Teixeira",
        "state": "PB"
    },
    {
        "name": "Tenório",
        "state": "PB"
    },
    {
        "name": "Triunfo",
        "state": "PB"
    },
    {
        "name": "Uiraúna",
        "state": "PB"
    },
    {
        "name": "Umbuzeiro",
        "state": "PB"
    },
    {
        "name": "Várzea",
        "state": "PB"
    },
    {
        "name": "Vieirópolis",
        "state": "PB"
    },
    {
        "name": "Vista Serrana",
        "state": "PB"
    },
    {
        "name": "Zabelê",
        "state": "PB"
    },
    {
        "name": "Abatiá",
        "state": "PR"
    },
    {
        "name": "Adrianópolis",
        "state": "PR"
    },
    {
        "name": "Agudos do Sul",
        "state": "PR"
    },
    {
        "name": "Almirante Tamandaré",
        "state": "PR"
    },
    {
        "name": "Altamira do Paraná",
        "state": "PR"
    },
    {
        "name": "Alto Paraíso",
        "state": "PR"
    },
    {
        "name": "Alto Paraná",
        "state": "PR"
    },
    {
        "name": "Alto Piquiri",
        "state": "PR"
    },
    {
        "name": "Altônia",
        "state": "PR"
    },
    {
        "name": "Alvorada do Sul",
        "state": "PR"
    },
    {
        "name": "Amaporã",
        "state": "PR"
    },
    {
        "name": "Ampére",
        "state": "PR"
    },
    {
        "name": "Anahy",
        "state": "PR"
    },
    {
        "name": "Andirá",
        "state": "PR"
    },
    {
        "name": "Ângulo",
        "state": "PR"
    },
    {
        "name": "Antonina",
        "state": "PR"
    },
    {
        "name": "Antônio Olinto",
        "state": "PR"
    },
    {
        "name": "Apucarana",
        "state": "PR"
    },
    {
        "name": "Arapongas",
        "state": "PR"
    },
    {
        "name": "Arapoti",
        "state": "PR"
    },
    {
        "name": "Arapuã",
        "state": "PR"
    },
    {
        "name": "Araruna",
        "state": "PR"
    },
    {
        "name": "Araucária",
        "state": "PR"
    },
    {
        "name": "Ariranha do Ivaí",
        "state": "PR"
    },
    {
        "name": "Assaí",
        "state": "PR"
    },
    {
        "name": "Assis Chateaubriand",
        "state": "PR"
    },
    {
        "name": "Astorga",
        "state": "PR"
    },
    {
        "name": "Atalaia",
        "state": "PR"
    },
    {
        "name": "Balsa Nova",
        "state": "PR"
    },
    {
        "name": "Bandeirantes",
        "state": "PR"
    },
    {
        "name": "Barbosa Ferraz",
        "state": "PR"
    },
    {
        "name": "Barra do Jacaré",
        "state": "PR"
    },
    {
        "name": "Barracão",
        "state": "PR"
    },
    {
        "name": "Bela Vista da Caroba",
        "state": "PR"
    },
    {
        "name": "Bela Vista do Paraíso",
        "state": "PR"
    },
    {
        "name": "Bituruna",
        "state": "PR"
    },
    {
        "name": "Boa Esperança",
        "state": "PR"
    },
    {
        "name": "Boa Esperança do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Boa Ventura de São Roque",
        "state": "PR"
    },
    {
        "name": "Boa Vista da Aparecida",
        "state": "PR"
    },
    {
        "name": "Bocaiúva do Sul",
        "state": "PR"
    },
    {
        "name": "Bom Jesus do Sul",
        "state": "PR"
    },
    {
        "name": "Bom Sucesso",
        "state": "PR"
    },
    {
        "name": "Bom Sucesso do Sul",
        "state": "PR"
    },
    {
        "name": "Borrazópolis",
        "state": "PR"
    },
    {
        "name": "Braganey",
        "state": "PR"
    },
    {
        "name": "Brasilândia do Sul",
        "state": "PR"
    },
    {
        "name": "Cafeara",
        "state": "PR"
    },
    {
        "name": "Cafelândia",
        "state": "PR"
    },
    {
        "name": "Cafezal do Sul",
        "state": "PR"
    },
    {
        "name": "Califórnia",
        "state": "PR"
    },
    {
        "name": "Cambará",
        "state": "PR"
    },
    {
        "name": "Cambé",
        "state": "PR"
    },
    {
        "name": "Cambira",
        "state": "PR"
    },
    {
        "name": "Campina da Lagoa",
        "state": "PR"
    },
    {
        "name": "Campina do Simão",
        "state": "PR"
    },
    {
        "name": "Campina Grande do Sul",
        "state": "PR"
    },
    {
        "name": "Campo Bonito",
        "state": "PR"
    },
    {
        "name": "Campo do Tenente",
        "state": "PR"
    },
    {
        "name": "Campo Largo",
        "state": "PR"
    },
    {
        "name": "Campo Magro",
        "state": "PR"
    },
    {
        "name": "Campo Mourão",
        "state": "PR"
    },
    {
        "name": "Cândido de Abreu",
        "state": "PR"
    },
    {
        "name": "Candói",
        "state": "PR"
    },
    {
        "name": "Cantagalo",
        "state": "PR"
    },
    {
        "name": "Capanema",
        "state": "PR"
    },
    {
        "name": "Capitão Leônidas Marques",
        "state": "PR"
    },
    {
        "name": "Carambeí",
        "state": "PR"
    },
    {
        "name": "Carlópolis",
        "state": "PR"
    },
    {
        "name": "Cascavel",
        "state": "PR"
    },
    {
        "name": "Castro",
        "state": "PR"
    },
    {
        "name": "Catanduvas",
        "state": "PR"
    },
    {
        "name": "Centenário do Sul",
        "state": "PR"
    },
    {
        "name": "Cerro Azul",
        "state": "PR"
    },
    {
        "name": "Céu Azul",
        "state": "PR"
    },
    {
        "name": "Chopinzinho",
        "state": "PR"
    },
    {
        "name": "Cianorte",
        "state": "PR"
    },
    {
        "name": "Cidade Gaúcha",
        "state": "PR"
    },
    {
        "name": "Clevelândia",
        "state": "PR"
    },
    {
        "name": "Colombo",
        "state": "PR"
    },
    {
        "name": "Colorado",
        "state": "PR"
    },
    {
        "name": "Congonhinhas",
        "state": "PR"
    },
    {
        "name": "Conselheiro Mairinck",
        "state": "PR"
    },
    {
        "name": "Contenda",
        "state": "PR"
    },
    {
        "name": "Corbélia",
        "state": "PR"
    },
    {
        "name": "Cornélio Procópio",
        "state": "PR"
    },
    {
        "name": "Coronel Domingos Soares",
        "state": "PR"
    },
    {
        "name": "Coronel Vivida",
        "state": "PR"
    },
    {
        "name": "Corumbataí do Sul",
        "state": "PR"
    },
    {
        "name": "Cruz Machado",
        "state": "PR"
    },
    {
        "name": "Cruzeiro do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Cruzeiro do Oeste",
        "state": "PR"
    },
    {
        "name": "Cruzeiro do Sul",
        "state": "PR"
    },
    {
        "name": "Cruzmaltina",
        "state": "PR"
    },
    {
        "name": "Curitiba",
        "state": "PR"
    },
    {
        "name": "Curiúva",
        "state": "PR"
    },
    {
        "name": "Diamante d`Oeste",
        "state": "PR"
    },
    {
        "name": "Diamante do Norte",
        "state": "PR"
    },
    {
        "name": "Diamante do Sul",
        "state": "PR"
    },
    {
        "name": "Dois Vizinhos",
        "state": "PR"
    },
    {
        "name": "Douradina",
        "state": "PR"
    },
    {
        "name": "Doutor Camargo",
        "state": "PR"
    },
    {
        "name": "Doutor Ulysses",
        "state": "PR"
    },
    {
        "name": "Enéas Marques",
        "state": "PR"
    },
    {
        "name": "Engenheiro Beltrão",
        "state": "PR"
    },
    {
        "name": "Entre Rios do Oeste",
        "state": "PR"
    },
    {
        "name": "Esperança Nova",
        "state": "PR"
    },
    {
        "name": "Espigão Alto do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Farol",
        "state": "PR"
    },
    {
        "name": "Faxinal",
        "state": "PR"
    },
    {
        "name": "Fazenda Rio Grande",
        "state": "PR"
    },
    {
        "name": "Fênix",
        "state": "PR"
    },
    {
        "name": "Fernandes Pinheiro",
        "state": "PR"
    },
    {
        "name": "Figueira",
        "state": "PR"
    },
    {
        "name": "Flor da Serra do Sul",
        "state": "PR"
    },
    {
        "name": "Floraí",
        "state": "PR"
    },
    {
        "name": "Floresta",
        "state": "PR"
    },
    {
        "name": "Florestópolis",
        "state": "PR"
    },
    {
        "name": "Flórida",
        "state": "PR"
    },
    {
        "name": "Formosa do Oeste",
        "state": "PR"
    },
    {
        "name": "Foz do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Foz do Jordão",
        "state": "PR"
    },
    {
        "name": "Francisco Alves",
        "state": "PR"
    },
    {
        "name": "Francisco Beltrão",
        "state": "PR"
    },
    {
        "name": "General Carneiro",
        "state": "PR"
    },
    {
        "name": "Godoy Moreira",
        "state": "PR"
    },
    {
        "name": "Goioerê",
        "state": "PR"
    },
    {
        "name": "Goioxim",
        "state": "PR"
    },
    {
        "name": "Grandes Rios",
        "state": "PR"
    },
    {
        "name": "Guaíra",
        "state": "PR"
    },
    {
        "name": "Guairaçá",
        "state": "PR"
    },
    {
        "name": "Guamiranga",
        "state": "PR"
    },
    {
        "name": "Guapirama",
        "state": "PR"
    },
    {
        "name": "Guaporema",
        "state": "PR"
    },
    {
        "name": "Guaraci",
        "state": "PR"
    },
    {
        "name": "Guaraniaçu",
        "state": "PR"
    },
    {
        "name": "Guarapuava",
        "state": "PR"
    },
    {
        "name": "Guaraqueçaba",
        "state": "PR"
    },
    {
        "name": "Guaratuba",
        "state": "PR"
    },
    {
        "name": "Honório Serpa",
        "state": "PR"
    },
    {
        "name": "Ibaiti",
        "state": "PR"
    },
    {
        "name": "Ibema",
        "state": "PR"
    },
    {
        "name": "Ibiporã",
        "state": "PR"
    },
    {
        "name": "Icaraíma",
        "state": "PR"
    },
    {
        "name": "Iguaraçu",
        "state": "PR"
    },
    {
        "name": "Iguatu",
        "state": "PR"
    },
    {
        "name": "Imbaú",
        "state": "PR"
    },
    {
        "name": "Imbituva",
        "state": "PR"
    },
    {
        "name": "Inácio Martins",
        "state": "PR"
    },
    {
        "name": "Inajá",
        "state": "PR"
    },
    {
        "name": "Indianópolis",
        "state": "PR"
    },
    {
        "name": "Ipiranga",
        "state": "PR"
    },
    {
        "name": "Iporã",
        "state": "PR"
    },
    {
        "name": "Iracema do Oeste",
        "state": "PR"
    },
    {
        "name": "Irati",
        "state": "PR"
    },
    {
        "name": "Iretama",
        "state": "PR"
    },
    {
        "name": "Itaguajé",
        "state": "PR"
    },
    {
        "name": "Itaipulândia",
        "state": "PR"
    },
    {
        "name": "Itambaracá",
        "state": "PR"
    },
    {
        "name": "Itambé",
        "state": "PR"
    },
    {
        "name": "Itapejara d`Oeste",
        "state": "PR"
    },
    {
        "name": "Itaperuçu",
        "state": "PR"
    },
    {
        "name": "Itaúna do Sul",
        "state": "PR"
    },
    {
        "name": "Ivaí",
        "state": "PR"
    },
    {
        "name": "Ivaiporã",
        "state": "PR"
    },
    {
        "name": "Ivaté",
        "state": "PR"
    },
    {
        "name": "Ivatuba",
        "state": "PR"
    },
    {
        "name": "Jaboti",
        "state": "PR"
    },
    {
        "name": "Jacarezinho",
        "state": "PR"
    },
    {
        "name": "Jaguapitã",
        "state": "PR"
    },
    {
        "name": "Jaguariaíva",
        "state": "PR"
    },
    {
        "name": "Jandaia do Sul",
        "state": "PR"
    },
    {
        "name": "Janiópolis",
        "state": "PR"
    },
    {
        "name": "Japira",
        "state": "PR"
    },
    {
        "name": "Japurá",
        "state": "PR"
    },
    {
        "name": "Jardim Alegre",
        "state": "PR"
    },
    {
        "name": "Jardim Olinda",
        "state": "PR"
    },
    {
        "name": "Jataizinho",
        "state": "PR"
    },
    {
        "name": "Jesuítas",
        "state": "PR"
    },
    {
        "name": "Joaquim Távora",
        "state": "PR"
    },
    {
        "name": "Jundiaí do Sul",
        "state": "PR"
    },
    {
        "name": "Juranda",
        "state": "PR"
    },
    {
        "name": "Jussara",
        "state": "PR"
    },
    {
        "name": "Kaloré",
        "state": "PR"
    },
    {
        "name": "Lapa",
        "state": "PR"
    },
    {
        "name": "Laranjal",
        "state": "PR"
    },
    {
        "name": "Laranjeiras do Sul",
        "state": "PR"
    },
    {
        "name": "Leópolis",
        "state": "PR"
    },
    {
        "name": "Lidianópolis",
        "state": "PR"
    },
    {
        "name": "Lindoeste",
        "state": "PR"
    },
    {
        "name": "Loanda",
        "state": "PR"
    },
    {
        "name": "Lobato",
        "state": "PR"
    },
    {
        "name": "Londrina",
        "state": "PR"
    },
    {
        "name": "Luiziana",
        "state": "PR"
    },
    {
        "name": "Lunardelli",
        "state": "PR"
    },
    {
        "name": "Lupionópolis",
        "state": "PR"
    },
    {
        "name": "Mallet",
        "state": "PR"
    },
    {
        "name": "Mamborê",
        "state": "PR"
    },
    {
        "name": "Mandaguaçu",
        "state": "PR"
    },
    {
        "name": "Mandaguari",
        "state": "PR"
    },
    {
        "name": "Mandirituba",
        "state": "PR"
    },
    {
        "name": "Manfrinópolis",
        "state": "PR"
    },
    {
        "name": "Mangueirinha",
        "state": "PR"
    },
    {
        "name": "Manoel Ribas",
        "state": "PR"
    },
    {
        "name": "Marechal Cândido Rondon",
        "state": "PR"
    },
    {
        "name": "Maria Helena",
        "state": "PR"
    },
    {
        "name": "Marialva",
        "state": "PR"
    },
    {
        "name": "Marilândia do Sul",
        "state": "PR"
    },
    {
        "name": "Marilena",
        "state": "PR"
    },
    {
        "name": "Mariluz",
        "state": "PR"
    },
    {
        "name": "Maringá",
        "state": "PR"
    },
    {
        "name": "Mariópolis",
        "state": "PR"
    },
    {
        "name": "Maripá",
        "state": "PR"
    },
    {
        "name": "Marmeleiro",
        "state": "PR"
    },
    {
        "name": "Marquinho",
        "state": "PR"
    },
    {
        "name": "Marumbi",
        "state": "PR"
    },
    {
        "name": "Matelândia",
        "state": "PR"
    },
    {
        "name": "Matinhos",
        "state": "PR"
    },
    {
        "name": "Mato Rico",
        "state": "PR"
    },
    {
        "name": "Mauá da Serra",
        "state": "PR"
    },
    {
        "name": "Medianeira",
        "state": "PR"
    },
    {
        "name": "Mercedes",
        "state": "PR"
    },
    {
        "name": "Mirador",
        "state": "PR"
    },
    {
        "name": "Miraselva",
        "state": "PR"
    },
    {
        "name": "Missal",
        "state": "PR"
    },
    {
        "name": "Moreira Sales",
        "state": "PR"
    },
    {
        "name": "Morretes",
        "state": "PR"
    },
    {
        "name": "Munhoz de Melo",
        "state": "PR"
    },
    {
        "name": "Nossa Senhora das Graças",
        "state": "PR"
    },
    {
        "name": "Nova Aliança do Ivaí",
        "state": "PR"
    },
    {
        "name": "Nova América da Colina",
        "state": "PR"
    },
    {
        "name": "Nova Aurora",
        "state": "PR"
    },
    {
        "name": "Nova Cantu",
        "state": "PR"
    },
    {
        "name": "Nova Esperança",
        "state": "PR"
    },
    {
        "name": "Nova Esperança do Sudoeste",
        "state": "PR"
    },
    {
        "name": "Nova Fátima",
        "state": "PR"
    },
    {
        "name": "Nova Laranjeiras",
        "state": "PR"
    },
    {
        "name": "Nova Londrina",
        "state": "PR"
    },
    {
        "name": "Nova Olímpia",
        "state": "PR"
    },
    {
        "name": "Nova Prata do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Nova Santa Bárbara",
        "state": "PR"
    },
    {
        "name": "Nova Santa Rosa",
        "state": "PR"
    },
    {
        "name": "Nova Tebas",
        "state": "PR"
    },
    {
        "name": "Novo Itacolomi",
        "state": "PR"
    },
    {
        "name": "Ortigueira",
        "state": "PR"
    },
    {
        "name": "Ourizona",
        "state": "PR"
    },
    {
        "name": "Ouro Verde do Oeste",
        "state": "PR"
    },
    {
        "name": "Paiçandu",
        "state": "PR"
    },
    {
        "name": "Palmas",
        "state": "PR"
    },
    {
        "name": "Palmeira",
        "state": "PR"
    },
    {
        "name": "Palmital",
        "state": "PR"
    },
    {
        "name": "Palotina",
        "state": "PR"
    },
    {
        "name": "Paraíso do Norte",
        "state": "PR"
    },
    {
        "name": "Paranacity",
        "state": "PR"
    },
    {
        "name": "Paranaguá",
        "state": "PR"
    },
    {
        "name": "Paranapoema",
        "state": "PR"
    },
    {
        "name": "Paranavaí",
        "state": "PR"
    },
    {
        "name": "Pato Bragado",
        "state": "PR"
    },
    {
        "name": "Pato Branco",
        "state": "PR"
    },
    {
        "name": "Paula Freitas",
        "state": "PR"
    },
    {
        "name": "Paulo Frontin",
        "state": "PR"
    },
    {
        "name": "Peabiru",
        "state": "PR"
    },
    {
        "name": "Perobal",
        "state": "PR"
    },
    {
        "name": "Pérola",
        "state": "PR"
    },
    {
        "name": "Pérola d`Oeste",
        "state": "PR"
    },
    {
        "name": "Piên",
        "state": "PR"
    },
    {
        "name": "Pinhais",
        "state": "PR"
    },
    {
        "name": "Pinhal de São Bento",
        "state": "PR"
    },
    {
        "name": "Pinhalão",
        "state": "PR"
    },
    {
        "name": "Pinhão",
        "state": "PR"
    },
    {
        "name": "Piraí do Sul",
        "state": "PR"
    },
    {
        "name": "Piraquara",
        "state": "PR"
    },
    {
        "name": "Pitanga",
        "state": "PR"
    },
    {
        "name": "Pitangueiras",
        "state": "PR"
    },
    {
        "name": "Planaltina do Paraná",
        "state": "PR"
    },
    {
        "name": "Planalto",
        "state": "PR"
    },
    {
        "name": "Ponta Grossa",
        "state": "PR"
    },
    {
        "name": "Pontal do Paraná",
        "state": "PR"
    },
    {
        "name": "Porecatu",
        "state": "PR"
    },
    {
        "name": "Porto Amazonas",
        "state": "PR"
    },
    {
        "name": "Porto Barreiro",
        "state": "PR"
    },
    {
        "name": "Porto Rico",
        "state": "PR"
    },
    {
        "name": "Porto Vitória",
        "state": "PR"
    },
    {
        "name": "Prado Ferreira",
        "state": "PR"
    },
    {
        "name": "Pranchita",
        "state": "PR"
    },
    {
        "name": "Presidente Castelo Branco",
        "state": "PR"
    },
    {
        "name": "Primeiro de Maio",
        "state": "PR"
    },
    {
        "name": "Prudentópolis",
        "state": "PR"
    },
    {
        "name": "Quarto Centenário",
        "state": "PR"
    },
    {
        "name": "Quatiguá",
        "state": "PR"
    },
    {
        "name": "Quatro Barras",
        "state": "PR"
    },
    {
        "name": "Quatro Pontes",
        "state": "PR"
    },
    {
        "name": "Quedas do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Querência do Norte",
        "state": "PR"
    },
    {
        "name": "Quinta do Sol",
        "state": "PR"
    },
    {
        "name": "Quitandinha",
        "state": "PR"
    },
    {
        "name": "Ramilândia",
        "state": "PR"
    },
    {
        "name": "Rancho Alegre",
        "state": "PR"
    },
    {
        "name": "Rancho Alegre d`Oeste",
        "state": "PR"
    },
    {
        "name": "Realeza",
        "state": "PR"
    },
    {
        "name": "Rebouças",
        "state": "PR"
    },
    {
        "name": "Renascença",
        "state": "PR"
    },
    {
        "name": "Reserva",
        "state": "PR"
    },
    {
        "name": "Reserva do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Ribeirão Claro",
        "state": "PR"
    },
    {
        "name": "Ribeirão do Pinhal",
        "state": "PR"
    },
    {
        "name": "Rio Azul",
        "state": "PR"
    },
    {
        "name": "Rio Bom",
        "state": "PR"
    },
    {
        "name": "Rio Bonito do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Rio Branco do Ivaí",
        "state": "PR"
    },
    {
        "name": "Rio Branco do Sul",
        "state": "PR"
    },
    {
        "name": "Rio Negro",
        "state": "PR"
    },
    {
        "name": "Rolândia",
        "state": "PR"
    },
    {
        "name": "Roncador",
        "state": "PR"
    },
    {
        "name": "Rondon",
        "state": "PR"
    },
    {
        "name": "Rosário do Ivaí",
        "state": "PR"
    },
    {
        "name": "Sabáudia",
        "state": "PR"
    },
    {
        "name": "Salgado Filho",
        "state": "PR"
    },
    {
        "name": "Salto do Itararé",
        "state": "PR"
    },
    {
        "name": "Salto do Lontra",
        "state": "PR"
    },
    {
        "name": "Santa Amélia",
        "state": "PR"
    },
    {
        "name": "Santa Cecília do Pavão",
        "state": "PR"
    },
    {
        "name": "Santa Cruz de Monte Castelo",
        "state": "PR"
    },
    {
        "name": "Santa Fé",
        "state": "PR"
    },
    {
        "name": "Santa Helena",
        "state": "PR"
    },
    {
        "name": "Santa Inês",
        "state": "PR"
    },
    {
        "name": "Santa Isabel do Ivaí",
        "state": "PR"
    },
    {
        "name": "Santa Izabel do Oeste",
        "state": "PR"
    },
    {
        "name": "Santa Lúcia",
        "state": "PR"
    },
    {
        "name": "Santa Maria do Oeste",
        "state": "PR"
    },
    {
        "name": "Santa Mariana",
        "state": "PR"
    },
    {
        "name": "Santa Mônica",
        "state": "PR"
    },
    {
        "name": "Santa Tereza do Oeste",
        "state": "PR"
    },
    {
        "name": "Santa Terezinha de Itaipu",
        "state": "PR"
    },
    {
        "name": "Santana do Itararé",
        "state": "PR"
    },
    {
        "name": "Santo Antônio da Platina",
        "state": "PR"
    },
    {
        "name": "Santo Antônio do Caiuá",
        "state": "PR"
    },
    {
        "name": "Santo Antônio do Paraíso",
        "state": "PR"
    },
    {
        "name": "Santo Antônio do Sudoeste",
        "state": "PR"
    },
    {
        "name": "Santo Inácio",
        "state": "PR"
    },
    {
        "name": "São Carlos do Ivaí",
        "state": "PR"
    },
    {
        "name": "São Jerônimo da Serra",
        "state": "PR"
    },
    {
        "name": "São João",
        "state": "PR"
    },
    {
        "name": "São João do Caiuá",
        "state": "PR"
    },
    {
        "name": "São João do Ivaí",
        "state": "PR"
    },
    {
        "name": "São João do Triunfo",
        "state": "PR"
    },
    {
        "name": "São Jorge d`Oeste",
        "state": "PR"
    },
    {
        "name": "São Jorge do Ivaí",
        "state": "PR"
    },
    {
        "name": "São Jorge do Patrocínio",
        "state": "PR"
    },
    {
        "name": "São José da Boa Vista",
        "state": "PR"
    },
    {
        "name": "São José das Palmeiras",
        "state": "PR"
    },
    {
        "name": "São José dos Pinhais",
        "state": "PR"
    },
    {
        "name": "São Manoel do Paraná",
        "state": "PR"
    },
    {
        "name": "São Mateus do Sul",
        "state": "PR"
    },
    {
        "name": "São Miguel do Iguaçu",
        "state": "PR"
    },
    {
        "name": "São Pedro do Iguaçu",
        "state": "PR"
    },
    {
        "name": "São Pedro do Ivaí",
        "state": "PR"
    },
    {
        "name": "São Pedro do Paraná",
        "state": "PR"
    },
    {
        "name": "São Sebastião da Amoreira",
        "state": "PR"
    },
    {
        "name": "São Tomé",
        "state": "PR"
    },
    {
        "name": "Sapopema",
        "state": "PR"
    },
    {
        "name": "Sarandi",
        "state": "PR"
    },
    {
        "name": "Saudade do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Sengés",
        "state": "PR"
    },
    {
        "name": "Serranópolis do Iguaçu",
        "state": "PR"
    },
    {
        "name": "Sertaneja",
        "state": "PR"
    },
    {
        "name": "Sertanópolis",
        "state": "PR"
    },
    {
        "name": "Siqueira Campos",
        "state": "PR"
    },
    {
        "name": "Sulina",
        "state": "PR"
    },
    {
        "name": "Tamarana",
        "state": "PR"
    },
    {
        "name": "Tamboara",
        "state": "PR"
    },
    {
        "name": "Tapejara",
        "state": "PR"
    },
    {
        "name": "Tapira",
        "state": "PR"
    },
    {
        "name": "Teixeira Soares",
        "state": "PR"
    },
    {
        "name": "Telêmaco Borba",
        "state": "PR"
    },
    {
        "name": "Terra Boa",
        "state": "PR"
    },
    {
        "name": "Terra Rica",
        "state": "PR"
    },
    {
        "name": "Terra Roxa",
        "state": "PR"
    },
    {
        "name": "Tibagi",
        "state": "PR"
    },
    {
        "name": "Tijucas do Sul",
        "state": "PR"
    },
    {
        "name": "Toledo",
        "state": "PR"
    },
    {
        "name": "Tomazina",
        "state": "PR"
    },
    {
        "name": "Três Barras do Paraná",
        "state": "PR"
    },
    {
        "name": "Tunas do Paraná",
        "state": "PR"
    },
    {
        "name": "Tuneiras do Oeste",
        "state": "PR"
    },
    {
        "name": "Tupãssi",
        "state": "PR"
    },
    {
        "name": "Turvo",
        "state": "PR"
    },
    {
        "name": "Ubiratã",
        "state": "PR"
    },
    {
        "name": "Umuarama",
        "state": "PR"
    },
    {
        "name": "União da Vitória",
        "state": "PR"
    },
    {
        "name": "Uniflor",
        "state": "PR"
    },
    {
        "name": "Uraí",
        "state": "PR"
    },
    {
        "name": "Ventania",
        "state": "PR"
    },
    {
        "name": "Vera Cruz do Oeste",
        "state": "PR"
    },
    {
        "name": "Verê",
        "state": "PR"
    },
    {
        "name": "Virmond",
        "state": "PR"
    },
    {
        "name": "Vitorino",
        "state": "PR"
    },
    {
        "name": "Wenceslau Braz",
        "state": "PR"
    },
    {
        "name": "Xambrê",
        "state": "PR"
    },
    {
        "name": "Abreu e Lima",
        "state": "PE"
    },
    {
        "name": "Afogados da Ingazeira",
        "state": "PE"
    },
    {
        "name": "Afrânio",
        "state": "PE"
    },
    {
        "name": "Agrestina",
        "state": "PE"
    },
    {
        "name": "Água Preta",
        "state": "PE"
    },
    {
        "name": "Águas Belas",
        "state": "PE"
    },
    {
        "name": "Alagoinha",
        "state": "PE"
    },
    {
        "name": "Aliança",
        "state": "PE"
    },
    {
        "name": "Altinho",
        "state": "PE"
    },
    {
        "name": "Amaraji",
        "state": "PE"
    },
    {
        "name": "Angelim",
        "state": "PE"
    },
    {
        "name": "Araçoiaba",
        "state": "PE"
    },
    {
        "name": "Araripina",
        "state": "PE"
    },
    {
        "name": "Arcoverde",
        "state": "PE"
    },
    {
        "name": "Barra de Guabiraba",
        "state": "PE"
    },
    {
        "name": "Barreiros",
        "state": "PE"
    },
    {
        "name": "Belém de Maria",
        "state": "PE"
    },
    {
        "name": "Belém de São Francisco",
        "state": "PE"
    },
    {
        "name": "Belo Jardim",
        "state": "PE"
    },
    {
        "name": "Betânia",
        "state": "PE"
    },
    {
        "name": "Bezerros",
        "state": "PE"
    },
    {
        "name": "Bodocó",
        "state": "PE"
    },
    {
        "name": "Bom Conselho",
        "state": "PE"
    },
    {
        "name": "Bom Jardim",
        "state": "PE"
    },
    {
        "name": "Bonito",
        "state": "PE"
    },
    {
        "name": "Brejão",
        "state": "PE"
    },
    {
        "name": "Brejinho",
        "state": "PE"
    },
    {
        "name": "Brejo da Madre de Deus",
        "state": "PE"
    },
    {
        "name": "Buenos Aires",
        "state": "PE"
    },
    {
        "name": "Buíque",
        "state": "PE"
    },
    {
        "name": "Cabo de Santo Agostinho",
        "state": "PE"
    },
    {
        "name": "Cabrobó",
        "state": "PE"
    },
    {
        "name": "Cachoeirinha",
        "state": "PE"
    },
    {
        "name": "Caetés",
        "state": "PE"
    },
    {
        "name": "Calçado",
        "state": "PE"
    },
    {
        "name": "Calumbi",
        "state": "PE"
    },
    {
        "name": "Camaragibe",
        "state": "PE"
    },
    {
        "name": "Camocim de São Félix",
        "state": "PE"
    },
    {
        "name": "Camutanga",
        "state": "PE"
    },
    {
        "name": "Canhotinho",
        "state": "PE"
    },
    {
        "name": "Capoeiras",
        "state": "PE"
    },
    {
        "name": "Carnaíba",
        "state": "PE"
    },
    {
        "name": "Carnaubeira da Penha",
        "state": "PE"
    },
    {
        "name": "Carpina",
        "state": "PE"
    },
    {
        "name": "Caruaru",
        "state": "PE"
    },
    {
        "name": "Casinhas",
        "state": "PE"
    },
    {
        "name": "Catende",
        "state": "PE"
    },
    {
        "name": "Cedro",
        "state": "PE"
    },
    {
        "name": "Chã de Alegria",
        "state": "PE"
    },
    {
        "name": "Chã Grande",
        "state": "PE"
    },
    {
        "name": "Condado",
        "state": "PE"
    },
    {
        "name": "Correntes",
        "state": "PE"
    },
    {
        "name": "Cortês",
        "state": "PE"
    },
    {
        "name": "Cumaru",
        "state": "PE"
    },
    {
        "name": "Cupira",
        "state": "PE"
    },
    {
        "name": "Custódia",
        "state": "PE"
    },
    {
        "name": "Dormentes",
        "state": "PE"
    },
    {
        "name": "Escada",
        "state": "PE"
    },
    {
        "name": "Exu",
        "state": "PE"
    },
    {
        "name": "Feira Nova",
        "state": "PE"
    },
    {
        "name": "Fernando de Noronha",
        "state": "PE"
    },
    {
        "name": "Ferreiros",
        "state": "PE"
    },
    {
        "name": "Flores",
        "state": "PE"
    },
    {
        "name": "Floresta",
        "state": "PE"
    },
    {
        "name": "Frei Miguelinho",
        "state": "PE"
    },
    {
        "name": "Gameleira",
        "state": "PE"
    },
    {
        "name": "Garanhuns",
        "state": "PE"
    },
    {
        "name": "Glória do Goitá",
        "state": "PE"
    },
    {
        "name": "Goiana",
        "state": "PE"
    },
    {
        "name": "Granito",
        "state": "PE"
    },
    {
        "name": "Gravatá",
        "state": "PE"
    },
    {
        "name": "Iati",
        "state": "PE"
    },
    {
        "name": "Ibimirim",
        "state": "PE"
    },
    {
        "name": "Ibirajuba",
        "state": "PE"
    },
    {
        "name": "Igarassu",
        "state": "PE"
    },
    {
        "name": "Iguaraci",
        "state": "PE"
    },
    {
        "name": "Ilha de Itamaracá",
        "state": "PE"
    },
    {
        "name": "Inajá",
        "state": "PE"
    },
    {
        "name": "Ingazeira",
        "state": "PE"
    },
    {
        "name": "Ipojuca",
        "state": "PE"
    },
    {
        "name": "Ipubi",
        "state": "PE"
    },
    {
        "name": "Itacuruba",
        "state": "PE"
    },
    {
        "name": "Itaíba",
        "state": "PE"
    },
    {
        "name": "Itambé",
        "state": "PE"
    },
    {
        "name": "Itapetim",
        "state": "PE"
    },
    {
        "name": "Itapissuma",
        "state": "PE"
    },
    {
        "name": "Itaquitinga",
        "state": "PE"
    },
    {
        "name": "Jaboatão dos Guararapes",
        "state": "PE"
    },
    {
        "name": "Jaqueira",
        "state": "PE"
    },
    {
        "name": "Jataúba",
        "state": "PE"
    },
    {
        "name": "Jatobá",
        "state": "PE"
    },
    {
        "name": "João Alfredo",
        "state": "PE"
    },
    {
        "name": "Joaquim Nabuco",
        "state": "PE"
    },
    {
        "name": "Jucati",
        "state": "PE"
    },
    {
        "name": "Jupi",
        "state": "PE"
    },
    {
        "name": "Jurema",
        "state": "PE"
    },
    {
        "name": "Lagoa do Carro",
        "state": "PE"
    },
    {
        "name": "Lagoa do Itaenga",
        "state": "PE"
    },
    {
        "name": "Lagoa do Ouro",
        "state": "PE"
    },
    {
        "name": "Lagoa dos Gatos",
        "state": "PE"
    },
    {
        "name": "Lagoa Grande",
        "state": "PE"
    },
    {
        "name": "Lajedo",
        "state": "PE"
    },
    {
        "name": "Limoeiro",
        "state": "PE"
    },
    {
        "name": "Macaparana",
        "state": "PE"
    },
    {
        "name": "Machados",
        "state": "PE"
    },
    {
        "name": "Manari",
        "state": "PE"
    },
    {
        "name": "Maraial",
        "state": "PE"
    },
    {
        "name": "Mirandiba",
        "state": "PE"
    },
    {
        "name": "Moreilândia",
        "state": "PE"
    },
    {
        "name": "Moreno",
        "state": "PE"
    },
    {
        "name": "Nazaré da Mata",
        "state": "PE"
    },
    {
        "name": "Olinda",
        "state": "PE"
    },
    {
        "name": "Orobó",
        "state": "PE"
    },
    {
        "name": "Orocó",
        "state": "PE"
    },
    {
        "name": "Ouricuri",
        "state": "PE"
    },
    {
        "name": "Palmares",
        "state": "PE"
    },
    {
        "name": "Palmeirina",
        "state": "PE"
    },
    {
        "name": "Panelas",
        "state": "PE"
    },
    {
        "name": "Paranatama",
        "state": "PE"
    },
    {
        "name": "Parnamirim",
        "state": "PE"
    },
    {
        "name": "Passira",
        "state": "PE"
    },
    {
        "name": "Paudalho",
        "state": "PE"
    },
    {
        "name": "Paulista",
        "state": "PE"
    },
    {
        "name": "Pedra",
        "state": "PE"
    },
    {
        "name": "Pesqueira",
        "state": "PE"
    },
    {
        "name": "Petrolândia",
        "state": "PE"
    },
    {
        "name": "Petrolina",
        "state": "PE"
    },
    {
        "name": "Poção",
        "state": "PE"
    },
    {
        "name": "Pombos",
        "state": "PE"
    },
    {
        "name": "Primavera",
        "state": "PE"
    },
    {
        "name": "Quipapá",
        "state": "PE"
    },
    {
        "name": "Quixaba",
        "state": "PE"
    },
    {
        "name": "Recife",
        "state": "PE"
    },
    {
        "name": "Riacho das Almas",
        "state": "PE"
    },
    {
        "name": "Ribeirão",
        "state": "PE"
    },
    {
        "name": "Rio Formoso",
        "state": "PE"
    },
    {
        "name": "Sairé",
        "state": "PE"
    },
    {
        "name": "Salgadinho",
        "state": "PE"
    },
    {
        "name": "Salgueiro",
        "state": "PE"
    },
    {
        "name": "Saloá",
        "state": "PE"
    },
    {
        "name": "Sanharó",
        "state": "PE"
    },
    {
        "name": "Santa Cruz",
        "state": "PE"
    },
    {
        "name": "Santa Cruz da Baixa Verde",
        "state": "PE"
    },
    {
        "name": "Santa Cruz do Capibaribe",
        "state": "PE"
    },
    {
        "name": "Santa Filomena",
        "state": "PE"
    },
    {
        "name": "Santa Maria da Boa Vista",
        "state": "PE"
    },
    {
        "name": "Santa Maria do Cambucá",
        "state": "PE"
    },
    {
        "name": "Santa Terezinha",
        "state": "PE"
    },
    {
        "name": "São Benedito do Sul",
        "state": "PE"
    },
    {
        "name": "São Bento do Una",
        "state": "PE"
    },
    {
        "name": "São Caitano",
        "state": "PE"
    },
    {
        "name": "São João",
        "state": "PE"
    },
    {
        "name": "São Joaquim do Monte",
        "state": "PE"
    },
    {
        "name": "São José da Coroa Grande",
        "state": "PE"
    },
    {
        "name": "São José do Belmonte",
        "state": "PE"
    },
    {
        "name": "São José do Egito",
        "state": "PE"
    },
    {
        "name": "São Lourenço da Mata",
        "state": "PE"
    },
    {
        "name": "São Vicente Ferrer",
        "state": "PE"
    },
    {
        "name": "Serra Talhada",
        "state": "PE"
    },
    {
        "name": "Serrita",
        "state": "PE"
    },
    {
        "name": "Sertânia",
        "state": "PE"
    },
    {
        "name": "Sirinhaém",
        "state": "PE"
    },
    {
        "name": "Solidão",
        "state": "PE"
    },
    {
        "name": "Surubim",
        "state": "PE"
    },
    {
        "name": "Tabira",
        "state": "PE"
    },
    {
        "name": "Tacaimbó",
        "state": "PE"
    },
    {
        "name": "Tacaratu",
        "state": "PE"
    },
    {
        "name": "Tamandaré",
        "state": "PE"
    },
    {
        "name": "Taquaritinga do Norte",
        "state": "PE"
    },
    {
        "name": "Terezinha",
        "state": "PE"
    },
    {
        "name": "Terra Nova",
        "state": "PE"
    },
    {
        "name": "Timbaúba",
        "state": "PE"
    },
    {
        "name": "Toritama",
        "state": "PE"
    },
    {
        "name": "Tracunhaém",
        "state": "PE"
    },
    {
        "name": "Trindade",
        "state": "PE"
    },
    {
        "name": "Triunfo",
        "state": "PE"
    },
    {
        "name": "Tupanatinga",
        "state": "PE"
    },
    {
        "name": "Tuparetama",
        "state": "PE"
    },
    {
        "name": "Venturosa",
        "state": "PE"
    },
    {
        "name": "Verdejante",
        "state": "PE"
    },
    {
        "name": "Vertente do Lério",
        "state": "PE"
    },
    {
        "name": "Vertentes",
        "state": "PE"
    },
    {
        "name": "Vicência",
        "state": "PE"
    },
    {
        "name": "Vitória de Santo Antão",
        "state": "PE"
    },
    {
        "name": "Xexéu",
        "state": "PE"
    },
    {
        "name": "Acauã",
        "state": "PI"
    },
    {
        "name": "Agricolândia",
        "state": "PI"
    },
    {
        "name": "Água Branca",
        "state": "PI"
    },
    {
        "name": "Alagoinha do Piauí",
        "state": "PI"
    },
    {
        "name": "Alegrete do Piauí",
        "state": "PI"
    },
    {
        "name": "Alto Longá",
        "state": "PI"
    },
    {
        "name": "Altos",
        "state": "PI"
    },
    {
        "name": "Alvorada do Gurguéia",
        "state": "PI"
    },
    {
        "name": "Amarante",
        "state": "PI"
    },
    {
        "name": "Angical do Piauí",
        "state": "PI"
    },
    {
        "name": "Anísio de Abreu",
        "state": "PI"
    },
    {
        "name": "Antônio Almeida",
        "state": "PI"
    },
    {
        "name": "Aroazes",
        "state": "PI"
    },
    {
        "name": "Aroeiras do Itaim",
        "state": "PI"
    },
    {
        "name": "Arraial",
        "state": "PI"
    },
    {
        "name": "Assunção do Piauí",
        "state": "PI"
    },
    {
        "name": "Avelino Lopes",
        "state": "PI"
    },
    {
        "name": "Baixa Grande do Ribeiro",
        "state": "PI"
    },
    {
        "name": "Barra d`Alcântara",
        "state": "PI"
    },
    {
        "name": "Barras",
        "state": "PI"
    },
    {
        "name": "Barreiras do Piauí",
        "state": "PI"
    },
    {
        "name": "Barro Duro",
        "state": "PI"
    },
    {
        "name": "Batalha",
        "state": "PI"
    },
    {
        "name": "Bela Vista do Piauí",
        "state": "PI"
    },
    {
        "name": "Belém do Piauí",
        "state": "PI"
    },
    {
        "name": "Beneditinos",
        "state": "PI"
    },
    {
        "name": "Bertolínia",
        "state": "PI"
    },
    {
        "name": "Betânia do Piauí",
        "state": "PI"
    },
    {
        "name": "Boa Hora",
        "state": "PI"
    },
    {
        "name": "Bocaina",
        "state": "PI"
    },
    {
        "name": "Bom Jesus",
        "state": "PI"
    },
    {
        "name": "Bom Princípio do Piauí",
        "state": "PI"
    },
    {
        "name": "Bonfim do Piauí",
        "state": "PI"
    },
    {
        "name": "Boqueirão do Piauí",
        "state": "PI"
    },
    {
        "name": "Brasileira",
        "state": "PI"
    },
    {
        "name": "Brejo do Piauí",
        "state": "PI"
    },
    {
        "name": "Buriti dos Lopes",
        "state": "PI"
    },
    {
        "name": "Buriti dos Montes",
        "state": "PI"
    },
    {
        "name": "Cabeceiras do Piauí",
        "state": "PI"
    },
    {
        "name": "Cajazeiras do Piauí",
        "state": "PI"
    },
    {
        "name": "Cajueiro da Praia",
        "state": "PI"
    },
    {
        "name": "Caldeirão Grande do Piauí",
        "state": "PI"
    },
    {
        "name": "Campinas do Piauí",
        "state": "PI"
    },
    {
        "name": "Campo Alegre do Fidalgo",
        "state": "PI"
    },
    {
        "name": "Campo Grande do Piauí",
        "state": "PI"
    },
    {
        "name": "Campo Largo do Piauí",
        "state": "PI"
    },
    {
        "name": "Campo Maior",
        "state": "PI"
    },
    {
        "name": "Canavieira",
        "state": "PI"
    },
    {
        "name": "Canto do Buriti",
        "state": "PI"
    },
    {
        "name": "Capitão de Campos",
        "state": "PI"
    },
    {
        "name": "Capitão Gervásio Oliveira",
        "state": "PI"
    },
    {
        "name": "Caracol",
        "state": "PI"
    },
    {
        "name": "Caraúbas do Piauí",
        "state": "PI"
    },
    {
        "name": "Caridade do Piauí",
        "state": "PI"
    },
    {
        "name": "Castelo do Piauí",
        "state": "PI"
    },
    {
        "name": "Caxingó",
        "state": "PI"
    },
    {
        "name": "Cocal",
        "state": "PI"
    },
    {
        "name": "Cocal de Telha",
        "state": "PI"
    },
    {
        "name": "Cocal dos Alves",
        "state": "PI"
    },
    {
        "name": "Coivaras",
        "state": "PI"
    },
    {
        "name": "Colônia do Gurguéia",
        "state": "PI"
    },
    {
        "name": "Colônia do Piauí",
        "state": "PI"
    },
    {
        "name": "Conceição do Canindé",
        "state": "PI"
    },
    {
        "name": "Coronel José Dias",
        "state": "PI"
    },
    {
        "name": "Corrente",
        "state": "PI"
    },
    {
        "name": "Cristalândia do Piauí",
        "state": "PI"
    },
    {
        "name": "Cristino Castro",
        "state": "PI"
    },
    {
        "name": "Curimatá",
        "state": "PI"
    },
    {
        "name": "Currais",
        "state": "PI"
    },
    {
        "name": "Curral Novo do Piauí",
        "state": "PI"
    },
    {
        "name": "Curralinhos",
        "state": "PI"
    },
    {
        "name": "Demerval Lobão",
        "state": "PI"
    },
    {
        "name": "Dirceu Arcoverde",
        "state": "PI"
    },
    {
        "name": "Dom Expedito Lopes",
        "state": "PI"
    },
    {
        "name": "Dom Inocêncio",
        "state": "PI"
    },
    {
        "name": "Domingos Mourão",
        "state": "PI"
    },
    {
        "name": "Elesbão Veloso",
        "state": "PI"
    },
    {
        "name": "Eliseu Martins",
        "state": "PI"
    },
    {
        "name": "Esperantina",
        "state": "PI"
    },
    {
        "name": "Fartura do Piauí",
        "state": "PI"
    },
    {
        "name": "Flores do Piauí",
        "state": "PI"
    },
    {
        "name": "Floresta do Piauí",
        "state": "PI"
    },
    {
        "name": "Floriano",
        "state": "PI"
    },
    {
        "name": "Francinópolis",
        "state": "PI"
    },
    {
        "name": "Francisco Ayres",
        "state": "PI"
    },
    {
        "name": "Francisco Macedo",
        "state": "PI"
    },
    {
        "name": "Francisco Santos",
        "state": "PI"
    },
    {
        "name": "Fronteiras",
        "state": "PI"
    },
    {
        "name": "Geminiano",
        "state": "PI"
    },
    {
        "name": "Gilbués",
        "state": "PI"
    },
    {
        "name": "Guadalupe",
        "state": "PI"
    },
    {
        "name": "Guaribas",
        "state": "PI"
    },
    {
        "name": "Hugo Napoleão",
        "state": "PI"
    },
    {
        "name": "Ilha Grande",
        "state": "PI"
    },
    {
        "name": "Inhuma",
        "state": "PI"
    },
    {
        "name": "Ipiranga do Piauí",
        "state": "PI"
    },
    {
        "name": "Isaías Coelho",
        "state": "PI"
    },
    {
        "name": "Itainópolis",
        "state": "PI"
    },
    {
        "name": "Itaueira",
        "state": "PI"
    },
    {
        "name": "Jacobina do Piauí",
        "state": "PI"
    },
    {
        "name": "Jaicós",
        "state": "PI"
    },
    {
        "name": "Jardim do Mulato",
        "state": "PI"
    },
    {
        "name": "Jatobá do Piauí",
        "state": "PI"
    },
    {
        "name": "Jerumenha",
        "state": "PI"
    },
    {
        "name": "João Costa",
        "state": "PI"
    },
    {
        "name": "Joaquim Pires",
        "state": "PI"
    },
    {
        "name": "Joca Marques",
        "state": "PI"
    },
    {
        "name": "José de Freitas",
        "state": "PI"
    },
    {
        "name": "Juazeiro do Piauí",
        "state": "PI"
    },
    {
        "name": "Júlio Borges",
        "state": "PI"
    },
    {
        "name": "Jurema",
        "state": "PI"
    },
    {
        "name": "Lagoa Alegre",
        "state": "PI"
    },
    {
        "name": "Lagoa de São Francisco",
        "state": "PI"
    },
    {
        "name": "Lagoa do Barro do Piauí",
        "state": "PI"
    },
    {
        "name": "Lagoa do Piauí",
        "state": "PI"
    },
    {
        "name": "Lagoa do Sítio",
        "state": "PI"
    },
    {
        "name": "Lagoinha do Piauí",
        "state": "PI"
    },
    {
        "name": "Landri Sales",
        "state": "PI"
    },
    {
        "name": "Luís Correia",
        "state": "PI"
    },
    {
        "name": "Luzilândia",
        "state": "PI"
    },
    {
        "name": "Madeiro",
        "state": "PI"
    },
    {
        "name": "Manoel Emídio",
        "state": "PI"
    },
    {
        "name": "Marcolândia",
        "state": "PI"
    },
    {
        "name": "Marcos Parente",
        "state": "PI"
    },
    {
        "name": "Massapê do Piauí",
        "state": "PI"
    },
    {
        "name": "Matias Olímpio",
        "state": "PI"
    },
    {
        "name": "Miguel Alves",
        "state": "PI"
    },
    {
        "name": "Miguel Leão",
        "state": "PI"
    },
    {
        "name": "Milton Brandão",
        "state": "PI"
    },
    {
        "name": "Monsenhor Gil",
        "state": "PI"
    },
    {
        "name": "Monsenhor Hipólito",
        "state": "PI"
    },
    {
        "name": "Monte Alegre do Piauí",
        "state": "PI"
    },
    {
        "name": "Morro Cabeça no Tempo",
        "state": "PI"
    },
    {
        "name": "Morro do Chapéu do Piauí",
        "state": "PI"
    },
    {
        "name": "Murici dos Portelas",
        "state": "PI"
    },
    {
        "name": "Nazaré do Piauí",
        "state": "PI"
    },
    {
        "name": "Nossa Senhora de Nazaré",
        "state": "PI"
    },
    {
        "name": "Nossa Senhora dos Remédios",
        "state": "PI"
    },
    {
        "name": "Nova Santa Rita",
        "state": "PI"
    },
    {
        "name": "Novo Oriente do Piauí",
        "state": "PI"
    },
    {
        "name": "Novo Santo Antônio",
        "state": "PI"
    },
    {
        "name": "Oeiras",
        "state": "PI"
    },
    {
        "name": "Olho d`Água do Piauí",
        "state": "PI"
    },
    {
        "name": "Padre Marcos",
        "state": "PI"
    },
    {
        "name": "Paes Landim",
        "state": "PI"
    },
    {
        "name": "Pajeú do Piauí",
        "state": "PI"
    },
    {
        "name": "Palmeira do Piauí",
        "state": "PI"
    },
    {
        "name": "Palmeirais",
        "state": "PI"
    },
    {
        "name": "Paquetá",
        "state": "PI"
    },
    {
        "name": "Parnaguá",
        "state": "PI"
    },
    {
        "name": "Parnaíba",
        "state": "PI"
    },
    {
        "name": "Passagem Franca do Piauí",
        "state": "PI"
    },
    {
        "name": "Patos do Piauí",
        "state": "PI"
    },
    {
        "name": "Pau d`Arco do Piauí",
        "state": "PI"
    },
    {
        "name": "Paulistana",
        "state": "PI"
    },
    {
        "name": "Pavussu",
        "state": "PI"
    },
    {
        "name": "Pedro II",
        "state": "PI"
    },
    {
        "name": "Pedro Laurentino",
        "state": "PI"
    },
    {
        "name": "Picos",
        "state": "PI"
    },
    {
        "name": "Pimenteiras",
        "state": "PI"
    },
    {
        "name": "Pio IX",
        "state": "PI"
    },
    {
        "name": "Piracuruca",
        "state": "PI"
    },
    {
        "name": "Piripiri",
        "state": "PI"
    },
    {
        "name": "Porto",
        "state": "PI"
    },
    {
        "name": "Porto Alegre do Piauí",
        "state": "PI"
    },
    {
        "name": "Prata do Piauí",
        "state": "PI"
    },
    {
        "name": "Queimada Nova",
        "state": "PI"
    },
    {
        "name": "Redenção do Gurguéia",
        "state": "PI"
    },
    {
        "name": "Regeneração",
        "state": "PI"
    },
    {
        "name": "Riacho Frio",
        "state": "PI"
    },
    {
        "name": "Ribeira do Piauí",
        "state": "PI"
    },
    {
        "name": "Ribeiro Gonçalves",
        "state": "PI"
    },
    {
        "name": "Rio Grande do Piauí",
        "state": "PI"
    },
    {
        "name": "Santa Cruz do Piauí",
        "state": "PI"
    },
    {
        "name": "Santa Cruz dos Milagres",
        "state": "PI"
    },
    {
        "name": "Santa Filomena",
        "state": "PI"
    },
    {
        "name": "Santa Luz",
        "state": "PI"
    },
    {
        "name": "Santa Rosa do Piauí",
        "state": "PI"
    },
    {
        "name": "Santana do Piauí",
        "state": "PI"
    },
    {
        "name": "Santo Antônio de Lisboa",
        "state": "PI"
    },
    {
        "name": "Santo Antônio dos Milagres",
        "state": "PI"
    },
    {
        "name": "Santo Inácio do Piauí",
        "state": "PI"
    },
    {
        "name": "São Braz do Piauí",
        "state": "PI"
    },
    {
        "name": "São Félix do Piauí",
        "state": "PI"
    },
    {
        "name": "São Francisco de Assis do Piauí",
        "state": "PI"
    },
    {
        "name": "São Francisco do Piauí",
        "state": "PI"
    },
    {
        "name": "São Gonçalo do Gurguéia",
        "state": "PI"
    },
    {
        "name": "São Gonçalo do Piauí",
        "state": "PI"
    },
    {
        "name": "São João da Canabrava",
        "state": "PI"
    },
    {
        "name": "São João da Fronteira",
        "state": "PI"
    },
    {
        "name": "São João da Serra",
        "state": "PI"
    },
    {
        "name": "São João da Varjota",
        "state": "PI"
    },
    {
        "name": "São João do Arraial",
        "state": "PI"
    },
    {
        "name": "São João do Piauí",
        "state": "PI"
    },
    {
        "name": "São José do Divino",
        "state": "PI"
    },
    {
        "name": "São José do Peixe",
        "state": "PI"
    },
    {
        "name": "São José do Piauí",
        "state": "PI"
    },
    {
        "name": "São Julião",
        "state": "PI"
    },
    {
        "name": "São Lourenço do Piauí",
        "state": "PI"
    },
    {
        "name": "São Luis do Piauí",
        "state": "PI"
    },
    {
        "name": "São Miguel da Baixa Grande",
        "state": "PI"
    },
    {
        "name": "São Miguel do Fidalgo",
        "state": "PI"
    },
    {
        "name": "São Miguel do Tapuio",
        "state": "PI"
    },
    {
        "name": "São Pedro do Piauí",
        "state": "PI"
    },
    {
        "name": "São Raimundo Nonato",
        "state": "PI"
    },
    {
        "name": "Sebastião Barros",
        "state": "PI"
    },
    {
        "name": "Sebastião Leal",
        "state": "PI"
    },
    {
        "name": "Sigefredo Pacheco",
        "state": "PI"
    },
    {
        "name": "Simões",
        "state": "PI"
    },
    {
        "name": "Simplício Mendes",
        "state": "PI"
    },
    {
        "name": "Socorro do Piauí",
        "state": "PI"
    },
    {
        "name": "Sussuapara",
        "state": "PI"
    },
    {
        "name": "Tamboril do Piauí",
        "state": "PI"
    },
    {
        "name": "Tanque do Piauí",
        "state": "PI"
    },
    {
        "name": "Teresina",
        "state": "PI"
    },
    {
        "name": "União",
        "state": "PI"
    },
    {
        "name": "Uruçuí",
        "state": "PI"
    },
    {
        "name": "Valença do Piauí",
        "state": "PI"
    },
    {
        "name": "Várzea Branca",
        "state": "PI"
    },
    {
        "name": "Várzea Grande",
        "state": "PI"
    },
    {
        "name": "Vera Mendes",
        "state": "PI"
    },
    {
        "name": "Vila Nova do Piauí",
        "state": "PI"
    },
    {
        "name": "Wall Ferraz",
        "state": "PI"
    },
    {
        "name": "Angra dos Reis",
        "state": "RJ"
    },
    {
        "name": "Aperibé",
        "state": "RJ"
    },
    {
        "name": "Araruama",
        "state": "RJ"
    },
    {
        "name": "Areal",
        "state": "RJ"
    },
    {
        "name": "Armação dos Búzios",
        "state": "RJ"
    },
    {
        "name": "Arraial do Cabo",
        "state": "RJ"
    },
    {
        "name": "Barra do Piraí",
        "state": "RJ"
    },
    {
        "name": "Barra Mansa",
        "state": "RJ"
    },
    {
        "name": "Belford Roxo",
        "state": "RJ"
    },
    {
        "name": "Bom Jardim",
        "state": "RJ"
    },
    {
        "name": "Bom Jesus do Itabapoana",
        "state": "RJ"
    },
    {
        "name": "Cabo Frio",
        "state": "RJ"
    },
    {
        "name": "Cachoeiras de Macacu",
        "state": "RJ"
    },
    {
        "name": "Cambuci",
        "state": "RJ"
    },
    {
        "name": "Campos dos Goytacazes",
        "state": "RJ"
    },
    {
        "name": "Cantagalo",
        "state": "RJ"
    },
    {
        "name": "Carapebus",
        "state": "RJ"
    },
    {
        "name": "Cardoso Moreira",
        "state": "RJ"
    },
    {
        "name": "Carmo",
        "state": "RJ"
    },
    {
        "name": "Casimiro de Abreu",
        "state": "RJ"
    },
    {
        "name": "Comendador Levy Gasparian",
        "state": "RJ"
    },
    {
        "name": "Conceição de Macabu",
        "state": "RJ"
    },
    {
        "name": "Cordeiro",
        "state": "RJ"
    },
    {
        "name": "Duas Barras",
        "state": "RJ"
    },
    {
        "name": "Duque de Caxias",
        "state": "RJ"
    },
    {
        "name": "Engenheiro Paulo de Frontin",
        "state": "RJ"
    },
    {
        "name": "Guapimirim",
        "state": "RJ"
    },
    {
        "name": "Iguaba Grande",
        "state": "RJ"
    },
    {
        "name": "Itaboraí",
        "state": "RJ"
    },
    {
        "name": "Itaguaí",
        "state": "RJ"
    },
    {
        "name": "Italva",
        "state": "RJ"
    },
    {
        "name": "Itaocara",
        "state": "RJ"
    },
    {
        "name": "Itaperuna",
        "state": "RJ"
    },
    {
        "name": "Itatiaia",
        "state": "RJ"
    },
    {
        "name": "Japeri",
        "state": "RJ"
    },
    {
        "name": "Laje do Muriaé",
        "state": "RJ"
    },
    {
        "name": "Macaé",
        "state": "RJ"
    },
    {
        "name": "Macuco",
        "state": "RJ"
    },
    {
        "name": "Magé",
        "state": "RJ"
    },
    {
        "name": "Mangaratiba",
        "state": "RJ"
    },
    {
        "name": "Maricá",
        "state": "RJ"
    },
    {
        "name": "Mendes",
        "state": "RJ"
    },
    {
        "name": "Mesquita",
        "state": "RJ"
    },
    {
        "name": "Miguel Pereira",
        "state": "RJ"
    },
    {
        "name": "Miracema",
        "state": "RJ"
    },
    {
        "name": "Natividade",
        "state": "RJ"
    },
    {
        "name": "Nilópolis",
        "state": "RJ"
    },
    {
        "name": "Niterói",
        "state": "RJ"
    },
    {
        "name": "Nova Friburgo",
        "state": "RJ"
    },
    {
        "name": "Nova Iguaçu",
        "state": "RJ"
    },
    {
        "name": "Paracambi",
        "state": "RJ"
    },
    {
        "name": "Paraíba do Sul",
        "state": "RJ"
    },
    {
        "name": "Parati",
        "state": "RJ"
    },
    {
        "name": "Paty do Alferes",
        "state": "RJ"
    },
    {
        "name": "Petrópolis",
        "state": "RJ"
    },
    {
        "name": "Pinheiral",
        "state": "RJ"
    },
    {
        "name": "Piraí",
        "state": "RJ"
    },
    {
        "name": "Porciúncula",
        "state": "RJ"
    },
    {
        "name": "Porto Real",
        "state": "RJ"
    },
    {
        "name": "Quatis",
        "state": "RJ"
    },
    {
        "name": "Queimados",
        "state": "RJ"
    },
    {
        "name": "Quissamã",
        "state": "RJ"
    },
    {
        "name": "Resende",
        "state": "RJ"
    },
    {
        "name": "Rio Bonito",
        "state": "RJ"
    },
    {
        "name": "Rio Claro",
        "state": "RJ"
    },
    {
        "name": "Rio das Flores",
        "state": "RJ"
    },
    {
        "name": "Rio das Ostras",
        "state": "RJ"
    },
    {
        "name": "Rio de Janeiro",
        "state": "RJ"
    },
    {
        "name": "Santa Maria Madalena",
        "state": "RJ"
    },
    {
        "name": "Santo Antônio de Pádua",
        "state": "RJ"
    },
    {
        "name": "São Fidélis",
        "state": "RJ"
    },
    {
        "name": "São Francisco de Itabapoana",
        "state": "RJ"
    },
    {
        "name": "São Gonçalo",
        "state": "RJ"
    },
    {
        "name": "São João da Barra",
        "state": "RJ"
    },
    {
        "name": "São João de Meriti",
        "state": "RJ"
    },
    {
        "name": "São José de Ubá",
        "state": "RJ"
    },
    {
        "name": "São José do Vale do Rio Preto",
        "state": "RJ"
    },
    {
        "name": "São Pedro da Aldeia",
        "state": "RJ"
    },
    {
        "name": "São Sebastião do Alto",
        "state": "RJ"
    },
    {
        "name": "Sapucaia",
        "state": "RJ"
    },
    {
        "name": "Saquarema",
        "state": "RJ"
    },
    {
        "name": "Seropédica",
        "state": "RJ"
    },
    {
        "name": "Silva Jardim",
        "state": "RJ"
    },
    {
        "name": "Sumidouro",
        "state": "RJ"
    },
    {
        "name": "Tanguá",
        "state": "RJ"
    },
    {
        "name": "Teresópolis",
        "state": "RJ"
    },
    {
        "name": "Trajano de Morais",
        "state": "RJ"
    },
    {
        "name": "Três Rios",
        "state": "RJ"
    },
    {
        "name": "Valença",
        "state": "RJ"
    },
    {
        "name": "Varre-Sai",
        "state": "RJ"
    },
    {
        "name": "Vassouras",
        "state": "RJ"
    },
    {
        "name": "Volta Redonda",
        "state": "RJ"
    },
    {
        "name": "Acari",
        "state": "RN"
    },
    {
        "name": "Açu",
        "state": "RN"
    },
    {
        "name": "Afonso Bezerra",
        "state": "RN"
    },
    {
        "name": "Água Nova",
        "state": "RN"
    },
    {
        "name": "Alexandria",
        "state": "RN"
    },
    {
        "name": "Almino Afonso",
        "state": "RN"
    },
    {
        "name": "Alto do Rodrigues",
        "state": "RN"
    },
    {
        "name": "Angicos",
        "state": "RN"
    },
    {
        "name": "Antônio Martins",
        "state": "RN"
    },
    {
        "name": "Apodi",
        "state": "RN"
    },
    {
        "name": "Areia Branca",
        "state": "RN"
    },
    {
        "name": "Arês",
        "state": "RN"
    },
    {
        "name": "Augusto Severo",
        "state": "RN"
    },
    {
        "name": "Baía Formosa",
        "state": "RN"
    },
    {
        "name": "Baraúna",
        "state": "RN"
    },
    {
        "name": "Barcelona",
        "state": "RN"
    },
    {
        "name": "Bento Fernandes",
        "state": "RN"
    },
    {
        "name": "Bodó",
        "state": "RN"
    },
    {
        "name": "Bom Jesus",
        "state": "RN"
    },
    {
        "name": "Brejinho",
        "state": "RN"
    },
    {
        "name": "Caiçara do Norte",
        "state": "RN"
    },
    {
        "name": "Caiçara do Rio do Vento",
        "state": "RN"
    },
    {
        "name": "Caicó",
        "state": "RN"
    },
    {
        "name": "Campo Redondo",
        "state": "RN"
    },
    {
        "name": "Canguaretama",
        "state": "RN"
    },
    {
        "name": "Caraúbas",
        "state": "RN"
    },
    {
        "name": "Carnaúba dos Dantas",
        "state": "RN"
    },
    {
        "name": "Carnaubais",
        "state": "RN"
    },
    {
        "name": "Ceará-Mirim",
        "state": "RN"
    },
    {
        "name": "Cerro Corá",
        "state": "RN"
    },
    {
        "name": "Coronel Ezequiel",
        "state": "RN"
    },
    {
        "name": "Coronel João Pessoa",
        "state": "RN"
    },
    {
        "name": "Cruzeta",
        "state": "RN"
    },
    {
        "name": "Currais Novos",
        "state": "RN"
    },
    {
        "name": "Doutor Severiano",
        "state": "RN"
    },
    {
        "name": "Encanto",
        "state": "RN"
    },
    {
        "name": "Equador",
        "state": "RN"
    },
    {
        "name": "Espírito Santo",
        "state": "RN"
    },
    {
        "name": "Extremoz",
        "state": "RN"
    },
    {
        "name": "Felipe Guerra",
        "state": "RN"
    },
    {
        "name": "Fernando Pedroza",
        "state": "RN"
    },
    {
        "name": "Florânia",
        "state": "RN"
    },
    {
        "name": "Francisco Dantas",
        "state": "RN"
    },
    {
        "name": "Frutuoso Gomes",
        "state": "RN"
    },
    {
        "name": "Galinhos",
        "state": "RN"
    },
    {
        "name": "Goianinha",
        "state": "RN"
    },
    {
        "name": "Governador Dix-Sept Rosado",
        "state": "RN"
    },
    {
        "name": "Grossos",
        "state": "RN"
    },
    {
        "name": "Guamaré",
        "state": "RN"
    },
    {
        "name": "Ielmo Marinho",
        "state": "RN"
    },
    {
        "name": "Ipanguaçu",
        "state": "RN"
    },
    {
        "name": "Ipueira",
        "state": "RN"
    },
    {
        "name": "Itajá",
        "state": "RN"
    },
    {
        "name": "Itaú",
        "state": "RN"
    },
    {
        "name": "Jaçanã",
        "state": "RN"
    },
    {
        "name": "Jandaíra",
        "state": "RN"
    },
    {
        "name": "Janduís",
        "state": "RN"
    },
    {
        "name": "Januário Cicco",
        "state": "RN"
    },
    {
        "name": "Japi",
        "state": "RN"
    },
    {
        "name": "Jardim de Angicos",
        "state": "RN"
    },
    {
        "name": "Jardim de Piranhas",
        "state": "RN"
    },
    {
        "name": "Jardim do Seridó",
        "state": "RN"
    },
    {
        "name": "João Câmara",
        "state": "RN"
    },
    {
        "name": "João Dias",
        "state": "RN"
    },
    {
        "name": "José da Penha",
        "state": "RN"
    },
    {
        "name": "Jucurutu",
        "state": "RN"
    },
    {
        "name": "Jundiá",
        "state": "RN"
    },
    {
        "name": "Lagoa d`Anta",
        "state": "RN"
    },
    {
        "name": "Lagoa de Pedras",
        "state": "RN"
    },
    {
        "name": "Lagoa de Velhos",
        "state": "RN"
    },
    {
        "name": "Lagoa Nova",
        "state": "RN"
    },
    {
        "name": "Lagoa Salgada",
        "state": "RN"
    },
    {
        "name": "Lajes",
        "state": "RN"
    },
    {
        "name": "Lajes Pintadas",
        "state": "RN"
    },
    {
        "name": "Lucrécia",
        "state": "RN"
    },
    {
        "name": "Luís Gomes",
        "state": "RN"
    },
    {
        "name": "Macaíba",
        "state": "RN"
    },
    {
        "name": "Macau",
        "state": "RN"
    },
    {
        "name": "Major Sales",
        "state": "RN"
    },
    {
        "name": "Marcelino Vieira",
        "state": "RN"
    },
    {
        "name": "Martins",
        "state": "RN"
    },
    {
        "name": "Maxaranguape",
        "state": "RN"
    },
    {
        "name": "Messias Targino",
        "state": "RN"
    },
    {
        "name": "Montanhas",
        "state": "RN"
    },
    {
        "name": "Monte Alegre",
        "state": "RN"
    },
    {
        "name": "Monte das Gameleiras",
        "state": "RN"
    },
    {
        "name": "Mossoró",
        "state": "RN"
    },
    {
        "name": "Natal",
        "state": "RN"
    },
    {
        "name": "Nísia Floresta",
        "state": "RN"
    },
    {
        "name": "Nova Cruz",
        "state": "RN"
    },
    {
        "name": "Olho-d`Água do Borges",
        "state": "RN"
    },
    {
        "name": "Ouro Branco",
        "state": "RN"
    },
    {
        "name": "Paraná",
        "state": "RN"
    },
    {
        "name": "Paraú",
        "state": "RN"
    },
    {
        "name": "Parazinho",
        "state": "RN"
    },
    {
        "name": "Parelhas",
        "state": "RN"
    },
    {
        "name": "Parnamirim",
        "state": "RN"
    },
    {
        "name": "Passa e Fica",
        "state": "RN"
    },
    {
        "name": "Passagem",
        "state": "RN"
    },
    {
        "name": "Patu",
        "state": "RN"
    },
    {
        "name": "Pau dos Ferros",
        "state": "RN"
    },
    {
        "name": "Pedra Grande",
        "state": "RN"
    },
    {
        "name": "Pedra Preta",
        "state": "RN"
    },
    {
        "name": "Pedro Avelino",
        "state": "RN"
    },
    {
        "name": "Pedro Velho",
        "state": "RN"
    },
    {
        "name": "Pendências",
        "state": "RN"
    },
    {
        "name": "Pilões",
        "state": "RN"
    },
    {
        "name": "Poço Branco",
        "state": "RN"
    },
    {
        "name": "Portalegre",
        "state": "RN"
    },
    {
        "name": "Porto do Mangue",
        "state": "RN"
    },
    {
        "name": "Presidente Juscelino",
        "state": "RN"
    },
    {
        "name": "Pureza",
        "state": "RN"
    },
    {
        "name": "Rafael Fernandes",
        "state": "RN"
    },
    {
        "name": "Rafael Godeiro",
        "state": "RN"
    },
    {
        "name": "Riacho da Cruz",
        "state": "RN"
    },
    {
        "name": "Riacho de Santana",
        "state": "RN"
    },
    {
        "name": "Riachuelo",
        "state": "RN"
    },
    {
        "name": "Rio do Fogo",
        "state": "RN"
    },
    {
        "name": "Rodolfo Fernandes",
        "state": "RN"
    },
    {
        "name": "Ruy Barbosa",
        "state": "RN"
    },
    {
        "name": "Santa Cruz",
        "state": "RN"
    },
    {
        "name": "Santa Maria",
        "state": "RN"
    },
    {
        "name": "Santana do Matos",
        "state": "RN"
    },
    {
        "name": "Santana do Seridó",
        "state": "RN"
    },
    {
        "name": "Santo Antônio",
        "state": "RN"
    },
    {
        "name": "São Bento do Norte",
        "state": "RN"
    },
    {
        "name": "São Bento do Trairí",
        "state": "RN"
    },
    {
        "name": "São Fernando",
        "state": "RN"
    },
    {
        "name": "São Francisco do Oeste",
        "state": "RN"
    },
    {
        "name": "São Gonçalo do Amarante",
        "state": "RN"
    },
    {
        "name": "São João do Sabugi",
        "state": "RN"
    },
    {
        "name": "São José de Mipibu",
        "state": "RN"
    },
    {
        "name": "São José do Campestre",
        "state": "RN"
    },
    {
        "name": "São José do Seridó",
        "state": "RN"
    },
    {
        "name": "São Miguel",
        "state": "RN"
    },
    {
        "name": "São Miguel do Gostoso",
        "state": "RN"
    },
    {
        "name": "São Paulo do Potengi",
        "state": "RN"
    },
    {
        "name": "São Pedro",
        "state": "RN"
    },
    {
        "name": "São Rafael",
        "state": "RN"
    },
    {
        "name": "São Tomé",
        "state": "RN"
    },
    {
        "name": "São Vicente",
        "state": "RN"
    },
    {
        "name": "Senador Elói de Souza",
        "state": "RN"
    },
    {
        "name": "Senador Georgino Avelino",
        "state": "RN"
    },
    {
        "name": "Serra de São Bento",
        "state": "RN"
    },
    {
        "name": "Serra do Mel",
        "state": "RN"
    },
    {
        "name": "Serra Negra do Norte",
        "state": "RN"
    },
    {
        "name": "Serrinha",
        "state": "RN"
    },
    {
        "name": "Serrinha dos Pintos",
        "state": "RN"
    },
    {
        "name": "Severiano Melo",
        "state": "RN"
    },
    {
        "name": "Sítio Novo",
        "state": "RN"
    },
    {
        "name": "Taboleiro Grande",
        "state": "RN"
    },
    {
        "name": "Taipu",
        "state": "RN"
    },
    {
        "name": "Tangará",
        "state": "RN"
    },
    {
        "name": "Tenente Ananias",
        "state": "RN"
    },
    {
        "name": "Tenente Laurentino Cruz",
        "state": "RN"
    },
    {
        "name": "Tibau",
        "state": "RN"
    },
    {
        "name": "Tibau do Sul",
        "state": "RN"
    },
    {
        "name": "Timbaúba dos Batistas",
        "state": "RN"
    },
    {
        "name": "Touros",
        "state": "RN"
    },
    {
        "name": "Triunfo Potiguar",
        "state": "RN"
    },
    {
        "name": "Umarizal",
        "state": "RN"
    },
    {
        "name": "Upanema",
        "state": "RN"
    },
    {
        "name": "Várzea",
        "state": "RN"
    },
    {
        "name": "Venha-Ver",
        "state": "RN"
    },
    {
        "name": "Vera Cruz",
        "state": "RN"
    },
    {
        "name": "Viçosa",
        "state": "RN"
    },
    {
        "name": "Vila Flor",
        "state": "RN"
    },
    {
        "name": "Aceguá",
        "state": "RS"
    },
    {
        "name": "Água Santa",
        "state": "RS"
    },
    {
        "name": "Agudo",
        "state": "RS"
    },
    {
        "name": "Ajuricaba",
        "state": "RS"
    },
    {
        "name": "Alecrim",
        "state": "RS"
    },
    {
        "name": "Alegrete",
        "state": "RS"
    },
    {
        "name": "Alegria",
        "state": "RS"
    },
    {
        "name": "Almirante Tamandaré do Sul",
        "state": "RS"
    },
    {
        "name": "Alpestre",
        "state": "RS"
    },
    {
        "name": "Alto Alegre",
        "state": "RS"
    },
    {
        "name": "Alto Feliz",
        "state": "RS"
    },
    {
        "name": "Alvorada",
        "state": "RS"
    },
    {
        "name": "Amaral Ferrador",
        "state": "RS"
    },
    {
        "name": "Ametista do Sul",
        "state": "RS"
    },
    {
        "name": "André da Rocha",
        "state": "RS"
    },
    {
        "name": "Anta Gorda",
        "state": "RS"
    },
    {
        "name": "Antônio Prado",
        "state": "RS"
    },
    {
        "name": "Arambaré",
        "state": "RS"
    },
    {
        "name": "Araricá",
        "state": "RS"
    },
    {
        "name": "Aratiba",
        "state": "RS"
    },
    {
        "name": "Arroio do Meio",
        "state": "RS"
    },
    {
        "name": "Arroio do Padre",
        "state": "RS"
    },
    {
        "name": "Arroio do Sal",
        "state": "RS"
    },
    {
        "name": "Arroio do Tigre",
        "state": "RS"
    },
    {
        "name": "Arroio dos Ratos",
        "state": "RS"
    },
    {
        "name": "Arroio Grande",
        "state": "RS"
    },
    {
        "name": "Arvorezinha",
        "state": "RS"
    },
    {
        "name": "Augusto Pestana",
        "state": "RS"
    },
    {
        "name": "Áurea",
        "state": "RS"
    },
    {
        "name": "Bagé",
        "state": "RS"
    },
    {
        "name": "Balneário Pinhal",
        "state": "RS"
    },
    {
        "name": "Barão",
        "state": "RS"
    },
    {
        "name": "Barão de Cotegipe",
        "state": "RS"
    },
    {
        "name": "Barão do Triunfo",
        "state": "RS"
    },
    {
        "name": "Barra do Guarita",
        "state": "RS"
    },
    {
        "name": "Barra do Quaraí",
        "state": "RS"
    },
    {
        "name": "Barra do Ribeiro",
        "state": "RS"
    },
    {
        "name": "Barra do Rio Azul",
        "state": "RS"
    },
    {
        "name": "Barra Funda",
        "state": "RS"
    },
    {
        "name": "Barracão",
        "state": "RS"
    },
    {
        "name": "Barros Cassal",
        "state": "RS"
    },
    {
        "name": "Benjamin Constant do Sul",
        "state": "RS"
    },
    {
        "name": "Bento Gonçalves",
        "state": "RS"
    },
    {
        "name": "Boa Vista das Missões",
        "state": "RS"
    },
    {
        "name": "Boa Vista do Buricá",
        "state": "RS"
    },
    {
        "name": "Boa Vista do Cadeado",
        "state": "RS"
    },
    {
        "name": "Boa Vista do Incra",
        "state": "RS"
    },
    {
        "name": "Boa Vista do Sul",
        "state": "RS"
    },
    {
        "name": "Bom Jesus",
        "state": "RS"
    },
    {
        "name": "Bom Princípio",
        "state": "RS"
    },
    {
        "name": "Bom Progresso",
        "state": "RS"
    },
    {
        "name": "Bom Retiro do Sul",
        "state": "RS"
    },
    {
        "name": "Boqueirão do Leão",
        "state": "RS"
    },
    {
        "name": "Bossoroca",
        "state": "RS"
    },
    {
        "name": "Bozano",
        "state": "RS"
    },
    {
        "name": "Braga",
        "state": "RS"
    },
    {
        "name": "Brochier",
        "state": "RS"
    },
    {
        "name": "Butiá",
        "state": "RS"
    },
    {
        "name": "Caçapava do Sul",
        "state": "RS"
    },
    {
        "name": "Cacequi",
        "state": "RS"
    },
    {
        "name": "Cachoeira do Sul",
        "state": "RS"
    },
    {
        "name": "Cachoeirinha",
        "state": "RS"
    },
    {
        "name": "Cacique Doble",
        "state": "RS"
    },
    {
        "name": "Caibaté",
        "state": "RS"
    },
    {
        "name": "Caiçara",
        "state": "RS"
    },
    {
        "name": "Camaquã",
        "state": "RS"
    },
    {
        "name": "Camargo",
        "state": "RS"
    },
    {
        "name": "Cambará do Sul",
        "state": "RS"
    },
    {
        "name": "Campestre da Serra",
        "state": "RS"
    },
    {
        "name": "Campina das Missões",
        "state": "RS"
    },
    {
        "name": "Campinas do Sul",
        "state": "RS"
    },
    {
        "name": "Campo Bom",
        "state": "RS"
    },
    {
        "name": "Campo Novo",
        "state": "RS"
    },
    {
        "name": "Campos Borges",
        "state": "RS"
    },
    {
        "name": "Candelária",
        "state": "RS"
    },
    {
        "name": "Cândido Godói",
        "state": "RS"
    },
    {
        "name": "Candiota",
        "state": "RS"
    },
    {
        "name": "Canela",
        "state": "RS"
    },
    {
        "name": "Canguçu",
        "state": "RS"
    },
    {
        "name": "Canoas",
        "state": "RS"
    },
    {
        "name": "Canudos do Vale",
        "state": "RS"
    },
    {
        "name": "Capão Bonito do Sul",
        "state": "RS"
    },
    {
        "name": "Capão da Canoa",
        "state": "RS"
    },
    {
        "name": "Capão do Cipó",
        "state": "RS"
    },
    {
        "name": "Capão do Leão",
        "state": "RS"
    },
    {
        "name": "Capela de Santana",
        "state": "RS"
    },
    {
        "name": "Capitão",
        "state": "RS"
    },
    {
        "name": "Capivari do Sul",
        "state": "RS"
    },
    {
        "name": "Caraá",
        "state": "RS"
    },
    {
        "name": "Carazinho",
        "state": "RS"
    },
    {
        "name": "Carlos Barbosa",
        "state": "RS"
    },
    {
        "name": "Carlos Gomes",
        "state": "RS"
    },
    {
        "name": "Casca",
        "state": "RS"
    },
    {
        "name": "Caseiros",
        "state": "RS"
    },
    {
        "name": "Catuípe",
        "state": "RS"
    },
    {
        "name": "Caxias do Sul",
        "state": "RS"
    },
    {
        "name": "Centenário",
        "state": "RS"
    },
    {
        "name": "Cerrito",
        "state": "RS"
    },
    {
        "name": "Cerro Branco",
        "state": "RS"
    },
    {
        "name": "Cerro Grande",
        "state": "RS"
    },
    {
        "name": "Cerro Grande do Sul",
        "state": "RS"
    },
    {
        "name": "Cerro Largo",
        "state": "RS"
    },
    {
        "name": "Chapada",
        "state": "RS"
    },
    {
        "name": "Charqueadas",
        "state": "RS"
    },
    {
        "name": "Charrua",
        "state": "RS"
    },
    {
        "name": "Chiapeta",
        "state": "RS"
    },
    {
        "name": "Chuí",
        "state": "RS"
    },
    {
        "name": "Chuvisca",
        "state": "RS"
    },
    {
        "name": "Cidreira",
        "state": "RS"
    },
    {
        "name": "Ciríaco",
        "state": "RS"
    },
    {
        "name": "Colinas",
        "state": "RS"
    },
    {
        "name": "Colorado",
        "state": "RS"
    },
    {
        "name": "Condor",
        "state": "RS"
    },
    {
        "name": "Constantina",
        "state": "RS"
    },
    {
        "name": "Coqueiro Baixo",
        "state": "RS"
    },
    {
        "name": "Coqueiros do Sul",
        "state": "RS"
    },
    {
        "name": "Coronel Barros",
        "state": "RS"
    },
    {
        "name": "Coronel Bicaco",
        "state": "RS"
    },
    {
        "name": "Coronel Pilar",
        "state": "RS"
    },
    {
        "name": "Cotiporã",
        "state": "RS"
    },
    {
        "name": "Coxilha",
        "state": "RS"
    },
    {
        "name": "Crissiumal",
        "state": "RS"
    },
    {
        "name": "Cristal",
        "state": "RS"
    },
    {
        "name": "Cristal do Sul",
        "state": "RS"
    },
    {
        "name": "Cruz Alta",
        "state": "RS"
    },
    {
        "name": "Cruzaltense",
        "state": "RS"
    },
    {
        "name": "Cruzeiro do Sul",
        "state": "RS"
    },
    {
        "name": "David Canabarro",
        "state": "RS"
    },
    {
        "name": "Derrubadas",
        "state": "RS"
    },
    {
        "name": "Dezesseis de Novembro",
        "state": "RS"
    },
    {
        "name": "Dilermando de Aguiar",
        "state": "RS"
    },
    {
        "name": "Dois Irmãos",
        "state": "RS"
    },
    {
        "name": "Dois Irmãos das Missões",
        "state": "RS"
    },
    {
        "name": "Dois Lajeados",
        "state": "RS"
    },
    {
        "name": "Dom Feliciano",
        "state": "RS"
    },
    {
        "name": "Dom Pedrito",
        "state": "RS"
    },
    {
        "name": "Dom Pedro de Alcântara",
        "state": "RS"
    },
    {
        "name": "Dona Francisca",
        "state": "RS"
    },
    {
        "name": "Doutor Maurício Cardoso",
        "state": "RS"
    },
    {
        "name": "Doutor Ricardo",
        "state": "RS"
    },
    {
        "name": "Eldorado do Sul",
        "state": "RS"
    },
    {
        "name": "Encantado",
        "state": "RS"
    },
    {
        "name": "Encruzilhada do Sul",
        "state": "RS"
    },
    {
        "name": "Engenho Velho",
        "state": "RS"
    },
    {
        "name": "Entre Rios do Sul",
        "state": "RS"
    },
    {
        "name": "Entre-Ijuís",
        "state": "RS"
    },
    {
        "name": "Erebango",
        "state": "RS"
    },
    {
        "name": "Erechim",
        "state": "RS"
    },
    {
        "name": "Ernestina",
        "state": "RS"
    },
    {
        "name": "Erval Grande",
        "state": "RS"
    },
    {
        "name": "Erval Seco",
        "state": "RS"
    },
    {
        "name": "Esmeralda",
        "state": "RS"
    },
    {
        "name": "Esperança do Sul",
        "state": "RS"
    },
    {
        "name": "Espumoso",
        "state": "RS"
    },
    {
        "name": "Estação",
        "state": "RS"
    },
    {
        "name": "Estância Velha",
        "state": "RS"
    },
    {
        "name": "Esteio",
        "state": "RS"
    },
    {
        "name": "Estrela",
        "state": "RS"
    },
    {
        "name": "Estrela Velha",
        "state": "RS"
    },
    {
        "name": "Eugênio de Castro",
        "state": "RS"
    },
    {
        "name": "Fagundes Varela",
        "state": "RS"
    },
    {
        "name": "Farroupilha",
        "state": "RS"
    },
    {
        "name": "Faxinal do Soturno",
        "state": "RS"
    },
    {
        "name": "Faxinalzinho",
        "state": "RS"
    },
    {
        "name": "Fazenda Vilanova",
        "state": "RS"
    },
    {
        "name": "Feliz",
        "state": "RS"
    },
    {
        "name": "Flores da Cunha",
        "state": "RS"
    },
    {
        "name": "Floriano Peixoto",
        "state": "RS"
    },
    {
        "name": "Fontoura Xavier",
        "state": "RS"
    },
    {
        "name": "Formigueiro",
        "state": "RS"
    },
    {
        "name": "Forquetinha",
        "state": "RS"
    },
    {
        "name": "Fortaleza dos Valos",
        "state": "RS"
    },
    {
        "name": "Frederico Westphalen",
        "state": "RS"
    },
    {
        "name": "Garibaldi",
        "state": "RS"
    },
    {
        "name": "Garruchos",
        "state": "RS"
    },
    {
        "name": "Gaurama",
        "state": "RS"
    },
    {
        "name": "General Câmara",
        "state": "RS"
    },
    {
        "name": "Gentil",
        "state": "RS"
    },
    {
        "name": "Getúlio Vargas",
        "state": "RS"
    },
    {
        "name": "Giruá",
        "state": "RS"
    },
    {
        "name": "Glorinha",
        "state": "RS"
    },
    {
        "name": "Gramado",
        "state": "RS"
    },
    {
        "name": "Gramado dos Loureiros",
        "state": "RS"
    },
    {
        "name": "Gramado Xavier",
        "state": "RS"
    },
    {
        "name": "Gravataí",
        "state": "RS"
    },
    {
        "name": "Guabiju",
        "state": "RS"
    },
    {
        "name": "Guaíba",
        "state": "RS"
    },
    {
        "name": "Guaporé",
        "state": "RS"
    },
    {
        "name": "Guarani das Missões",
        "state": "RS"
    },
    {
        "name": "Harmonia",
        "state": "RS"
    },
    {
        "name": "Herval",
        "state": "RS"
    },
    {
        "name": "Herveiras",
        "state": "RS"
    },
    {
        "name": "Horizontina",
        "state": "RS"
    },
    {
        "name": "Hulha Negra",
        "state": "RS"
    },
    {
        "name": "Humaitá",
        "state": "RS"
    },
    {
        "name": "Ibarama",
        "state": "RS"
    },
    {
        "name": "Ibiaçá",
        "state": "RS"
    },
    {
        "name": "Ibiraiaras",
        "state": "RS"
    },
    {
        "name": "Ibirapuitã",
        "state": "RS"
    },
    {
        "name": "Ibirubá",
        "state": "RS"
    },
    {
        "name": "Igrejinha",
        "state": "RS"
    },
    {
        "name": "Ijuí",
        "state": "RS"
    },
    {
        "name": "Ilópolis",
        "state": "RS"
    },
    {
        "name": "Imbé",
        "state": "RS"
    },
    {
        "name": "Imigrante",
        "state": "RS"
    },
    {
        "name": "Independência",
        "state": "RS"
    },
    {
        "name": "Inhacorá",
        "state": "RS"
    },
    {
        "name": "Ipê",
        "state": "RS"
    },
    {
        "name": "Ipiranga do Sul",
        "state": "RS"
    },
    {
        "name": "Iraí",
        "state": "RS"
    },
    {
        "name": "Itaara",
        "state": "RS"
    },
    {
        "name": "Itacurubi",
        "state": "RS"
    },
    {
        "name": "Itapuca",
        "state": "RS"
    },
    {
        "name": "Itaqui",
        "state": "RS"
    },
    {
        "name": "Itati",
        "state": "RS"
    },
    {
        "name": "Itatiba do Sul",
        "state": "RS"
    },
    {
        "name": "Ivorá",
        "state": "RS"
    },
    {
        "name": "Ivoti",
        "state": "RS"
    },
    {
        "name": "Jaboticaba",
        "state": "RS"
    },
    {
        "name": "Jacuizinho",
        "state": "RS"
    },
    {
        "name": "Jacutinga",
        "state": "RS"
    },
    {
        "name": "Jaguarão",
        "state": "RS"
    },
    {
        "name": "Jaguari",
        "state": "RS"
    },
    {
        "name": "Jaquirana",
        "state": "RS"
    },
    {
        "name": "Jari",
        "state": "RS"
    },
    {
        "name": "Jóia",
        "state": "RS"
    },
    {
        "name": "Júlio de Castilhos",
        "state": "RS"
    },
    {
        "name": "Lagoa Bonita do Sul",
        "state": "RS"
    },
    {
        "name": "Lagoa dos Três Cantos",
        "state": "RS"
    },
    {
        "name": "Lagoa Vermelha",
        "state": "RS"
    },
    {
        "name": "Lagoão",
        "state": "RS"
    },
    {
        "name": "Lajeado",
        "state": "RS"
    },
    {
        "name": "Lajeado do Bugre",
        "state": "RS"
    },
    {
        "name": "Lavras do Sul",
        "state": "RS"
    },
    {
        "name": "Liberato Salzano",
        "state": "RS"
    },
    {
        "name": "Lindolfo Collor",
        "state": "RS"
    },
    {
        "name": "Linha Nova",
        "state": "RS"
    },
    {
        "name": "Maçambara",
        "state": "RS"
    },
    {
        "name": "Machadinho",
        "state": "RS"
    },
    {
        "name": "Mampituba",
        "state": "RS"
    },
    {
        "name": "Manoel Viana",
        "state": "RS"
    },
    {
        "name": "Maquiné",
        "state": "RS"
    },
    {
        "name": "Maratá",
        "state": "RS"
    },
    {
        "name": "Marau",
        "state": "RS"
    },
    {
        "name": "Marcelino Ramos",
        "state": "RS"
    },
    {
        "name": "Mariana Pimentel",
        "state": "RS"
    },
    {
        "name": "Mariano Moro",
        "state": "RS"
    },
    {
        "name": "Marques de Souza",
        "state": "RS"
    },
    {
        "name": "Mata",
        "state": "RS"
    },
    {
        "name": "Mato Castelhano",
        "state": "RS"
    },
    {
        "name": "Mato Leitão",
        "state": "RS"
    },
    {
        "name": "Mato Queimado",
        "state": "RS"
    },
    {
        "name": "Maximiliano de Almeida",
        "state": "RS"
    },
    {
        "name": "Minas do Leão",
        "state": "RS"
    },
    {
        "name": "Miraguaí",
        "state": "RS"
    },
    {
        "name": "Montauri",
        "state": "RS"
    },
    {
        "name": "Monte Alegre dos Campos",
        "state": "RS"
    },
    {
        "name": "Monte Belo do Sul",
        "state": "RS"
    },
    {
        "name": "Montenegro",
        "state": "RS"
    },
    {
        "name": "Mormaço",
        "state": "RS"
    },
    {
        "name": "Morrinhos do Sul",
        "state": "RS"
    },
    {
        "name": "Morro Redondo",
        "state": "RS"
    },
    {
        "name": "Morro Reuter",
        "state": "RS"
    },
    {
        "name": "Mostardas",
        "state": "RS"
    },
    {
        "name": "Muçum",
        "state": "RS"
    },
    {
        "name": "Muitos Capões",
        "state": "RS"
    },
    {
        "name": "Muliterno",
        "state": "RS"
    },
    {
        "name": "Não-Me-Toque",
        "state": "RS"
    },
    {
        "name": "Nicolau Vergueiro",
        "state": "RS"
    },
    {
        "name": "Nonoai",
        "state": "RS"
    },
    {
        "name": "Nova Alvorada",
        "state": "RS"
    },
    {
        "name": "Nova Araçá",
        "state": "RS"
    },
    {
        "name": "Nova Bassano",
        "state": "RS"
    },
    {
        "name": "Nova Boa Vista",
        "state": "RS"
    },
    {
        "name": "Nova Bréscia",
        "state": "RS"
    },
    {
        "name": "Nova Candelária",
        "state": "RS"
    },
    {
        "name": "Nova Esperança do Sul",
        "state": "RS"
    },
    {
        "name": "Nova Hartz",
        "state": "RS"
    },
    {
        "name": "Nova Pádua",
        "state": "RS"
    },
    {
        "name": "Nova Palma",
        "state": "RS"
    },
    {
        "name": "Nova Petrópolis",
        "state": "RS"
    },
    {
        "name": "Nova Prata",
        "state": "RS"
    },
    {
        "name": "Nova Ramada",
        "state": "RS"
    },
    {
        "name": "Nova Roma do Sul",
        "state": "RS"
    },
    {
        "name": "Nova Santa Rita",
        "state": "RS"
    },
    {
        "name": "Novo Barreiro",
        "state": "RS"
    },
    {
        "name": "Novo Cabrais",
        "state": "RS"
    },
    {
        "name": "Novo Hamburgo",
        "state": "RS"
    },
    {
        "name": "Novo Machado",
        "state": "RS"
    },
    {
        "name": "Novo Tiradentes",
        "state": "RS"
    },
    {
        "name": "Novo Xingu",
        "state": "RS"
    },
    {
        "name": "Osório",
        "state": "RS"
    },
    {
        "name": "Paim Filho",
        "state": "RS"
    },
    {
        "name": "Palmares do Sul",
        "state": "RS"
    },
    {
        "name": "Palmeira das Missões",
        "state": "RS"
    },
    {
        "name": "Palmitinho",
        "state": "RS"
    },
    {
        "name": "Panambi",
        "state": "RS"
    },
    {
        "name": "Pantano Grande",
        "state": "RS"
    },
    {
        "name": "Paraí",
        "state": "RS"
    },
    {
        "name": "Paraíso do Sul",
        "state": "RS"
    },
    {
        "name": "Pareci Novo",
        "state": "RS"
    },
    {
        "name": "Parobé",
        "state": "RS"
    },
    {
        "name": "Passa Sete",
        "state": "RS"
    },
    {
        "name": "Passo do Sobrado",
        "state": "RS"
    },
    {
        "name": "Passo Fundo",
        "state": "RS"
    },
    {
        "name": "Paulo Bento",
        "state": "RS"
    },
    {
        "name": "Paverama",
        "state": "RS"
    },
    {
        "name": "Pedras Altas",
        "state": "RS"
    },
    {
        "name": "Pedro Osório",
        "state": "RS"
    },
    {
        "name": "Pejuçara",
        "state": "RS"
    },
    {
        "name": "Pelotas",
        "state": "RS"
    },
    {
        "name": "Picada Café",
        "state": "RS"
    },
    {
        "name": "Pinhal",
        "state": "RS"
    },
    {
        "name": "Pinhal da Serra",
        "state": "RS"
    },
    {
        "name": "Pinhal Grande",
        "state": "RS"
    },
    {
        "name": "Pinheirinho do Vale",
        "state": "RS"
    },
    {
        "name": "Pinheiro Machado",
        "state": "RS"
    },
    {
        "name": "Pirapó",
        "state": "RS"
    },
    {
        "name": "Piratini",
        "state": "RS"
    },
    {
        "name": "Planalto",
        "state": "RS"
    },
    {
        "name": "Poço das Antas",
        "state": "RS"
    },
    {
        "name": "Pontão",
        "state": "RS"
    },
    {
        "name": "Ponte Preta",
        "state": "RS"
    },
    {
        "name": "Portão",
        "state": "RS"
    },
    {
        "name": "Porto Alegre",
        "state": "RS"
    },
    {
        "name": "Porto Lucena",
        "state": "RS"
    },
    {
        "name": "Porto Mauá",
        "state": "RS"
    },
    {
        "name": "Porto Vera Cruz",
        "state": "RS"
    },
    {
        "name": "Porto Xavier",
        "state": "RS"
    },
    {
        "name": "Pouso Novo",
        "state": "RS"
    },
    {
        "name": "Presidente Lucena",
        "state": "RS"
    },
    {
        "name": "Progresso",
        "state": "RS"
    },
    {
        "name": "Protásio Alves",
        "state": "RS"
    },
    {
        "name": "Putinga",
        "state": "RS"
    },
    {
        "name": "Quaraí",
        "state": "RS"
    },
    {
        "name": "Quatro Irmãos",
        "state": "RS"
    },
    {
        "name": "Quevedos",
        "state": "RS"
    },
    {
        "name": "Quinze de Novembro",
        "state": "RS"
    },
    {
        "name": "Redentora",
        "state": "RS"
    },
    {
        "name": "Relvado",
        "state": "RS"
    },
    {
        "name": "Restinga Seca",
        "state": "RS"
    },
    {
        "name": "Rio dos Índios",
        "state": "RS"
    },
    {
        "name": "Rio Grande",
        "state": "RS"
    },
    {
        "name": "Rio Pardo",
        "state": "RS"
    },
    {
        "name": "Riozinho",
        "state": "RS"
    },
    {
        "name": "Roca Sales",
        "state": "RS"
    },
    {
        "name": "Rodeio Bonito",
        "state": "RS"
    },
    {
        "name": "Rolador",
        "state": "RS"
    },
    {
        "name": "Rolante",
        "state": "RS"
    },
    {
        "name": "Ronda Alta",
        "state": "RS"
    },
    {
        "name": "Rondinha",
        "state": "RS"
    },
    {
        "name": "Roque Gonzales",
        "state": "RS"
    },
    {
        "name": "Rosário do Sul",
        "state": "RS"
    },
    {
        "name": "Sagrada Família",
        "state": "RS"
    },
    {
        "name": "Saldanha Marinho",
        "state": "RS"
    },
    {
        "name": "Salto do Jacuí",
        "state": "RS"
    },
    {
        "name": "Salvador das Missões",
        "state": "RS"
    },
    {
        "name": "Salvador do Sul",
        "state": "RS"
    },
    {
        "name": "Sananduva",
        "state": "RS"
    },
    {
        "name": "Santa Bárbara do Sul",
        "state": "RS"
    },
    {
        "name": "Santa Cecília do Sul",
        "state": "RS"
    },
    {
        "name": "Santa Clara do Sul",
        "state": "RS"
    },
    {
        "name": "Santa Cruz do Sul",
        "state": "RS"
    },
    {
        "name": "Santa Margarida do Sul",
        "state": "RS"
    },
    {
        "name": "Santa Maria",
        "state": "RS"
    },
    {
        "name": "Santa Maria do Herval",
        "state": "RS"
    },
    {
        "name": "Santa Rosa",
        "state": "RS"
    },
    {
        "name": "Santa Tereza",
        "state": "RS"
    },
    {
        "name": "Santa Vitória do Palmar",
        "state": "RS"
    },
    {
        "name": "Santana da Boa Vista",
        "state": "RS"
    },
    {
        "name": "Santana do Livramento",
        "state": "RS"
    },
    {
        "name": "Santiago",
        "state": "RS"
    },
    {
        "name": "Santo Ângelo",
        "state": "RS"
    },
    {
        "name": "Santo Antônio da Patrulha",
        "state": "RS"
    },
    {
        "name": "Santo Antônio das Missões",
        "state": "RS"
    },
    {
        "name": "Santo Antônio do Palma",
        "state": "RS"
    },
    {
        "name": "Santo Antônio do Planalto",
        "state": "RS"
    },
    {
        "name": "Santo Augusto",
        "state": "RS"
    },
    {
        "name": "Santo Cristo",
        "state": "RS"
    },
    {
        "name": "Santo Expedito do Sul",
        "state": "RS"
    },
    {
        "name": "São Borja",
        "state": "RS"
    },
    {
        "name": "São Domingos do Sul",
        "state": "RS"
    },
    {
        "name": "São Francisco de Assis",
        "state": "RS"
    },
    {
        "name": "São Francisco de Paula",
        "state": "RS"
    },
    {
        "name": "São Gabriel",
        "state": "RS"
    },
    {
        "name": "São Jerônimo",
        "state": "RS"
    },
    {
        "name": "São João da Urtiga",
        "state": "RS"
    },
    {
        "name": "São João do Polêsine",
        "state": "RS"
    },
    {
        "name": "São Jorge",
        "state": "RS"
    },
    {
        "name": "São José das Missões",
        "state": "RS"
    },
    {
        "name": "São José do Herval",
        "state": "RS"
    },
    {
        "name": "São José do Hortêncio",
        "state": "RS"
    },
    {
        "name": "São José do Inhacorá",
        "state": "RS"
    },
    {
        "name": "São José do Norte",
        "state": "RS"
    },
    {
        "name": "São José do Ouro",
        "state": "RS"
    },
    {
        "name": "São José do Sul",
        "state": "RS"
    },
    {
        "name": "São José dos Ausentes",
        "state": "RS"
    },
    {
        "name": "São Leopoldo",
        "state": "RS"
    },
    {
        "name": "São Lourenço do Sul",
        "state": "RS"
    },
    {
        "name": "São Luiz Gonzaga",
        "state": "RS"
    },
    {
        "name": "São Marcos",
        "state": "RS"
    },
    {
        "name": "São Martinho",
        "state": "RS"
    },
    {
        "name": "São Martinho da Serra",
        "state": "RS"
    },
    {
        "name": "São Miguel das Missões",
        "state": "RS"
    },
    {
        "name": "São Nicolau",
        "state": "RS"
    },
    {
        "name": "São Paulo das Missões",
        "state": "RS"
    },
    {
        "name": "São Pedro da Serra",
        "state": "RS"
    },
    {
        "name": "São Pedro das Missões",
        "state": "RS"
    },
    {
        "name": "São Pedro do Butiá",
        "state": "RS"
    },
    {
        "name": "São Pedro do Sul",
        "state": "RS"
    },
    {
        "name": "São Sebastião do Caí",
        "state": "RS"
    },
    {
        "name": "São Sepé",
        "state": "RS"
    },
    {
        "name": "São Valentim",
        "state": "RS"
    },
    {
        "name": "São Valentim do Sul",
        "state": "RS"
    },
    {
        "name": "São Valério do Sul",
        "state": "RS"
    },
    {
        "name": "São Vendelino",
        "state": "RS"
    },
    {
        "name": "São Vicente do Sul",
        "state": "RS"
    },
    {
        "name": "Sapiranga",
        "state": "RS"
    },
    {
        "name": "Sapucaia do Sul",
        "state": "RS"
    },
    {
        "name": "Sarandi",
        "state": "RS"
    },
    {
        "name": "Seberi",
        "state": "RS"
    },
    {
        "name": "Sede Nova",
        "state": "RS"
    },
    {
        "name": "Segredo",
        "state": "RS"
    },
    {
        "name": "Selbach",
        "state": "RS"
    },
    {
        "name": "Senador Salgado Filho",
        "state": "RS"
    },
    {
        "name": "Sentinela do Sul",
        "state": "RS"
    },
    {
        "name": "Serafina Corrêa",
        "state": "RS"
    },
    {
        "name": "Sério",
        "state": "RS"
    },
    {
        "name": "Sertão",
        "state": "RS"
    },
    {
        "name": "Sertão Santana",
        "state": "RS"
    },
    {
        "name": "Sete de Setembro",
        "state": "RS"
    },
    {
        "name": "Severiano de Almeida",
        "state": "RS"
    },
    {
        "name": "Silveira Martins",
        "state": "RS"
    },
    {
        "name": "Sinimbu",
        "state": "RS"
    },
    {
        "name": "Sobradinho",
        "state": "RS"
    },
    {
        "name": "Soledade",
        "state": "RS"
    },
    {
        "name": "Tabaí",
        "state": "RS"
    },
    {
        "name": "Tapejara",
        "state": "RS"
    },
    {
        "name": "Tapera",
        "state": "RS"
    },
    {
        "name": "Tapes",
        "state": "RS"
    },
    {
        "name": "Taquara",
        "state": "RS"
    },
    {
        "name": "Taquari",
        "state": "RS"
    },
    {
        "name": "Taquaruçu do Sul",
        "state": "RS"
    },
    {
        "name": "Tavares",
        "state": "RS"
    },
    {
        "name": "Tenente Portela",
        "state": "RS"
    },
    {
        "name": "Terra de Areia",
        "state": "RS"
    },
    {
        "name": "Teutônia",
        "state": "RS"
    },
    {
        "name": "Tio Hugo",
        "state": "RS"
    },
    {
        "name": "Tiradentes do Sul",
        "state": "RS"
    },
    {
        "name": "Toropi",
        "state": "RS"
    },
    {
        "name": "Torres",
        "state": "RS"
    },
    {
        "name": "Tramandaí",
        "state": "RS"
    },
    {
        "name": "Travesseiro",
        "state": "RS"
    },
    {
        "name": "Três Arroios",
        "state": "RS"
    },
    {
        "name": "Três Cachoeiras",
        "state": "RS"
    },
    {
        "name": "Três Coroas",
        "state": "RS"
    },
    {
        "name": "Três de Maio",
        "state": "RS"
    },
    {
        "name": "Três Forquilhas",
        "state": "RS"
    },
    {
        "name": "Três Palmeiras",
        "state": "RS"
    },
    {
        "name": "Três Passos",
        "state": "RS"
    },
    {
        "name": "Trindade do Sul",
        "state": "RS"
    },
    {
        "name": "Triunfo",
        "state": "RS"
    },
    {
        "name": "Tucunduva",
        "state": "RS"
    },
    {
        "name": "Tunas",
        "state": "RS"
    },
    {
        "name": "Tupanci do Sul",
        "state": "RS"
    },
    {
        "name": "Tupanciretã",
        "state": "RS"
    },
    {
        "name": "Tupandi",
        "state": "RS"
    },
    {
        "name": "Tuparendi",
        "state": "RS"
    },
    {
        "name": "Turuçu",
        "state": "RS"
    },
    {
        "name": "Ubiretama",
        "state": "RS"
    },
    {
        "name": "União da Serra",
        "state": "RS"
    },
    {
        "name": "Unistalda",
        "state": "RS"
    },
    {
        "name": "Uruguaiana",
        "state": "RS"
    },
    {
        "name": "Vacaria",
        "state": "RS"
    },
    {
        "name": "Vale do Sol",
        "state": "RS"
    },
    {
        "name": "Vale Real",
        "state": "RS"
    },
    {
        "name": "Vale Verde",
        "state": "RS"
    },
    {
        "name": "Vanini",
        "state": "RS"
    },
    {
        "name": "Venâncio Aires",
        "state": "RS"
    },
    {
        "name": "Vera Cruz",
        "state": "RS"
    },
    {
        "name": "Veranópolis",
        "state": "RS"
    },
    {
        "name": "Vespasiano Correa",
        "state": "RS"
    },
    {
        "name": "Viadutos",
        "state": "RS"
    },
    {
        "name": "Viamão",
        "state": "RS"
    },
    {
        "name": "Vicente Dutra",
        "state": "RS"
    },
    {
        "name": "Victor Graeff",
        "state": "RS"
    },
    {
        "name": "Vila Flores",
        "state": "RS"
    },
    {
        "name": "Vila Lângaro",
        "state": "RS"
    },
    {
        "name": "Vila Maria",
        "state": "RS"
    },
    {
        "name": "Vila Nova do Sul",
        "state": "RS"
    },
    {
        "name": "Vista Alegre",
        "state": "RS"
    },
    {
        "name": "Vista Alegre do Prata",
        "state": "RS"
    },
    {
        "name": "Vista Gaúcha",
        "state": "RS"
    },
    {
        "name": "Vitória das Missões",
        "state": "RS"
    },
    {
        "name": "Westfália",
        "state": "RS"
    },
    {
        "name": "Xangri-lá",
        "state": "RS"
    },
    {
        "name": "Alta Floresta d`Oeste",
        "state": "RO"
    },
    {
        "name": "Alto Alegre dos Parecis",
        "state": "RO"
    },
    {
        "name": "Alto Paraíso",
        "state": "RO"
    },
    {
        "name": "Alvorada d`Oeste",
        "state": "RO"
    },
    {
        "name": "Ariquemes",
        "state": "RO"
    },
    {
        "name": "Buritis",
        "state": "RO"
    },
    {
        "name": "Cabixi",
        "state": "RO"
    },
    {
        "name": "Cacaulândia",
        "state": "RO"
    },
    {
        "name": "Cacoal",
        "state": "RO"
    },
    {
        "name": "Campo Novo de Rondônia",
        "state": "RO"
    },
    {
        "name": "Candeias do Jamari",
        "state": "RO"
    },
    {
        "name": "Castanheiras",
        "state": "RO"
    },
    {
        "name": "Cerejeiras",
        "state": "RO"
    },
    {
        "name": "Chupinguaia",
        "state": "RO"
    },
    {
        "name": "Colorado do Oeste",
        "state": "RO"
    },
    {
        "name": "Corumbiara",
        "state": "RO"
    },
    {
        "name": "Costa Marques",
        "state": "RO"
    },
    {
        "name": "Cujubim",
        "state": "RO"
    },
    {
        "name": "Espigão d`Oeste",
        "state": "RO"
    },
    {
        "name": "Governador Jorge Teixeira",
        "state": "RO"
    },
    {
        "name": "Guajará-Mirim",
        "state": "RO"
    },
    {
        "name": "Itapuã do Oeste",
        "state": "RO"
    },
    {
        "name": "Jaru",
        "state": "RO"
    },
    {
        "name": "Ji-Paraná",
        "state": "RO"
    },
    {
        "name": "Machadinho d`Oeste",
        "state": "RO"
    },
    {
        "name": "Ministro Andreazza",
        "state": "RO"
    },
    {
        "name": "Mirante da Serra",
        "state": "RO"
    },
    {
        "name": "Monte Negro",
        "state": "RO"
    },
    {
        "name": "Nova Brasilândia d`Oeste",
        "state": "RO"
    },
    {
        "name": "Nova Mamoré",
        "state": "RO"
    },
    {
        "name": "Nova União",
        "state": "RO"
    },
    {
        "name": "Novo Horizonte do Oeste",
        "state": "RO"
    },
    {
        "name": "Ouro Preto do Oeste",
        "state": "RO"
    },
    {
        "name": "Parecis",
        "state": "RO"
    },
    {
        "name": "Pimenta Bueno",
        "state": "RO"
    },
    {
        "name": "Pimenteiras do Oeste",
        "state": "RO"
    },
    {
        "name": "Porto Velho",
        "state": "RO"
    },
    {
        "name": "Presidente Médici",
        "state": "RO"
    },
    {
        "name": "Primavera de Rondônia",
        "state": "RO"
    },
    {
        "name": "Rio Crespo",
        "state": "RO"
    },
    {
        "name": "Rolim de Moura",
        "state": "RO"
    },
    {
        "name": "Santa Luzia d`Oeste",
        "state": "RO"
    },
    {
        "name": "São Felipe d`Oeste",
        "state": "RO"
    },
    {
        "name": "São Francisco do Guaporé",
        "state": "RO"
    },
    {
        "name": "São Miguel do Guaporé",
        "state": "RO"
    },
    {
        "name": "Seringueiras",
        "state": "RO"
    },
    {
        "name": "Teixeirópolis",
        "state": "RO"
    },
    {
        "name": "Theobroma",
        "state": "RO"
    },
    {
        "name": "Urupá",
        "state": "RO"
    },
    {
        "name": "Vale do Anari",
        "state": "RO"
    },
    {
        "name": "Vale do Paraíso",
        "state": "RO"
    },
    {
        "name": "Vilhena",
        "state": "RO"
    },
    {
        "name": "Alto Alegre",
        "state": "RR"
    },
    {
        "name": "Amajari",
        "state": "RR"
    },
    {
        "name": "Boa Vista",
        "state": "RR"
    },
    {
        "name": "Bonfim",
        "state": "RR"
    },
    {
        "name": "Cantá",
        "state": "RR"
    },
    {
        "name": "Caracaraí",
        "state": "RR"
    },
    {
        "name": "Caroebe",
        "state": "RR"
    },
    {
        "name": "Iracema",
        "state": "RR"
    },
    {
        "name": "Mucajaí",
        "state": "RR"
    },
    {
        "name": "Normandia",
        "state": "RR"
    },
    {
        "name": "Pacaraima",
        "state": "RR"
    },
    {
        "name": "Rorainópolis",
        "state": "RR"
    },
    {
        "name": "São João da Baliza",
        "state": "RR"
    },
    {
        "name": "São Luiz",
        "state": "RR"
    },
    {
        "name": "Uiramutã",
        "state": "RR"
    },
    {
        "name": "Abdon Batista",
        "state": "SC"
    },
    {
        "name": "Abelardo Luz",
        "state": "SC"
    },
    {
        "name": "Agrolândia",
        "state": "SC"
    },
    {
        "name": "Agronômica",
        "state": "SC"
    },
    {
        "name": "Água Doce",
        "state": "SC"
    },
    {
        "name": "Águas de Chapecó",
        "state": "SC"
    },
    {
        "name": "Águas Frias",
        "state": "SC"
    },
    {
        "name": "Águas Mornas",
        "state": "SC"
    },
    {
        "name": "Alfredo Wagner",
        "state": "SC"
    },
    {
        "name": "Alto Bela Vista",
        "state": "SC"
    },
    {
        "name": "Anchieta",
        "state": "SC"
    },
    {
        "name": "Angelina",
        "state": "SC"
    },
    {
        "name": "Anita Garibaldi",
        "state": "SC"
    },
    {
        "name": "Anitápolis",
        "state": "SC"
    },
    {
        "name": "Antônio Carlos",
        "state": "SC"
    },
    {
        "name": "Apiúna",
        "state": "SC"
    },
    {
        "name": "Arabutã",
        "state": "SC"
    },
    {
        "name": "Araquari",
        "state": "SC"
    },
    {
        "name": "Araranguá",
        "state": "SC"
    },
    {
        "name": "Armazém",
        "state": "SC"
    },
    {
        "name": "Arroio Trinta",
        "state": "SC"
    },
    {
        "name": "Arvoredo",
        "state": "SC"
    },
    {
        "name": "Ascurra",
        "state": "SC"
    },
    {
        "name": "Atalanta",
        "state": "SC"
    },
    {
        "name": "Aurora",
        "state": "SC"
    },
    {
        "name": "Balneário Arroio do Silva",
        "state": "SC"
    },
    {
        "name": "Balneário Barra do Sul",
        "state": "SC"
    },
    {
        "name": "Balneário Camboriú",
        "state": "SC"
    },
    {
        "name": "Balneário Gaivota",
        "state": "SC"
    },
    {
        "name": "Bandeirante",
        "state": "SC"
    },
    {
        "name": "Barra Bonita",
        "state": "SC"
    },
    {
        "name": "Barra Velha",
        "state": "SC"
    },
    {
        "name": "Bela Vista do Toldo",
        "state": "SC"
    },
    {
        "name": "Belmonte",
        "state": "SC"
    },
    {
        "name": "Benedito Novo",
        "state": "SC"
    },
    {
        "name": "Biguaçu",
        "state": "SC"
    },
    {
        "name": "Blumenau",
        "state": "SC"
    },
    {
        "name": "Bocaina do Sul",
        "state": "SC"
    },
    {
        "name": "Bom Jardim da Serra",
        "state": "SC"
    },
    {
        "name": "Bom Jesus",
        "state": "SC"
    },
    {
        "name": "Bom Jesus do Oeste",
        "state": "SC"
    },
    {
        "name": "Bom Retiro",
        "state": "SC"
    },
    {
        "name": "Bombinhas",
        "state": "SC"
    },
    {
        "name": "Botuverá",
        "state": "SC"
    },
    {
        "name": "Braço do Norte",
        "state": "SC"
    },
    {
        "name": "Braço do Trombudo",
        "state": "SC"
    },
    {
        "name": "Brunópolis",
        "state": "SC"
    },
    {
        "name": "Brusque",
        "state": "SC"
    },
    {
        "name": "Caçador",
        "state": "SC"
    },
    {
        "name": "Caibi",
        "state": "SC"
    },
    {
        "name": "Calmon",
        "state": "SC"
    },
    {
        "name": "Camboriú",
        "state": "SC"
    },
    {
        "name": "Campo Alegre",
        "state": "SC"
    },
    {
        "name": "Campo Belo do Sul",
        "state": "SC"
    },
    {
        "name": "Campo Erê",
        "state": "SC"
    },
    {
        "name": "Campos Novos",
        "state": "SC"
    },
    {
        "name": "Canelinha",
        "state": "SC"
    },
    {
        "name": "Canoinhas",
        "state": "SC"
    },
    {
        "name": "Capão Alto",
        "state": "SC"
    },
    {
        "name": "Capinzal",
        "state": "SC"
    },
    {
        "name": "Capivari de Baixo",
        "state": "SC"
    },
    {
        "name": "Catanduvas",
        "state": "SC"
    },
    {
        "name": "Caxambu do Sul",
        "state": "SC"
    },
    {
        "name": "Celso Ramos",
        "state": "SC"
    },
    {
        "name": "Cerro Negro",
        "state": "SC"
    },
    {
        "name": "Chapadão do Lageado",
        "state": "SC"
    },
    {
        "name": "Chapecó",
        "state": "SC"
    },
    {
        "name": "Cocal do Sul",
        "state": "SC"
    },
    {
        "name": "Concórdia",
        "state": "SC"
    },
    {
        "name": "Cordilheira Alta",
        "state": "SC"
    },
    {
        "name": "Coronel Freitas",
        "state": "SC"
    },
    {
        "name": "Coronel Martins",
        "state": "SC"
    },
    {
        "name": "Correia Pinto",
        "state": "SC"
    },
    {
        "name": "Corupá",
        "state": "SC"
    },
    {
        "name": "Criciúma",
        "state": "SC"
    },
    {
        "name": "Cunha Porã",
        "state": "SC"
    },
    {
        "name": "Cunhataí",
        "state": "SC"
    },
    {
        "name": "Curitibanos",
        "state": "SC"
    },
    {
        "name": "Descanso",
        "state": "SC"
    },
    {
        "name": "Dionísio Cerqueira",
        "state": "SC"
    },
    {
        "name": "Dona Emma",
        "state": "SC"
    },
    {
        "name": "Doutor Pedrinho",
        "state": "SC"
    },
    {
        "name": "Entre Rios",
        "state": "SC"
    },
    {
        "name": "Ermo",
        "state": "SC"
    },
    {
        "name": "Erval Velho",
        "state": "SC"
    },
    {
        "name": "Faxinal dos Guedes",
        "state": "SC"
    },
    {
        "name": "Flor do Sertão",
        "state": "SC"
    },
    {
        "name": "Florianópolis",
        "state": "SC"
    },
    {
        "name": "Formosa do Sul",
        "state": "SC"
    },
    {
        "name": "Forquilhinha",
        "state": "SC"
    },
    {
        "name": "Fraiburgo",
        "state": "SC"
    },
    {
        "name": "Frei Rogério",
        "state": "SC"
    },
    {
        "name": "Galvão",
        "state": "SC"
    },
    {
        "name": "Garopaba",
        "state": "SC"
    },
    {
        "name": "Garuva",
        "state": "SC"
    },
    {
        "name": "Gaspar",
        "state": "SC"
    },
    {
        "name": "Governador Celso Ramos",
        "state": "SC"
    },
    {
        "name": "Grão Pará",
        "state": "SC"
    },
    {
        "name": "Gravatal",
        "state": "SC"
    },
    {
        "name": "Guabiruba",
        "state": "SC"
    },
    {
        "name": "Guaraciaba",
        "state": "SC"
    },
    {
        "name": "Guaramirim",
        "state": "SC"
    },
    {
        "name": "Guarujá do Sul",
        "state": "SC"
    },
    {
        "name": "Guatambú",
        "state": "SC"
    },
    {
        "name": "Herval d`Oeste",
        "state": "SC"
    },
    {
        "name": "Ibiam",
        "state": "SC"
    },
    {
        "name": "Ibicaré",
        "state": "SC"
    },
    {
        "name": "Ibirama",
        "state": "SC"
    },
    {
        "name": "Içara",
        "state": "SC"
    },
    {
        "name": "Ilhota",
        "state": "SC"
    },
    {
        "name": "Imaruí",
        "state": "SC"
    },
    {
        "name": "Imbituba",
        "state": "SC"
    },
    {
        "name": "Imbuia",
        "state": "SC"
    },
    {
        "name": "Indaial",
        "state": "SC"
    },
    {
        "name": "Iomerê",
        "state": "SC"
    },
    {
        "name": "Ipira",
        "state": "SC"
    },
    {
        "name": "Iporã do Oeste",
        "state": "SC"
    },
    {
        "name": "Ipuaçu",
        "state": "SC"
    },
    {
        "name": "Ipumirim",
        "state": "SC"
    },
    {
        "name": "Iraceminha",
        "state": "SC"
    },
    {
        "name": "Irani",
        "state": "SC"
    },
    {
        "name": "Irati",
        "state": "SC"
    },
    {
        "name": "Irineópolis",
        "state": "SC"
    },
    {
        "name": "Itá",
        "state": "SC"
    },
    {
        "name": "Itaiópolis",
        "state": "SC"
    },
    {
        "name": "Itajaí",
        "state": "SC"
    },
    {
        "name": "Itapema",
        "state": "SC"
    },
    {
        "name": "Itapiranga",
        "state": "SC"
    },
    {
        "name": "Itapoá",
        "state": "SC"
    },
    {
        "name": "Ituporanga",
        "state": "SC"
    },
    {
        "name": "Jaborá",
        "state": "SC"
    },
    {
        "name": "Jacinto Machado",
        "state": "SC"
    },
    {
        "name": "Jaguaruna",
        "state": "SC"
    },
    {
        "name": "Jaraguá do Sul",
        "state": "SC"
    },
    {
        "name": "Jardinópolis",
        "state": "SC"
    },
    {
        "name": "Joaçaba",
        "state": "SC"
    },
    {
        "name": "Joinville",
        "state": "SC"
    },
    {
        "name": "José Boiteux",
        "state": "SC"
    },
    {
        "name": "Jupiá",
        "state": "SC"
    },
    {
        "name": "Lacerdópolis",
        "state": "SC"
    },
    {
        "name": "Lages",
        "state": "SC"
    },
    {
        "name": "Laguna",
        "state": "SC"
    },
    {
        "name": "Lajeado Grande",
        "state": "SC"
    },
    {
        "name": "Laurentino",
        "state": "SC"
    },
    {
        "name": "Lauro Muller",
        "state": "SC"
    },
    {
        "name": "Lebon Régis",
        "state": "SC"
    },
    {
        "name": "Leoberto Leal",
        "state": "SC"
    },
    {
        "name": "Lindóia do Sul",
        "state": "SC"
    },
    {
        "name": "Lontras",
        "state": "SC"
    },
    {
        "name": "Luiz Alves",
        "state": "SC"
    },
    {
        "name": "Luzerna",
        "state": "SC"
    },
    {
        "name": "Macieira",
        "state": "SC"
    },
    {
        "name": "Mafra",
        "state": "SC"
    },
    {
        "name": "Major Gercino",
        "state": "SC"
    },
    {
        "name": "Major Vieira",
        "state": "SC"
    },
    {
        "name": "Maracajá",
        "state": "SC"
    },
    {
        "name": "Maravilha",
        "state": "SC"
    },
    {
        "name": "Marema",
        "state": "SC"
    },
    {
        "name": "Massaranduba",
        "state": "SC"
    },
    {
        "name": "Matos Costa",
        "state": "SC"
    },
    {
        "name": "Meleiro",
        "state": "SC"
    },
    {
        "name": "Mirim Doce",
        "state": "SC"
    },
    {
        "name": "Modelo",
        "state": "SC"
    },
    {
        "name": "Mondaí",
        "state": "SC"
    },
    {
        "name": "Monte Carlo",
        "state": "SC"
    },
    {
        "name": "Monte Castelo",
        "state": "SC"
    },
    {
        "name": "Morro da Fumaça",
        "state": "SC"
    },
    {
        "name": "Morro Grande",
        "state": "SC"
    },
    {
        "name": "Navegantes",
        "state": "SC"
    },
    {
        "name": "Nova Erechim",
        "state": "SC"
    },
    {
        "name": "Nova Itaberaba",
        "state": "SC"
    },
    {
        "name": "Nova Trento",
        "state": "SC"
    },
    {
        "name": "Nova Veneza",
        "state": "SC"
    },
    {
        "name": "Novo Horizonte",
        "state": "SC"
    },
    {
        "name": "Orleans",
        "state": "SC"
    },
    {
        "name": "Otacílio Costa",
        "state": "SC"
    },
    {
        "name": "Ouro",
        "state": "SC"
    },
    {
        "name": "Ouro Verde",
        "state": "SC"
    },
    {
        "name": "Paial",
        "state": "SC"
    },
    {
        "name": "Painel",
        "state": "SC"
    },
    {
        "name": "Palhoça",
        "state": "SC"
    },
    {
        "name": "Palma Sola",
        "state": "SC"
    },
    {
        "name": "Palmeira",
        "state": "SC"
    },
    {
        "name": "Palmitos",
        "state": "SC"
    },
    {
        "name": "Papanduva",
        "state": "SC"
    },
    {
        "name": "Paraíso",
        "state": "SC"
    },
    {
        "name": "Passo de Torres",
        "state": "SC"
    },
    {
        "name": "Passos Maia",
        "state": "SC"
    },
    {
        "name": "Paulo Lopes",
        "state": "SC"
    },
    {
        "name": "Pedras Grandes",
        "state": "SC"
    },
    {
        "name": "Penha",
        "state": "SC"
    },
    {
        "name": "Peritiba",
        "state": "SC"
    },
    {
        "name": "Petrolândia",
        "state": "SC"
    },
    {
        "name": "Piçarras",
        "state": "SC"
    },
    {
        "name": "Pinhalzinho",
        "state": "SC"
    },
    {
        "name": "Pinheiro Preto",
        "state": "SC"
    },
    {
        "name": "Piratuba",
        "state": "SC"
    },
    {
        "name": "Planalto Alegre",
        "state": "SC"
    },
    {
        "name": "Pomerode",
        "state": "SC"
    },
    {
        "name": "Ponte Alta",
        "state": "SC"
    },
    {
        "name": "Ponte Alta do Norte",
        "state": "SC"
    },
    {
        "name": "Ponte Serrada",
        "state": "SC"
    },
    {
        "name": "Porto Belo",
        "state": "SC"
    },
    {
        "name": "Porto União",
        "state": "SC"
    },
    {
        "name": "Pouso Redondo",
        "state": "SC"
    },
    {
        "name": "Praia Grande",
        "state": "SC"
    },
    {
        "name": "Presidente Castelo Branco",
        "state": "SC"
    },
    {
        "name": "Presidente Getúlio",
        "state": "SC"
    },
    {
        "name": "Presidente Nereu",
        "state": "SC"
    },
    {
        "name": "Princesa",
        "state": "SC"
    },
    {
        "name": "Quilombo",
        "state": "SC"
    },
    {
        "name": "Rancho Queimado",
        "state": "SC"
    },
    {
        "name": "Rio das Antas",
        "state": "SC"
    },
    {
        "name": "Rio do Campo",
        "state": "SC"
    },
    {
        "name": "Rio do Oeste",
        "state": "SC"
    },
    {
        "name": "Rio do Sul",
        "state": "SC"
    },
    {
        "name": "Rio dos Cedros",
        "state": "SC"
    },
    {
        "name": "Rio Fortuna",
        "state": "SC"
    },
    {
        "name": "Rio Negrinho",
        "state": "SC"
    },
    {
        "name": "Rio Rufino",
        "state": "SC"
    },
    {
        "name": "Riqueza",
        "state": "SC"
    },
    {
        "name": "Rodeio",
        "state": "SC"
    },
    {
        "name": "Romelândia",
        "state": "SC"
    },
    {
        "name": "Salete",
        "state": "SC"
    },
    {
        "name": "Saltinho",
        "state": "SC"
    },
    {
        "name": "Salto Veloso",
        "state": "SC"
    },
    {
        "name": "Sangão",
        "state": "SC"
    },
    {
        "name": "Santa Cecília",
        "state": "SC"
    },
    {
        "name": "Santa Helena",
        "state": "SC"
    },
    {
        "name": "Santa Rosa de Lima",
        "state": "SC"
    },
    {
        "name": "Santa Rosa do Sul",
        "state": "SC"
    },
    {
        "name": "Santa Terezinha",
        "state": "SC"
    },
    {
        "name": "Santa Terezinha do Progresso",
        "state": "SC"
    },
    {
        "name": "Santiago do Sul",
        "state": "SC"
    },
    {
        "name": "Santo Amaro da Imperatriz",
        "state": "SC"
    },
    {
        "name": "São Bento do Sul",
        "state": "SC"
    },
    {
        "name": "São Bernardino",
        "state": "SC"
    },
    {
        "name": "São Bonifácio",
        "state": "SC"
    },
    {
        "name": "São Carlos",
        "state": "SC"
    },
    {
        "name": "São Cristovão do Sul",
        "state": "SC"
    },
    {
        "name": "São Domingos",
        "state": "SC"
    },
    {
        "name": "São Francisco do Sul",
        "state": "SC"
    },
    {
        "name": "São João Batista",
        "state": "SC"
    },
    {
        "name": "São João do Itaperiú",
        "state": "SC"
    },
    {
        "name": "São João do Oeste",
        "state": "SC"
    },
    {
        "name": "São João do Sul",
        "state": "SC"
    },
    {
        "name": "São Joaquim",
        "state": "SC"
    },
    {
        "name": "São José",
        "state": "SC"
    },
    {
        "name": "São José do Cedro",
        "state": "SC"
    },
    {
        "name": "São José do Cerrito",
        "state": "SC"
    },
    {
        "name": "São Lourenço do Oeste",
        "state": "SC"
    },
    {
        "name": "São Ludgero",
        "state": "SC"
    },
    {
        "name": "São Martinho",
        "state": "SC"
    },
    {
        "name": "São Miguel da Boa Vista",
        "state": "SC"
    },
    {
        "name": "São Miguel do Oeste",
        "state": "SC"
    },
    {
        "name": "São Pedro de Alcântara",
        "state": "SC"
    },
    {
        "name": "Saudades",
        "state": "SC"
    },
    {
        "name": "Schroeder",
        "state": "SC"
    },
    {
        "name": "Seara",
        "state": "SC"
    },
    {
        "name": "Serra Alta",
        "state": "SC"
    },
    {
        "name": "Siderópolis",
        "state": "SC"
    },
    {
        "name": "Sombrio",
        "state": "SC"
    },
    {
        "name": "Sul Brasil",
        "state": "SC"
    },
    {
        "name": "Taió",
        "state": "SC"
    },
    {
        "name": "Tangará",
        "state": "SC"
    },
    {
        "name": "Tigrinhos",
        "state": "SC"
    },
    {
        "name": "Tijucas",
        "state": "SC"
    },
    {
        "name": "Timbé do Sul",
        "state": "SC"
    },
    {
        "name": "Timbó",
        "state": "SC"
    },
    {
        "name": "Timbó Grande",
        "state": "SC"
    },
    {
        "name": "Três Barras",
        "state": "SC"
    },
    {
        "name": "Treviso",
        "state": "SC"
    },
    {
        "name": "Treze de Maio",
        "state": "SC"
    },
    {
        "name": "Treze Tílias",
        "state": "SC"
    },
    {
        "name": "Trombudo Central",
        "state": "SC"
    },
    {
        "name": "Tubarão",
        "state": "SC"
    },
    {
        "name": "Tunápolis",
        "state": "SC"
    },
    {
        "name": "Turvo",
        "state": "SC"
    },
    {
        "name": "União do Oeste",
        "state": "SC"
    },
    {
        "name": "Urubici",
        "state": "SC"
    },
    {
        "name": "Urupema",
        "state": "SC"
    },
    {
        "name": "Urussanga",
        "state": "SC"
    },
    {
        "name": "Vargeão",
        "state": "SC"
    },
    {
        "name": "Vargem",
        "state": "SC"
    },
    {
        "name": "Vargem Bonita",
        "state": "SC"
    },
    {
        "name": "Vidal Ramos",
        "state": "SC"
    },
    {
        "name": "Videira",
        "state": "SC"
    },
    {
        "name": "Vitor Meireles",
        "state": "SC"
    },
    {
        "name": "Witmarsum",
        "state": "SC"
    },
    {
        "name": "Xanxerê",
        "state": "SC"
    },
    {
        "name": "Xavantina",
        "state": "SC"
    },
    {
        "name": "Xaxim",
        "state": "SC"
    },
    {
        "name": "Zortéa",
        "state": "SC"
    },
    {
        "name": "Adamantina",
        "state": "SP"
    },
    {
        "name": "Adolfo",
        "state": "SP"
    },
    {
        "name": "Aguaí",
        "state": "SP"
    },
    {
        "name": "Águas da Prata",
        "state": "SP"
    },
    {
        "name": "Águas de Lindóia",
        "state": "SP"
    },
    {
        "name": "Águas de Santa Bárbara",
        "state": "SP"
    },
    {
        "name": "Águas de São Pedro",
        "state": "SP"
    },
    {
        "name": "Agudos",
        "state": "SP"
    },
    {
        "name": "Alambari",
        "state": "SP"
    },
    {
        "name": "Alfredo Marcondes",
        "state": "SP"
    },
    {
        "name": "Altair",
        "state": "SP"
    },
    {
        "name": "Altinópolis",
        "state": "SP"
    },
    {
        "name": "Alto Alegre",
        "state": "SP"
    },
    {
        "name": "Alumínio",
        "state": "SP"
    },
    {
        "name": "Álvares Florence",
        "state": "SP"
    },
    {
        "name": "Álvares Machado",
        "state": "SP"
    },
    {
        "name": "Álvaro de Carvalho",
        "state": "SP"
    },
    {
        "name": "Alvinlândia",
        "state": "SP"
    },
    {
        "name": "Americana",
        "state": "SP"
    },
    {
        "name": "Américo Brasiliense",
        "state": "SP"
    },
    {
        "name": "Américo de Campos",
        "state": "SP"
    },
    {
        "name": "Amparo",
        "state": "SP"
    },
    {
        "name": "Analândia",
        "state": "SP"
    },
    {
        "name": "Andradina",
        "state": "SP"
    },
    {
        "name": "Angatuba",
        "state": "SP"
    },
    {
        "name": "Anhembi",
        "state": "SP"
    },
    {
        "name": "Anhumas",
        "state": "SP"
    },
    {
        "name": "Aparecida",
        "state": "SP"
    },
    {
        "name": "Aparecida d`Oeste",
        "state": "SP"
    },
    {
        "name": "Apiaí",
        "state": "SP"
    },
    {
        "name": "Araçariguama",
        "state": "SP"
    },
    {
        "name": "Araçatuba",
        "state": "SP"
    },
    {
        "name": "Araçoiaba da Serra",
        "state": "SP"
    },
    {
        "name": "Aramina",
        "state": "SP"
    },
    {
        "name": "Arandu",
        "state": "SP"
    },
    {
        "name": "Arapeí",
        "state": "SP"
    },
    {
        "name": "Araraquara",
        "state": "SP"
    },
    {
        "name": "Araras",
        "state": "SP"
    },
    {
        "name": "Arco-Íris",
        "state": "SP"
    },
    {
        "name": "Arealva",
        "state": "SP"
    },
    {
        "name": "Areias",
        "state": "SP"
    },
    {
        "name": "Areiópolis",
        "state": "SP"
    },
    {
        "name": "Ariranha",
        "state": "SP"
    },
    {
        "name": "Artur Nogueira",
        "state": "SP"
    },
    {
        "name": "Arujá",
        "state": "SP"
    },
    {
        "name": "Aspásia",
        "state": "SP"
    },
    {
        "name": "Assis",
        "state": "SP"
    },
    {
        "name": "Atibaia",
        "state": "SP"
    },
    {
        "name": "Auriflama",
        "state": "SP"
    },
    {
        "name": "Avaí",
        "state": "SP"
    },
    {
        "name": "Avanhandava",
        "state": "SP"
    },
    {
        "name": "Avaré",
        "state": "SP"
    },
    {
        "name": "Bady Bassitt",
        "state": "SP"
    },
    {
        "name": "Balbinos",
        "state": "SP"
    },
    {
        "name": "Bálsamo",
        "state": "SP"
    },
    {
        "name": "Bananal",
        "state": "SP"
    },
    {
        "name": "Barão de Antonina",
        "state": "SP"
    },
    {
        "name": "Barbosa",
        "state": "SP"
    },
    {
        "name": "Bariri",
        "state": "SP"
    },
    {
        "name": "Barra Bonita",
        "state": "SP"
    },
    {
        "name": "Barra do Chapéu",
        "state": "SP"
    },
    {
        "name": "Barra do Turvo",
        "state": "SP"
    },
    {
        "name": "Barretos",
        "state": "SP"
    },
    {
        "name": "Barrinha",
        "state": "SP"
    },
    {
        "name": "Barueri",
        "state": "SP"
    },
    {
        "name": "Bastos",
        "state": "SP"
    },
    {
        "name": "Batatais",
        "state": "SP"
    },
    {
        "name": "Bauru",
        "state": "SP"
    },
    {
        "name": "Bebedouro",
        "state": "SP"
    },
    {
        "name": "Bento de Abreu",
        "state": "SP"
    },
    {
        "name": "Bernardino de Campos",
        "state": "SP"
    },
    {
        "name": "Bertioga",
        "state": "SP"
    },
    {
        "name": "Bilac",
        "state": "SP"
    },
    {
        "name": "Birigui",
        "state": "SP"
    },
    {
        "name": "Biritiba-Mirim",
        "state": "SP"
    },
    {
        "name": "Boa Esperança do Sul",
        "state": "SP"
    },
    {
        "name": "Bocaina",
        "state": "SP"
    },
    {
        "name": "Bofete",
        "state": "SP"
    },
    {
        "name": "Boituva",
        "state": "SP"
    },
    {
        "name": "Bom Jesus dos Perdões",
        "state": "SP"
    },
    {
        "name": "Bom Sucesso de Itararé",
        "state": "SP"
    },
    {
        "name": "Borá",
        "state": "SP"
    },
    {
        "name": "Boracéia",
        "state": "SP"
    },
    {
        "name": "Borborema",
        "state": "SP"
    },
    {
        "name": "Borebi",
        "state": "SP"
    },
    {
        "name": "Botucatu",
        "state": "SP"
    },
    {
        "name": "Bragança Paulista",
        "state": "SP"
    },
    {
        "name": "Braúna",
        "state": "SP"
    },
    {
        "name": "Brejo Alegre",
        "state": "SP"
    },
    {
        "name": "Brodowski",
        "state": "SP"
    },
    {
        "name": "Brotas",
        "state": "SP"
    },
    {
        "name": "Buri",
        "state": "SP"
    },
    {
        "name": "Buritama",
        "state": "SP"
    },
    {
        "name": "Buritizal",
        "state": "SP"
    },
    {
        "name": "Cabrália Paulista",
        "state": "SP"
    },
    {
        "name": "Cabreúva",
        "state": "SP"
    },
    {
        "name": "Caçapava",
        "state": "SP"
    },
    {
        "name": "Cachoeira Paulista",
        "state": "SP"
    },
    {
        "name": "Caconde",
        "state": "SP"
    },
    {
        "name": "Cafelândia",
        "state": "SP"
    },
    {
        "name": "Caiabu",
        "state": "SP"
    },
    {
        "name": "Caieiras",
        "state": "SP"
    },
    {
        "name": "Caiuá",
        "state": "SP"
    },
    {
        "name": "Cajamar",
        "state": "SP"
    },
    {
        "name": "Cajati",
        "state": "SP"
    },
    {
        "name": "Cajobi",
        "state": "SP"
    },
    {
        "name": "Cajuru",
        "state": "SP"
    },
    {
        "name": "Campina do Monte Alegre",
        "state": "SP"
    },
    {
        "name": "Campinas",
        "state": "SP"
    },
    {
        "name": "Campo Limpo Paulista",
        "state": "SP"
    },
    {
        "name": "Campos do Jordão",
        "state": "SP"
    },
    {
        "name": "Campos Novos Paulista",
        "state": "SP"
    },
    {
        "name": "Cananéia",
        "state": "SP"
    },
    {
        "name": "Canas",
        "state": "SP"
    },
    {
        "name": "Cândido Mota",
        "state": "SP"
    },
    {
        "name": "Cândido Rodrigues",
        "state": "SP"
    },
    {
        "name": "Canitar",
        "state": "SP"
    },
    {
        "name": "Capão Bonito",
        "state": "SP"
    },
    {
        "name": "Capela do Alto",
        "state": "SP"
    },
    {
        "name": "Capivari",
        "state": "SP"
    },
    {
        "name": "Caraguatatuba",
        "state": "SP"
    },
    {
        "name": "Carapicuíba",
        "state": "SP"
    },
    {
        "name": "Cardoso",
        "state": "SP"
    },
    {
        "name": "Casa Branca",
        "state": "SP"
    },
    {
        "name": "Cássia dos Coqueiros",
        "state": "SP"
    },
    {
        "name": "Castilho",
        "state": "SP"
    },
    {
        "name": "Catanduva",
        "state": "SP"
    },
    {
        "name": "Catiguá",
        "state": "SP"
    },
    {
        "name": "Cedral",
        "state": "SP"
    },
    {
        "name": "Cerqueira César",
        "state": "SP"
    },
    {
        "name": "Cerquilho",
        "state": "SP"
    },
    {
        "name": "Cesário Lange",
        "state": "SP"
    },
    {
        "name": "Charqueada",
        "state": "SP"
    },
    {
        "name": "Chavantes",
        "state": "SP"
    },
    {
        "name": "Clementina",
        "state": "SP"
    },
    {
        "name": "Colina",
        "state": "SP"
    },
    {
        "name": "Colômbia",
        "state": "SP"
    },
    {
        "name": "Conchal",
        "state": "SP"
    },
    {
        "name": "Conchas",
        "state": "SP"
    },
    {
        "name": "Cordeirópolis",
        "state": "SP"
    },
    {
        "name": "Coroados",
        "state": "SP"
    },
    {
        "name": "Coronel Macedo",
        "state": "SP"
    },
    {
        "name": "Corumbataí",
        "state": "SP"
    },
    {
        "name": "Cosmópolis",
        "state": "SP"
    },
    {
        "name": "Cosmorama",
        "state": "SP"
    },
    {
        "name": "Cotia",
        "state": "SP"
    },
    {
        "name": "Cravinhos",
        "state": "SP"
    },
    {
        "name": "Cristais Paulista",
        "state": "SP"
    },
    {
        "name": "Cruzália",
        "state": "SP"
    },
    {
        "name": "Cruzeiro",
        "state": "SP"
    },
    {
        "name": "Cubatão",
        "state": "SP"
    },
    {
        "name": "Cunha",
        "state": "SP"
    },
    {
        "name": "Descalvado",
        "state": "SP"
    },
    {
        "name": "Diadema",
        "state": "SP"
    },
    {
        "name": "Dirce Reis",
        "state": "SP"
    },
    {
        "name": "Divinolândia",
        "state": "SP"
    },
    {
        "name": "Dobrada",
        "state": "SP"
    },
    {
        "name": "Dois Córregos",
        "state": "SP"
    },
    {
        "name": "Dolcinópolis",
        "state": "SP"
    },
    {
        "name": "Dourado",
        "state": "SP"
    },
    {
        "name": "Dracena",
        "state": "SP"
    },
    {
        "name": "Duartina",
        "state": "SP"
    },
    {
        "name": "Dumont",
        "state": "SP"
    },
    {
        "name": "Echaporã",
        "state": "SP"
    },
    {
        "name": "Eldorado",
        "state": "SP"
    },
    {
        "name": "Elias Fausto",
        "state": "SP"
    },
    {
        "name": "Elisiário",
        "state": "SP"
    },
    {
        "name": "Embaúba",
        "state": "SP"
    },
    {
        "name": "Embu",
        "state": "SP"
    },
    {
        "name": "Embu-Guaçu",
        "state": "SP"
    },
    {
        "name": "Emilianópolis",
        "state": "SP"
    },
    {
        "name": "Engenheiro Coelho",
        "state": "SP"
    },
    {
        "name": "Espírito Santo do Pinhal",
        "state": "SP"
    },
    {
        "name": "Espírito Santo do Turvo",
        "state": "SP"
    },
    {
        "name": "Estiva Gerbi",
        "state": "SP"
    },
    {
        "name": "Estrela d`Oeste",
        "state": "SP"
    },
    {
        "name": "Estrela do Norte",
        "state": "SP"
    },
    {
        "name": "Euclides da Cunha Paulista",
        "state": "SP"
    },
    {
        "name": "Fartura",
        "state": "SP"
    },
    {
        "name": "Fernando Prestes",
        "state": "SP"
    },
    {
        "name": "Fernandópolis",
        "state": "SP"
    },
    {
        "name": "Fernão",
        "state": "SP"
    },
    {
        "name": "Ferraz de Vasconcelos",
        "state": "SP"
    },
    {
        "name": "Flora Rica",
        "state": "SP"
    },
    {
        "name": "Floreal",
        "state": "SP"
    },
    {
        "name": "Flórida Paulista",
        "state": "SP"
    },
    {
        "name": "Florínia",
        "state": "SP"
    },
    {
        "name": "Franca",
        "state": "SP"
    },
    {
        "name": "Francisco Morato",
        "state": "SP"
    },
    {
        "name": "Franco da Rocha",
        "state": "SP"
    },
    {
        "name": "Gabriel Monteiro",
        "state": "SP"
    },
    {
        "name": "Gália",
        "state": "SP"
    },
    {
        "name": "Garça",
        "state": "SP"
    },
    {
        "name": "Gastão Vidigal",
        "state": "SP"
    },
    {
        "name": "Gavião Peixoto",
        "state": "SP"
    },
    {
        "name": "General Salgado",
        "state": "SP"
    },
    {
        "name": "Getulina",
        "state": "SP"
    },
    {
        "name": "Glicério",
        "state": "SP"
    },
    {
        "name": "Guaiçara",
        "state": "SP"
    },
    {
        "name": "Guaimbê",
        "state": "SP"
    },
    {
        "name": "Guaíra",
        "state": "SP"
    },
    {
        "name": "Guapiaçu",
        "state": "SP"
    },
    {
        "name": "Guapiara",
        "state": "SP"
    },
    {
        "name": "Guará",
        "state": "SP"
    },
    {
        "name": "Guaraçaí",
        "state": "SP"
    },
    {
        "name": "Guaraci",
        "state": "SP"
    },
    {
        "name": "Guarani d`Oeste",
        "state": "SP"
    },
    {
        "name": "Guarantã",
        "state": "SP"
    },
    {
        "name": "Guararapes",
        "state": "SP"
    },
    {
        "name": "Guararema",
        "state": "SP"
    },
    {
        "name": "Guaratinguetá",
        "state": "SP"
    },
    {
        "name": "Guareí",
        "state": "SP"
    },
    {
        "name": "Guariba",
        "state": "SP"
    },
    {
        "name": "Guarujá",
        "state": "SP"
    },
    {
        "name": "Guarulhos",
        "state": "SP"
    },
    {
        "name": "Guatapará",
        "state": "SP"
    },
    {
        "name": "Guzolândia",
        "state": "SP"
    },
    {
        "name": "Herculândia",
        "state": "SP"
    },
    {
        "name": "Holambra",
        "state": "SP"
    },
    {
        "name": "Hortolândia",
        "state": "SP"
    },
    {
        "name": "Iacanga",
        "state": "SP"
    },
    {
        "name": "Iacri",
        "state": "SP"
    },
    {
        "name": "Iaras",
        "state": "SP"
    },
    {
        "name": "Ibaté",
        "state": "SP"
    },
    {
        "name": "Ibirá",
        "state": "SP"
    },
    {
        "name": "Ibirarema",
        "state": "SP"
    },
    {
        "name": "Ibitinga",
        "state": "SP"
    },
    {
        "name": "Ibiúna",
        "state": "SP"
    },
    {
        "name": "Icém",
        "state": "SP"
    },
    {
        "name": "Iepê",
        "state": "SP"
    },
    {
        "name": "Igaraçu do Tietê",
        "state": "SP"
    },
    {
        "name": "Igarapava",
        "state": "SP"
    },
    {
        "name": "Igaratá",
        "state": "SP"
    },
    {
        "name": "Iguape",
        "state": "SP"
    },
    {
        "name": "Ilha Comprida",
        "state": "SP"
    },
    {
        "name": "Ilha Solteira",
        "state": "SP"
    },
    {
        "name": "Ilhabela",
        "state": "SP"
    },
    {
        "name": "Indaiatuba",
        "state": "SP"
    },
    {
        "name": "Indiana",
        "state": "SP"
    },
    {
        "name": "Indiaporã",
        "state": "SP"
    },
    {
        "name": "Inúbia Paulista",
        "state": "SP"
    },
    {
        "name": "Ipaussu",
        "state": "SP"
    },
    {
        "name": "Iperó",
        "state": "SP"
    },
    {
        "name": "Ipeúna",
        "state": "SP"
    },
    {
        "name": "Ipiguá",
        "state": "SP"
    },
    {
        "name": "Iporanga",
        "state": "SP"
    },
    {
        "name": "Ipuã",
        "state": "SP"
    },
    {
        "name": "Iracemápolis",
        "state": "SP"
    },
    {
        "name": "Irapuã",
        "state": "SP"
    },
    {
        "name": "Irapuru",
        "state": "SP"
    },
    {
        "name": "Itaberá",
        "state": "SP"
    },
    {
        "name": "Itaí",
        "state": "SP"
    },
    {
        "name": "Itajobi",
        "state": "SP"
    },
    {
        "name": "Itaju",
        "state": "SP"
    },
    {
        "name": "Itanhaém",
        "state": "SP"
    },
    {
        "name": "Itaóca",
        "state": "SP"
    },
    {
        "name": "Itapecerica da Serra",
        "state": "SP"
    },
    {
        "name": "Itapetininga",
        "state": "SP"
    },
    {
        "name": "Itapeva",
        "state": "SP"
    },
    {
        "name": "Itapevi",
        "state": "SP"
    },
    {
        "name": "Itapira",
        "state": "SP"
    },
    {
        "name": "Itapirapuã Paulista",
        "state": "SP"
    },
    {
        "name": "Itápolis",
        "state": "SP"
    },
    {
        "name": "Itaporanga",
        "state": "SP"
    },
    {
        "name": "Itapuí",
        "state": "SP"
    },
    {
        "name": "Itapura",
        "state": "SP"
    },
    {
        "name": "Itaquaquecetuba",
        "state": "SP"
    },
    {
        "name": "Itararé",
        "state": "SP"
    },
    {
        "name": "Itariri",
        "state": "SP"
    },
    {
        "name": "Itatiba",
        "state": "SP"
    },
    {
        "name": "Itatinga",
        "state": "SP"
    },
    {
        "name": "Itirapina",
        "state": "SP"
    },
    {
        "name": "Itirapuã",
        "state": "SP"
    },
    {
        "name": "Itobi",
        "state": "SP"
    },
    {
        "name": "Itu",
        "state": "SP"
    },
    {
        "name": "Itupeva",
        "state": "SP"
    },
    {
        "name": "Ituverava",
        "state": "SP"
    },
    {
        "name": "Jaborandi",
        "state": "SP"
    },
    {
        "name": "Jaboticabal",
        "state": "SP"
    },
    {
        "name": "Jacareí",
        "state": "SP"
    },
    {
        "name": "Jaci",
        "state": "SP"
    },
    {
        "name": "Jacupiranga",
        "state": "SP"
    },
    {
        "name": "Jaguariúna",
        "state": "SP"
    },
    {
        "name": "Jales",
        "state": "SP"
    },
    {
        "name": "Jambeiro",
        "state": "SP"
    },
    {
        "name": "Jandira",
        "state": "SP"
    },
    {
        "name": "Jardinópolis",
        "state": "SP"
    },
    {
        "name": "Jarinu",
        "state": "SP"
    },
    {
        "name": "Jaú",
        "state": "SP"
    },
    {
        "name": "Jeriquara",
        "state": "SP"
    },
    {
        "name": "Joanópolis",
        "state": "SP"
    },
    {
        "name": "João Ramalho",
        "state": "SP"
    },
    {
        "name": "José Bonifácio",
        "state": "SP"
    },
    {
        "name": "Júlio Mesquita",
        "state": "SP"
    },
    {
        "name": "Jumirim",
        "state": "SP"
    },
    {
        "name": "Jundiaí",
        "state": "SP"
    },
    {
        "name": "Junqueirópolis",
        "state": "SP"
    },
    {
        "name": "Juquiá",
        "state": "SP"
    },
    {
        "name": "Juquitiba",
        "state": "SP"
    },
    {
        "name": "Lagoinha",
        "state": "SP"
    },
    {
        "name": "Laranjal Paulista",
        "state": "SP"
    },
    {
        "name": "Lavínia",
        "state": "SP"
    },
    {
        "name": "Lavrinhas",
        "state": "SP"
    },
    {
        "name": "Leme",
        "state": "SP"
    },
    {
        "name": "Lençóis Paulista",
        "state": "SP"
    },
    {
        "name": "Limeira",
        "state": "SP"
    },
    {
        "name": "Lindóia",
        "state": "SP"
    },
    {
        "name": "Lins",
        "state": "SP"
    },
    {
        "name": "Lorena",
        "state": "SP"
    },
    {
        "name": "Lourdes",
        "state": "SP"
    },
    {
        "name": "Louveira",
        "state": "SP"
    },
    {
        "name": "Lucélia",
        "state": "SP"
    },
    {
        "name": "Lucianópolis",
        "state": "SP"
    },
    {
        "name": "Luís Antônio",
        "state": "SP"
    },
    {
        "name": "Luiziânia",
        "state": "SP"
    },
    {
        "name": "Lupércio",
        "state": "SP"
    },
    {
        "name": "Lutécia",
        "state": "SP"
    },
    {
        "name": "Macatuba",
        "state": "SP"
    },
    {
        "name": "Macaubal",
        "state": "SP"
    },
    {
        "name": "Macedônia",
        "state": "SP"
    },
    {
        "name": "Magda",
        "state": "SP"
    },
    {
        "name": "Mairinque",
        "state": "SP"
    },
    {
        "name": "Mairiporã",
        "state": "SP"
    },
    {
        "name": "Manduri",
        "state": "SP"
    },
    {
        "name": "Marabá Paulista",
        "state": "SP"
    },
    {
        "name": "Maracaí",
        "state": "SP"
    },
    {
        "name": "Marapoama",
        "state": "SP"
    },
    {
        "name": "Mariápolis",
        "state": "SP"
    },
    {
        "name": "Marília",
        "state": "SP"
    },
    {
        "name": "Marinópolis",
        "state": "SP"
    },
    {
        "name": "Martinópolis",
        "state": "SP"
    },
    {
        "name": "Matão",
        "state": "SP"
    },
    {
        "name": "Mauá",
        "state": "SP"
    },
    {
        "name": "Mendonça",
        "state": "SP"
    },
    {
        "name": "Meridiano",
        "state": "SP"
    },
    {
        "name": "Mesópolis",
        "state": "SP"
    },
    {
        "name": "Miguelópolis",
        "state": "SP"
    },
    {
        "name": "Mineiros do Tietê",
        "state": "SP"
    },
    {
        "name": "Mira Estrela",
        "state": "SP"
    },
    {
        "name": "Miracatu",
        "state": "SP"
    },
    {
        "name": "Mirandópolis",
        "state": "SP"
    },
    {
        "name": "Mirante do Paranapanema",
        "state": "SP"
    },
    {
        "name": "Mirassol",
        "state": "SP"
    },
    {
        "name": "Mirassolândia",
        "state": "SP"
    },
    {
        "name": "Mococa",
        "state": "SP"
    },
    {
        "name": "Mogi das Cruzes",
        "state": "SP"
    },
    {
        "name": "Mogi Guaçu",
        "state": "SP"
    },
    {
        "name": "Moji Mirim",
        "state": "SP"
    },
    {
        "name": "Mombuca",
        "state": "SP"
    },
    {
        "name": "Monções",
        "state": "SP"
    },
    {
        "name": "Mongaguá",
        "state": "SP"
    },
    {
        "name": "Monte Alegre do Sul",
        "state": "SP"
    },
    {
        "name": "Monte Alto",
        "state": "SP"
    },
    {
        "name": "Monte Aprazível",
        "state": "SP"
    },
    {
        "name": "Monte Azul Paulista",
        "state": "SP"
    },
    {
        "name": "Monte Castelo",
        "state": "SP"
    },
    {
        "name": "Monte Mor",
        "state": "SP"
    },
    {
        "name": "Monteiro Lobato",
        "state": "SP"
    },
    {
        "name": "Morro Agudo",
        "state": "SP"
    },
    {
        "name": "Morungaba",
        "state": "SP"
    },
    {
        "name": "Motuca",
        "state": "SP"
    },
    {
        "name": "Murutinga do Sul",
        "state": "SP"
    },
    {
        "name": "Nantes",
        "state": "SP"
    },
    {
        "name": "Narandiba",
        "state": "SP"
    },
    {
        "name": "Natividade da Serra",
        "state": "SP"
    },
    {
        "name": "Nazaré Paulista",
        "state": "SP"
    },
    {
        "name": "Neves Paulista",
        "state": "SP"
    },
    {
        "name": "Nhandeara",
        "state": "SP"
    },
    {
        "name": "Nipoã",
        "state": "SP"
    },
    {
        "name": "Nova Aliança",
        "state": "SP"
    },
    {
        "name": "Nova Campina",
        "state": "SP"
    },
    {
        "name": "Nova Canaã Paulista",
        "state": "SP"
    },
    {
        "name": "Nova Castilho",
        "state": "SP"
    },
    {
        "name": "Nova Europa",
        "state": "SP"
    },
    {
        "name": "Nova Granada",
        "state": "SP"
    },
    {
        "name": "Nova Guataporanga",
        "state": "SP"
    },
    {
        "name": "Nova Independência",
        "state": "SP"
    },
    {
        "name": "Nova Luzitânia",
        "state": "SP"
    },
    {
        "name": "Nova Odessa",
        "state": "SP"
    },
    {
        "name": "Novais",
        "state": "SP"
    },
    {
        "name": "Novo Horizonte",
        "state": "SP"
    },
    {
        "name": "Nuporanga",
        "state": "SP"
    },
    {
        "name": "Ocauçu",
        "state": "SP"
    },
    {
        "name": "Óleo",
        "state": "SP"
    },
    {
        "name": "Olímpia",
        "state": "SP"
    },
    {
        "name": "Onda Verde",
        "state": "SP"
    },
    {
        "name": "Oriente",
        "state": "SP"
    },
    {
        "name": "Orindiúva",
        "state": "SP"
    },
    {
        "name": "Orlândia",
        "state": "SP"
    },
    {
        "name": "Osasco",
        "state": "SP"
    },
    {
        "name": "Oscar Bressane",
        "state": "SP"
    },
    {
        "name": "Osvaldo Cruz",
        "state": "SP"
    },
    {
        "name": "Ourinhos",
        "state": "SP"
    },
    {
        "name": "Ouro Verde",
        "state": "SP"
    },
    {
        "name": "Ouroeste",
        "state": "SP"
    },
    {
        "name": "Pacaembu",
        "state": "SP"
    },
    {
        "name": "Palestina",
        "state": "SP"
    },
    {
        "name": "Palmares Paulista",
        "state": "SP"
    },
    {
        "name": "Palmeira d`Oeste",
        "state": "SP"
    },
    {
        "name": "Palmital",
        "state": "SP"
    },
    {
        "name": "Panorama",
        "state": "SP"
    },
    {
        "name": "Paraguaçu Paulista",
        "state": "SP"
    },
    {
        "name": "Paraibuna",
        "state": "SP"
    },
    {
        "name": "Paraíso",
        "state": "SP"
    },
    {
        "name": "Paranapanema",
        "state": "SP"
    },
    {
        "name": "Paranapuã",
        "state": "SP"
    },
    {
        "name": "Parapuã",
        "state": "SP"
    },
    {
        "name": "Pardinho",
        "state": "SP"
    },
    {
        "name": "Pariquera-Açu",
        "state": "SP"
    },
    {
        "name": "Parisi",
        "state": "SP"
    },
    {
        "name": "Patrocínio Paulista",
        "state": "SP"
    },
    {
        "name": "Paulicéia",
        "state": "SP"
    },
    {
        "name": "Paulínia",
        "state": "SP"
    },
    {
        "name": "Paulistânia",
        "state": "SP"
    },
    {
        "name": "Paulo de Faria",
        "state": "SP"
    },
    {
        "name": "Pederneiras",
        "state": "SP"
    },
    {
        "name": "Pedra Bela",
        "state": "SP"
    },
    {
        "name": "Pedranópolis",
        "state": "SP"
    },
    {
        "name": "Pedregulho",
        "state": "SP"
    },
    {
        "name": "Pedreira",
        "state": "SP"
    },
    {
        "name": "Pedrinhas Paulista",
        "state": "SP"
    },
    {
        "name": "Pedro de Toledo",
        "state": "SP"
    },
    {
        "name": "Penápolis",
        "state": "SP"
    },
    {
        "name": "Pereira Barreto",
        "state": "SP"
    },
    {
        "name": "Pereiras",
        "state": "SP"
    },
    {
        "name": "Peruíbe",
        "state": "SP"
    },
    {
        "name": "Piacatu",
        "state": "SP"
    },
    {
        "name": "Piedade",
        "state": "SP"
    },
    {
        "name": "Pilar do Sul",
        "state": "SP"
    },
    {
        "name": "Pindamonhangaba",
        "state": "SP"
    },
    {
        "name": "Pindorama",
        "state": "SP"
    },
    {
        "name": "Pinhalzinho",
        "state": "SP"
    },
    {
        "name": "Piquerobi",
        "state": "SP"
    },
    {
        "name": "Piquete",
        "state": "SP"
    },
    {
        "name": "Piracaia",
        "state": "SP"
    },
    {
        "name": "Piracicaba",
        "state": "SP"
    },
    {
        "name": "Piraju",
        "state": "SP"
    },
    {
        "name": "Pirajuí",
        "state": "SP"
    },
    {
        "name": "Pirangi",
        "state": "SP"
    },
    {
        "name": "Pirapora do Bom Jesus",
        "state": "SP"
    },
    {
        "name": "Pirapozinho",
        "state": "SP"
    },
    {
        "name": "Pirassununga",
        "state": "SP"
    },
    {
        "name": "Piratininga",
        "state": "SP"
    },
    {
        "name": "Pitangueiras",
        "state": "SP"
    },
    {
        "name": "Planalto",
        "state": "SP"
    },
    {
        "name": "Platina",
        "state": "SP"
    },
    {
        "name": "Poá",
        "state": "SP"
    },
    {
        "name": "Poloni",
        "state": "SP"
    },
    {
        "name": "Pompéia",
        "state": "SP"
    },
    {
        "name": "Pongaí",
        "state": "SP"
    },
    {
        "name": "Pontal",
        "state": "SP"
    },
    {
        "name": "Pontalinda",
        "state": "SP"
    },
    {
        "name": "Pontes Gestal",
        "state": "SP"
    },
    {
        "name": "Populina",
        "state": "SP"
    },
    {
        "name": "Porangaba",
        "state": "SP"
    },
    {
        "name": "Porto Feliz",
        "state": "SP"
    },
    {
        "name": "Porto Ferreira",
        "state": "SP"
    },
    {
        "name": "Potim",
        "state": "SP"
    },
    {
        "name": "Potirendaba",
        "state": "SP"
    },
    {
        "name": "Pracinha",
        "state": "SP"
    },
    {
        "name": "Pradópolis",
        "state": "SP"
    },
    {
        "name": "Praia Grande",
        "state": "SP"
    },
    {
        "name": "Pratânia",
        "state": "SP"
    },
    {
        "name": "Presidente Alves",
        "state": "SP"
    },
    {
        "name": "Presidente Bernardes",
        "state": "SP"
    },
    {
        "name": "Presidente Epitácio",
        "state": "SP"
    },
    {
        "name": "Presidente Prudente",
        "state": "SP"
    },
    {
        "name": "Presidente Venceslau",
        "state": "SP"
    },
    {
        "name": "Promissão",
        "state": "SP"
    },
    {
        "name": "Quadra",
        "state": "SP"
    },
    {
        "name": "Quatá",
        "state": "SP"
    },
    {
        "name": "Queiroz",
        "state": "SP"
    },
    {
        "name": "Queluz",
        "state": "SP"
    },
    {
        "name": "Quintana",
        "state": "SP"
    },
    {
        "name": "Rafard",
        "state": "SP"
    },
    {
        "name": "Rancharia",
        "state": "SP"
    },
    {
        "name": "Redenção da Serra",
        "state": "SP"
    },
    {
        "name": "Regente Feijó",
        "state": "SP"
    },
    {
        "name": "Reginópolis",
        "state": "SP"
    },
    {
        "name": "Registro",
        "state": "SP"
    },
    {
        "name": "Restinga",
        "state": "SP"
    },
    {
        "name": "Ribeira",
        "state": "SP"
    },
    {
        "name": "Ribeirão Bonito",
        "state": "SP"
    },
    {
        "name": "Ribeirão Branco",
        "state": "SP"
    },
    {
        "name": "Ribeirão Corrente",
        "state": "SP"
    },
    {
        "name": "Ribeirão do Sul",
        "state": "SP"
    },
    {
        "name": "Ribeirão dos Índios",
        "state": "SP"
    },
    {
        "name": "Ribeirão Grande",
        "state": "SP"
    },
    {
        "name": "Ribeirão Pires",
        "state": "SP"
    },
    {
        "name": "Ribeirão Preto",
        "state": "SP"
    },
    {
        "name": "Rifaina",
        "state": "SP"
    },
    {
        "name": "Rincão",
        "state": "SP"
    },
    {
        "name": "Rinópolis",
        "state": "SP"
    },
    {
        "name": "Rio Claro",
        "state": "SP"
    },
    {
        "name": "Rio das Pedras",
        "state": "SP"
    },
    {
        "name": "Rio Grande da Serra",
        "state": "SP"
    },
    {
        "name": "Riolândia",
        "state": "SP"
    },
    {
        "name": "Riversul",
        "state": "SP"
    },
    {
        "name": "Rosana",
        "state": "SP"
    },
    {
        "name": "Roseira",
        "state": "SP"
    },
    {
        "name": "Rubiácea",
        "state": "SP"
    },
    {
        "name": "Rubinéia",
        "state": "SP"
    },
    {
        "name": "Sabino",
        "state": "SP"
    },
    {
        "name": "Sagres",
        "state": "SP"
    },
    {
        "name": "Sales",
        "state": "SP"
    },
    {
        "name": "Sales Oliveira",
        "state": "SP"
    },
    {
        "name": "Salesópolis",
        "state": "SP"
    },
    {
        "name": "Salmourão",
        "state": "SP"
    },
    {
        "name": "Saltinho",
        "state": "SP"
    },
    {
        "name": "Salto",
        "state": "SP"
    },
    {
        "name": "Salto de Pirapora",
        "state": "SP"
    },
    {
        "name": "Salto Grande",
        "state": "SP"
    },
    {
        "name": "Sandovalina",
        "state": "SP"
    },
    {
        "name": "Santa Adélia",
        "state": "SP"
    },
    {
        "name": "Santa Albertina",
        "state": "SP"
    },
    {
        "name": "Santa Bárbara d`Oeste",
        "state": "SP"
    },
    {
        "name": "Santa Branca",
        "state": "SP"
    },
    {
        "name": "Santa Clara d`Oeste",
        "state": "SP"
    },
    {
        "name": "Santa Cruz da Conceição",
        "state": "SP"
    },
    {
        "name": "Santa Cruz da Esperança",
        "state": "SP"
    },
    {
        "name": "Santa Cruz das Palmeiras",
        "state": "SP"
    },
    {
        "name": "Santa Cruz do Rio Pardo",
        "state": "SP"
    },
    {
        "name": "Santa Ernestina",
        "state": "SP"
    },
    {
        "name": "Santa Fé do Sul",
        "state": "SP"
    },
    {
        "name": "Santa Gertrudes",
        "state": "SP"
    },
    {
        "name": "Santa Isabel",
        "state": "SP"
    },
    {
        "name": "Santa Lúcia",
        "state": "SP"
    },
    {
        "name": "Santa Maria da Serra",
        "state": "SP"
    },
    {
        "name": "Santa Mercedes",
        "state": "SP"
    },
    {
        "name": "Santa Rita d`Oeste",
        "state": "SP"
    },
    {
        "name": "Santa Rita do Passa Quatro",
        "state": "SP"
    },
    {
        "name": "Santa Rosa de Viterbo",
        "state": "SP"
    },
    {
        "name": "Santa Salete",
        "state": "SP"
    },
    {
        "name": "Santana da Ponte Pensa",
        "state": "SP"
    },
    {
        "name": "Santana de Parnaíba",
        "state": "SP"
    },
    {
        "name": "Santo Anastácio",
        "state": "SP"
    },
    {
        "name": "Santo André",
        "state": "SP"
    },
    {
        "name": "Santo Antônio da Alegria",
        "state": "SP"
    },
    {
        "name": "Santo Antônio de Posse",
        "state": "SP"
    },
    {
        "name": "Santo Antônio do Aracanguá",
        "state": "SP"
    },
    {
        "name": "Santo Antônio do Jardim",
        "state": "SP"
    },
    {
        "name": "Santo Antônio do Pinhal",
        "state": "SP"
    },
    {
        "name": "Santo Expedito",
        "state": "SP"
    },
    {
        "name": "Santópolis do Aguapeí",
        "state": "SP"
    },
    {
        "name": "Santos",
        "state": "SP"
    },
    {
        "name": "São Bento do Sapucaí",
        "state": "SP"
    },
    {
        "name": "São Bernardo do Campo",
        "state": "SP"
    },
    {
        "name": "São Caetano do Sul",
        "state": "SP"
    },
    {
        "name": "São Carlos",
        "state": "SP"
    },
    {
        "name": "São Francisco",
        "state": "SP"
    },
    {
        "name": "São João da Boa Vista",
        "state": "SP"
    },
    {
        "name": "São João das Duas Pontes",
        "state": "SP"
    },
    {
        "name": "São João de Iracema",
        "state": "SP"
    },
    {
        "name": "São João do Pau d`Alho",
        "state": "SP"
    },
    {
        "name": "São Joaquim da Barra",
        "state": "SP"
    },
    {
        "name": "São José da Bela Vista",
        "state": "SP"
    },
    {
        "name": "São José do Barreiro",
        "state": "SP"
    },
    {
        "name": "São José do Rio Pardo",
        "state": "SP"
    },
    {
        "name": "São José do Rio Preto",
        "state": "SP"
    },
    {
        "name": "São José dos Campos",
        "state": "SP"
    },
    {
        "name": "São Lourenço da Serra",
        "state": "SP"
    },
    {
        "name": "São Luís do Paraitinga",
        "state": "SP"
    },
    {
        "name": "São Manuel",
        "state": "SP"
    },
    {
        "name": "São Miguel Arcanjo",
        "state": "SP"
    },
    {
        "name": "São Paulo",
        "state": "SP"
    },
    {
        "name": "São Pedro",
        "state": "SP"
    },
    {
        "name": "São Pedro do Turvo",
        "state": "SP"
    },
    {
        "name": "São Roque",
        "state": "SP"
    },
    {
        "name": "São Sebastião",
        "state": "SP"
    },
    {
        "name": "São Sebastião da Grama",
        "state": "SP"
    },
    {
        "name": "São Simão",
        "state": "SP"
    },
    {
        "name": "São Vicente",
        "state": "SP"
    },
    {
        "name": "Sarapuí",
        "state": "SP"
    },
    {
        "name": "Sarutaiá",
        "state": "SP"
    },
    {
        "name": "Sebastianópolis do Sul",
        "state": "SP"
    },
    {
        "name": "Serra Azul",
        "state": "SP"
    },
    {
        "name": "Serra Negra",
        "state": "SP"
    },
    {
        "name": "Serrana",
        "state": "SP"
    },
    {
        "name": "Sertãozinho",
        "state": "SP"
    },
    {
        "name": "Sete Barras",
        "state": "SP"
    },
    {
        "name": "Severínia",
        "state": "SP"
    },
    {
        "name": "Silveiras",
        "state": "SP"
    },
    {
        "name": "Socorro",
        "state": "SP"
    },
    {
        "name": "Sorocaba",
        "state": "SP"
    },
    {
        "name": "Sud Mennucci",
        "state": "SP"
    },
    {
        "name": "Sumaré",
        "state": "SP"
    },
    {
        "name": "Suzanápolis",
        "state": "SP"
    },
    {
        "name": "Suzano",
        "state": "SP"
    },
    {
        "name": "Tabapuã",
        "state": "SP"
    },
    {
        "name": "Tabatinga",
        "state": "SP"
    },
    {
        "name": "Taboão da Serra",
        "state": "SP"
    },
    {
        "name": "Taciba",
        "state": "SP"
    },
    {
        "name": "Taguaí",
        "state": "SP"
    },
    {
        "name": "Taiaçu",
        "state": "SP"
    },
    {
        "name": "Taiúva",
        "state": "SP"
    },
    {
        "name": "Tambaú",
        "state": "SP"
    },
    {
        "name": "Tanabi",
        "state": "SP"
    },
    {
        "name": "Tapiraí",
        "state": "SP"
    },
    {
        "name": "Tapiratiba",
        "state": "SP"
    },
    {
        "name": "Taquaral",
        "state": "SP"
    },
    {
        "name": "Taquaritinga",
        "state": "SP"
    },
    {
        "name": "Taquarituba",
        "state": "SP"
    },
    {
        "name": "Taquarivaí",
        "state": "SP"
    },
    {
        "name": "Tarabai",
        "state": "SP"
    },
    {
        "name": "Tarumã",
        "state": "SP"
    },
    {
        "name": "Tatuí",
        "state": "SP"
    },
    {
        "name": "Taubaté",
        "state": "SP"
    },
    {
        "name": "Tejupá",
        "state": "SP"
    },
    {
        "name": "Teodoro Sampaio",
        "state": "SP"
    },
    {
        "name": "Terra Roxa",
        "state": "SP"
    },
    {
        "name": "Tietê",
        "state": "SP"
    },
    {
        "name": "Timburi",
        "state": "SP"
    },
    {
        "name": "Torre de Pedra",
        "state": "SP"
    },
    {
        "name": "Torrinha",
        "state": "SP"
    },
    {
        "name": "Trabiju",
        "state": "SP"
    },
    {
        "name": "Tremembé",
        "state": "SP"
    },
    {
        "name": "Três Fronteiras",
        "state": "SP"
    },
    {
        "name": "Tuiuti",
        "state": "SP"
    },
    {
        "name": "Tupã",
        "state": "SP"
    },
    {
        "name": "Tupi Paulista",
        "state": "SP"
    },
    {
        "name": "Turiúba",
        "state": "SP"
    },
    {
        "name": "Turmalina",
        "state": "SP"
    },
    {
        "name": "Ubarana",
        "state": "SP"
    },
    {
        "name": "Ubatuba",
        "state": "SP"
    },
    {
        "name": "Ubirajara",
        "state": "SP"
    },
    {
        "name": "Uchoa",
        "state": "SP"
    },
    {
        "name": "União Paulista",
        "state": "SP"
    },
    {
        "name": "Urânia",
        "state": "SP"
    },
    {
        "name": "Uru",
        "state": "SP"
    },
    {
        "name": "Urupês",
        "state": "SP"
    },
    {
        "name": "Valentim Gentil",
        "state": "SP"
    },
    {
        "name": "Valinhos",
        "state": "SP"
    },
    {
        "name": "Valparaíso",
        "state": "SP"
    },
    {
        "name": "Vargem",
        "state": "SP"
    },
    {
        "name": "Vargem Grande do Sul",
        "state": "SP"
    },
    {
        "name": "Vargem Grande Paulista",
        "state": "SP"
    },
    {
        "name": "Várzea Paulista",
        "state": "SP"
    },
    {
        "name": "Vera Cruz",
        "state": "SP"
    },
    {
        "name": "Vinhedo",
        "state": "SP"
    },
    {
        "name": "Viradouro",
        "state": "SP"
    },
    {
        "name": "Vista Alegre do Alto",
        "state": "SP"
    },
    {
        "name": "Vitória Brasil",
        "state": "SP"
    },
    {
        "name": "Votorantim",
        "state": "SP"
    },
    {
        "name": "Votuporanga",
        "state": "SP"
    },
    {
        "name": "Zacarias",
        "state": "SP"
    },
    {
        "name": "Amparo de São Francisco",
        "state": "SE"
    },
    {
        "name": "Aquidabã",
        "state": "SE"
    },
    {
        "name": "Aracaju",
        "state": "SE"
    },
    {
        "name": "Arauá",
        "state": "SE"
    },
    {
        "name": "Areia Branca",
        "state": "SE"
    },
    {
        "name": "Barra dos Coqueiros",
        "state": "SE"
    },
    {
        "name": "Boquim",
        "state": "SE"
    },
    {
        "name": "Brejo Grande",
        "state": "SE"
    },
    {
        "name": "Campo do Brito",
        "state": "SE"
    },
    {
        "name": "Canhoba",
        "state": "SE"
    },
    {
        "name": "Canindé de São Francisco",
        "state": "SE"
    },
    {
        "name": "Capela",
        "state": "SE"
    },
    {
        "name": "Carira",
        "state": "SE"
    },
    {
        "name": "Carmópolis",
        "state": "SE"
    },
    {
        "name": "Cedro de São João",
        "state": "SE"
    },
    {
        "name": "Cristinápolis",
        "state": "SE"
    },
    {
        "name": "Cumbe",
        "state": "SE"
    },
    {
        "name": "Divina Pastora",
        "state": "SE"
    },
    {
        "name": "Estância",
        "state": "SE"
    },
    {
        "name": "Feira Nova",
        "state": "SE"
    },
    {
        "name": "Frei Paulo",
        "state": "SE"
    },
    {
        "name": "Gararu",
        "state": "SE"
    },
    {
        "name": "General Maynard",
        "state": "SE"
    },
    {
        "name": "Gracho Cardoso",
        "state": "SE"
    },
    {
        "name": "Ilha das Flores",
        "state": "SE"
    },
    {
        "name": "Indiaroba",
        "state": "SE"
    },
    {
        "name": "Itabaiana",
        "state": "SE"
    },
    {
        "name": "Itabaianinha",
        "state": "SE"
    },
    {
        "name": "Itabi",
        "state": "SE"
    },
    {
        "name": "Itaporanga d`Ajuda",
        "state": "SE"
    },
    {
        "name": "Japaratuba",
        "state": "SE"
    },
    {
        "name": "Japoatã",
        "state": "SE"
    },
    {
        "name": "Lagarto",
        "state": "SE"
    },
    {
        "name": "Laranjeiras",
        "state": "SE"
    },
    {
        "name": "Macambira",
        "state": "SE"
    },
    {
        "name": "Malhada dos Bois",
        "state": "SE"
    },
    {
        "name": "Malhador",
        "state": "SE"
    },
    {
        "name": "Maruim",
        "state": "SE"
    },
    {
        "name": "Moita Bonita",
        "state": "SE"
    },
    {
        "name": "Monte Alegre de Sergipe",
        "state": "SE"
    },
    {
        "name": "Muribeca",
        "state": "SE"
    },
    {
        "name": "Neópolis",
        "state": "SE"
    },
    {
        "name": "Nossa Senhora Aparecida",
        "state": "SE"
    },
    {
        "name": "Nossa Senhora da Glória",
        "state": "SE"
    },
    {
        "name": "Nossa Senhora das Dores",
        "state": "SE"
    },
    {
        "name": "Nossa Senhora de Lourdes",
        "state": "SE"
    },
    {
        "name": "Nossa Senhora do Socorro",
        "state": "SE"
    },
    {
        "name": "Pacatuba",
        "state": "SE"
    },
    {
        "name": "Pedra Mole",
        "state": "SE"
    },
    {
        "name": "Pedrinhas",
        "state": "SE"
    },
    {
        "name": "Pinhão",
        "state": "SE"
    },
    {
        "name": "Pirambu",
        "state": "SE"
    },
    {
        "name": "Poço Redondo",
        "state": "SE"
    },
    {
        "name": "Poço Verde",
        "state": "SE"
    },
    {
        "name": "Porto da Folha",
        "state": "SE"
    },
    {
        "name": "Propriá",
        "state": "SE"
    },
    {
        "name": "Riachão do Dantas",
        "state": "SE"
    },
    {
        "name": "Riachuelo",
        "state": "SE"
    },
    {
        "name": "Ribeirópolis",
        "state": "SE"
    },
    {
        "name": "Rosário do Catete",
        "state": "SE"
    },
    {
        "name": "Salgado",
        "state": "SE"
    },
    {
        "name": "Santa Luzia do Itanhy",
        "state": "SE"
    },
    {
        "name": "Santa Rosa de Lima",
        "state": "SE"
    },
    {
        "name": "Santana do São Francisco",
        "state": "SE"
    },
    {
        "name": "Santo Amaro das Brotas",
        "state": "SE"
    },
    {
        "name": "São Cristóvão",
        "state": "SE"
    },
    {
        "name": "São Domingos",
        "state": "SE"
    },
    {
        "name": "São Francisco",
        "state": "SE"
    },
    {
        "name": "São Miguel do Aleixo",
        "state": "SE"
    },
    {
        "name": "Simão Dias",
        "state": "SE"
    },
    {
        "name": "Siriri",
        "state": "SE"
    },
    {
        "name": "Telha",
        "state": "SE"
    },
    {
        "name": "Tobias Barreto",
        "state": "SE"
    },
    {
        "name": "Tomar do Geru",
        "state": "SE"
    },
    {
        "name": "Umbaúba",
        "state": "SE"
    },
    {
        "name": "Abreulândia",
        "state": "TO"
    },
    {
        "name": "Aguiarnópolis",
        "state": "TO"
    },
    {
        "name": "Aliança do Tocantins",
        "state": "TO"
    },
    {
        "name": "Almas",
        "state": "TO"
    },
    {
        "name": "Alvorada",
        "state": "TO"
    },
    {
        "name": "Ananás",
        "state": "TO"
    },
    {
        "name": "Angico",
        "state": "TO"
    },
    {
        "name": "Aparecida do Rio Negro",
        "state": "TO"
    },
    {
        "name": "Aragominas",
        "state": "TO"
    },
    {
        "name": "Araguacema",
        "state": "TO"
    },
    {
        "name": "Araguaçu",
        "state": "TO"
    },
    {
        "name": "Araguaína",
        "state": "TO"
    },
    {
        "name": "Araguanã",
        "state": "TO"
    },
    {
        "name": "Araguatins",
        "state": "TO"
    },
    {
        "name": "Arapoema",
        "state": "TO"
    },
    {
        "name": "Arraias",
        "state": "TO"
    },
    {
        "name": "Augustinópolis",
        "state": "TO"
    },
    {
        "name": "Aurora do Tocantins",
        "state": "TO"
    },
    {
        "name": "Axixá do Tocantins",
        "state": "TO"
    },
    {
        "name": "Babaçulândia",
        "state": "TO"
    },
    {
        "name": "Bandeirantes do Tocantins",
        "state": "TO"
    },
    {
        "name": "Barra do Ouro",
        "state": "TO"
    },
    {
        "name": "Barrolândia",
        "state": "TO"
    },
    {
        "name": "Bernardo Sayão",
        "state": "TO"
    },
    {
        "name": "Bom Jesus do Tocantins",
        "state": "TO"
    },
    {
        "name": "Brasilândia do Tocantins",
        "state": "TO"
    },
    {
        "name": "Brejinho de Nazaré",
        "state": "TO"
    },
    {
        "name": "Buriti do Tocantins",
        "state": "TO"
    },
    {
        "name": "Cachoeirinha",
        "state": "TO"
    },
    {
        "name": "Campos Lindos",
        "state": "TO"
    },
    {
        "name": "Cariri do Tocantins",
        "state": "TO"
    },
    {
        "name": "Carmolândia",
        "state": "TO"
    },
    {
        "name": "Carrasco Bonito",
        "state": "TO"
    },
    {
        "name": "Caseara",
        "state": "TO"
    },
    {
        "name": "Centenário",
        "state": "TO"
    },
    {
        "name": "Chapada da Natividade",
        "state": "TO"
    },
    {
        "name": "Chapada de Areia",
        "state": "TO"
    },
    {
        "name": "Colinas do Tocantins",
        "state": "TO"
    },
    {
        "name": "Colméia",
        "state": "TO"
    },
    {
        "name": "Combinado",
        "state": "TO"
    },
    {
        "name": "Conceição do Tocantins",
        "state": "TO"
    },
    {
        "name": "Couto de Magalhães",
        "state": "TO"
    },
    {
        "name": "Cristalândia",
        "state": "TO"
    },
    {
        "name": "Crixás do Tocantins",
        "state": "TO"
    },
    {
        "name": "Darcinópolis",
        "state": "TO"
    },
    {
        "name": "Dianópolis",
        "state": "TO"
    },
    {
        "name": "Divinópolis do Tocantins",
        "state": "TO"
    },
    {
        "name": "Dois Irmãos do Tocantins",
        "state": "TO"
    },
    {
        "name": "Dueré",
        "state": "TO"
    },
    {
        "name": "Esperantina",
        "state": "TO"
    },
    {
        "name": "Fátima",
        "state": "TO"
    },
    {
        "name": "Figueirópolis",
        "state": "TO"
    },
    {
        "name": "Filadélfia",
        "state": "TO"
    },
    {
        "name": "Formoso do Araguaia",
        "state": "TO"
    },
    {
        "name": "Fortaleza do Tabocão",
        "state": "TO"
    },
    {
        "name": "Goianorte",
        "state": "TO"
    },
    {
        "name": "Goiatins",
        "state": "TO"
    },
    {
        "name": "Guaraí",
        "state": "TO"
    },
    {
        "name": "Gurupi",
        "state": "TO"
    },
    {
        "name": "Ipueiras",
        "state": "TO"
    },
    {
        "name": "Itacajá",
        "state": "TO"
    },
    {
        "name": "Itaguatins",
        "state": "TO"
    },
    {
        "name": "Itapiratins",
        "state": "TO"
    },
    {
        "name": "Itaporã do Tocantins",
        "state": "TO"
    },
    {
        "name": "Jaú do Tocantins",
        "state": "TO"
    },
    {
        "name": "Juarina",
        "state": "TO"
    },
    {
        "name": "Lagoa da Confusão",
        "state": "TO"
    },
    {
        "name": "Lagoa do Tocantins",
        "state": "TO"
    },
    {
        "name": "Lajeado",
        "state": "TO"
    },
    {
        "name": "Lavandeira",
        "state": "TO"
    },
    {
        "name": "Lizarda",
        "state": "TO"
    },
    {
        "name": "Luzinópolis",
        "state": "TO"
    },
    {
        "name": "Marianópolis do Tocantins",
        "state": "TO"
    },
    {
        "name": "Mateiros",
        "state": "TO"
    },
    {
        "name": "Maurilândia do Tocantins",
        "state": "TO"
    },
    {
        "name": "Miracema do Tocantins",
        "state": "TO"
    },
    {
        "name": "Miranorte",
        "state": "TO"
    },
    {
        "name": "Monte do Carmo",
        "state": "TO"
    },
    {
        "name": "Monte Santo do Tocantins",
        "state": "TO"
    },
    {
        "name": "Muricilândia",
        "state": "TO"
    },
    {
        "name": "Natividade",
        "state": "TO"
    },
    {
        "name": "Nazaré",
        "state": "TO"
    },
    {
        "name": "Nova Olinda",
        "state": "TO"
    },
    {
        "name": "Nova Rosalândia",
        "state": "TO"
    },
    {
        "name": "Novo Acordo",
        "state": "TO"
    },
    {
        "name": "Novo Alegre",
        "state": "TO"
    },
    {
        "name": "Novo Jardim",
        "state": "TO"
    },
    {
        "name": "Oliveira de Fátima",
        "state": "TO"
    },
    {
        "name": "Palmas",
        "state": "TO"
    },
    {
        "name": "Palmeirante",
        "state": "TO"
    },
    {
        "name": "Palmeiras do Tocantins",
        "state": "TO"
    },
    {
        "name": "Palmeirópolis",
        "state": "TO"
    },
    {
        "name": "Paraíso do Tocantins",
        "state": "TO"
    },
    {
        "name": "Paranã",
        "state": "TO"
    },
    {
        "name": "Pau d`Arco",
        "state": "TO"
    },
    {
        "name": "Pedro Afonso",
        "state": "TO"
    },
    {
        "name": "Peixe",
        "state": "TO"
    },
    {
        "name": "Pequizeiro",
        "state": "TO"
    },
    {
        "name": "Pindorama do Tocantins",
        "state": "TO"
    },
    {
        "name": "Piraquê",
        "state": "TO"
    },
    {
        "name": "Pium",
        "state": "TO"
    },
    {
        "name": "Ponte Alta do Bom Jesus",
        "state": "TO"
    },
    {
        "name": "Ponte Alta do Tocantins",
        "state": "TO"
    },
    {
        "name": "Porto Alegre do Tocantins",
        "state": "TO"
    },
    {
        "name": "Porto Nacional",
        "state": "TO"
    },
    {
        "name": "Praia Norte",
        "state": "TO"
    },
    {
        "name": "Presidente Kennedy",
        "state": "TO"
    },
    {
        "name": "Pugmil",
        "state": "TO"
    },
    {
        "name": "Recursolândia",
        "state": "TO"
    },
    {
        "name": "Riachinho",
        "state": "TO"
    },
    {
        "name": "Rio da Conceição",
        "state": "TO"
    },
    {
        "name": "Rio dos Bois",
        "state": "TO"
    },
    {
        "name": "Rio Sono",
        "state": "TO"
    },
    {
        "name": "Sampaio",
        "state": "TO"
    },
    {
        "name": "Sandolândia",
        "state": "TO"
    },
    {
        "name": "Santa Fé do Araguaia",
        "state": "TO"
    },
    {
        "name": "Santa Maria do Tocantins",
        "state": "TO"
    },
    {
        "name": "Santa Rita do Tocantins",
        "state": "TO"
    },
    {
        "name": "Santa Rosa do Tocantins",
        "state": "TO"
    },
    {
        "name": "Santa Tereza do Tocantins",
        "state": "TO"
    },
    {
        "name": "Santa Terezinha do Tocantins",
        "state": "TO"
    },
    {
        "name": "São Bento do Tocantins",
        "state": "TO"
    },
    {
        "name": "São Félix do Tocantins",
        "state": "TO"
    },
    {
        "name": "São Miguel do Tocantins",
        "state": "TO"
    },
    {
        "name": "São Salvador do Tocantins",
        "state": "TO"
    },
    {
        "name": "São Sebastião do Tocantins",
        "state": "TO"
    },
    {
        "name": "São Valério da Natividade",
        "state": "TO"
    },
    {
        "name": "Silvanópolis",
        "state": "TO"
    },
    {
        "name": "Sítio Novo do Tocantins",
        "state": "TO"
    },
    {
        "name": "Sucupira",
        "state": "TO"
    },
    {
        "name": "Taguatinga",
        "state": "TO"
    },
    {
        "name": "Taipas do Tocantins",
        "state": "TO"
    },
    {
        "name": "Talismã",
        "state": "TO"
    },
    {
        "name": "Tocantínia",
        "state": "TO"
    },
    {
        "name": "Tocantinópolis",
        "state": "TO"
    },
    {
        "name": "Tupirama",
        "state": "TO"
    },
    {
        "name": "Tupiratins",
        "state": "TO"
    },
    {
        "name": "Wanderlândia",
        "state": "TO"
    },
    {
        "name": "Xambioá",
        "state": "TO"
    }
]