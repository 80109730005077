import React from "react";
import { Row, Col, Card, Checkbox, Form } from "antd";
import { cpf, cnpj } from "cpf-cnpj-validator";
import MaskedInput from "../../../../common/components/masked-input";
import {
  BlueSquareButton,
} from "../../../../common/components/button";

import Select from "../../../../common/components/select";
import getAddressByCep from "../../../../service/viacep";
import { Link } from "react-router-dom";

import Input from "../../../../common/components/input";
// import MaskInput from "../../../../common/components/mask-input";
// import { beautifyCpfCnpj } from "../../../../utils/formatters";

const RegiterStep2 = ({ data, onFinish, loading }) => {
  const [form] = Form.useForm();

  const searchAddressByCep = (value) => {
    if (value?.length !== 9) return;

    getAddressByCep(value).then((response) => {
      form.setFields([
        { name: ["address", "city"], value: response.localidade },
        { name: ["address", "street"], value: response.logradouro },
        { name: ["address", "neighborhood"], value: response.bairro },
        { name: ["address", "state"], value: response.uf },
      ]);

      // form.validateFields()
    });
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={data}
        size="large"
        scrollToFirstError="true"
      >
        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={12}>
            <Form.Item
              name={"cpf_cnpj"}
              label="CNPJ ou CPF"
              initialValue=""
              rules={[
                () => ({
                  validator(_, value) {
                    if (
                      value !== undefined &&
                      value !== null &&
                      value.length > 0
                    ) {
                      const val = value.replace(/[^0-9]+/g, "");

                      if (val.length > 11) {
                        if (cnpj.isValid(val)) return Promise.resolve();

                        return Promise.reject(new Error("CNPJ inválido"));
                      }

                      if (!cpf.isValid(val))
                        return Promise.reject(new Error("CPF inválido"));

                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Informe seu CNPJ ou CPF")
                      );
                    }
                  },
                }),
              ]}
            >
              <MaskedInput
                initialValue={null}
                formats={[
                  {
                    condition: (value) => value.length <= 11,
                    format: "###.###.###-##",
                  },
                  {
                    condition: (value) => value.length > 11,
                    format: "##.###.###/####-##",
                  },
                ]}
                placeholder="Informe seu CNPJ ou CPF"
                mask="_"
              />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12}>
            <Form.Item
              label="CEP"
              name={["address", "cep"]}
              rules={[{ required: true, message: "Favor, digite o cep!" }]}
              hasFeedback
            >
              <MaskedInput
                type="tel"
                formats={[
                  {
                    condition: (value) => value?.length <= 11,
                    format: "#####-###",
                  },
                ]}
                placeholder="Digite o CEP"
                mask="_"
                onBlur={(event) => {
                  searchAddressByCep(event.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={20}>
            <Form.Item
              name={["address", "street"]}
              label="Endereço"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Informe a rua!</div>,
                },
              ]}
            >
              <Input placeholder="Digite a rua" />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={4}>
            <Form.Item
              name={["address", "number"]}
              label="Número"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Informe o número!</div>,
                },
              ]}
            >
              <Input placeholder="123" />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={["address", "neighborhood"]}
              label="Bairro"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Informe o bairro!</div>,
                },
              ]}
            >
              <Input placeholder="Digite o bairro" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={20}>
            <Form.Item
              name={["address", "city"]}
              label="Cidade"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Informe a cidade!</div>,
                },
              ]}
            >
              <Input disabled placeholder="-" />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={4}>
            <Form.Item
              name={["address", "state"]}
              label="Estado"
              initialValue={""}
              rules={[
                {
                  min: 2,
                  max: 2,
                  message: (
                    <div className="normal-12">
                      Informe a sigla do estado. Ex.: SP
                    </div>
                  ),
                },
              ]}
            >
              <Input disabled placeholder="-" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"main_interest"}
              label="Interesse principal"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: (
                    <div
                      style={{ marginTop: "7px", color: "#ff4d4f" }}
                      className="normal-12"
                    >
                      Informe uma opção válida!
                    </div>
                  ),
                },
              ]}
            >
              <Select
                placeholder="Selecione o interesse"
                options={[
                  {
                    value: "analise_credito",
                    label: "Análise de crédito",
                  },
                  {
                    value: "preventivo",
                    label: "Pesquisas preventivas (due diligence)",
                  },
                  { value: "outros", label: "Outros" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"how_find_inquest"}
              label="Como você nos achou?"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: (
                    <div
                      style={{ marginTop: "7px", color: "#ff4d4f" }}
                      className="normal-12"
                    >
                      Informe uma opção válida!
                    </div>
                  ),
                },
              ]}
            >
              <Select
                placeholder="Selecione uma opção"
                options={[
                  { value: "Google", label: "Google" },
                  { value: "Facebook", label: "Facebook" },
                  { value: "Instagram", label: "Instagram" },
                  { value: "Youtube", label: "Youtube" },
                  { value: "Outro", label: "Outro" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16} className="card-termos">
          <Col sm={24} xs={24} md={24}>
            <Card
              style={{
                width: "100%",
                maxWidth: "100%",
                backgroundColor: "#eee",
              }}
            >
              <Form.Item
                name="termos"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Aceite os termos")),
                  },
                ]}
              >
                <Checkbox>
                  Li e concordo com os termos de uso
                  <Link to="/termos" target="_blank" rel="noreferrer">
                    {" "}
                    termos de uso{" "}
                  </Link>
                  e
                  <Link to="/termos" target="_blank" rel="noreferrer">
                    {" "}
                    políticas de privacidade{" "}
                  </Link>
                  .
                </Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16} justify="end">
          <Col>
            <BlueSquareButton
              htmlType="button"
              onClick={() => {
                form.submit();
              }}
              isLoading={loading}
              disabled={loading}
            >
              CRIAR CONTA
            </BlueSquareButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RegiterStep2;
