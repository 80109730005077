import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createReport,
  listReports,
  getReport,
  calcReportPrice,
  createOrder,
  editOrder,
  getOrder,
} from "./service";
import StateStatus from "../../../../utils/stateStatus";
import { createCreditCardToken } from "../../../../utils/pagarme";

export const getListReportsThunk = createAsyncThunk(
  "reports/list-reports",
  async ({ page, pageSize, filter, onlyOwner, status }) => {
    const response = await listReports({
      pagination: pageSize ?? 15,
      page,
      filter,
      only_owner: onlyOwner ?? true,
      status,
    });

    return {
      pageSize: pageSize ?? 15,
      onlyOwner: onlyOwner ?? true,
      status,
      filter,
      ...response.data,
    };
  }
);

export const calcReportPriceThunk = createAsyncThunk(
  "reports/calc-price",
  async (data) => {
    const response = await calcReportPrice(data);
    return response.data;
  }
);

export const createReportThunk = createAsyncThunk(
  "reports/create",
  async (data) => {
    const response = await createReport(data);
    return response.data;
  }
);

export const getReportThunk = createAsyncThunk("reports/get", async (reportId) => {
  const response = await getReport(reportId)
  return response.data
})

export const getOrderThunk = createAsyncThunk("reports/get-order", async (code) => {
  const response = await getOrder(code)
  return response.data
})


export const purchaseThunk = createAsyncThunk("reports/purchase", async (data) => {
  const response = await createOrder(data)
  return response.data
})

export const createCreditCardTokenThunk = createAsyncThunk("reports/card-token", async (data) => {
  const token = await createCreditCardToken(data)
  return token
})

export const editOrderThunk = createAsyncThunk("reports/edit-order", async ({id, data}) => {
  const response = await editOrder(id, data)
  return response.data
})



const initialState = {
  status: {
    listReports: StateStatus.idle,
    createReport: StateStatus.idle,
    calcReportPrice: StateStatus.idle,
    getReport: StateStatus.idle,
    getOrder: StateStatus.idle,
    purchase: StateStatus.idle,
    editOrder: StateStatus.idle,
    cardToken: StateStatus.idle,
  },
  data: {
    cardToken: null,
    reports: {
      items: null,
      pagination: {
        page: 0,
        totalCount: 0,
        totalPages: 1,
      },
      filters: {
        filter: "",
        onlyOwner: true,
        status: null,
      },
    },
    report: null,
    order: null,
  },
  errMessage: {
    listReports: null,
  },
};

export const listReportsSlice = createSlice({
  name: "listReports",
  initialState,
  reducers: {
    resetCalcReportPrice: (state, action) => {
      state.status.calcReportPrice = StateStatus.idle;
    },
    resetCreateReport: (state, action) => {
      state.status.createReport = StateStatus.idle;
    },
    resetPurchaseOrder: (state, action) => {
      state.status.purchase = StateStatus.idle;
      state.status.editOrder = StateStatus.idle;
    },
    resetGetOrder: (state, action) => {
      state.status.order = null;
      state.status.getOrder = StateStatus.idle;
    },
    resetCardToken: (state, action) => {
      state.data.cardToken = null;
      state.status.cardToken = StateStatus.idle;
    },
  },
  extraReducers: {
    //reports
    [getListReportsThunk.pending]: (state) => {
      state.status.listReports = StateStatus.loading;
    },
    [getListReportsThunk.fulfilled]: (state, action) => {
      state.status.listReports = StateStatus.succeeded;
      state.data.reports.items = action.payload.items;
      state.data.reports.pagination.totalCount = action.payload.pagination.total_count;
      state.data.reports.pagination.totalPages = action.payload.pagination.total_pages;
      state.data.reports.pagination.pageSize = action.payload.pageSize;
      state.data.reports.pagination.page = action.payload.pagination.current_page;
      state.data.reports.filters.filter = action.payload.filter;
      state.data.reports.filters.onlyOwner = action.payload.onlyOwner;
      state.data.reports.filters.status = action.payload.status;
    },
    [getListReportsThunk.rejected]: (state, action) => {
      state.status.listReports = StateStatus.failed;
      state.errMessage.listReports = action.error.message;
    },

    [createReportThunk.pending]: (state) => {
      state.status.createReport = StateStatus.loading;
    },
    [createReportThunk.fulfilled]: (state, action) => {
      state.status.createReport = StateStatus.succeeded;
      state.data.report = action.payload;
    },
    [createReportThunk.rejected]: (state, action) => {
      state.status.createReport = StateStatus.failed;
      state.errMessage = action.error.message;
    },


    [calcReportPriceThunk.pending]: (state, action) => {
      state.status.calcReportPrice = StateStatus.loading;
      state.data.report = action.meta.arg;
    },
    [calcReportPriceThunk.fulfilled]: (state, action) => {
      state.status.calcReportPrice = StateStatus.succeeded;
      state.data.report.targets = action.payload.targets;
      state.data.report.docs_products = action.payload.docs_products;
      state.data.report.price = action.payload.price;
    },
    [calcReportPriceThunk.rejected]: (state, action) => {
      state.status.calcReportPrice = StateStatus.failed;
      state.errMessage = action.error.message;
    },


    // report thunk
    [getReportThunk.pending]: (state) => {
      state.status.getReport = StateStatus.loading
    },
    [getReportThunk.fulfilled]: (state, action) => {
        state.data.report = action.payload
        state.status.getReport = StateStatus.succeeded
    },
    [getReportThunk.failed]: (state, action) => {
        state.status.getReport = StateStatus.failed
        state.errMessage.getReport = action.error.message
    },

    // get order
    [getOrderThunk.pending]: (state) => {
      state.status.getOrder = StateStatus.loading
    },
    [getOrderThunk.fulfilled]: (state, action) => {
        state.data.order = action.payload
        state.status.getOrder = StateStatus.succeeded
    },
    [getOrderThunk.failed]: (state, action) => {
        state.status.getOrder = StateStatus.failed
        state.errMessage.getOrder = action.error.message
    },


    // purchase thunk
    [purchaseThunk.pending]: (state) => {
      state.status.purchase = StateStatus.loading
    },
    [purchaseThunk.fulfilled]: (state, action) => {
      state.status.purchase = StateStatus.succeeded
      state.data.order = action.payload
    },
    [purchaseThunk.failed]: (state, action) => {
      state.status.purchase = StateStatus.failed
      state.errMessage.purchase = action.error.message
    },

    // Card token thunk
    [createCreditCardTokenThunk.pending]: (state) => {
      state.status.cardToken = StateStatus.loading
    },
    [createCreditCardTokenThunk.fulfilled]: (state, action) => {
      state.status.cardToken = StateStatus.succeeded
      state.data.cardToken = action.payload
    },
    [createCreditCardTokenThunk.failed]: (state, action) => {
      state.status.cardToken = StateStatus.failed
      state.errMessage.cardToken = "Não foi possível gerar o token do cartão"
    },

    


    // edit order thunk
    [editOrderThunk.pending]: (state) => {
      state.status.editOrder = StateStatus.loading
    },
    [editOrderThunk.fulfilled]: (state, action) => {
      state.status.editOrder = StateStatus.succeeded
      state.data.order = action.payload
    },
    [editOrderThunk.failed]: (state, action) => {
      state.status.editOrder = StateStatus.failed
      state.errMessage.editOrder = action.error.message
    },
    
  },
});

export const { resetCreateReport, resetCalcReportPrice, resetPurchaseOrder, resetGetOrder, resetCardToken } = listReportsSlice.actions;
export const selectSearchListState = (state) => state.listReports;
