import React, { useState } from "react"
import PropTypes from "prop-types"
//
import { useNavigate } from "react-router-dom"
//
import "./index.scss"

function Menu({ routes }) {
  const navigate = useNavigate()
  // 
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div
        className={`container-menu ${showModal ? "menu-open" : ""}`}
        role="presentation"
        onClick={() => {
          setShowModal(false)
        }}
      >
        <div className="menu">
          <div className="options">
            {routes &&
              routes.map((route) => (
                <button
                  type="button"
                  key={route.name}
                  className="href-mobile option"
                  onClick={() => {
                    if(route.path?.includes("https")) {
                      window.open(route.path, "_blank")
                    } else if(route.path){
                      navigate(route.path)
                    }
                  }}
                >
                  {route.name}
                </button>
              ))}
          </div>
        </div>
      </div>

      <div
        className={`menu-btn ${showModal ? "open" : ""}`}
        onClick={() => setShowModal(!showModal)}
        role="presentation"
      >
        <div className="menu-btn-burger" />
      </div>
    </>
  )
}

Menu.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      ref: PropTypes.func,
    })
  ).isRequired,
}

export default Menu
