import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listServicesStatus, retryService } from "./service";
import StateStatus from "../../../../utils/stateStatus";

export const getListTargetsThunk = createAsyncThunk(
  "monitoringSlice/list-targets",
  async ({
    page,
    pageSize,
    filter,
    status,
    service,
    created_starts_at,
    created_ends_at,
  }) => {
    const response = await listServicesStatus({
      pagination: pageSize ?? 15,
      page,
      filter,
      status,
      service,
      created_starts_at,
      created_ends_at,
    });

    return {
      pageSize: pageSize ?? 15,
      filter,
      status,
      service,
      created_starts_at,
      created_ends_at,
      ...response.data,
    };
  }
);

export const retryServiceThunk = createAsyncThunk(
  "monitoringSlice/retry",
  async (serviceId) => {
    const response = await retryService(serviceId);
    return response.data;
  }
);

const initialState = {
  status: {
    listTargets: StateStatus.idle,
    retryService: StateStatus.idle,
  },
  data: {
    targets: {
      items: null,
      pagination: {
        page: 0,
        totalCount: 0,
        totalPages: 1,
      },
      filters: {
        filter: "",
        status: "error",
        service: null,
      },
    },
    retryServiceId: null,
  },
  errMessage: {
    listTargets: null,
  },
};

export const monitoringSlice = createSlice({
  name: "monitoringSlice",
  initialState,
  reducers: {
    resetListTargets: (state, action) => {
      state.status.listTargets = StateStatus.idle;
    },
    resetRetryService: (state, action) => {
      state.status.retryService = StateStatus.idle;
    },
  },
  extraReducers: {
    //reports
    [getListTargetsThunk.pending]: (state) => {
      state.status.listTargets = StateStatus.loading;
    },
    [getListTargetsThunk.fulfilled]: (state, action) => {
      state.status.listTargets = StateStatus.succeeded;
      state.data.targets.items = action.payload.items;
      state.data.targets.pagination.totalCount =
        action.payload.pagination.total_count;
      state.data.targets.pagination.totalPages =
        action.payload.pagination.total_pages;
      state.data.targets.pagination.pageSize = action.payload.pageSize;
      state.data.targets.pagination.page =
        action.payload.pagination.current_page;
      state.data.targets.filters.filter = action.payload.filter;
      state.data.targets.filters.status = action.payload.status;
      state.data.targets.filters.service = action.payload.service;
      state.data.targets.filters.created_starts_at =
        action.payload.created_starts_at;
      state.data.targets.filters.created_ends_at =
        action.payload.created_ends_at;
    },
    [getListTargetsThunk.rejected]: (state, action) => {
      state.status.listTargets = StateStatus.failed;
      state.errMessage.listTargets = action.error.message;
    },

    // retry service thunk
    [retryServiceThunk.pending]: (state, action) => {
      state.data.retryServiceId = action.meta.arg;
      state.status.retryService = StateStatus.loading;
    },
    [retryServiceThunk.fulfilled]: (state, action) => {
      state.status.retryService = StateStatus.succeeded;
      state.data.targets.items = state.data.targets.items.map((x) => {
        x.services = x.services.map((s) =>
          s.id === action.meta.arg
            ? { ...s, service_status: "loading", delay_minutes: 0 }
            : s
        );
        return x;
      });
    },
    [retryServiceThunk.failed]: (state, action) => {
      state.status.getReportStatus = StateStatus.failed;
      state.errMessage.retryService = action.error.message;
    },
  },
});

export const { resetListTargets, resetRetryService } = monitoringSlice.actions;
export const selectMonitoringState = (state) => state.monitoring;
