import axios from "axios"
import routes from "./apiRoutes"

export const apiUrl = `${process.env.REACT_APP_BASEURL}`

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})


export const requestRefreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token")
  const data = {refresh: refreshToken}
  try {    
    const response = await axios.post(`${apiUrl}${routes.refreshToken}`, data,  {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    localStorage.setItem("access_token", response.data.access)
    // localStorage.setItem("refresh_token", response.data.refresh)

    return response
  } catch (error) {
    console.error(error)
  }

  return null
}

api.interceptors.request.use(
  async (config) => {
    if(!config.noauth) {

      try {
        // await requestRefreshToken()
      } catch (err) {
        console.error(err)
      }

      const accessToken = localStorage.getItem("access_token")
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Customize error handling as per your requirements
    console.error('API Error:', error);
    
    if (
      error.response &&
      [401, 403, 422].includes(error.response.status) &&
      !window.location.href.endsWith(".tech/") &&
      !window.location.href.endsWith(".tech") &&
      !window.location.href.endsWith("/login")
    ) {
      if(!error.config.noRedirectOnError) {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        window.location = "/#/"
      }
    }

    throw error;
  }
);



export default api;
