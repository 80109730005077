import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { addTargets, getReport } from "./service"


export const addTargetsThunk = createAsyncThunk(
    "add-targets", async (data) => {
        const response = await addTargets(data)
        return response.data
})

export const getReportThunk = createAsyncThunk(
    "add-targets/get-report", async (reportId) => {
        const response = await getReport(reportId)
        return response.data
})



const initialState = {
    status: {
        addTarget: StateStatus.idle,
        getReport:  StateStatus.idle,
    },
    data: {
        report: null,
    },
    errMessage: null
}

export const addTargetsSlice = createSlice({
    name: "add-targets",
    initialState,
    reducers: {
        clearReport: (state) => {
            state.data.report = null
            state.status.getReport = StateStatus.idle
        },
    },
    extraReducers: {
        [addTargetsThunk.pending]: (state) => {
            state.status.addTargets = StateStatus.loading
        },
        [addTargetsThunk.fulfilled]: (state, action) => {
            state.status.addTargets = StateStatus.succeeded
            state.data.report = action.payload
        },
        [addTargetsThunk.rejected]: (state) => {
            state.status.addTargets = StateStatus.failed
            state.errMessage = "Erro ao adicionar novos targets ao caso."
        },

        [getReportThunk.pending]: (state) => {
            state.status.getReport = StateStatus.loading
        },
        [getReportThunk.fulfilled]: (state, action) => {
            state.status.getReport = StateStatus.succeeded
            state.data.report = action.payload
        },
        [getReportThunk.rejected]: (state) => {
            state.status.getReport = StateStatus.failed
            state.data.report = null
            state.errMessage = "Erro ao buscar informações do caso."
        },
    }
})

export const {
    clearReport,
} = addTargetsSlice.actions

export const selectAddTargetsState = (state) => state.addTargets