import React from "react";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
  MinusCircleOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

const IconStatus = ({ status }) => {
  switch (status) {
    case "completed":
      return (
        <Tooltip title="Completo">
          <CheckCircleOutlined />
        </Tooltip>
      )
    case "released":
      return (
        <Tooltip title="Liberado">
          <CheckSquareOutlined />
        </Tooltip>
      )
    case "error":
      return (
        <Tooltip title="Erro">
          <WarningOutlined />
        </Tooltip>
      )
    case "idle":
      return (
        <Tooltip title="Parado">
          <MinusCircleOutlined />
        </Tooltip>
      )
    case "loading":
    default:
      return (
        <Tooltip title="Carregando">
          <LoadingOutlined spin />
        </Tooltip>
      )
  }
}

export default IconStatus;
