// SERVICES
const simple_pro = [
    { id: "charge_and_credit_recovery", name: "Contexto de cobrança e Recuperação de Crédito", label: "Para os casos cujo contexto seja recuperação de crédito", smart_id: "charge_and_credit_recovery"},
    { id: "judicial_recovery", name: "Contexto de Recuperação Judicial (RJ)", label: "Para os casos cujo contexto seja recuperação judicial", smart_id: "judicial_recovery"},
    { id: "due_dilligence_merge_and_acquisition", name: "Contexto de investigação preventiva (due diligence) para fusões e aquisições (Mergers and Acquisitions – M&A)", label: "Contexto de investigação preventiva (due diligence) para fusões e aquisições (Mergers and Acquisitions – M&A)", smart_id: "due_dilligence_merge_and_acquisition"},
    { id: "due_dilligence_general", name: "Contexto de investigação preventiva (due diligence) de caráter geral", label: "Bom para pesquisas preventivas antes da celebração de um negócio relevante, como a compra de um imóvel, antes de ser fiador de alguém, antes de se tornar sócio de alguém etc.", smart_id: "due_dilligence_general"},
    { id: "compliance", name: "Contexto de compliance", label: "Formulário para fornecimento dos dados fundamentais para início de uma investigação com foco em compliance (ex.: elucidação de ilícitos, obtenção de documentos preparatórios para ajuizamento de uma demanda judicial, esclarecimentos de denúncias, instrução de sindicâncias etc).", smart_id: "compliance"},
    { id: "divorce", name: "Contexto de divórcio", label: "Contexto de Divórcio", smart_id: "divorce"},
    { id: "inventory", name: "Contexto de inventário", label: "Contexto de inventário", smart_id: "inventory"},
    // { id: "docs", name: "Contexto de DOC", label: "Para aquisição de documentos", smart_id: "docs"},
    // { id: "coringa", name: "Contexto coringa", label: "Contexto sem a necessidade de informações adicionais", smart_id: "coringa"}
]

const smart = [
    { id: "charge_and_credit_recovery", name: "Contexto de cobrança e Recuperação de Crédito", label: "Para os casos cujo contexto seja recuperação de crédito", smart_id: "charge_and_credit_recovery"},
    { id: "judicial_recovery", name: "Contexto de Recuperação Judicial (RJ)", label: "Para os casos cujo contexto seja recuperação judicial", smart_id: "judicial_recovery"},
    // { id: "due_dilligence_merge_and_acquisition", name: "Contexto de investigação preventiva (due diligence) para fusões e aquisições (Mergers and Acquisitions – M&A)", label: "Contexto de investigação preventiva (due diligence) para fusões e aquisições (Mergers and Acquisitions – M&A)", smart_id: "due_dilligence_merge_and_acquisition"},
    // { id: "due_dilligence_general", name: "Contexto de investigação preventiva (due diligence) de caráter geral", label: "Bom para pesquisas preventivas antes da celebração de um negócio relevante, como a compra de um imóvel, antes de ser fiador de alguém, antes de se tornar sócio de alguém etc.", smart_id: "due_dilligence_general"},
    // { id: "compliance", name: "Contexto de compliance", label: "Formulário para fornecimento dos dados fundamentais para início de uma investigação com foco em compliance (ex.: elucidação de ilícitos, obtenção de documentos preparatórios para ajuizamento de uma demanda judicial, esclarecimentos de denúncias, instrução de sindicâncias etc).", smart_id: "compliance"},
    { id: "divorce", name: "Contexto de divórcio", label: "Contexto de Divórcio", smart_id: "divorce"},
    // { id: "inventory", name: "Contexto de inventário", label: "Contexto de inventário", smart_id: "inventory"},
    // { id: "docs", name: "Contexto de DOC", label: "Para aquisição de documentos", smart_id: "docs"},
    // { id: "coringa", name: "Contexto coringa", label: "Contexto sem a necessidade de informações adicionais", smart_id: "coringa"}
]

const simple = null

const custom = null

const survey = [
    {id: 'placa_veiculo', name: "Busca de veículo por placa", label: "Busca de veículo por placa",  value: 24.0, smart_id: 'car_plate'},
    {id: 'phone', name: "Busca de dados pessoais por telefone", label: "Busca de dados pessoais por telefone",  value: 0.96, smart_id: 'personal_data'},
    {id: 'processos', name: "Busca de processos por nome", label: "Busca de processos por nome",  value: 13.80, smart_id: 'processes_by_name'},
    // {id: 'negativacoes', name: "Busca de score e negativações", label: "Busca de score e negativações",  value: 8.00, smart_id: 'score_and_negotiations'},
]

const preventive = [
    { id: "credit_analysis", name: "Contexto de análise de crédito", label: "Contexto de análise de crédito", smart_id: "credit_analysis" },
    { id: "human_resources", name: "Contexto de RH", label: "Preventivo (RH, Fornecedores ou Parceiros)", smart_id: "human_resources" },
    // { id: "coringa", name: "Contexto coringa", label: "Contexto sem a necessidade de informações adicionai", smart_id: "coringa"}
]

// const docs = [
//     { id: "docs", name: "Contexto de DOC", label: "Para aquisição de documentos", smart_id: "docs"}
// ]

const docs = null

export const serviceCases = {
    "simples": simple,
    "smart": smart,      // simple
    "smart_pro": simple_pro,      // pro
    "credit_analysis": preventive,      
    "docs": docs,
    "preventivo": preventive,
    "survey": survey,
    "custom": custom,
}

