import React from "react"
import PropTypes from "prop-types"
//
import { Select as AntSelect } from "antd"
//
import "./index.scss"

const { Option } = AntSelect

const Select = ({
  onChange,
  placeholder,
  defaultValue,
  options,
  ...props
}) => 
  <AntSelect
    value={defaultValue}
    placeholder={placeholder}
    className="simple-select"
    onChange={(value) => onChange(value)}
    {...props}
  >
    {options.map((option, key) => 
      <Option key={key} value={option.value}>
        {option.key || option.label}
      </Option>
    )}
  </AntSelect>

Select.defaultProps = {
  onChange: () => {}
}

Select.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Select
