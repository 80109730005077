import React from "react"
import { InputNumber as AntInputNumber } from "antd"
// 
import "./index.scss"


const InputNumber = (props) => {
	return (
	  <AntInputNumber
		className="input-number simple-input"
		{...props}
	  />
	)
}
  
export default InputNumber