import React from "react";
import { Form, Row, Col } from "antd";
import { BlueSquareButton } from "../../../../common/components/button";
import Input, {
  InputPhone,
} from "../../../../common/components/input";

const RegiterStep1 = ({ data, onFinish, loading, passValidation }) => {
  const [form] = Form.useForm()

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        style={{ width: "495px" }}
        layout="vertical"
        initialValues={{
          prefix: "55",
          ...(data || {}),
        }}
        size="large"
        scrollToFirstError="true"
      >
        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"name"}
              label="Nome"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Informe um nome válido!</div>
                  ),
                },
              ]}
            >
              <Input placeholder="Nome e sobrenome" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"email"}
              label="E-mail"
              initialValue={""}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Informe um e-mail.</div>
                  ),
                },
                {
                  type: "email",
                  message: (
                    <div className="normal-12">Informe um e-mail válido.</div>
                  ),
                },
              ]}
            >
              <Input placeholder="exemplo@exemplo.com" />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name="confirm_email"
              label="Confirme E-mail"
              dependencies={["email"]}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Informe um e-mail.</div>
                  ),
                },
                {
                  type: "email",
                  message: (
                    <div className="normal-12">Informe um e-mail válido.</div>
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("email") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "O e-mail não corresponde."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input isBlockPaste={true} placeholder="exemplo@exemplo.com" />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={24}>
            <Form.Item
              name={"phone"}
              label="Telefone"
              initialValue={""}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "Informe um telefone!",
                },
              ]}
            >
              <InputPhone style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: "495px" }} gutter={16}>
          <Col sm={24} xs={24} md={24}>
            <Form.Item
              label="Senha"
              name="password"
              // tooltip={infoPassRequiremts}
              rules={[
                { required: true, message: "Favor, digite a senha!" },
                { min: 8, message: "A senha deve ter no mínimo 8 caracteres." }
                // () => ({
                //   validator(_, __) {
                //     const falses = Object.entries(passValidation).filter(
                //       (item) => item[1] === false
                //     );
                //     if (falses.length > 0) {
                //       return Promise.reject(new Error("Senha inválida"));
                //     }
                //     return Promise.resolve();
                //   },
                // }),
              ]}
              hasFeedback
            >
              <Input
                type="password" placeholder="Senha"
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} md={24}>
            <Form.Item
              label="Confirmar senha"
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Favor, confirme a senha!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "A senha não corresponde."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Confirmar senha" />
            </Form.Item>
          </Col>
        </Row>
        < br />
        <Row style={{ width: "495px" }} gutter={16} justify="end">
          <Col>
            <BlueSquareButton
              htmlType="button"
              onClick={() => {
                form.submit()
              }}
              isLoading={loading}
              disabled={loading}
            >
              Próxima etapa
            </BlueSquareButton>
            {/* <PlainButton
              loading={loading}
              disabled={loading}
              htmlType="button"
              color={"primary"}
              onClick={() => {
                form.submit()
              }}
            >
              Próxima etapa
            </PlainButton> */}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RegiterStep1;
