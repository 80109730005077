import  './style.scss';

import React from "react";

const Information = ({title = 'Informações', showText = false, children}) => {

    return (
        <div className="info-title-container">
            <h1 className="info-title"> {title}</h1>
            {showText ?
                (<p className="info-text">
                    Com as buscas disponíveis abaixo vamos coletar dados valiosos sobre o CPF ou CNPJ pesquisado ou de ambos no mesmo caso.
                    Independente do tipo de busca que será realizada, vamos entregar todos os dados em formato de relatório.
                    Ao pesquisar uma pessoa ou empresa abaixo traremos para você informações como:

                    <br/><br/>

                    <ul>
                        <li>Veículos</li>
                        <li> Sócios, empresas, grupos econômicos e conexões com o pesquisado;</li>
                        <li>Processos passivos, ativos, indiretos etc, com valores nominais conforme cadastrados nos tribunais e diários oficiais em nível Brasil;</li>
                        <li> Telefones (WhatsApp, fixo, celular etc), e-mails, endereços completos;</li>
                        <li> Score, Negativações e Protestos;</li>
                        <li> Imóveis nos estados de consulta automatizada (Outros estados em nível Brasil podem ser solicitados avulso);</li>
                        <li> Fazendas;</li>
                        <li> Marcas e Patentes registradas no INPI;</li>
                        <li> Entre outras informações</li>
                    </ul>


                </p>)
                : ''
            }

            {children}
        </div>
    );
}

export default Information;