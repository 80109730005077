import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { getTarget, getService, getReportFiles } from "./service"


export const getTargetThunk = createAsyncThunk("targetPage/get", async (targetId) => {
    const response = await getTarget(targetId)
    return response.data
})

export const getServiceThunk = createAsyncThunk("targetPage/get-service", async (data) => {
    const response = await getService(data.serviceId, data.targetId, data.next)
    return response.data
})


export const listReportFilesThunk = createAsyncThunk("targetPage/list-files", async (targetId) => {
    const response = await getReportFiles(targetId)
    return response.data
})



const initialState = {
    status: {
        getTarget: StateStatus.idle,
        getAllServices: StateStatus.idle,
        listReportFiles: StateStatus.idle,
    },
    stackService: {},
    data: {
        target: {
            id: null,
            status: null,
            services: [],
            cpf_cnpj: null,
            requester_email: null
        },
        service: {},
        reportFiles: [],
    },
    errMessage: {
        getTarget: null,
        getService: null,
        listReportFiles: null,
    }
}

export const targetPageSlice = createSlice({
    name: "targetPage",
    initialState,
    reducers: {
        resetGetTarget: (state) => {
            state.status.getTarget = StateStatus.idle
        },
    },
    extraReducers: {

        // report thunk
        [getTargetThunk.pending]: (state) => {
            state.status.getTarget = StateStatus.loading
            state.status.getAllServices = StateStatus.idle
        },
        [getTargetThunk.fulfilled]: (state, action) => {
            state.data.target = action.payload
            const services_error_msg = ["credilink", "receita_federal"]
            state.data.target.services.forEach((service, idx) => {
                if(service.block_status === "completed" || services_error_msg.includes(service.service)) {
                    state.stackService[`${service.id}_${service.next ?? 0}`] = { 
                        status: StateStatus.idle, 
                        ...service,
                        order: idx,
                    }
                }
            })
            state.status.getTarget = StateStatus.succeeded
            state.status.getAllServices = StateStatus.loading
        },
        [getTargetThunk.failed]: (state, action) => {
            state.status.getTarget = StateStatus.failed
            state.status.getAllServices = StateStatus.failed
            state.errMessage.getTarget = action.error.message
        },


        // report service
        [getServiceThunk.pending]: (state, action) => {
            state.stackService[`${action.meta.arg.serviceId}_${action.meta.arg.next ?? 0}`]['status'] = StateStatus.loading
        },
        [getServiceThunk.fulfilled]: (state, action) => {
            state.stackService[`${action.meta.arg.serviceId}_${action.meta.arg.next ?? 0}`]['status'] = StateStatus.succeeded
            state.stackService[`${action.meta.arg.serviceId}_${action.meta.arg.next ?? 0}`]['block_data'] = action.payload
            if (action.payload.next) {
                state.stackService[`${action.meta.arg.serviceId}_${action.payload.next}`] = {
                    status: StateStatus.idle,
                    id: action.meta.arg.serviceId,
                    next: action.payload.next,
                    service: action.payload.service,
                    order: state.stackService[`${action.meta.arg.serviceId}_0`].order,
                    created_at: state.stackService[`${action.meta.arg.serviceId}_0`].created_at
                }
            }

            const completed = Object.entries(state.stackService).filter(
                service =>  service[1].status === StateStatus.succeeded
            ).length

            if(Object.keys(state.stackService).length === completed) {
                state.status.getAllServices = StateStatus.succeeded
            }
        },
        [getServiceThunk.failed]: (state, action) => {
            state.stackService[`${action.meta.arg.serviceId}_${action.meta.arg.next ?? 0}`]['status'] = StateStatus.failed
            state.status.getAllServices = StateStatus.failed
            state.errMessage.getService = action.error.message
        },


        // report thunk
        [listReportFilesThunk.pending]: (state) => {
            state.status.listReportFiles = StateStatus.loading
        },
        [listReportFilesThunk.fulfilled]: (state, action) => {
            state.data.reportFiles = action.payload?.items ?? []
            state.status.listReportFiles = StateStatus.succeeded
        },
        [listReportFilesThunk.failed]: (state, action) => {
            state.status.listReportFiles = StateStatus.failed
            state.errMessage.listReportFiles = action.error.message
        },
    }
})

export const {
    resetGetTarget
} = targetPageSlice.actions
export const selectTargetState = (state) => state.targetPage