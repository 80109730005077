import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { getSurvey, getSurveyBlock } from "./service"


export const getSurveyThunk = createAsyncThunk("surveyPage/get-survey", async (surveyId) => {
    const response = await getSurvey(surveyId)
    return response.data
})

export const getSurveyBlockThunk = createAsyncThunk("surveyPage/get-survey-block", async (surveyId) => {
    const response = await getSurveyBlock(surveyId)
    return response.data
})



const initialState = {
    status: {
        getSurvey: StateStatus.idle,
        getSurveyBlock: StateStatus.idle,
    },
    stackService: {},
    data: {
        survey: {},
        surveyBlock: {},
    },
    errMessage: {
        getSurvey: null,
        getSurveyBlock: null,
    }
}

export const surveyPageSlice = createSlice({
    name: "surveyPage",
    initialState,
    reducers: {
        resetGetSurvey: (state) => {
            state.status.getSurvey = StateStatus.idle
        },
        resetGetSurveyblock: (state) => {
            state.status.getSurveyBlock = StateStatus.idle
        },
        resetAll: (state) => {
            state.status = {...initialState.status}
            state.data = {...initialState.data}
            state.data = {...initialState.errMessage}
        },
    },
    extraReducers: {

        // survey thunk
        [getSurveyThunk.pending]: (state) => {
            state.status.getSurvey = StateStatus.loading
        },
        [getSurveyThunk.fulfilled]: (state, action) => {
            state.data.survey = action.payload
            state.status.getSurvey = StateStatus.succeeded
        },
        [getSurveyThunk.failed]: (state, action) => {
            state.status.getSurvey = StateStatus.failed
            state.errMessage.getSurvey = action.error.message
        },
        // survey block thunk
        [getSurveyBlockThunk.pending]: (state) => {
            state.status.getSurveyBlock = StateStatus.loading
        },
        [getSurveyBlockThunk.fulfilled]: (state, action) => {
            state.data.surveyBlock = action.payload
            state.status.getSurveyBlock = StateStatus.succeeded
        },
        [getSurveyBlockThunk.failed]: (state, action) => {
            state.status.getSurveyBlock = StateStatus.failed
            state.errMessage.getSurveyBlock = action.error.message
        },
    }
})

export const {
    resetGetSurvey,
    resetGetSurveyblock,
} = surveyPageSlice.actions
export const selectSurveyState = (state) => state.surveyPage