/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getSurveyBlockThunk,
  getSurveyThunk,
  resetGetSurvey,
  resetGetSurveyblock,
  selectSurveyState,
} from "./slices";
import { HeaderDash } from "../../common/components/header";
import "./styles.scss";
import { Col, Row, Skeleton } from "antd";
import Icons from "../../common/components/Icons";
import StateStatus from "../../utils/stateStatus";

const SurveyPage = () => {
  const params = useParams();
  const surveyState = useSelector(selectSurveyState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetGetSurvey());
    dispatch(resetGetSurveyblock());
    dispatch(getSurveyThunk(params.surveyId));
  }, [params.surveyId]);

  useEffect(() => {
    if (surveyState.status.getSurvey === StateStatus.succeeded) {
      dispatch(getSurveyBlockThunk(params.surveyId));
    }
  }, [surveyState.status.getSurvey]);

  return (
    <>
      <HeaderDash />
      <div className="survey-container">
        {surveyState.status.getSurvey === StateStatus.succeeded ? (
          <>
            <Row
              gutter={[16, 24]}
              className="survey-header"
              justify="start"
              align="middle"
            >
              <Col xs={24} sm={24} md={8} lg={8}>
                <div className="survey-title">{"Busca customizada"}</div>
                <div className="survey-info">
                  <div>
                    <label>Tipo de busca:</label>
                    <span>
                      {surveyState.data.survey?.type_display ??
                        surveyState.data.survey?.type}
                    </span>
                  </div>
                  <div>
                    <label>Termo buscado:</label>
                    <span>{surveyState.data.survey?.search}</span>
                  </div>
                  <div>
                    <label>Criado em:</label>
                    <span>{surveyState.data.survey?.created_at}</span>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Row
                  justify="start"
                  className="survey-status-info"
                  align="middle"
                >
                  {surveyState.data.survey?.status === "completed" ? (
                    <>
                      <Col span={4}>
                        <Icons icon="checked-circle" title="Buscas completas" />
                      </Col>
                      <Col span={10}>
                        <p>
                          A buscas estão executadas, você pode analisar abaixo
                          os dados coletados do termo pesquisado.
                        </p>
                      </Col>
                    </>
                  ) : surveyState.data.survey?.status === "released" ? (
                    <>
                      <Col span={4}>
                        <Icons
                          icon="circle-alert"
                          title={surveyState.data.survey?.status_display}
                        />
                      </Col>
                      <Col span={20}>
                        <p>
                          A pré-visualização das buscas foi liberada, mas ainda há 
                          pendências a serem entregues. Aguarde o caso estar totalmente
                          completo para concluir sua pesquisa.
                        </p>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={4}>
                        <Icons
                          icon="loading"
                          spin
                          title="Buscas em andamento..."
                        />
                      </Col>
                      <Col span={20}>
                        <p>
                          Aguarde as buscas terminarem para analisar a pesquisa
                          por completo. Você receberá um e-mail em breve, quando
                          as buscas estiverem executadas.
                        </p>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            {surveyState.status.getSurveyBlock === StateStatus.succeeded ? (
              <Row>
                <div
                  className="survey-section"
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: surveyState.data.surveyBlock?.block ?? "",
                  }}
                ></div>
              </Row>
            ) : (
              <div className="loading-text">
                <Skeleton active />
              </div>
            )}
          </>
        ) : (
          <div className="loading-text">
            <Skeleton active />
          </div>
        )}
      </div>
    </>
  );
};

export default SurveyPage;
