import { Card, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { beautifyCpfCnpj } from "../../../../utils/formatters";
import "./styles.scss";

const DocsStatusCard = ({ doc, report }) => {
  return (
    <div>
      <Row gutter={[16, 0]} justify="start" className="docs-info">
        <Col span={24}>
          <Link to={`/report/${report.id}`} target="_blank">
            <div className="report-subtitle">
              {report.case_type_display === "DOCS" ? (
                <>
                  DOC para <br />
                  {doc.cpf_cnpj
                    ? `${beautifyCpfCnpj(doc.cpf_cnpj)}`
                    : `Matrícula ${doc.num_matricula}`}
                </>
              ) : report.case_type_display === "MAPA DE CALOR" ? (
                <>
                  MAPA DE CALOR para <br />
                  {`${beautifyCpfCnpj(doc.cpf_cnpj)}`}
                </>
              ) : null}
            </div>
          </Link>
        </Col>
        {report.case_type_display === "DOCS" ? (
          <Col span={24}>
            <Card>
              <div>
                <span>Produto:</span> {doc.product_display || " - "}
              </div>
              <div>
                <span>CPF/CNPJ:</span> {beautifyCpfCnpj(doc.cpf_cnpj) ?? " - "}
              </div>
              <div>
                <span>Matrícula:</span> {doc.num_matricula || " - "}
              </div>
              <div>
                <span>Cartório:</span>
                {doc.livro || " - "}
              </div>
              <div>
                <span>Estado:</span> {doc.uf || " - "}
              </div>
              <div>
                <span>Cidade:</span> {doc.city || " - "}
              </div>
              <div>
                <span>Quantidade:</span> {doc.qtd || " - "}
              </div>
            </Card>
          </Col>
        ) : report.case_type_display === "MAPA DE CALOR" ? (
          <Col span={24}>
            <Card>
              <div>
                <span>Produto:</span> {"Mapa de calor"}
              </div>
              <div>
                <span>CPF/CNPJ:</span> {beautifyCpfCnpj(doc.cpf_cnpj) ?? " - "}
              </div>
            </Card>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default DocsStatusCard;
