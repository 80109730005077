/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//
import { Row, Modal, Select, Col } from "antd";
import { useSelector } from "react-redux";
import { selectReportStatusState } from "../../slices";
import { BlueSquareButton } from "../../../../common/components/button";
import StateStatus from "../../../../utils/stateStatus";
import FileUploadBox from "../../../../common/components/FileUploadBox";
import { uploadReportFile } from "../../service";
import "./styles.scss"

const ModalUploadFilesReport = ({visible, report, onClose}) => {
  const slice = useSelector(selectReportStatusState);
  const [error, setError] = useState();
  const [errorResponse, setErrorResponse] = useState();
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState();
  
  useEffect(() => {
    setFiles([])
  }, [report?.id, visible])

  
  const beforeUpload = (file) => {
    if (file.size / 1000000 >= 500) {
      setError("O Arquivo precisa ser menor que 500MB!");
      return null;
    }

    // if (file.type === "application/pdf") {
    //   setError("O Arquivo precisa ser menor que 500MB!");
    //   return;
    // }

    return file;
  };

  const removeFile = (data) => {
    const newFiles = files.filter(x => x.uid !== data.uid)
    setError(null)
    setFiles(newFiles)
  }
  

  const customRequest = ({ file, onSuccess, onProgress, onError, data }) => {
    if (!beforeUpload(file)) return

    
    const newFile = {
      uid: new Date().getTime(),
      name: file.name,
      url: window.URL.createObjectURL(new Blob([file], { type: file.type })),
      status: "uploading",
      response: null,
      mimeType: file.type,
      percent: 0,
      file,
      type: fileType,
    };

    setFiles(files => {
      // Evitar erro de enviar duas vezes mesmo arquivo.
      if(files.filter(x => newFile.name === x.name).length > 0) {
        newFile.status = "error"
      }

      const newFiles  = [...files, newFile]
      if(newFile.status !== "error") {
        uploadFileRequest(newFile, onProgress, newFiles)
      }
      return newFiles
    })
  };

  const setProgressFile = (newFile, onProgress, files, percent) => {
    const file_ = files?.filter(x => x.uid === newFile.uid)[0]
        if(!file_) return

        file_.percent = Math.floor(percent)

        if (percent === 101) {
          file_.status = "done";
        }

        const updateFiles = [...files.filter(x => x.uid !== newFile.uid), file_]
        updateFiles.sort((a, b) => a.uid - b.uid)
        
        setFiles(updateFiles)
        onProgress({ percent });
  }


  const uploadFileRequest = (newFile, onProgress, files) => { 

    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: function (event) {
        setProgressFile(newFile, onProgress, files, (event.loaded / event.total) * 100)
      }
    };

    const form = new FormData()
    form.append("file", newFile.file)
    form.append("report_id", report.id)
    form.append("type", newFile.type)
    form.append("validate", true)

    uploadReportFile(form, config).then((resp) => {
      setProgressFile(newFile, onProgress, files, 101)
    }).catch((err) => {
      setErrorResponse(err.response.data)
      
      const file_ = files?.filter(x => x.uid === newFile.uid)[0]
      if(!file_) return

      file_.status = "error";

      const updateFiles = [...files.filter(x => x.uid !== newFile.uid), file_]
      updateFiles.sort((a, b) => a.uid - b.uid)
      
      setFiles(updateFiles)
    })
  }

  return (
    <>
    <Modal
      className="upload-files-report"
      title={"Anexar arquivos ao Report"}
      open={visible}
      onCancel={onClose}
      footer={[
        <Row justify="end" style={{ marginTop: "30px" }}>
          {/* <BlueSquareOulinedButton
            onClick={onCancel}>
            Cancelar
          </BlueSquareOulinedButton> */}

          <BlueSquareButton
            onClick={onClose}
            isLoading={slice.status.createReportFile === StateStatus.loading}
            disabled={slice.status.createReportFile === StateStatus.loading}
          >
            Fechar
          </BlueSquareButton>
        </Row>,
      ]}
      width={"900px"}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
            <p className="upload-files-title">
              Selecione o tipo de arquivos a anexar:
            </p>
            <Select
              style={{minWidth: "100%"}}
              placeholder={"Tipo de arquivo..."}
              allowClear={true}
              onClear={() => {
                setFileType(null)
              }}
              onChange={(value) => {
                setFileType(value)
              }}
            >
              {["docs", "smart_pro", "smart", "simples"].includes(report.case_type) ? (<>
                <Select.Option value="imoveis">IMÓVEIS (EXCEL)</Select.Option>
              </>) : null}

              {["docs"].includes(report.case_type) ? (<>
                <Select.Option value="aquisicao_matricula">AQUISIÇÃO DE MATRÍCULAS (PDF)</Select.Option>
              </>) : null}

              {["mapa_calor", "smart_pro", "smart", "simples"].includes(report.case_type) ? (<>
                <Select.Option value="escrituras_procuracoes">ESCRITURAS E PROCURAÇÕES (EXCEL)</Select.Option>
              </>) : null}

              {["mapa_calor"].includes(report.case_type) ? (<>
                <Select.Option value="aquisicao_escrituras_procuracoes">AQUISIÇÃO DE ESCRITURAS E PROCURAÇÕES (PDF)</Select.Option>
              </>) : null}

              <Select.Option value="attachment">OUTROS ANEXOS</Select.Option>
            </Select>
        </Col>
        {fileType ? (
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="select-file-box">
                <p className="upload-files-title">
                  Selecione ou arraste o arquivo a ser enviado abaixo:
                </p>
                <Row justify="center">
                  <div style={{color: "red"}}>{error}</div>
                </Row>
                <FileUploadBox
                  fileList={files}
                  accept={(
                    ["imoveis", "escrituras_procuracoes"].includes(fileType) ? ".csv,.xls,.xlsx" 
                    : ["aquisicao_matricula", "aquisicao_escrituras_procuracoes"].includes(fileType) ? ".pdf" 
                    : null
                  )}
                  multiple={["attachment", "aquisicao_matricula", "aquisicao_escrituras_procuracoes"].includes(fileType)}
                  customRequest={customRequest}
                  beforeUpload={beforeUpload}
                  onRemove={(file) => removeFile(file)}
                  onClick={() => {
                    setError(null)
                    setErrorResponse(null)
                  }}
                />
              </div>
            </Col>
        ) : null}
        {errorResponse ? (
        <Col xs={24} sm={24} md={24} lg={24} style={{color: "red"}}>
          <div style={{marginBottom: "10px"}}>{errorResponse.msg}</div>
          <ul style={{fontSize: "10px"}}>
          {errorResponse.erros?.map((x) => (
            <li>{x}</li>
          ))}
          </ul>
        </Col>): null}
        </Row>
      </Modal>
    </>
  );
};

export default ModalUploadFilesReport;