import React from "react"
import PropTypes from "prop-types"
// 
import "./index.scss"
import { Spin, Button as AntdButton } from "antd"
import {
  LoadingOutlined
} from "@ant-design/icons"

const Button = ({ children, isSubmit, loading, className, ...props }) => (
  <button type={isSubmit ? "submit" : "button"} className={`simple-button ${className ?? ''}`} {...props}>
    {!loading
      ? children
      : <Spin />
    }
  </button>
)

const RoudedButton = ({ loading, disabled, onClick, icon, className, children, ...props }) => {
  return (
    <AntdButton
      className={`rounded-button ${className ?? ""}`}
      disabled={disabled}
      onClick={onClick}
      icon={loading ? <LoadingOutlined /> : icon}
      {...props}
    >
      {children}
    </AntdButton>
  )
}

const BlueSquareButton = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}

const BlueSquareSmallButton = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-small-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}

const BlueSquareOulinedButton = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-outlined-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}

const BlueSquareOulinedSmallButton = ({ isLoading, children, onClick, disabled }) => {
  return (
    <button onClick={onClick} disabled={disabled} className="blue-square-outlined-small-button">
      <div>
        {isLoading ? <LoadingOutlined /> : null}
        {children}
      </div>
    </button>
  )
}
const PlainButton = ({ loading, disabled, onClick, icon, className, children, color, format, ...props }) => {
  const _colorClass = color ? `color-${color}` : ''
  const _fmtClass = format ? `fmt-${format}` : ''

  return (
    <AntdButton
      className={`plain-button ${className ?? ""} ${_colorClass} ${_fmtClass}`}
      disabled={disabled}
      onClick={onClick}
      icon={loading ? <LoadingOutlined /> : icon}
      {...props}
    >
      {children}
    </AntdButton>
  )
}

Button.defaultProps = {
  isSubmit: true,
  loading: false,
}

Button.propTypes = {
  loading: PropTypes.bool,
  isSubmit: PropTypes.bool,
  children: PropTypes.string.isRequired,
}


RoudedButton.defaultProps = {
  disabled: false,
  loading: false,
}

RoudedButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  children: PropTypes.string.isRequired,
}

PlainButton.defaultProps = {
  disabled: false,
  loading: false,
}

PlainButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  children: PropTypes.string.isRequired,
}

BlueSquareButton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

BlueSquareOulinedButton.prototype = {
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

BlueSquareOulinedSmallButton.prototype ={
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

BlueSquareSmallButton.prototype ={
  isLoading: PropTypes.bool,
  children: PropTypes.string.isRequired,
}



export default Button
export { RoudedButton, PlainButton, BlueSquareButton, BlueSquareOulinedButton, BlueSquareOulinedSmallButton, BlueSquareSmallButton }
