/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";

import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { Form, Modal, Result } from "antd";
import logo from "../../common/images/logo_azul.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  createAccountSelector,
  createAccountThunk,
  resetCreateAccount,
} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../utils/stateStatus";
import RegiterStep1 from "./pages/step1";
import RegiterStep2 from "./pages/step2";
import Stepper from "../../common/components/steps";
import { BlueSquareButton } from "../../common/components/button";

const NewAccountPage = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();
  const [showModal, updateShowModal] = useState();
  const dispatch = useDispatch();
  const [passValidation, updatePassValidation] = useState({});
  const { page } = useParams();
  const navigate = useNavigate();
  // const [page, setPage] = useState(parseInt(params.page) - 1)

  const createAccountState = useSelector(createAccountSelector);

  const onFinish = async (values) => {

    dispatch(createAccountThunk(values));
  };


  useEffect(() => {
    switch (createAccountState.status.create) {
      case StateStatus.failed:
        updateShowModal("error-sent");
        break;

      case StateStatus.succeeded:
        updateShowModal("success-sent");
        dispatch(resetCreateAccount());
        form.resetFields();
        break;

      default:
        break;
    }
  }, [createAccountState.status.create]);

  return (
    <>
      {/* <Header /> */}
      <div className="access-container">
        <div className="register-left"></div>
        <div className="register-rigth">

          <div className="info-box">
            <img src={logo} alt="Inquest" />

            <div className="auth-page-name">REGISTRAR</div>

            <div className="steps-register">
              <Stepper
                current={parseInt(page) - 1}
                items={[
                  {
                    title: "Dados de acesso",
                    description: null,
                    subTitle: null,
                  },
                  {
                    title: "Dados cadastrais",
                    description: null,
                    subTitle: null,
                  },
                ]}
              />
            </div>
          </div>

          <div className="new-account-form">
            {
              page === "1" ? <RegiterStep1
                data={formData}
                onFinish={(values) => {
                  const phone = values.phone.replace(/\D/g, "");
                  values.phone_area_code = phone?.slice(0, 2);
                  values.phone_number = phone?.slice(2);
                  values.phone_country_code = "55"
                  delete values.phone_prefix;
                  delete values.phone;
                  delete values.confirm;

                  setFormData({ ...(formData || {}), ...values })
                  navigate("/nova-conta/2")

                }}
                passValidation={passValidation}
                updatePassValidation={updatePassValidation}
              /> : <RegiterStep2
                data={formData}
                onFinish={(values) => {
                  values.address.complement = values.address.complement?.length
                    ? values.address.complement
                    : null;
                  values.cpf_cnpj = values.cpf_cnpj.replace(/[.\-/]/g, "");
                  values.address.cep = values.address.cep.replace(/[.\-/]/g, "");

                  const data = { ...(formData || {}), ...values }
                  setFormData(data)
                  onFinish(data)
                }}
                loading={createAccountState.status.create === StateStatus.loading}
              />
            }
            {/* <Routes>
              <Route
                path="/1?"
                element={

                }
              />
              <Route
                path="/2"
                element={

                }
              />
            </Routes> */}
          </div>
        </div>
      </div>

      <Modal
        open={showModal === "success-sent"}
        footer={null}
        onCancel={() => updateShowModal(null)}
      >
        <Result
          status="success"
          title="Cadastro realizado!"
          subTitle={
            <>
              Clique abaixo para acessar a plataforma e começar a realizar suas
              buscas.
            </>
          }
          extra={[
            <BlueSquareButton
              className={"btn-blue"}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Acessar
            </BlueSquareButton>,
          ]}
        />
      </Modal>

      <Modal
        open={showModal === "error-sent"}
        footer={null}
        onCancel={() => updateShowModal(null)}
      >
        <Result
          status="error"
          title="Falha ao criar o cadastro!"
          subTitle={
            <>
              {createAccountState.errMessage ?
                createAccountState.errMessage === "Usuário com mesmo e-mail já existe" ? <div>Usuário com mesmo e-mail já existe: <a style={{textDecoration: "underline", fontWeight: "bold"}} href="#/esqueci-minha-senha" >Clique aqui para recuperar sua senha.</a></div> : createAccountState.errMessage :
                "Não foi possível criar o cadatro no momento. Favor tente novamente mais tarde."}
            </>
          }
          extra={[
            <BlueSquareButton
              className={"btn-blue"}
              onClick={() => {
                updateShowModal(null);
              }}
            >
              Fechar
            </BlueSquareButton>,
          ]}
        />
      </Modal>
    </>
  );
};

// NewAccountPage.propTypes = {};

export default NewAccountPage;
