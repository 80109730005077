import React from "react"
import { AppRoutes } from "./routes"

function App() {
  return (
      <div className="app">
        <AppRoutes />
      </div>
  )
}

export default App
