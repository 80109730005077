/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//
import { Form, Row, Col } from "antd";
// import MaskInput from "../../../../../../common/components/mask-input";
import MaskedInput from "../../../../../../common/components/masked-input";
//
import { cnpj, cpf } from "cpf-cnpj-validator";
import "./index.scss";
import Icons from "../../../../../../common/components/Icons";
import { BlueSquareOulinedSmallButton } from "../../../../../../common/components/button";
// import InputNumber from "../../../../../../common/components/input-number";
import Input from "../../../../../../common/components/input";
import { Select as AntSelect } from "antd";
import {
  citiesByProvince,
  provinceOptions,
} from "../../../../../../utils/constants";

const InputDocsList = ({
  service,
  states,
  excludeStates,
  initialValue,
  form,
  name,
  label,
}) => {
  const [cities, setCities] = useState({});

  const SelectWrapper = ({ index, ...props }) => {
    const change = (value) => {
      const citiesLocal = citiesByProvince.filter(
        (province) => province.sigla === value
      )[0].cidades;
      const tempCitites = {};
      tempCitites[index] = citiesLocal.map((value) => {
        return { label: value, value };
      });
      setCities({ ...cities, ...tempCitites });
      props.onChange(value);
    };

    useEffect(() => {
      // define initial value
      if (props.value && cities[index] === undefined) {
        change(props.value);
      }
    }, []);

    const provinces =
      excludeStates?.length > 0
        ? provinceOptions.filter((x) => !excludeStates.includes(x.value))
        : states?.length > 0
        ? provinceOptions.filter((x) => states.includes(x.value))
        : provinceOptions;

    return (
      <AntSelect
        placeholder={"UF"}
        className="select-search-type"
        options={provinces}
        {...props}
        onChange={change}
      />
    );
  };

  return (
    <div className="input-docs-list">
      {label}

      <Form.List
        name={name}
        label={label}
        initialValue={
          initialValue?.length > 0
            ? initialValue
            : [
                {
                  cpf_cnpj: null,
                  uf: null,
                  city: null,
                  livro: null,
                  num_matricula: null,
                  qtd: null,
                },
              ]
        }
        rules={[
          () => ({
            validator(_, values) {
              if (
                values === null ||
                values === undefined ||
                values === "undefined" ||
                values.length < 1
              ) {
                return Promise.reject(
                  new Error("Adicione pessoas a sua pesquisa")
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <Row
                    className={`fields-edit ${index > 0 ? "border" : ""}`}
                    justify="space-between"
                    align={"top"}
                    gutter={[16, 16]}
                    key={field}
                  >
                    <Col xs={24} md={3} lg={3}>
                      <Form.Item
                        label="Estado"
                        key="uf"
                        name={[index, "uf"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <div className="normal-12">Campo obrigatório</div>
                            ),
                          },
                        ]}
                      >
                        <SelectWrapper index={index} />
                      </Form.Item>
                    </Col>
                    {!["pesquisa_imobiliaria_estadual"].includes(service) ? (
                      <>
                        <Col xs={24} md={6} lg={6}>
                          <Form.Item
                            label="Cidade"
                            key="city"
                            name={[index, "city"]}
                            rules={[
                              {
                                required: true,
                                message: (
                                  <div className="normal-12">
                                    Campo obrigatório
                                  </div>
                                ),
                              },
                            ]}
                          >
                            <AntSelect
                              placeholder="Cidade"
                              className="select-search-type"
                              disabled={cities[index] === undefined}
                              options={cities[index] || []}
                            />
                          </Form.Item>
                        </Col>{" "}
                      </>
                    ) : null}

                    {service === "aquisicao_matricula" ? (
                      <>
                        <Col xs={24} md={6} lg={6}>
                          <Form.Item
                            label="Nome do Cartório"
                            key="livro"
                            name={[index, "livro"]}
                            rules={[
                              {
                                required: true,
                                message: (
                                  <div className="normal-12">
                                    Campo obrigatório
                                  </div>
                                ),
                              },
                            ]}
                          >
                            <Input placeholder="Cartório" />
                          </Form.Item>
                        </Col>{" "}
                        <Col xs={24} md={6} lg={6}>
                          <Form.Item
                            label="Matrícula"
                            key="num_matricula"
                            name={[index, "num_matricula"]}
                            rules={[
                              {
                                required: true,
                                message: (
                                  <div className="normal-12">
                                    Campo obrigatório
                                  </div>
                                ),
                              },
                            ]}
                          >
                            <Input placeholder="Matrícula" />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}

                    {!["aquisicao_matricula"].includes(service) ? (
                      <Col xs={24} md={8} lg={8}>
                        <Form.Item
                          label="CPF ou CNPJ"
                          key="cpf_cnpj"
                          name={[index, "cpf_cnpj"]}
                          initialValue=""
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value)
                                  return Promise.reject(
                                    new Error("Informe um CPF ou CNPJ")
                                  );

                                const val = value.replace(/[^0-9]+/g, "");

                                if (val.length > 11) {
                                  if (cnpj.isValid(val))
                                    return Promise.resolve();

                                  return Promise.reject(
                                    new Error("Informe um  CNPJ inválido")
                                  );
                                }

                                if (!cpf.isValid(val))
                                  return Promise.reject(
                                    new Error("Informe um CPF inválido")
                                  );

                                // const values = form?.getFieldValue(name) || [];
                                // const cpfCnpjs = Array.from(
                                //   values
                                //     .filter((x) => !!x)
                                //     .map((x) =>
                                //       [x.cpf_cnpj.replace(/[^0-9]+/g, ""), x.city, x.uf]
                                //     )
                                // );

                                // const data = form?.getFieldValue(name)[index];
                                // const validations = cpfCnpjs.filter((cpfCnpj) => (
                                //   cpfCnpj[0] === data.cpf_cnpj?.replace(/[^0-9]+/g, "") 
                                //   && cpfCnpj[1] === data.city 
                                //   && cpfCnpj[2] === data.uf
                                // ))

                                // if (validations.length >= 2) {
                                //   setTimeout(() => form.setFieldValue(
                                //     [name, index], 
                                //     {cpf_cnpj: null, city: data.city, uf: data.uf}
                                //   ), 10)

                                //   return Promise.reject(
                                //     new Error(
                                //       `${
                                //         val.length > 11 ? "CNPJ" : "CPF"
                                //       } duplicado na mesma localidade`
                                //     )
                                //   );
                                // }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <MaskedInput
                            // initialValue={null}
                            formats={[
                              {
                                condition: (value) => value.length <= 11,
                                format: "###.###.###-##",
                              },
                              {
                                condition: (value) => value.length > 11,
                                format: "##.###.###/####-##",
                              },
                            ]}
                            placeholder="Insira o CPF ou CNPJ a ser pesquisado"
                            mask="_"
                          />
                        </Form.Item>
                      </Col>
                    ) : null}

                    {/* {![
                      "aquisicao_matricula",
                      "pesquisa_imobiliaria_estadual",
                    ].includes(service) ? (
                      <Col xs={24} md={2} lg={2}>
                        <Form.Item
                          label="Qtd."
                          name={[index, "qtd"]}
                          className="input-docs-qtd"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (
                                  value === undefined ||
                                  value === null ||
                                  value < 1
                                ) {
                                  return Promise.reject(
                                    new Error("Informe um número válido")
                                  );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            placeholder="Qtd."
                            // parser={(value) => value.replace(/\D/g, "")}
                          />
                        </Form.Item>
                      </Col>
                    ) : null} */}
                    <Col xs={24} md={2} lg={2}>
                      {fields.length > 1 && (
                        <Icons
                          icon="delete"
                          onClick={() => {
                            // form && form.validateFields();
                            remove(index);
                          }}
                          className="button-remove"
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}

              <Form.Item>
                <BlueSquareOulinedSmallButton onClick={() => add()}>
                  Incluir outra aquisição
                </BlueSquareOulinedSmallButton>
                {/* <PlainButton
                  icon={<Icons icon="plus" />}
                  type="button"
                  className={"button-add"}
                  onClick={() => add()}
                  color={"secondary"}
                >
                  Incluir outra aquisição
                </PlainButton> */}
              </Form.Item>

              <Form.ErrorList className="list-error" errors={errors} />

              <br />

              {/* {fields.length > 0 && service && service === "3" && (
                <div className="price">
                  Para <b>{fields.length}</b> pessoa(s), você paga{" "}
                  <b>
                    {prices[
                      fields.length >= 4 ? 4 : fields.length
                    ].toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>{" "}
                  por pessoa. Totalizando em{" "}
                  <b>
                    {(
                      fields.length *
                      prices[fields.length >= 4 ? 4 : fields.length]
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </b>
                  .
                </div>
              )} */}
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

InputDocsList.defaultProps = {
  initialValue: [],
  service: null,
  form: null,
};

InputDocsList.propTypes = {
  service: PropTypes.string,
  states: PropTypes.array,
  excludeStates: PropTypes.array,
  initialValue: PropTypes.any,
  form: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default InputDocsList;
