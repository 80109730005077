import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { listPurchasedProducts } from "./service";
import StateStatus from "../../../../utils/stateStatus";


export const listPurchasedProductsThunk = createAsyncThunk('purchasedProducts/list', async (data) => {
    return listPurchasedProducts(data)
})

const initialState = {
    status: {
        listPurchasedProducts: StateStatus.idle,
    },
    data: {
        orders: [],
    },
    errMessage: null
}

export const purchasedProductsSlice = createSlice({
    name: 'purchasedProductsSlice',
    initialState,
    extraReducers: {
        // purchasedProductsThunk
        [listPurchasedProductsThunk.pending]: (state) => {
            state.status.listPurchasedProducts = StateStatus.loading
        },
        [listPurchasedProductsThunk.fulfilled]: (state, action) => {
            state.status.listPurchasedProducts = StateStatus.succeeded
            state.data.orders = action.payload.data.orders
        },
        [listPurchasedProductsThunk.rejected]: (state, action) => {
            state.status.listPurchasedProducts = StateStatus.failed
            state.errMessage = action.error.message
        },

    }
})

export const selectPurchasedProductsState = (state) => state.purchasedProducts