import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changePassword } from "./service";
import StateStatus from "../../utils/stateStatus";


export const changePasswordThunk = createAsyncThunk(
    "auth/change-password",
    async (data) => {
        const response = await changePassword(data)

        localStorage.setItem("access_token", response.data.access_token)
        localStorage.setItem("refresh_token", response.data.refresh_token)

        return response.data
    }
)

const initialState = {
    status: {
        changePassword: StateStatus.idle
    },
    token: null,
    errMessage: null
}

export const changePasswordSlice = createSlice({
    name: "change",
    initialState,
    reducers: {
        resetChangePasswordState: (state, action) => {
            state.status.changePassword = StateStatus.idle
        },
    },
    extraReducers: {
        [changePasswordThunk.pending]: (state) => {
            state.status.changePassword = StateStatus.loading
        },
        [changePasswordThunk.rejected]: (state, action) => {
            state.status.changePassword = StateStatus.failed
            state.errMessage = action.error.message;
        },
        [changePasswordThunk.fulfilled]: (state, action) => {
            state.status.changePassword = StateStatus.succeeded
        },
    }
})

export const { resetChangePasswordState } = changePasswordSlice.actions
export const changePasswordSelector = (state) => state.changePassword