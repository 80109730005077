import React from "react"

export const WhatsappIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.47953 0.320364C1.55612 1.98319 -1.69251 8.41772 0.930221 13.2932L1.41603 14.1968L0.675406 16.5662C0.268065 17.8695 -0.0343981 18.9634 0.00315077 18.9971C0.0404407 19.0308 1.26039 18.7782 2.71418 18.4361L5.35685 17.8141L7.0349 18.3461C9.34713 19.0792 12.812 18.9676 14.9153 18.0916C17.1135 17.1763 18.8905 15.5882 20.0255 13.5246C20.9578 11.8297 21.0008 11.6485 21 9.41416C20.9995 7.28381 20.9285 6.94236 20.1967 5.55423C18.8115 2.9266 16.8771 1.35518 13.931 0.463643C12.0913 -0.0929586 9.18192 -0.15762 7.47953 0.320364ZM14.3582 2.2837C17.3399 3.66904 19.0736 6.05338 19.258 9.02177C19.3986 11.2882 18.7465 12.9587 17.0656 14.638C14.416 17.285 10.036 18.0586 6.60296 16.4855C5.70696 16.075 5.57412 16.0715 4.19387 16.4241C3.39058 16.6295 2.67793 16.7477 2.61008 16.6867C2.54249 16.626 2.67042 16.0215 2.89468 15.3433C3.29218 14.141 3.28622 14.0775 2.65954 12.8033C1.74775 10.9498 1.57684 9.41067 2.08491 7.62945C2.78125 5.18743 4.22106 3.53763 6.62704 2.42442C8.14583 1.72152 8.74506 1.62197 11.0309 1.69245C12.7009 1.74385 13.5037 1.88666 14.3582 2.2837ZM6.03946 5.50748C5.3035 6.23783 5.08261 7.08285 5.37731 8.04138C6.24093 10.8507 10.5049 13.9386 13.5205 13.9386C14.1933 13.9386 14.6433 13.7532 15.2123 13.2425C16.3165 12.2507 16.1399 11.6282 14.5913 11.053L13.3607 10.596L12.6136 11.2389L11.8665 11.8818L10.8662 11.4196C9.95206 10.9974 9.10268 10.299 8.20358 9.22971C7.91743 8.88942 7.92986 8.73103 8.28126 8.24932C8.82689 7.50129 8.8098 7.13495 8.17716 6.02105C7.59373 4.99391 6.75885 4.79341 6.03946 5.50748Z"
          fill="#0038D1"
        />
      </svg>
    </span>
  )
}
