import React from "react"
// 
import InputNumber from "../input-number"
// 
import "./index.scss"

const InputCurrency = (props) => {
    const formatCurrency = (value) => {
      value = value?.toString() ?? ""
      if (value.length === 0) return ""
      if (value.length <= 2) return `R$ 0,${value}`
  
      const prefixValue = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      return `R$ ${prefixValue},${value.slice(-2)}`
    }
  
    return <InputNumber
      formatter={formatCurrency}
      parser={(value) => value.replace(/\D/g, "")}
      {...props}
    />
}

export default InputCurrency