/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Graph from "react-graph-vis";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import StateStatus from '../../utils/stateStatus';
import { getGraphThunk, resetGraphState, selectGraphState } from './slices';
import { getReportThunk, selectReportState } from '../report_page/slices'
import { Button, message, Spin } from 'antd';
// import back from "../../common/images/back.svg"
import "./styles.scss"
import logo from "../../common/images/shortinquestazul.png";
import { companyPath } from '../../common/components/Icons/components/CircleCompanyIcon';
import { personPath } from '../../common/components/Icons/components/CirclePersonIcon';
import { processPath } from '../../common/components/Icons/components/CircleProcessIcon';
import Icons from '../../common/components/Icons';


const iconMap = {
    Person: new Path2D(personPath),
    Company: new Path2D(companyPath),
    Process: new Path2D(processPath),
}

const colorMap = {
    Person: "#074a83",
    Company: "#bd1287",
    Process: "#a0a0a0"
}


const chooseColor = (isTarget, isSelected, nodeType) => {
    return isTarget ? isSelected ? "#000000" :"#ff5705": isSelected ? "#000000":  colorMap[nodeType]

}


const GraphPage = () => {

    const dispatch = useDispatch()
    const graphState = useSelector(selectGraphState)
    const reportState = useSelector(selectReportState)
    const params = useParams()
    const navigate = useNavigate()
    const [network, setNetwork] = useState(null)
    const [isIncompleteReport, setIsIncompleteReport] = useState(false)
    const [isLoadingReport, setIsLoadingReport] = useState(false)

    useEffect(() => {
        if (reportState.status.getReport === StateStatus.idle 
                || !reportState.data.report?.targets 
                || reportState.data.report?.targets.length === 0) {
            setNetwork(null)
            dispatch(resetGraphState())
            dispatch(getReportThunk(params.reportId))
        }
    }, [params.reportId])


    useEffect(() => {
        if(isLoadingReport) {
            message.info(`Aguarde o caso estar completo para visualizar o grafo com todos os pesquisados.`)
        }
    }, [isLoadingReport])


    useEffect(() => {
        if(reportState.status.getReport === StateStatus.succeeded) {
            if (Object.keys(graphState.status.getGraph).length === 0) {
                let countIncompleteTargets = 0
                setIsIncompleteReport(false)
                reportState.data.report?.targets?.forEach((item) => {
                    if(item.can_render_graph) {
                        dispatch(getGraphThunk(item.id))
                    } else {
                        countIncompleteTargets += 1
                    }
                })

                if(countIncompleteTargets === reportState.data.report?.targets?.length) {
                    setIsIncompleteReport(true)
                } else if(countIncompleteTargets > 0) {
                    setIsLoadingReport(true)
                }
            }
        }
    }, [graphState.status.getGraph, params.reportId, reportState.data, reportState.status.getReport])

    useEffect(() => {
        if (graphState.status.getAllGraph === StateStatus.succeeded && network) {            
            network.body.data.nodes.add(graphState.graph.nodes.map(x => {
                return {...x}
            }))
            network.body.data.edges.add(graphState.graph.edges)
        }
    }, [graphState.status.getAllGraph, network, graphState])


    function ctxRenderer({ ctx, id, x, y, state: { selected, hover }, style, label }) {

        const drawNode = () => {
            const nodeData = graphState.graph.nodeLibrary[id]
            // personObject.moveTo(x,y)
            // ctx.fillStyle = "white"
            // ctx.fillRect(x -30, y -30, 52, 52)
            ctx.fillStyle = selected ? "black" : "#666666ff"
            const textDimensions = ctx.measureText(label);

            ctx.fillText(label, x - (textDimensions.width / 2), y + 35);
            ctx.translate(x -30, y -30)
            ctx.fillStyle = chooseColor(nodeData.is_target, selected, nodeData.type)
            const path = iconMap[nodeData.type]
            ctx.fill(path)
            ctx.font = "12px Poppins";
        }

        return {
            drawNode,
            nodeDimensions: { width: style.size, height: style.size }
        }
    }


    const options = {
        layout: {
            hierarchical: false
        },
        // height: window.height,
        edges: {
            color: "#000000",
            physics: false
        },
        // height: "500px",
        nodes: {
            shape: "custom",
            ctxRenderer,
            font: {
                size: 14,
                face: 'Poppins',
                align: 'bottom'

            }
            // ctxRenderer: ctxRenderer
        },
        physics: {
            solver: 'repulsion',
            enabled: true,
            repulsion: {
                centralGravity: 0
            },

        }
    };

    return (
        <div className="graph-page">
            <div className="graph-nav">
                <Button
                    className="outlined-button"
                    onClick={() => {
                        navigate(-1)
                        // dispatch(resetGraphState())
                    }}
                    icon={<Icons icon="left-arrow-circle"/>}
                >
                    Voltar para relatório
                </Button>
                <div className="logo-graph">
                    <img id="logo" alt="" className="logo" src={logo} />
                    <span> | </span>
                    <span>Grafos</span>
                </div>
            </div>
            {
                graphState.status.getAllGraph === StateStatus.loading ? <div className="graph-loading">
                    <Spin size='large' />
                </div> : null
            }
            {
                graphState.status.getAllGraph === StateStatus.failed ? <div className="graph-loading">
                    {graphState.errMessage.getGraph}
                </div> : null
            }

            {isIncompleteReport && (
                <div className="graph-msg">
                    <div>
                        <Icons icon="circle-alert" />
                        Aguarde o caso estar completo 
                        para visualizar o grafo.
                    </div>
                </div>
            )}

            <Graph options={options} graph={{ nodes: [], edges: [] }} getNetwork={(n) => setNetwork(n)} />
        </div>
    )
}

export default GraphPage