import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createClient} from "./service";
import StateStatus from "../../../../../utils/stateStatus";

export const createClientThunk = createAsyncThunk('client/create', async (values) => {
    return await createClient(values)
})

const initialState = {
    status: {
        create: StateStatus.idle
    },
    data: {
        client: null
    },
    errMessage: null,
}

export const newClientSlice = createSlice({
    name: 'newClient',
    initialState,
    reducers: {
        resetNewClient: (state) => {
            state.status.create = StateStatus.idle
        }
    },
    extraReducers: {
        [createClientThunk.pending]: (state) => {
            state.status.create = StateStatus.loading
        },
        [createClientThunk.rejected]: (state, action) => {
            state.status.create = StateStatus.failed
            state.errMessage = action.error.message
        },
        [createClientThunk.fulfilled]: (state, action) => {
            state.status.create = StateStatus.succeeded
            state.data.client = action.payload.data
        },
    }
})

export const {resetNewClient} = newClientSlice.actions
export const selectNewClientState = (state) => state.newClient