import axios from "axios"

export const createCreditCardToken = async ({
    cardNumber,
    cardName,
    validity,
    cvv,
  }) => {

    let monthExpiration = validity.slice(0, 2)
    let yearExpiration = null

    if (validity.includes("/")) {
        yearExpiration = validity.slice(3)
    } else {
        yearExpiration = validity.slice(2)
    }
    
    const data = {
        type: "card",
        card: {
            holder_name: cardName,
            number: cardNumber,
            exp_month: monthExpiration,
            exp_year: yearExpiration, 
            cvv,
            label: "Meu cartão"
        },
    }

    

    const apikey = process.env.REACT_APP_PAGARME_PUBLIC_KEY
    const response = await axios.post(`https://api.pagar.me/core/v5/tokens?appId=${apikey}`, data,  {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
    })

    const cardToken = response.data["id"]

    return cardToken
}