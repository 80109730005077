import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { makeLogin, getProfile, makeLoginWithToken } from "./service"


export const loginThunk = createAsyncThunk("auth/login", async (data) => {
    const response = await makeLogin({
        username: data.email,
        password: data.password,
    })

    localStorage.setItem("access_token", response.data.access_token)
    localStorage.setItem("refresh_token", response.data.refresh_token)

    return response.data
})

export const loginWithTokenThunk = createAsyncThunk("auth/login-token", async (data) => {
    const response = await makeLoginWithToken({
        token: data.token,
    })

    localStorage.setItem("access_token", response.data.access_token)
    localStorage.setItem("refresh_token", response.data.refresh_token)

    return response.data
})

export const getProfileThunk = createAsyncThunk("auth/get-profile", async (data) => {
    const response = await getProfile()
    return response.data
})


export const logoutThunk = createAsyncThunk("auth/logout", async () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    return true
})

export const isAuthenticated = () => {
    const accessToken = localStorage.getItem("access_token")
    return accessToken !== null
}

const initialState = {
    errMessage: null,
    notRegistered: null,
    status: {
        login: StateStatus.idle,
        loginWithToken: StateStatus.idle,
        logout: StateStatus.idle,
        getProfile: StateStatus.idle,
    },
    data: {
        profile: null,
        client:  null,
    }
}

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        resetLoginState: (state) => {
            state.status.login = StateStatus.idle
            state.status.loginWithToken = StateStatus.idle
        },
        resetGetProfileState: (state) => {
            state.status.getProfile = StateStatus.idle
        },
    },
    extraReducers: {
        [loginThunk.pending]: (state) => {
            state.status.login = StateStatus.loading
        },
        [loginThunk.fulfilled]: (state, action) => {
            state.status.login = StateStatus.succeeded
            state.data.profile = action.payload.profile
            state.data.client = action.payload.client
        },
        [loginThunk.rejected]: (state, action) => {
            state.status.login = StateStatus.failed
            state.errMessage = action.error.message
        },

        [loginWithTokenThunk.pending]: (state) => {
            state.status.loginWithToken = StateStatus.loading
        },
        [loginWithTokenThunk.fulfilled]: (state, action) => {
            state.status.loginWithToken = StateStatus.succeeded
            state.data.profile = action.payload.profile
            state.data.client = action.payload.client
        },
        [loginWithTokenThunk.rejected]: (state, action) => {
            state.status.loginWithToken = StateStatus.failed
            state.errMessage = action.error.message
        },
        
        [getProfileThunk.pending]: (state) => {
            state.status.getProfile = StateStatus.loading
        },
        [getProfileThunk.fulfilled]: (state, action) => {
            state.status.getProfile = StateStatus.succeeded
            state.data.profile = action.payload.profile
            state.data.client = action.payload.client
        },
        [getProfileThunk.rejected]: (state, action) => {
            state.status.getProfile = StateStatus.failed
            state.errMessage = action.error.message
        },
        
        [logoutThunk.pending]: (state) => {
            state.status.logout = StateStatus.loading
        },
        [logoutThunk.fulfilled]: (state) => {
            state.status.logout = StateStatus.succeeded
            state.data.profile = null
            state.data.client = null
        },
        [logoutThunk.rejected]: (state, action) => {
            state.status.logout = StateStatus.failed
            state.errMessage = action.error.message
        },
    },
})

export const { resetLoginState, resetGetProfileState } = loginSlice.actions

export const loginSelector = (state) => state.login