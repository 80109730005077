import React from "react";
import PropTypes from "prop-types";
//
import { Form } from "antd";
import Input from "../../../../../../common/components/input";
import MaskedInput from "../../../../../../common/components/masked-input";
//
import { cnpj, cpf } from "cpf-cnpj-validator";
import "./index.scss";

const FormDefendantList = ({ initialValue, context, rules }) => {
  return (
    <div className="form-list">
      <p className="form-list-title">
        Inserir aqui os CPFs ou CNPJs dos Réus/Executados
      </p>

      <Form.List
        name={[context, "defendants"]}
        initialValue={initialValue}
        rules={[
          ...rules,
          //     () => ({
          //         validator(_, values) {
          //             if (values === null || values === undefined || values === "undefined" || values.length < 1) {
          //                 return Promise.reject(new Error("Adicione os réus"))
          //             }
          //             return Promise.resolve()
          //         },
          //     }),
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div
                    className={`fields-edit ${index > 0 ? "border" : ""}`}
                    key={field.key}
                  >
                    <Form.Item
                      label="CPF ou CNPJ *"
                      key={field.key}
                      name={[index, "cpf_cnpj"]}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value)
                              return Promise.reject(
                                new Error("Informe um CPF ou CNPJ")
                              );

                            const val = value.replace(/[^0-9]+/g, "");

                            if (val.length > 11) {
                              if (cnpj.isValid(val)) return Promise.resolve();

                              return Promise.reject(
                                new Error("Informe um  CNPJ inválido")
                              );
                            }

                            if (!cpf.isValid(val))
                              return Promise.reject(
                                new Error("Informe um CPF inválido")
                              );

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <MaskedInput
                        initialValue={
                          initialValue[index]
                            ? initialValue[index]["cpf_cnpj"]
                            : null
                        }
                        formats={[
                          {
                            condition: (value) => value.length <= 11,
                            format: "###.###.###-##",
                          },
                          {
                            condition: (value) => value.length > 11,
                            format: "##.###.###/####-##",
                          },
                        ]}
                        placeholder="Insira o CPF ou CNPJ"
                        mask="_"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Data da citação do réu"
                      key="date"
                      name={[index, "citation_date"]}
                    >
                      <MaskedInput
                        initialValue={
                          initialValue[index]
                            ? initialValue[index]["date"]
                            : null
                        }
                        formats={[
                          {
                            condition: () => true,
                            format: "##/##/####",
                          },
                        ]}
                        placeholder="Insira a data de citação"
                        mask="_"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Nome *"
                      key="name"
                      name={[index, "name"]}
                      rules={[
                        {
                          required: true,
                          message: (
                            <div className="normal-12">Informe um nome</div>
                          ),
                        },
                      ]}
                    >
                      <Input placeholder="Insira o nome" />
                    </Form.Item>

                    {fields.length >= 1 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="button-remove"
                      >
                        Remover
                      </button>
                    )}
                  </div>
                );
              })}

              <Form.Item>
                <button
                  type="button"
                  onClick={() => add()}
                  className="button-add"
                >
                  Adicionar
                </button>
              </Form.Item>

              <Form.ErrorList className="list-error" errors={errors} />
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

FormDefendantList.defaultProps = {
  initialValue: [],
  rules: [],
};

FormDefendantList.propTypes = {
  initialValue: PropTypes.any,
  rules: PropTypes.array,
  context: PropTypes.string,
};

export default FormDefendantList;
