import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listClients, editClient} from "./service";
import StateStatus from "../../../../utils/stateStatus";

export const getListClientsThunk = createAsyncThunk('client/clients', async ({page, pageSize, filter}) => {
    const response = await listClients({
        pagination: pageSize ?? 15,
        page,
        filter,
    })

    return {
        pageSize: pageSize ?? 15, 
        filter, 
        ...response.data,
    }
})

export const editClientThunk = createAsyncThunk('purchaseCart/editClient', async (data) => {
    const id = data.id
    delete data.id
    return editClient(id, data)
})

const initialState = {
    status: {
        listClients: StateStatus.idle,
        editClient: StateStatus.idle,
    },
    data: {
        clients: null,
        pagination: {
            page: 0,
            totalCount: 0,
            totalPages: 1,
        },
        filters: {
            filter: "",
        }
    },
    errMessage: null
}

export const listClientsSlice = createSlice({
    name: 'listClients',
    initialState,
    reducers: {
        resetListClientsCase: (state, action) => {
            state.status.listClients = StateStatus.idle
        }
    },
    extraReducers: {

        [getListClientsThunk.pending]: (state) => {
            state.status.listClients = StateStatus.loading
        },
        [getListClientsThunk.fulfilled]: (state, action) => {
            state.status.listClients = StateStatus.succeeded
            state.data.pagination.totalCount = action.payload.pagination.total_count
            state.data.pagination.totalPages = action.payload.pagination.total_pages
            state.data.pagination.pageSize = action.payload.pageSize
            state.data.pagination.page = action.payload.pagination.current_page
            state.data.filters.filter = action.payload.filter
            state.data.clients = action.payload.items
        },
        [getListClientsThunk.rejected]: (state, action) => {
            state.status.listClients = StateStatus.failed
            state.errMessage = action.error.message
        },

        // editClientThunk
        [editClientThunk.pending]: (state) => {
            state.status.editClient = StateStatus.loading
        },
        [editClientThunk.fulfilled]: (state, action) => {
            state.status.editClient = StateStatus.succeeded
        },
        [editClientThunk.rejected]: (state, action) => {
            state.status.editClient = StateStatus.failed
            state.errMessage = action.error.message
        },
    }
})

export const {resetListClientsCase} = listClientsSlice.actions
export const selectListClientsState = (state) => state.listClients