export const beautifyCpfCnpj = (value) => {
  if (!value) return ""

  const final = value.replace(/[.\-/]/g, "")

  if (final.length < 12) {
    return cpfMask(value)
  } else {
    return cnpjMask(value)
  }
}

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const formatCurrency = (value) => {
  value = value?.toString() ?? ""
  if (value.length === 0) return ""
  if (value.length <= 2) return `R$ 0,${value}`

  const prefixValue = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return `R$ ${prefixValue},${value.slice(-2)}`
}
