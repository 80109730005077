import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip } from "antd";
import React from "react";
import Icons from "../../../../common/components/Icons";
import { urlToNewTabLink } from "../../../../utils/files";
import IconStatus from "../status_icon";

const RowFileReport = ({
  filename,
  file,
  isRebuildLoading,
  isDeleteLoading,
  onClickRebuild,
  onClickDelete,
  ...props
}) => {
  return (
    <Row
      className="row-file-info"
      gutter={[16, 16]}
      align={"middle"}
      justify={"space-between"}
      {...props}
    >
      <Col span={24} className="infos-file">
        <Row justify="start">
          <Col>
            <span className={`status-icon status-${file?.status}`}>
                {<IconStatus status={file?.status} />}
              </span>
          </Col>
          <Col >
            <div className="file-type">{file?.type_display}</div>
            <div className="file-created-at">
              {file?.created_at ? `Atualiado em ${file.updated_at ?? file.created_at}` : "Arquivo não foi criado"}
            </div>
            {file?.id ? (
              <div className="file-created-at">ID: {file?.id}</div>
            ) : null}
            <div className="file-created-at">
              {file.status === "error" ? (
                <div className="file-error-msg" span={3}>
                  {file.error_message?.length > 300 ? file.error_message.slice(0, 300) : file.error_message}
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={16} className="filename">
        <Tooltip title={""}>
          {file.type === "pdf" ? (
            <Icons icon="pdf-file" />
          ) : file.type === "xlsx" ? (
            <Icons icon="excel-file" />
          ) : file.type === "zip_pdf" ? (
            <Icons icon="zip-file" />
          ) : null}{" "}
          {!file?.created_at ? "Não criado" : (filename ?? "")}
        </Tooltip>
      </Col>
      <Col span={8}>
        <Row gutter={[8, 8]}>
          <Col>
            <Tooltip title={"Baixar"}>
              <Button
                icon={<Icons icon="down-arrow" />}
                size={5}
                disabled={file.status !== "completed"}
                onClick={() => {
                  const link = urlToNewTabLink(file.link, filename);
                  link.click();
                }}
              />
            </Tooltip>
          </Col>
          <Col>
            {(file.type.includes("xlsx") || file.type.includes("pdf")) &&
            !file.type.includes("part") ? (
              <Tooltip title={file.status === "idle" ? "Criar" : "Recriar"}>
                <Button
                  icon={<SyncOutlined />}
                  disabled={file.status === "loading"}
                  loading={isRebuildLoading || file.status === "loading"}
                  onClick={onClickRebuild}
                />
              </Tooltip>
            ) : null}
          </Col>
          <Col>
            {file?.id && onClickDelete ? (
              <Tooltip title={"Remover"}>
                <Button
                  icon={<DeleteOutlined />}
                  loading={isDeleteLoading}
                  onClick={onClickDelete}
                />
              </Tooltip>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RowFileReport;
